import { Button, Modal, UploadFile } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { RcFile } from 'antd/es/upload'
import Dragger from 'antd/es/upload/Dragger'
import { message } from 'antd/lib'
import { FC, useContext, useEffect, useMemo, useState } from 'react'
import { useAPI } from '../../../api'
import { resourceUrl } from '../../../global-vars'
import { ReactComponent as Spinner } from '../../../res/spin.svg'
import { ReactComponent as Warning } from '../../../res/warning.svg'

interface IProps {
  uploadType?: string
  onClose?: () => void
  onUploadSuccess?: (resource?: any) => void
  showTips?: boolean
  title?: string
  apiPath?: string
}

export const UploadVoiceSplotModal: FC<IProps> = (props) => {
  const { uploadType, onClose, onUploadSuccess, showTips, title, apiPath } = props
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [resource, setResource] = useState<any>()
  const [splitText, setSplitText] = useState('')
  const urlSource = useContext(resourceUrl)
  const api = useAPI()

  useEffect(() => {
    setFileList([])
    setResource(undefined)
    setSplitText('')
  }, [uploadType])

  const content = useMemo(() => {
    const uploading = fileList?.filter((f) => f.status === 'uploading').length
    if (resource) {
      return (
        <>
          <audio src={urlSource(resource.key)} controls />
        </>
      )
    }
    if (uploading) {
      return (
        <>
          <Spinner className="spin" />
          <p>音频上传中</p>
        </>
      )
    }
    return (
      <>
        <h4 className="white">将文件拖到此处，或点击上传按钮</h4>
        <p className="white">支持上传 wav/mp3 文件，单个文件大小不大于 300MB</p>
      </>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList, resource, urlSource])

  const uploadResource = async (file: RcFile, tag?: string, options?: any) => {
    const path = apiPath || `/upload`
    const { onError } = options || {}
    try {
      const segs = (file.name || '').split(/\./)
      const { resource, upload_url } =
        (
          await api.post(
            path,
            {
              extension: segs[segs.length - 1],
              tag
            },
            {
              refreshMode: 'disabled'
            } as any
          )
        ).data || {}
      if (!upload_url) {
        throw new Error('failed to upload file')
      }

      await api.put(upload_url.replace(/^http:\/\//, 'https://').replace('-internal', ''), file, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      setResource(resource)
      return resource
    } catch (err: any) {
      onError?.({ err })
      throw err
    }
  }

  const beforeUpload = async (file: RcFile) => {
    const size = file?.size || 0
    if (size / 1000 / 1000 > 300) {
      message.warning('单个文件大小不能大于300MB')
      return false
    }
    return true
  }

  const useVoice = () => {
    onUploadSuccess?.({
      ...resource,
      splitText
    })
    setResource(undefined)
  }

  return (
    <Modal
      className="upload-modal"
      title={title || '上传音频'}
      open={!!uploadType}
      onCancel={onClose}
      footer={
        resource ? (
          <>
            <Button onClick={onClose}>取消</Button>
            <Button type="primary" onClick={useVoice} disabled={!splitText}>
              说话人分离
            </Button>
          </>
        ) : null
      }
    >
      {showTips && (
        <div className="upload-modal-tips">
          <Warning />
          <h4>原文本将会被覆盖</h4>
          <p className="text">
            您当前剧本中已有话术内容，如果选择上传新音频，我们会将您新上传的音频自动识别成文本，您剧本中的原文本会被覆盖
          </p>
        </div>
      )}
      <Dragger
        name="upload"
        showUploadList={false}
        accept=".mp3,.wav"
        customRequest={({ onSuccess, onError, file, onProgress }) =>
          uploadResource(file as RcFile, uploadType, {
            onSuccess,
            onError,
            onProgress
          })
        }
        beforeUpload={async (file) => {
          return await beforeUpload(file)
        }}
        onChange={(info: any) => {
          if (info?.file?.status === 'error') {
            message.error(`${info.file.name} 上传失败`)
          }
          setFileList(info.fileList)
        }}
      >
        {content}
      </Dragger>
      {resource && (
        <>
          <div style={{ position: 'relative', marginTop: 20 }}>
            <TextArea
              style={{ resize: 'none', height: 140 }}
              className="input-with-hint"
              placeholder={
                '请输入切分点, 格式为: 环节名称, 开始时间, 结束时间\n建议在剪映中查看具体时间点(精确到秒), 示例如下: \n第一段欢迎, 0:00:01, 0:00:30\n第二段讲品, 0:00:41, 0:01:59\n第三段塑品, 0:03:01, 0:08:30'
              }
              value={splitText}
              onChange={(e) => {
                setSplitText(e.target.value)
              }}
            />
            <div className="input-hint">
              <span style={{ color: 'red' }}>*</span>切分点
            </div>
          </div>
          <div style={{ marginTop: 10, color: 'red' }}>
            <span>*</span>请确保切分后的每一段音频开头第一句话是主播在说话, 否则主播及助播声音的划分会颠倒
          </div>
        </>
      )}
    </Modal>
  )
}
