import { Button, InputNumber, Popover } from 'antd'
import {
  FC,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react'
import { flushSync } from 'react-dom'
import Moveable, { MoveableManagerInterface, Renderer } from 'react-moveable'
import { map } from 'rxjs'
import { BlockOutlined, CloseOutlined } from '@ant-design/icons'
import { Providing, resourceUrl, targetViewPort } from '../global-vars'
import { debounce } from '../lib/util'
import { useBehaviorSubject, useObservable } from '../react-rx'
import { ReactComponent as HasAlpha } from '../res/has_alpha.svg'
import { ReactComponent as LayersDown } from '../res/layers-down.svg'
import { ReactComponent as LayersUp } from '../res/layers-up.svg'
import { ReactComponent as NewScreen } from '../res/new-screen.svg'
import { ReactComponent as NoAlpha } from '../res/no_alpha.svg'
import { ReactComponent as PicReplace } from '../res/pic-replace.svg'
import { ReactComponent as SoundOff } from '../res/sound-off.svg'
import { ReactComponent as SoundOn } from '../res/sound-on.svg'
import { ReactComponent as Trash } from '../res/trash.svg'
import { Layer, scriptTab } from '../room/states'
import { Render, useForceRender } from '../types'
import { rangeIntersects } from './paragraph-selection'
import { multipleSectionKey, multipleVideos, sections, segments } from './script-card'
import {
  background,
  editingLayer,
  layers,
  layersChange,
  selectedRange,
  selectedRangeChange,
  viewPort,
  w2lVideo
} from './states'

export const LayerPreview: FC = () => {
  const { tw, th } = useContext(targetViewPort)
  const [viewPortSize, setViewPort] = useState<Record<'w' | 'h' | 'tw' | 'th', number> | undefined>()
  const tab = useContext(scriptTab)
  const [editing, setEditing] = useBehaviorSubject(useContext(editingLayer))
  const [bg] = useBehaviorSubject(useContext(background))
  const rangeChange = useContext(selectedRangeChange)
  const layersSubj = useContext(layers)
  const [ls, setLayers] = useBehaviorSubject(layersSubj)
  const url = useContext(resourceUrl)

  const [layerPopContent, setLayerPopContent] = useState<JSX.Element>()
  const editingLayerRef = useRef<IMovableLayerRef>(null)
  const change = useContext(layersChange)
  const currentLayer = ls.find((l) => l.key === editing)

  useMemo(() => {
    setLayerPopContent(
      <div>
        {' '}
        {ls.map((layer) => (
          <img
            className={layer.key === editing ? 'selected' : ''}
            key={layer.key}
            src={url(layer.image_key)}
            alt={layer.key}
            onClick={() => setEditing(layer.key)}
          />
        ))}{' '}
      </div>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ls, editing])

  const onConfigChange = (config: any) => {
    setLayers(
      ls.map((l) => {
        return l.key === editing ? { ...l, ...config } : l
      })
    )
    change.next()
  }

  return (
    <Providing _={(_) => _(viewPort, viewPortSize)}>
      <div className="layer-tools">
        <Popover title="所有图层" content={layerPopContent} placement="bottomRight" overlayClassName="layer-dropdown">
          <Button style={{ float: 'left' }} size="small" icon={<BlockOutlined />} title="所有图层" />
        </Popover>
        <Button
          disabled={!editing}
          size="small"
          icon={<NewScreen />}
          title="居中"
          onClick={() => editingLayerRef.current?.onNewScreen()}
        />
        <Button
          disabled={!editing}
          size="small"
          icon={<LayersUp />}
          title="上移一层"
          onClick={() => editingLayerRef.current?.onLayersUp()}
        />
        <Button
          disabled={!editing}
          size="small"
          icon={<LayersDown />}
          title="下移一层"
          onClick={() => editingLayerRef.current?.onLayersDown()}
        />
      </div>
      {!!editing && (
        <div
          style={{
            color: '#fff',
            position: 'absolute',
            top: 164,
            left: -280,
            boxSizing: 'border-box',
            backgroundColor: '#1b1b1c',
            boxShadow: '3px 3px 6px 6px rgb(36, 37, 37)',
            border: '1px solid rgb(52, 51, 55)',
            borderRadius: 4
          }}
        >
          <div
            style={{
              position: 'relative',
              color: '#D4FF00',
              fontWeight: 'bold',
              padding: '8px 15px',
              textAlign: 'center',
              background: '#29292d'
            }}
          >
            参数配置
            <CloseOutlined
              style={{ position: 'absolute', top: 10, right: 10, color: '#fff', cursor: 'pointer' }}
              onClick={() => setEditing('')}
            />
          </div>
          <div style={{ padding: '20px 20px' }}>
            {currentLayer?.type === 'intro_video' && (
              <>
                <div style={{ display: 'flex', alignItems: 'center', fontSize: 13, marginBottom: 16 }}>
                  <label style={{ marginRight: 16 }}>与前段片尾的重合时间</label>
                  <div style={{ display: 'flex', alignItems: 'center', marginRight: 24 }}>
                    <InputNumber
                      maxLength={5}
                      precision={0}
                      min={0}
                      defaultValue={1}
                      value={currentLayer?.intro_video.prev_overlap_msec}
                      onChange={(value) =>
                        onConfigChange({
                          intro_video: {
                            ...currentLayer?.intro_video,
                            prev_overlap_msec: value || 0
                          }
                        })
                      }
                      style={{
                        color: '#fff',
                        background: '#343337',
                        border: 'none',
                        boxShadow: 'none',
                        borderRadius: '6px 0 0 6px',
                        width: 84
                      }}
                    />
                    <label
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        height: 30,
                        justifyContent: 'center',
                        color: '#999',
                        background: '#343337',
                        borderRadius: '0 6px 6px 0',
                        padding: '0 10px',
                        width: 12
                      }}
                    >
                      秒
                    </label>
                  </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', fontSize: 13, marginBottom: 16 }}>
                  <label style={{ marginRight: 16 }}>与后段片头的重合时间</label>
                  <div style={{ display: 'flex', alignItems: 'center', marginRight: 24 }}>
                    <InputNumber
                      maxLength={5}
                      precision={0}
                      min={0}
                      defaultValue={1}
                      value={currentLayer?.intro_video.current_overlap_msec}
                      onChange={(value) =>
                        onConfigChange({
                          intro_video: {
                            ...currentLayer?.intro_video,
                            current_overlap_msec: value || 0
                          }
                        })
                      }
                      style={{
                        color: '#fff',
                        background: '#343337',
                        border: 'none',
                        boxShadow: 'none',
                        borderRadius: '6px 0 0 6px',
                        width: 84
                      }}
                    />
                    <label
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        height: 30,
                        justifyContent: 'center',
                        color: '#999',
                        background: '#343337',
                        borderRadius: '0 6px 6px 0',
                        padding: '0 10px',
                        width: 12
                      }}
                    >
                      秒
                    </label>
                  </div>
                </div>
              </>
            )}

            {currentLayer?.type !== 'intro_video' && (
              <>
                <div style={{ display: 'flex', alignItems: 'center', fontSize: 13, marginBottom: 16 }}>
                  <label style={{ marginRight: 16 }}>位置</label>
                  <div style={{ display: 'flex', alignItems: 'center', marginRight: 24 }}>
                    <label
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        height: 30,
                        justifyContent: 'center',
                        color: '#999',
                        background: '#343337',
                        borderRadius: '6px 0 0 6px',
                        paddingLeft: 10,
                        width: 12
                      }}
                    >
                      X
                    </label>
                    <InputNumber
                      maxLength={5}
                      precision={0}
                      value={currentLayer?.x}
                      onChange={(value) => onConfigChange({ x: value })}
                      style={{
                        color: '#fff',
                        background: '#343337',
                        border: 'none',
                        boxShadow: 'none',
                        borderRadius: '0 6px 6px 0',
                        width: 76
                      }}
                    />
                  </div>

                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        height: 30,
                        justifyContent: 'center',
                        color: '#999',
                        background: '#343337',
                        borderRadius: '6px 0 0 6px',
                        paddingLeft: 10,
                        width: 12
                      }}
                    >
                      Y
                    </label>
                    <InputNumber
                      value={currentLayer?.y}
                      maxLength={5}
                      precision={0}
                      onChange={(value) => onConfigChange({ y: value })}
                      style={{
                        color: '#fff',
                        background: '#343337',
                        border: 'none',
                        boxShadow: 'none',
                        borderRadius: '0 6px 6px 0',
                        width: 76
                      }}
                    />
                  </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', fontSize: 13 }}>
                  <label style={{ marginRight: 16 }}>宽高</label>
                  <div style={{ display: 'flex', alignItems: 'center', marginRight: 24 }}>
                    <label
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        height: 30,
                        justifyContent: 'center',
                        color: '#999',
                        background: '#343337',
                        borderRadius: '6px 0 0 6px',
                        paddingLeft: 10,
                        width: 12
                      }}
                    >
                      W
                    </label>
                    <InputNumber
                      precision={0}
                      maxLength={5}
                      min={1}
                      value={currentLayer?.w}
                      onChange={(value) => {
                        if (value) {
                          onConfigChange({ w: value, h: value * ((currentLayer?.h || 1) / (currentLayer?.w || 1)) })
                        }
                      }}
                      style={{
                        color: '#fff',
                        background: '#343337',
                        border: 'none',
                        boxShadow: 'none',
                        borderRadius: '0 6px 6px 0',
                        width: 76
                      }}
                    />
                  </div>

                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        height: 30,
                        justifyContent: 'center',
                        color: '#999',
                        background: '#343337',
                        borderRadius: '6px 0 0 6px',
                        paddingLeft: 10,
                        width: 12
                      }}
                    >
                      H
                    </label>
                    <InputNumber
                      maxLength={5}
                      precision={0}
                      min={1}
                      value={currentLayer?.h}
                      onChange={(value) => {
                        if (value) {
                          onConfigChange({ h: value, w: value * ((currentLayer?.w || 1) / (currentLayer?.h || 1)) })
                        }
                      }}
                      style={{
                        color: '#fff',
                        background: '#343337',
                        border: 'none',
                        boxShadow: 'none',
                        borderRadius: '0 6px 6px 0',
                        width: 76
                      }}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}

      <div
        ref={(e) => {
          if (e) {
            const { clientHeight: h, clientWidth: w } = e
            if (w !== viewPortSize?.w || h !== viewPortSize?.h) {
              setViewPort({ w, h, tw, th })
            }
          }
        }}
        id="viewport"
        style={{
          width: 360,
          height: 640,
          position: 'relative',
          overflow: 'hidden',
          borderRadius: 1,
          backgroundColor: '#28282C'
        }}
      >
        <Render key={bg?.key}>
          {function Background() {
            if (!viewPortSize) return <></>
            if (!bg)
              return (
                <div
                  style={{
                    position: 'absolute',
                    height: viewPortSize.h,
                    width: viewPortSize.w,
                    top: 0,
                    left: 0
                  }}
                  onClick={() => {
                    setEditing('')
                    if (tab.value === 'script') {
                      rangeChange.next(undefined)
                    }
                  }}
                />
              )
            const scale = Math.max(viewPortSize.w / bg.w, viewPortSize.h / bg.h)
            return (
              <img
                alt="background"
                src={url(bg.key)}
                style={{
                  position: 'absolute',
                  height: bg.h * scale,
                  width: bg.w * scale,
                  top: (viewPortSize.h - bg.h * scale) / 2,
                  left: (viewPortSize.w - bg.w * scale) / 2
                }}
                onClick={() => {
                  setEditing('')
                  if (tab.value === 'script') {
                    rangeChange.next(undefined)
                  }
                }}
              />
            )
          }}
        </Render>
        {ls.map((l) => (
          <MovableLayer key={l.key} layer={l} ref={editing === l.key ? editingLayerRef : null} />
        ))}
      </div>
    </Providing>
  )
}

type MovableLayerProps = {
  layer: Layer
}
type IMovableLayerRef = {
  onNewScreen: () => void
  onLayersUp: () => void
  onLayersDown: () => void
  onDisableChroma: () => void
}

const sectionKeyIn = (layer: Layer, ls: Layer[], sectionKey: string, tab: string, focusKey: string) => {
  const isMultup = tab === 'multiple_videos'
  return (
    !['w2l_video', 'multiple_w2l_video'].includes(layer.type) ||
    (layer.type === 'w2l_video' &&
      ((isMultup && !ls.some((l) => l.type === 'multiple_w2l_video' && l.key === sectionKey)) ||
        (!isMultup && focusKey === layer.key))) ||
    (layer.type === 'multiple_w2l_video' && ((sectionKey === layer.key && isMultup) || focusKey === layer.key))
  )
}

export const MovableLayer = forwardRef<IMovableLayerRef, MovableLayerProps>(({ layer }, ref) => {
  const [video] = useBehaviorSubject(useContext(w2lVideo))
  const [ls, setLayers] = useBehaviorSubject(useContext(layers))
  const vp = useContext(viewPort)
  const change = useContext(layersChange)
  const [editing, setEditing] = useBehaviorSubject(useContext(editingLayer))
  const index = ls.findIndex((l) => l.key === layer.key)
  const canMoveUp = index < ls.length - 1
  const canMoveDown = index > 0
  const enabled = editing === layer.key
  const moved = useContext(layersChange)
  const [section] = useBehaviorSubject(useContext(sections))
  const [forceRender, token] = useForceRender()
  const [target, setTarget] = useState<any>()
  const ht2r = useCallback((tw: number): number => (!vp ? 0 : (tw * vp.w) / vp.tw), [vp])
  const hr2t = useCallback((w: number): number => (!vp ? 0 : (w * vp.tw) / vp.w), [vp])
  const vt2r = useCallback((th: number): number => (!vp ? 0 : (th * vp.h) / vp.th), [vp])
  const vr2t = useCallback((h: number): number => (!vp ? 0 : (h * vp.th) / vp.h), [vp])
  const transform = useCallback(
    () => `translate(${ht2r(layer.x || 0)}px, ${vt2r(layer.y || 0)}px) scale(${layer.sx}, ${layer.sy})`,
    [ht2r, layer, vt2r]
  )
  const url = useContext(resourceUrl)
  const range = useContext(selectedRange)
  const [r] = useBehaviorSubject(useContext(selectedRange))
  const [sectionKey] = useBehaviorSubject(useContext(multipleSectionKey))
  const [tab] = useBehaviorSubject(useContext(scriptTab))
  const visibleObservable = useMemo(() => range.pipe(map((r) => rangeIntersects(r, layer.range))), [range, layer])
  const [visible] = useObservable(visibleObservable, () => rangeIntersects(range.value, layer.range), [layer])
  const [s] = useBehaviorSubject(useContext(segments))
  const [multipleV] = useBehaviorSubject(useContext(multipleVideos))
  const canDisableChroma = layer.type === 'w2l_video' && !video?.no_alpha
  const [disableAlpha, setDisableAlpha] = useState<boolean>(
    layer.type === 'w2l_video' ? !!(layer.disable_alpha_channel ?? false) : false
  )

  const focusKey = useMemo(() => {
    if (!multipleV) {
      return 'video'
    }
    const groupKey = s.find((d) => d.key === r?.key)?.groupInfo?.key
    return section.find((s) => s.key === groupKey)?.w2l_video_frame_key || 'video'
  }, [multipleV, s, section, r?.key])

  const showVideo = useMemo(() => {
    return sectionKeyIn(
      layer,
      ls,
      section.find((s) => s.key === sectionKey)?.w2l_video_frame_key || '',
      tab,
      focusKey as string
    )
  }, [layer, ls, section, tab, focusKey, sectionKey])

  const scriptTabSubj = useContext(scriptTab)
  const xCenterRef = useRef<any>()
  const yCenterRef = useRef<any>()
  const xCenter = useCallback(() => (layer.x === 0 ? 'block' : 'none'), [layer])
  const yCenter = useCallback(() => (layer.y === 0 ? 'block' : 'none'), [layer])
  const renderLayer = ls.filter((l) => rangeIntersects(range.value, l.range) && showVideo)
  const [disableEvent, setDisableEvent] = useState(false)
  const editingRef = useRef<any>()

  const onNewScreen = () => {
    if (!vp) return
    const { tw, th } = vp
    if (!tw || !th) return
    layer.x = layer.y = 0
    layer.sx = layer.sy = Math.min(tw / layer.w, th / layer.h)
    change.next()
    forceRender()
  }
  const onLayersUp = () => {
    const index = ls.indexOf(layer)
    if (index >= 0 && index < ls.length - 1) {
      const copy = ls.filter((l) => l !== layer)
      copy.splice(index + 1, 0, layer)
      setLayers(copy)
    }
  }
  const onLayersDown = () => {
    const index = ls.indexOf(layer)
    if (index > 0) {
      const copy = ls.filter((l) => l !== layer)
      copy.splice(index - 1, 0, layer)
      setLayers(copy)
    }
  }

  const onDisableChroma = () => {
    if (layer.type === 'w2l_video') {
      layer.disable_alpha_channel = !layer.disable_alpha_channel
      setDisableAlpha(layer.disable_alpha_channel)
    }
  }

  const onLayerMouseover = (e: any) => {
    if (editingRef.current !== layer.key) {
      const editingIndex = renderLayer.findIndex((l) => l.key === editingRef.current)
      if (editingIndex > -1) {
        const bounding = document.querySelectorAll('.layer-img')[editingIndex].getBoundingClientRect()
        const xInRange = e.clientX >= bounding.x && e.clientX <= bounding.x + bounding.width
        const yInRange = e.clientY >= bounding.y && e.clientY <= bounding.y + bounding.height
        if (xInRange && yInRange) {
          console.log('diabled')
          setDisableEvent(true)
        } else {
          console.log('move')
          setDisableEvent(false)
        }
      }
    } else {
      setDisableEvent(false)
    }
  }

  useEffect(() => {
    editingRef.current = editing
    const viewport = document.querySelector('#viewport')
    const func = debounce(onLayerMouseover, 50)
    if (viewport) {
      if (editing) {
        viewport.addEventListener('mouseover', func)
      }
    }

    return () => {
      if (viewport) {
        viewport.removeEventListener('mouseover', func)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editing])

  useImperativeHandle(ref, () => ({
    onNewScreen,
    onLayersUp,
    onLayersDown,
    onDisableChroma
  }))

  if (!vp) return <></>
  if (!visible) return <></>
  if (!showVideo) return <></>
  // if (!multipleV && layer.type === 'multiple_w2l_video') return <></>
  if (layer.type === 'intro_video') return <></>
  return (
    <>
      <img
        className="layer-img"
        key={`${layer.image_key}-${token}`}
        ref={(e) => setTarget(e)}
        src={url(
          (!canDisableChroma
            ? layer.image_key
            : disableAlpha
            ? layer.image_key?.replace('.png', '.jpg')
            : layer.image_key?.replace('.jpg', '.png')) || ''
        )}
        alt={`${layer.type} element`}
        style={{
          visibility: visible ? 'visible' : 'hidden',
          position: 'absolute',
          height: vt2r(layer.h),
          width: ht2r(layer.w),
          top: (vp.h - vt2r(layer.h)) / 2,
          left: (vp.w - ht2r(layer.w)) / 2,
          transform: transform(),
          pointerEvents: !disableEvent || !editing || editing === layer.key ? 'initial' : 'none'
        }}
        onClick={(e) => {
          setEditing(layer.key)
          if (enabled) {
            const clickLayer = renderLayer.filter((l, index) => {
              const dom = document.querySelectorAll('.layer-img')[index]
              if (dom) {
                const bounding = document.querySelectorAll('.layer-img')[index].getBoundingClientRect()
                const xInRange = e.clientX >= bounding.x && e.clientX <= bounding.x + bounding.width
                const yInRange = e.clientY >= bounding.y && e.clientY <= bounding.y + bounding.height
                return xInRange && yInRange
              } else {
                return false
              }
            })
            const index = clickLayer.findIndex((l) => l.key === editing)
            const nextIndex = index === clickLayer.length - 1 ? 0 : index + 1
            setEditing(clickLayer[nextIndex].key)
            setTimeout(() => {
              onLayerMouseover(e)
            })
          }
        }}
      />
      {enabled && (
        <div
          ref={xCenterRef}
          className="center"
          style={{
            position: 'absolute',
            top: 0,
            left: '50%',
            width: 1,
            height: '100%',
            pointerEvents: 'none',
            backgroundColor: 'red',
            display: xCenter(),
            zIndex: 1
          }}
        ></div>
      )}

      {enabled && (
        <div
          ref={yCenterRef}
          className="center"
          style={{
            position: 'absolute',
            top: '50%',
            left: 0,
            width: '100%',
            height: 1,
            pointerEvents: 'none',
            backgroundColor: 'red',
            display: yCenter(),
            zIndex: 1
          }}
        ></div>
      )}

      <Moveable
        key={token}
        target={target}
        flushSync={flushSync}
        scalable={true}
        className={!enabled ? 'disabled' : undefined}
        keepRatio={true}
        throttleScale={0}
        renderDirections={enabled ? ['nw', 'ne', 'sw', 'se'] : []}
        edge={enabled ? ['n', 'w', 's', 'e'] : []}
        origin={false}
        draggable={enabled}
        ables={[
          {
            name: 'deletable',
            render(moveable: MoveableManagerInterface<any, any>, React: Renderer) {
              const rect = moveable.getRect()
              const { pos2 } = moveable.state
              const Icon = layer.type === 'video' && layer.muted ? SoundOff : SoundOn

              return (
                <div
                  key="deletable"
                  style={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    willChange: 'transform',
                    transformOrigin: '0px 0px',
                    transform: `translate(${Math.max(74, pos2[0])}px, ${pos2[1]}px) rotate(${
                      rect.rotation
                    }deg) translate(-34px, 8px)`
                  }}
                >
                  <Trash
                    className="moveable-button"
                    onClick={() => {
                      setLayers(ls.filter((l) => l !== layer))
                      setEditing('')
                    }}
                  />
                  <PicReplace
                    className="moveable-button"
                    onClick={() => {
                      scriptTabSubj.next('resource_change')
                    }}
                  />
                  {layer.type === 'video' && (
                    <Icon
                      className="moveable-button"
                      onClick={() => {
                        if (layer.type === 'video') {
                          layer.muted = !layer.muted
                          change.next()
                          forceRender()
                        }
                      }}
                    />
                  )}
                </div>
              )
            }
          },
          {
            name: 'editable',
            render(moveable: MoveableManagerInterface<any, any>, React: Renderer) {
              const rect = moveable.getRect()
              return (
                <div
                  key="editable"
                  style={{
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    willChange: 'transform',
                    transformOrigin: '0px 0px',
                    transform: `rotate(${rect.rotation}deg) translate(32px, 8px)`
                  }}
                >
                  <NewScreen className="moveable-button" onClick={onNewScreen} />
                  <LayersUp
                    className={['moveable-button', !canMoveUp && 'disabled'].filter(Boolean).join(' ')}
                    onClick={onLayersUp}
                  />
                  <LayersDown
                    className={['moveable-button', !canMoveDown && 'disabled'].filter(Boolean).join(' ')}
                    onClick={onLayersDown}
                  />
                  {canDisableChroma && !disableAlpha && (
                    <HasAlpha
                      style={{ cursor: 'pointer' }}
                      className={['moveable-button'].filter(Boolean).join(' ')}
                      onClick={onDisableChroma}
                    />
                  )}
                  {canDisableChroma && disableAlpha && (
                    <NoAlpha
                      style={{ cursor: 'pointer' }}
                      className={['moveable-button'].filter(Boolean).join(' ')}
                      onClick={onDisableChroma}
                    />
                  )}
                </div>
              )
            }
          }
        ]}
        onDragStart={(e) => {
          setEditing(layer.key)
          e.set([ht2r(layer.x), vt2r(layer.y)])
        }}
        onDrag={(e) => {
          const x = e.beforeTranslate[0]
          const y = e.beforeTranslate[1]
          const sx = x > 0 ? Math.floor(x) : Math.ceil(x)
          const sy = y > 0 ? Math.floor(y) : Math.ceil(y)
          layer.x = vr2t(sx)
          layer.y = vr2t(sy)
          target.style.transform = transform()
          xCenterRef.current.style.display = xCenter()
          yCenterRef.current.style.display = yCenter()
        }}
        onDragEnd={() => {
          moved.next()
        }}
        props={{
          deletable: layer.type !== 'w2l_video' && enabled,
          editable: enabled
        }}
        padding={{ left: 0, top: 0, right: 0, bottom: 0 }}
        onScaleStart={(e) => {
          e.set([layer.sx, layer.sy])
          e.dragStart && e.dragStart.set([ht2r(layer.x), vt2r(layer.y)])
        }}
        onScale={(e) => {
          layer.x = hr2t(e.drag.beforeTranslate[0])
          layer.y = vr2t(e.drag.beforeTranslate[1])
          const [sx, sy] = e.scale
          const minSx = Math.max(vr2t(10) / layer.w, sx)
          const minSy = Math.max(vr2t(10) / layer.h, sy)
          const s = Math.max(minSx, minSy)
          layer.sx = s
          layer.sy = s
          target.style.transform = transform()
          moved.next()
        }}
      />
    </>
  )
})
