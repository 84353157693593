import './App.css'
import './index.sass'
import { Button, notification, Tabs, Tooltip } from 'antd'
import { AxiosError } from 'axios'
import { Buffer } from 'buffer'
import React, { Context, lazy, ReactElement, ReactNode, Suspense, useDeferredValue, useEffect, useMemo } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { createBrowserRouter, Navigate, Outlet, RouterProvider, useParams } from 'react-router-dom'
import { BehaviorSubject, fromEvent, map, mergeWith } from 'rxjs'
import { BugOutlined } from '@ant-design/icons'
import { Auth } from './auth'
import { GlobalStates } from './auth/global-states'
import { Permission, permissionOverride } from './auth/states'
import CustomerService from './components/customer-service'
import {
  apiDomains,
  badges,
  devDomains,
  mouseDown,
  panel,
  panelTypes,
  prodDomains,
  Providing,
  resourceUrl,
  roomFacade,
  roomId,
  socketContext,
  socketTransings,
  templateId,
  WithPermission
} from './global-vars'
import { MainPanel, RoomFacade } from './panel'
import EditProductPage from './product-center/edit_product_page'
import ProductCenterPage from './product-center/product_center_page'
import { ProductReplyList } from './product-center/product_reply'
import { useBehaviorSubject } from './react-rx'
import { RoomEditor } from './room/room-editor'
import { BackgroundList, DecorList } from './room/room-setting'
import { RoomTemplateEditor } from './room/room-template'
import RoomTemplateList from './room/room-template-list'
import { Layer } from './room/states'
import { layers } from './script-and-layer/states'
import { Render, tuple } from './types'

const RoomList = lazy(() => import('./room/room'))
const ResetPassword = lazy(() => import('./auth/reset-password'))
const ResetPasswordWithAuth = lazy(() => import('./auth/reset-password-with-auth'))
const Plans = lazy(() => import('./plan'))
const LiveControl = lazy(() => import('./live'))
const StandalonePlayer = lazy(() => import('./live/standalone-player'))
const UserCenter = lazy(() => import('./user-center'))

export default function App() {
  const isDev = useMemo(() => !!window.location.host.match(/dev|localhost/), [])
  const domains = useMemo(() => (isDev ? devDomains : prodDomains), [isDev])
  const mouseDownSubj = useMemo(() => new BehaviorSubject(false), [])
  const permissionOverrideSubj = useMemo(() => new BehaviorSubject<Permission>({}), [])
  const socketContextSubj = useMemo(() => new BehaviorSubject<any>(undefined), [])
  const socketTransingsSubj = useMemo(() => new BehaviorSubject<string[]>([]), [])

  const badgeSubj = useMemo(
    () =>
      new BehaviorSubject<[string, ReactNode][]>(
        isDev
          ? [
              tuple(
                'dev',
                <Tooltip placement="left" title="您正在使用测试环境。点击跳转到正式环境。" key="dev">
                  <Button danger onClick={() => window.open('//fantasy.lingverse.co')} icon={<BugOutlined />} />
                </Tooltip>
              )
            ]
          : []
      ),
    [isDev]
  )
  const [badgeElements] = useBehaviorSubject(badgeSubj)
  useEffect(() => {
    const prevErr = notification.error
    notification.error = function (...args) {
      const err = args[0]
      if (err instanceof AxiosError && typeof err.response?.data?.message === 'string') {
        err.message = err.response?.data?.message
      }
      prevErr.call(this, ...args)
    }
  }, [])
  useEffect(() => {
    if (window) {
      window.Buffer = Buffer
      const subscription = fromEvent(window, 'onmousedown')
        .pipe(
          map(() => true),
          mergeWith(fromEvent(window, 'onmouseup').pipe(map(() => false)))
        )
        .subscribe(mouseDownSubj)
      return () => subscription.unsubscribe()
    }
  }, [mouseDownSubj])
  useEffect(() => {
    import('./plan')
  }, [])
  let content = (
    <DndProvider backend={HTML5Backend}>
      <GlobalStates>
        <Suspense>
          <RouterProvider router={router} />
        </Suspense>
      </GlobalStates>
    </DndProvider>
  )

  content = (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      {content}
      <div
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          opacity: 0.8,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end'
        }}
      >
        {badgeElements.map(([, _]) => _)}
      </div>
      <CustomerService />
    </div>
  )
  return (
    <Providing
      _={(_) =>
        _(resourceUrl, (key: string) => `${domains.cdn}/${key}`)(apiDomains, domains)(mouseDown, mouseDownSubj)(
          permissionOverride,
          permissionOverrideSubj
        )(badges, badgeSubj)(socketContext, socketContextSubj)(socketTransings, socketTransingsSubj)
      }
    >
      {content}
    </Providing>
  )
}

const router = createBrowserRouter([
  {
    path: '/reset_password',
    element: <ResetPassword />
  },
  {
    path: '/',
    element: (
      <Auth>
        <Suspense>
          <Outlet />
        </Suspense>
      </Auth>
    ),
    children: [
      ...panelTypes.map((path) => ({
        path,
        element: (
          <Providing _={(_) => _(panel, path)}>
            <MainPanel>
              <Render>
                {function Content() {
                  const p = useDeferredValue(path)
                  const layerSubj = useMemo(() => new BehaviorSubject<Layer[]>(undefined as any), [])
                  return (
                    <>
                      {p === 'templates' && <RoomTemplateList />}
                      {p === 'rooms' && <RoomList />}
                      {p === 'products' && <ProductCenterPage />}
                      {p === 'materials' && (
                        <div style={{ margin: '20px 20px 0 20px' }}>
                          <Tabs
                            defaultActiveKey="background"
                            centered
                            items={[
                              {
                                key: 'background',
                                label: '背景素材',
                                children: <BackgroundList />
                              },
                              {
                                key: 'decor',
                                label: '装饰素材',
                                children: (
                                  <Providing _={(_) => _(layers, layerSubj)}>
                                    <DecorList />
                                  </Providing>
                                )
                              }
                            ]}
                          />
                          layerSubj
                        </div>
                      )}
                      {p === 'account' && <UserCenter />}
                      {p === 'plans' && <Plans />}
                      {p === 'safe' && <ResetPasswordWithAuth />}
                    </>
                  )
                }}
              </Render>
            </MainPanel>
          </Providing>
        )
      })),
      {
        path: 'live',
        element: <StandalonePlayer />
      },
      {
        path: 'templates/:templateId',
        element: provideParams({ templateId }, <RoomTemplateEditor />)
      },
      {
        path: 'products/add',
        element: (
          <Providing _={(_) => _(panel, 'products')}>
            <MainPanel>
              <EditProductPage />
            </MainPanel>
          </Providing>
        )
      },
      {
        path: 'products/edit',
        element: (
          <Providing _={(_) => _(panel, 'products')}>
            <MainPanel>
              <EditProductPage />
            </MainPanel>
          </Providing>
        )
      },
      {
        path: 'products/reply',
        element: (
          <Providing _={(_) => _(panel, 'products')}>
            <MainPanel>
              <ProductReplyList />
            </MainPanel>
          </Providing>
        )
      },
      {
        path: 'rooms/:roomId',
        element: provideParams({ roomId }),
        children: [
          {
            path: 'setting',
            element: (
              <Render>
                {function Content() {
                  return (
                    <Providing _={(p) => p(roomFacade, 'setting')}>
                      <RoomFacade>
                        <RoomEditor />
                      </RoomFacade>
                    </Providing>
                  )
                }}
              </Render>
            )
          },
          {
            path: 'live',
            element: (
              <Providing _={(p) => p(roomFacade, 'live')}>
                <RoomFacade>
                  <LiveControl />
                </RoomFacade>
              </Providing>
            )
          },
          {
            path: 'template',
            element: (
              <WithPermission permission="admin" fallback={<Navigate to=".." />}>
                <RoomTemplateEditor />
              </WithPermission>
            )
          },
          ...defaultsTo('setting')
        ]
      },
      ...defaultsTo('templates')
    ]
  }
])

function defaultsTo(path: string) {
  return [
    {
      path: '',
      element: <Navigate replace to={path} />
    },
    {
      path: '*',
      element: <Navigate replace to={path} />
    }
  ]
}

function provideParams(vars: Record<string, Context<any>>, children: ReactElement = <Outlet />) {
  return (
    <Render>
      {function Provide() {
        const params = useParams()
        return (
          <Providing
            _={(p) => {
              for (let varsKey in vars) p(vars[varsKey], params[varsKey])
            }}
          >
            {children}
          </Providing>
        )
      }}
    </Render>
  )
}
