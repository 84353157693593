import { Button, notification, Space, Table, Typography } from 'antd'
import { Link } from 'react-router-dom'
import { useAPI, useGet } from '../api'
import { usePermission } from '../auth/states'
import { Resource } from '../room/states'

import type { ColumnsType } from 'antd/es/table'
const { Text } = Typography

export type Product = {
  id: string
  name: string
  price: number
  description: string
  long_description: string
  skuId: string
  platform: string
  state: number
  line_contents?: {
    text: string
  }[]
}

export function useProducts() {
  return [...(useGet<{ products: Product[] }>('/products', { suspense: true }).data?.products || [])]
}

export function useProductResources(productId: string) {
  return useGet<{ resources: Resource[] }>(`/products/${productId}/resources`, { suspense: true }).data?.resources || []
}

function ProductCenterPage() {
  const permission = usePermission()
  const columns: ColumnsType<Product> = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 200,
      render: (_, __, index) => <Text style={{ color: 'white' }}>{index + 1}</Text>
    },
    {
      title: '商品名称',
      dataIndex: 'name',
      width: 200,
      key: 'name',
      render: (_, record) => <Text style={{ color: 'white' }}>{record.name}</Text>
    },
    {
      title: '销售价格',
      width: 200,
      dataIndex: 'price',
      key: 'price',
      render: (_, record) => <Text style={{ color: 'white' }}>¥ {record.price / 100}</Text>
    },
    {
      title: '商品详情',
      dataIndex: 'description',
      key: 'description',
      render: (_, record) => <Text style={{ color: 'white' }}>{record.description}</Text>
    },
    {
      title: '操作',
      key: 'aciton',
      width: 200,
      render: (_, record, index) => (
        <Space size="middle">
          <Link to="edit" state={record}>
            <Text style={{ color: '#6B83FF' }}>编辑</Text>
          </Link>
          <Text
            style={{ color: '#EF446D', cursor: 'pointer' }}
            onClick={async () => {
              try {
                await api.delete(`/products/${record.id}`)
              } catch (e: any) {
                notification.error(e)
              }
            }}
          >
            删除
          </Text>
          <Link to="reply" state={record}>
            <Text style={{ color: '#6B83FF' }}>问答库</Text>
          </Link>
        </Space>
      )
    }
  ]

  if (permission.admin) {
    columns.splice(2, 0, {
      title: '来源',
      dataIndex: 'platform',
      width: 200,
      key: 'platform',
      render: (_, record) => <Text style={{ color: 'white' }}>{record.platform ? '外部链接' : '自主添加'}</Text>
    })
  }

  const products = useProducts()
  const api = useAPI()
  return (
    <div style={{ padding: '84px 44px 0px 44px' }}>
      <div className="section-title" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        商品管理
        <Link to="add">
          <Button className="highlight-contentful">添加商品</Button>
        </Link>
      </div>
      <Table id="room-table" columns={columns} dataSource={products} bordered pagination={false} rowKey="id"></Table>
    </div>
  )
}

export default ProductCenterPage
