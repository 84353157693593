import { Input } from 'antd'
import { $createParagraphNode, $createTextNode, $getRoot } from 'lexical'
import { createContext, FC, useContext, useEffect, useMemo, useState } from 'react'
import { BehaviorSubject } from 'rxjs'
import * as uuid from 'uuid'
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin'
import { wsContext } from '../../global-vars'
import { NumericInput } from '../../numeric-input'
import { useBehaviorSubject } from '../../react-rx'
import minusCircle from '../../res/minus-circle.svg'
import { ReactComponent as Play } from '../../res/play.svg'
import { ReactComponent as Plus } from '../../res/plus.svg'
import { ReactComponent as Trash } from '../../res/trash.svg'
import { PatternReply } from '../../room/states'
import RecordingRowModal from '../../script-and-layer/components/voice-upload/recording-row-modal'
import { flatten, runCatching } from '../../types'
import { HighlightNode, HighlightPlugin } from '../lexical/hight-light-plugin'
import { SensitiveWordsNode, SensitiveWordsPlugin } from '../lexical/sensitive-words-plugin'

export type ProductReply = {
  id: string
  category: string
  replies: Array<PatternReply>
}
export const productReplySubj = createContext<BehaviorSubject<ProductReply>>(undefined as any)

export const PatternReplyItem: FC<{ id: string }> = ({ id }) => {
  const reactionSubj = useContext(productReplySubj)
  const [reactions, setReactionSetting] = useBehaviorSubject(reactionSubj)
  const pattern = useMemo(() => reactions?.replies?.find((p) => p.key === id), [reactions, id])
  const [regex, setRegex] = useBehaviorSubject(useMemo(() => new BehaviorSubject(pattern?.pattern || ''), [pattern]))
  const [error, setError] = useState('')
  const [collapsed, setCollapsed] = useState(false)
  useEffect(() => {
    const r = flatten(runCatching(() => new RegExp(regex)))
    if (r.error) {
      setError(r.error.message)
    } else {
      setError('')
      reactionSubj.next({
        ...reactionSubj.value,
        replies: reactionSubj.value.replies?.map((r) =>
          r.key === id
            ? {
                ...r,
                pattern: regex
              }
            : r
        )
      })
    }
  }, [setError, regex, reactionSubj, id])

  const addReply = () => {
    pattern?.replies?.push({ key: uuid.v4(), text: '你好' })
    console.log(pattern)
    setReactionSetting({ ...reactions })
  }

  const addCondition = () => {
    pattern?.conditions?.push({ key: uuid.v4(), text: '' })
    setReactionSetting({ ...reactions })
  }

  const deleteReply = (key: string) => {
    pattern?.replies?.filter((rr) => rr.key !== key)
    setReactionSetting({ ...reactions })
  }

  const deleteCondition = (key: string) => {
    pattern?.conditions?.filter((rr) => rr.key !== key)
    setReactionSetting({ ...reactions })
  }
  return (
    <div style={{ marginBottom: 24, padding: 20, backgroundColor: 'rgba(40, 40, 44, 1)', borderRadius: 4 }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          fontWeight: 'bold',
          color: 'white',
          alignItems: 'center'
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>
          {collapsed ? (
            <CaretUpOutlined onClick={() => setCollapsed(false)} />
          ) : (
            <CaretDownOutlined onClick={() => setCollapsed(true)} />
          )}
          <div style={{ display: 'flex', width: '100%' }}>
            <Input className="input-with-hint" value={regex} onChange={(e) => setRegex(e.target.value)} />
            <div className="input-hint" style={{ color: 'red', backgroundColor: 'rgba(40, 40, 44, 1)' }}>
              {error}
            </div>
          </div>

          <ReplyConfig pattern={pattern} />
        </div>
      </div>

      {collapsed ? (
        ''
      ) : (
        <>
          <div onClick={addCondition} className="sub-section-title">
            触发条件 <Plus className="small-square-button" />
            <span style={{ marginLeft: '20px', color: '#999' }}>
              可以填写多个关键词(以|分隔)，或正则表达式(以^开头且以$结尾)
            </span>
          </div>
          {pattern?.conditions?.map((r) => (
            <ReactionRow
              key={r.key}
              placeholder=""
              onDelete={() => deleteCondition(r.key)}
              onChange={(s) => {
                r.text = s
                setReactionSetting({ ...reactions })
              }}
              initialValue={r.text}
            />
          ))}
          <div onClick={addReply} className="sub-section-title">
            回复话术 <Plus className="small-square-button" />
          </div>
          {pattern?.replies?.map((r) => (
            <ReactionRow
              key={r.key}
              onDelete={() => deleteReply(r.key)}
              initialValue={r.text}
              reply={r}
              onChange={(s) => {
                r.text = s
                setReactionSetting({ ...reactions })
              }}
              onVoiceChange={(s: any) => {
                pattern?.replies?.map((t) => (t.key === s.key ? { ...s } : t))
                setReactionSetting({ ...reactions })
              }}
            />
          ))}
        </>
      )}
    </div>
  )
}

export const ReactionRow: FC<{
  onDelete: () => void
  onChange: (_: string) => void
  initialValue: string
  placeholder?: string
  reply?: any
  onVoiceChange?: (_: any) => void
}> = ({ onDelete, onChange, initialValue, placeholder, reply, onVoiceChange }) => {
  const [recordingRowModalVisble, setRecordingRowModalVisble] = useState(false)
  const [content, setContent] = useState<any>()
  const onVoiceClick = () => {
    setRecordingRowModalVisble(true)
    setContent({
      text: reply?.text,
      voice: reply?.voice_key || ''
    })
  }

  const onRecordingRowSuccess = (resource: any) => {
    setRecordingRowModalVisble(false)
    onVoiceChange?.({
      ...reply,
      voice_key: resource.key
    })
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img
        className="primary-button-color hidden-button"
        src={minusCircle}
        alt="delete"
        style={{
          color: 'red',
          width: 18,
          height: 18,
          verticalAlign: 'center',
          textAlign: 'center'
        }}
        onClick={onDelete}
      />
      <LexicalComposer
        initialConfig={{
          namespace: 'row',
          editorState: () => $getRoot().append($createParagraphNode().append($createTextNode(initialValue))),
          onError(error, editor) {
            console.error('editor error', error)
          },
          nodes: [HighlightNode, SensitiveWordsNode]
        }}
      >
        <PlainTextPlugin
          contentEditable={<ContentEditable style={{ color: 'white', flex: 1, width: 0, outline: 'none' }} />}
          placeholder={<div className="lexical-placeholder"> {placeholder ?? '互动话术'} </div>}
          ErrorBoundary={LexicalErrorBoundary}
        />
        <HighlightPlugin text="[观众昵称]" />
        <HighlightPlugin text="[礼物名称]" />
        <HighlightPlugin text="[礼物数量]" />
        <OnChangePlugin onChange={(state) => onChange(state.read(() => $getRoot().getTextContent()))} />
        <HistoryPlugin />
        <SensitiveWordsPlugin />
      </LexicalComposer>
      {reply && (
        <>
          {reply?.voice_key ? (
            <img
              style={{
                width: '24px',
                cursor: 'pointer'
              }}
              src={require('../../res/icon-voice-on.png')}
              alt=""
              onClick={onVoiceClick}
            />
          ) : (
            <img
              style={{
                width: '24px',
                cursor: 'pointer'
              }}
              src={require('../../res/icon-voice.png')}
              alt=""
              onClick={onVoiceClick}
            />
          )}
          <RecordingRowModal
            content={content}
            visible={recordingRowModalVisble}
            onCancel={() => {
              setRecordingRowModalVisble(false)
            }}
            onOk={onRecordingRowSuccess}
          />
        </>
      )}
    </div>
  )
}

export const ReplyConfig: FC<{ pattern?: PatternReply }> = ({ pattern }) => {
  const reactionSubj = useContext(productReplySubj)
  const [reactions, setReactionSetting] = useBehaviorSubject(reactionSubj)
  const ws = useContext(wsContext)
  return (
    <>
      <div style={{ position: 'relative', right: 0, width: 200, marginRight: 12 }}>
        <NumericInput
          className="input-with-hint"
          value={pattern?.cool_down_duration_seconds || 0}
          onChange={(cool_down_duration_seconds) =>
            reactionSubj.next({
              ...reactionSubj.value,
              replies: reactionSubj.value.replies?.map((r) =>
                r.key === pattern?.key
                  ? {
                      ...r,
                      cool_down_duration_seconds
                    }
                  : r
              )
            })
          }
        />
        <div className="input-hint" style={{ backgroundColor: 'rgba(40, 40, 44, 1)' }}>
          触发间隔(秒)
        </div>
      </div>

      <Trash
        title="删除这条规则"
        style={{ cursor: 'pointer' }}
        onClick={() => {
          setReactionSetting({ ...reactions, replies: reactions?.replies?.filter((p) => p.key !== pattern?.key) })
        }}
      />

      <Play
        title="立即播放"
        style={{ marginLeft: '20px', cursor: 'pointer' }}
        onClick={() => {
          ws &&
            ws((_) =>
              _.send(
                JSON.stringify({
                  type: 'play_reaction',
                  pattern_reply_key: pattern?.key
                })
              )
            )
        }}
      />
    </>
  )
}
