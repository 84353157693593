import { message } from 'antd'
import { createContext, FC, memo, useContext, useEffect, useMemo, useRef } from 'react'
import { BehaviorSubject } from 'rxjs'
import { currentUser } from '../../../auth/states'
import { devDomains, prodDomains } from '../../../global-vars'
import { Product } from '../../../product-center/product_center_page'
import { useBehaviorSubject } from '../../../react-rx'
import { segments, segmentsChange } from '../../script-card'
import { save } from '../../states'

export const gptStreamingList = createContext<BehaviorSubject<Product[]>>([] as any)

const GptStreaming: FC = () => {
  const [productList, setProductList] = useBehaviorSubject(useContext(gptStreamingList))
  const isDev = useMemo(() => !!window.location.host.match(/dev|localhost/), [])
  const domains = useMemo(() => (isDev ? devDomains : prodDomains), [isDev])
  const user = useContext(currentUser)
  const segmentsSubj = useContext(segments)
  const segmentsChangeSubj = useContext(segmentsChange)
  const saveSubj = useContext(save)
  const wsRef = useRef<any>()
  const msgRef = useRef<any>()
  const timerRef = useRef<any>()
  const contentRef = useRef<any>()

  useEffect(() => {
    if (productList.length && !wsRef.current) {
      generateProductScript()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productList])

  const closeWs = () => {
    if (wsRef.current) {
      wsRef.current.close()
      wsRef.current = null
    }
    if (timerRef) {
      clearInterval(timerRef.current)
      timerRef.current = null
    }
  }

  const sendMessage = (message: any[]) => {
    wsRef.current?.send(
      JSON.stringify({
        type: 'stream_chat',
        message
      })
    )
  }

  const changeSeg = (content: string, product: Product) => {
    const t = (content.includes('io.EOF') ? '' : content.replaceAll(/\n/g, '')) || ''
    segmentsChangeSubj.next(
      segmentsSubj.value.map((s) => {
        if (s.attributes?.some((a) => a?.product?.product_id === product?.id)) {
          if (!s.line_contents?.length) {
            return {
              ...s,
              id: t,
              line_contents: [
                {
                  text: t
                }
              ]
            }
          } else {
            const cur = s.line_contents[s.line_contents.length - 1]
            let text = ''
            if (content.includes('io.EOF') && msgRef.current?.length < 5) {
              s.line_contents?.push({
                text
              })
            } else if (content.includes('io.newLine')) {
              text = content.replaceAll(/io.newLine/g, '')
              s.line_contents?.push({
                text
              })
            } else {
              text = `${cur.text}${t}`
              s.line_contents[s.line_contents.length - 1] = {
                ...cur,
                text
              }
            }
            return { ...s, id: text }
          }
        } else {
          return s
        }
      })
    )
  }

  const generateProductScript = () => {
    const product = productList[0]
    msgRef.current = [
      {
        role: 'user',
        content: `帮我写一段直播场景下商品介绍话术.我的商品叫${product.name}${
          product.description ? `商品详情是 ${product.description}` : ''
        }，商品售价是 ¥${product.price / 100}${
          product.long_description ? `，核心卖点是${product.long_description}` : ''
        }`
      }
    ]
    const ws = new WebSocket(`wss:${domains.cms}/ai/chatGPT_stream?token=${user.token}`)
    wsRef.current = ws

    timerRef.current = setInterval(() => {
      wsRef.current?.send(JSON.stringify({ type: 'healthcheck-client' }))
    }, 20000)

    wsRef.current.onopen = () => {
      sendMessage(msgRef.current)
    }

    wsRef.current.onmessage = (msg: any) => {
      const data = JSON.parse(msg.data)
      let { content, error, type } = data

      if (type === 'stream_chat_ack') {
        if (!error) {
          if (!contentRef.current) {
            changeSeg(content, product)
          } else {
            contentRef.current = `${contentRef.current}${content}`
          }
          if (content.includes('\n')) {
            contentRef.current = 'io.newLine'
            setTimeout(() => {
              changeSeg(contentRef.current, product)
              contentRef.current = ''
            }, 200)
          }
        }

        if (content === 'io.EOF') {
          if (msgRef.current?.length === 1) {
            const sub = segmentsSubj.value.find((t) =>
              t.attributes?.some((a) => a?.product?.product_id === product?.id)
            )
            msgRef.current = [
              ...msgRef.current,
              {
                role: 'assistant',
                content: sub?.line_contents?.reduce((pre, cur) => {
                  return `${pre}${cur.text}`
                }, '')
              },
              { role: 'user', content: '我为什么要买你的产品,请说得详细一点' }
            ]
            sendMessage(msgRef.current)
          } else if (msgRef.current?.length === 3) {
            const sub = segmentsSubj.value.find((t) =>
              t.attributes?.some((a) => a?.product?.product_id === product?.id)
            )
            setTimeout(() => {
              msgRef.current = [
                ...msgRef.current,
                {
                  role: 'assistant',
                  content: sub?.line_contents?.reduce((pre, cur) => {
                    return `${pre}${cur.text}`
                  }, '')
                },
                { role: 'user', content: '你再介绍一下产品的核心特色,详细一点' }
              ]
              sendMessage(msgRef.current)
            })
          } else if (msgRef.current?.length === 5) {
            closeWs()
            const list = productList.filter((t) => {
              return t.id !== product.id
            })
            if (!list.length) {
              message.success('智能生成完成')
              saveSubj.next(false)
            }
            setProductList(list)
          }
        }

        if (error && error !== 'stream error') {
          closeWs()
          message.warning('本月智能生成剧本次数已达上限')
        }
      }
    }
  }

  return null
}

export default memo(GptStreaming)
