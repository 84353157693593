import { useQuery } from '@tanstack/react-query'
import { useOrderAPI } from '../api'

// 用户当前购买状态
export type Package = {
  product?: Product
  product_id?: number
  remains?: number
  expire_at?: string
  expire_at_date?: Date
  extra_duration?: number
}

// 付费 sku
export type Product = {
  id: number
  name: string
  type: number
  description: string
  price: number
  duration: number
  level: number
  permission?: {
    live?: boolean
    available_video_ids?: string[]
    monthly_duration?: number
    visible_in_list?: boolean
  }
}

const empty = {} as const

export function useUserPackage(refetchInterval: number = 30000): Package {
  const path = '/v1/user_package'
  const orderApi = useOrderAPI()
  return (
    useQuery({
      queryKey: [`/orders/${path}`],
      queryFn: async () => {
        const data = (await orderApi.get<Package>(path)).data
        if (typeof data?.expire_at === 'string') {
          data.expire_at_date = new Date(Date.parse(data.expire_at))
        }
        if (data?.product?.id) {
          data.product_id = data?.product?.id
        }
        if (typeof data?.product?.permission === 'string' && data?.product?.permission) {
          data.product.permission = JSON.parse(data?.product?.permission as any)
          if (data?.product?.permission && Array.isArray(data?.product?.permission.available_video_ids)) {
            data.product.permission.available_video_ids = data.product.permission.available_video_ids.map((_) =>
              _?.toString()
            )
          }
        }
        return data
      },
      suspense: true,
      refetchInterval
    }).data || empty
  )
}

export function useProducts(): { products?: Product[] } {
  const path = '/product'
  const orderApi = useOrderAPI()
  return (
    useQuery({
      queryKey: [`/orders/${path}`],
      queryFn: async () => {
        const body = (await orderApi.get<ReturnType<typeof useProducts>>(path)).data
        body?.products?.forEach((p) => {
          if (typeof p.permission === 'string' && p.permission) p.permission = JSON.parse(p.permission as any)
          if (p.permission && Array.isArray(p.permission.available_video_ids))
            p.permission.available_video_ids = p.permission.available_video_ids.map((_) => _?.toString())
        })
        return body
      },
      suspense: true
    }).data || empty
  )
}
