import React, { FC, PropsWithChildren, ReactElement, useContext } from 'react'
import { FallbackProps } from 'react-error-boundary'
import { Navigate } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { QueryErrorResetBoundary } from '@tanstack/react-query'
import { userState } from './auth/states'

export const RetryOnError: FC<PropsWithChildren<{ fallbackRender?: (_: FallbackProps) => ReactElement }>> = ({
  children
}) => {
  const userSubj = useContext(userState)
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <Sentry.ErrorBoundary
          onReset={reset}
          fallback={({ error }) => {
            if ((error as any).response?.status === 401) {
              userSubj?.next(undefined)
              return <Navigate to="/" />
            }
            return (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  paddingTop: 36,
                  alignItems: 'center',
                  alignContent: 'center',
                  color: 'white',
                  flexDirection: 'column'
                }}
              >
                <div className="section-title">网络似乎出现了一些问题</div>
              </div>
            )
          }}
        >
          {children}
        </Sentry.ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  )
}
