import { Image, Input, message, Select } from 'antd'
import { FC, useContext, useEffect, useMemo, useState } from 'react'
import { concatMap, connectable, debounce, filter, interval, map, retry, Subject, Subscription } from 'rxjs'
import { fromPromise } from 'rxjs/internal/observable/innerFrom'
import { LoadingOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { apiDomains, roomId, wsContext, wsMessages } from '../global-vars'
import { useSession } from '../room/states'

/**
 * 连接直播房间组件
 * @returns
 */
export const RoomConnection: FC = () => {
  const globalSession = useSession()
  const rid = useContext(roomId)
  const session = useMemo(
    () => (!globalSession?.room_id || globalSession.room_id === rid ? globalSession : undefined),
    [globalSession, rid]
  )
  //房间号
  const [id, setId] = useState('')
  //房间号输入合规
  const [idValid, setIdValid] = useState(false)
  const [showQR, setShowQR] = useState(false)
  const [messageApi, contextHolder] = message.useMessage()
  //直播间类型 d/k/w/api
  const [roomType, setRoomType] = useState('d')

  //连接状态  NO未连接 ING连接中 OK连接成功
  enum ConnectStatusEnum {
    NO,
    ING,
    OK
  }
  const [connectStatus, setConnectStatus] = useState(ConnectStatusEnum.NO)
  const [qrcode, setQRCode] = useState('')
  const [qrcodeLoginSuccess, setQrcodeLoginSuccess] = useState(false)
  const [placeholder, setPlaceholder] = useState('')
  const { live: liveDomain } = useContext(apiDomains)

  const disconnect = useMemo(() => new Subject<void>(), [])
  const messages = useContext(wsMessages)
  const ws = useContext(wsContext)

  useEffect(() => {
    const subscription = new Subscription()
    const messageJson = connectable(
      messages.pipe(
        map((s) => JSON.parse(s)),
        retry()
      )
    )

    subscription.add(
      messageJson.pipe(filter((o) => o?.type === 'start')).subscribe((o) => {
        if (o.session) {
          setRoomType(o.session.description?.Data?.room_type)
          setId(o.session.description?.Data?.room_id)
        }
      })
    )

    subscription.add(
      messageJson.pipe(filter((o) => o?.type === 'live-healthcheck')).subscribe((o) => {
        if (o.roomid) {
          setRoomType(o.roomType)
          if (o.roomType !== 'w') {
            setId(o.roomid)
          }
        }
      })
    )
    subscription.add(
      messageJson
        .pipe(filter((o) => ['comment', 'enter', 'like', 'gift', 'social'].includes(o?.type)))
        .subscribe((o) => {
          console.log('setConnectStatus to ok', o)
          if (connectStatus !== ConnectStatusEnum.OK) setConnectStatus(ConnectStatusEnum.OK)
        })
    )

    subscription.add(
      messageJson
        .pipe(
          filter((o) => o?.type === 'qrcode'),
          map((o) => (liveDomain + '/w/' + o?.path) as string)
        )
        .subscribe((v) => {
          setQRCode(v)
          setQrcodeLoginSuccess(false)
        })
    )

    subscription.add(
      messageJson.pipe(filter((o) => o?.type === 'wechat_state' && o?.content === 'login success')).subscribe((v) => {
        setQrcodeLoginSuccess(true)
        setQRCode('')
      })
    )

    subscription.add(messageJson.connect())
    return () => subscription.unsubscribe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages, liveDomain])

  useEffect(() => {
    //直播已结束，但是还连接着房间
    if (!session?.video_url && connectStatus === ConnectStatusEnum.OK) {
      disconnect.next()
    } else if (session?.video_url && connectStatus === ConnectStatusEnum.ING) {
      if (roomType === 'w') {
        if (qrcodeLoginSuccess) {
          setQRCode('')
        }
      } else {
        setQRCode('')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectStatus, session?.video_url, qrcodeLoginSuccess])
  useEffect(() => {
    const subscription = disconnect
      .pipe(
        debounce(() => interval(300)),
        concatMap(() =>
          fromPromise(
            (async () => {
              setConnectStatus(ConnectStatusEnum.NO)
              setShowQR(false)
              setQRCode('')
              ws && ws((_: any) => _.send(JSON.stringify({ type: 'close_live_message' })))
              await new Promise((r) => setTimeout(r, 1_000))
            })()
          )
        ),
        retry()
      )
      .subscribe()
    return () => subscription.unsubscribe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disconnect])
  useMemo(() => {
    if (roomType === 't') {
      setPlaceholder('直播间分享口令，用于连接直播间弹幕')
    } else if (roomType === 'w') {
      setPlaceholder('扫码登陆视频号助手，用于连接直播间弹幕')
    } else {
      setPlaceholder('直播间链接，用于连接直播间弹幕，预计需要1分钟左右')
    }
    setIdValid(roomType === 'w' || id.length > 0)
  }, [roomType, id])

  const extractIdFromUrl = (roomType: string, id: string) => {
    // https://live.kuaishou.com/u/xxx?
    // https://live.douyin.com/xxx?
    let queryPos = id.indexOf('?'),
      startPos = id.indexOf('/', 10)
    if (roomType === 'k' && id.startsWith('http')) {
      return queryPos > -1 ? id.substring(startPos + 3, queryPos) : id.substring(startPos + 3)
    } else if (roomType === 'd' && id.startsWith('http')) {
      return queryPos > -1 ? id.substring(startPos + 1, queryPos) : id.substring(startPos + 1)
    } else if (roomType === 't' && id.length > 12) {
      return id.replace(/[^\da-zA-Z]/g, '')
    }
    return id
  }

  return (
    <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
      <div style={{ display: 'flex', alignItems: 'center', width: '100%', margin: '6px 0' }}>
        <Select
          style={{ width: 160, zIndex: 200, border: 'solid 1px rgb(67, 67, 67)', borderRadius: 4 }}
          className="fantasy-select room-type-select"
          popupClassName="fantasy-popup"
          onChange={(v) => {
            setRoomType(v)
            setId(v === 'api' ? session?.room_id || '' : '')
            setShowQR(false)
            setConnectStatus(ConnectStatusEnum.NO)
          }}
          dropdownMatchSelectWidth={120}
          value={roomType}
          defaultValue="d"
          bordered={false}
          disabled={connectStatus === ConnectStatusEnum.OK}
          options={[
            { value: 'd', label: '抖音' },
            { value: 'k', label: '快手' },
            { value: 'w', label: '视频号' },
            { value: 't', label: '淘宝直播' },
            { value: 'api', label: '开放平台' },
            { value: 'tiktok', label: 'tiktok' }
          ]}
        />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flex: 1,
            width: '100%',
            height: '100%',
            justifyContent: 'flex-end'
          }}
        >
          {connectStatus === ConnectStatusEnum.OK && (
            <div className="highlight-contentful input-icon-end">
              <span style={{ cursor: 'pointer', color: '#EF446D', marginLeft: 8 }} onClick={() => disconnect.next()}>
                断开连接
              </span>
            </div>
          )}
          {connectStatus === ConnectStatusEnum.ING && (
            <div className="input-icon-end" style={{ color: 'rgba(107, 131, 255, 0.5)', cursor: 'not-allowed' }}>
              <span style={{ marginRight: 4 }}>{roomType === 'w' ? '扫码登陆视频号助手' : '正在努力连接，请稍等'}</span>
              {roomType === 'w' ? <QuestionCircleOutlined /> : <LoadingOutlined />}
              {showQR ? (
                <div
                  style={{
                    position: 'absolute',
                    top: 110,
                    right: -10,
                    border: '10px solid white',
                    background: 'white',
                    zIndex: 1
                  }}
                >
                  {qrcode ? (
                    <Image width={200} height={200} preview={false} src={qrcode} />
                  ) : (
                    <div
                      style={{ width: 200, height: 200, lineHeight: '200px', textAlign: 'center', background: 'white' }}
                    >
                      二维码生成中...
                    </div>
                  )}
                </div>
              ) : (
                ''
              )}
            </div>
          )}
          {contextHolder}
          {connectStatus === ConnectStatusEnum.NO && (
            <div className="input-icon-end">
              <span
                style={{
                  cursor: idValid ? 'pointer' : 'not-allowed',
                  color: idValid ? 'rgb(107, 131, 255)' : 'rgba(107, 131, 255, 0.5)'
                }}
                onClick={async () => {
                  if (!idValid) return
                  if (!session?.video_url) {
                    messageApi.open({
                      type: 'warning',
                      content: '请先点击"开始直播"按钮，请确认此房间号已经开播'
                    })
                    return
                  }
                  setConnectStatus(ConnectStatusEnum.ING)
                  if (roomType === 'w') {
                    setShowQR(true)
                  }
                  ws &&
                    ws((_: any) =>
                      _.send(
                        JSON.stringify({
                          type: 'connect_live_message',
                          room_id: extractIdFromUrl(roomType, id),
                          room_type: roomType
                        })
                      )
                    )
                }}
              >
                {roomType === 'w' ? '扫码登陆视频号助手' : '连接直播间'}
              </span>
            </div>
          )}
        </div>
      </div>
      <Input
        className="input-with-hint room-id-input"
        value={id}
        onChange={(e) => setId(e.target.value)}
        placeholder={placeholder}
      />
    </div>
  )
}
