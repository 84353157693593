import '../index.sass'
import { Button, Checkbox, message, Modal, notification, Radio } from 'antd'
import React, { FC, useContext, useMemo, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { BehaviorSubject } from 'rxjs'
import * as uuid from 'uuid'
import { useQueryClient } from '@tanstack/react-query'
import { useAPI } from '../api'
import { resourceUrl, targetViewPort, WithPermission } from '../global-vars'
import { PlansModal } from '../plan/plans-modal'
import { Product as DProduct } from '../plan/states'
import { Product, useProducts } from '../product-center/product_center_page'
import { useBehaviorSubject } from '../react-rx'
import { ReactComponent as DragHandle } from '../res/drag_handle_horizontal.svg'
import { Layer, Resource } from '../room/states'
import { Render } from '../types'
import GptStreaming, { gptStreamingList } from './components/gpt-streaming'
import { DndItem, dndProps } from './draggable'
import { chatGPTContext, generateProductWelfareScript, moveListItem, segments } from './script-card'
import { layers, ScriptSegment } from './states'

export const PlainTextProductList: FC<{
  title?: string
  open: boolean
  reorderable?: boolean
  onCancel: () => void
  onConfirm: () => void
  dType?: string
  groupInfo?: any
}> = ({ title, open, reorderable, onCancel, onConfirm, dType, groupInfo }) => {
  const [tabIndex, setTabIndex] = useState(0)
  const [loading, setLoading] = useState(false)
  const segmentsSubj = useContext(segments)
  const [ss, setSegments] = useBehaviorSubject(segmentsSubj)
  const products = useProducts()
  const productsSubj = useMemo(() => {
    const selectedIds = ss.flatMap(
      (s) => s.attributes?.filter((a) => a.product?.product_id)?.map((a) => a.product!.product_id) || []
    )
    let ps: Product[] = []
    selectedIds
      .map((id) => products.find((p) => p.id === id))
      .filter(Boolean)
      .forEach((p) => {
        if (p && !ps.includes(p)) ps.push(p)
      })
    products.filter((p) => !ps.includes(p)).forEach((p) => ps.push(p))

    return new BehaviorSubject(ps)
  }, [products, ss])
  const localProducts = productsSubj.value
  const currentProducts = useMemo(() => {
    if (tabIndex === 0) {
      return localProducts.filter((p) => !p.platform)
    } else {
      return localProducts.filter((p) => p.platform && p.state === 3)
    }
  }, [tabIndex, localProducts])
  const moveItem = useMemo(() => moveListItem<Product>((_) => productsSubj.next(_(productsSubj.value))), [productsSubj])
  const queryClient = useQueryClient()
  const layersSubj = useContext(layers)
  const selectedProductIds = useMemo(() => {
    const ids: string[] = []
    ss.forEach((_) =>
      _.attributes?.forEach((a) => {
        const id = a.product?.product_id
        if (id && !ids.includes(id) && _.groupInfo?.key === groupInfo?.key) ids.push(id)
      })
    )
    return new BehaviorSubject(ids)
  }, [groupInfo?.key, ss])
  const [ids, setIds] = useBehaviorSubject(selectedProductIds)
  const api = useAPI()
  const url = useContext(resourceUrl)
  const { tw, th } = useContext(targetViewPort)
  const [maxW, maxH] = [Math.min(300, tw), Math.min(300, th)]
  const [promotingProduct, setPromotingProduct] = useState<DProduct>()
  const [, setChatGPT] = useBehaviorSubject(useContext(chatGPTContext))
  const [welfareProductId, setWelfareProductId] = useState((segmentsSubj.value || [])[3]?.key || '')
  const isSup = dType === 'save_up_popularity'
  const [, setGptProductList] = useBehaviorSubject(useContext(gptStreamingList))

  return (
    <>
      <Modal
        title={title}
        open={open}
        onCancel={() => {
          if (loading) return
          setSegments([...ss])
          onCancel()
        }}
        footer={null}
        width={700}
      >
        <div
          style={{
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 20,
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <PlansModal
            product={promotingProduct}
            onConfirm={() => setPromotingProduct(undefined)}
            onCancel={() => setPromotingProduct(undefined)}
          />
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 12, padding: '6px 0' }}>
            <WithPermission permission="admin">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  className={`border-button ${tabIndex === 0 ? 'selected' : ''}`}
                  onClick={() => {
                    setTabIndex(0)
                  }}
                >
                  自主添加
                </Button>
                <Button
                  style={{ marginLeft: 12 }}
                  className={`border-button ${tabIndex === 1 ? 'selected' : ''}`}
                  onClick={() => {
                    setTabIndex(1)
                  }}
                >
                  外部链接
                </Button>
              </div>
            </WithPermission>
            <Link to="/products" style={{ marginLeft: 'auto' }}>
              {'打开商品管理页 >'}
            </Link>
          </div>

          <>
            <div
              style={{
                backgroundColor: '#28282C',
                height: 46,
                boxSizing: 'border-box',
                display: 'flex',
                width: '100%',
                alignItems: 'center'
              }}
            >
              {reorderable && <span style={{ display: 'inline-block', width: 50 }} />}
              <div style={{ display: 'inline-block', width: 50, textAlign: 'center' }}>
                <Render>
                  {function SelectAll() {
                    const selectedAll = useMemo(
                      () => currentProducts?.every((p) => ids.includes(p.id)) || false,
                      // eslint-disable-next-line react-hooks/exhaustive-deps
                      [ids, products]
                    )
                    return (
                      <Checkbox
                        checked={selectedAll}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setIds(Array.from(new Set([...ids, ...(currentProducts?.map((p) => p.id) || [])])))
                          } else {
                            setIds(ids.filter((s) => currentProducts?.every((p) => p.id !== s)))
                            setWelfareProductId('')
                          }
                        }}
                      />
                    )
                  }}
                </Render>
              </div>
              <span style={{ display: 'inline-block', width: 0, flex: 1, paddingLeft: 8 }}>名称</span>
              <span
                style={{
                  display: 'inline-block',
                  width: 150,
                  paddingLeft: 8
                }}
              >
                价格
              </span>
              {isSup && (
                <span
                  style={{
                    display: 'inline-block',
                    width: 150,
                    paddingLeft: 8
                  }}
                >
                  商品属性
                </span>
              )}
            </div>
            <div style={{ width: '100%', height: 0, flex: 1, maxHeight: 500, overflowY: 'auto', overflowX: 'hidden' }}>
              {localProducts
                .filter((l) => (tabIndex === 0 ? !l.platform : l.platform))
                .map((product, index) => (
                  <DndItem key={product.id} id={product.id} index={index} moveItem={moveItem}>
                    <Render>
                      {function Line() {
                        const { drag, drop, isDragging, handlerId } = useContext(dndProps)
                        const dragHandleRef = useRef<any>()
                        drag(dragHandleRef)
                        return (
                          <div
                            key={product.id}
                            ref={drop}
                            data-handler-id={handlerId}
                            style={{
                              display: 'flex',
                              opacity: isDragging || (tabIndex === 1 && product?.state !== 3) ? 0.5 : 1,
                              minHeight: 46,
                              alignItems: 'center',
                              backgroundColor: '#1C1D1E',
                              borderBottom: '1px solid #434343'
                            }}
                          >
                            {reorderable && (
                              <div
                                ref={dragHandleRef}
                                className="drag-handle"
                                style={{
                                  display: 'inline-flex',
                                  alignItems: 'center',
                                  width: 50,
                                  justifyContent: 'center'
                                }}
                              >
                                <DragHandle />
                              </div>
                            )}
                            <div style={{ display: 'inline-block', width: 50, textAlign: 'center' }}>
                              <Render key={product.id}>
                                {function SelectProduct() {
                                  // eslint-disable-next-line react-hooks/exhaustive-deps
                                  const checked = useMemo(() => ids.includes(product.id), [ids, product])
                                  return (
                                    <Checkbox
                                      checked={checked}
                                      disabled={tabIndex === 1 && product?.state !== 3}
                                      onChange={(e) => {
                                        if (e.target.checked && !ids.includes(product.id)) {
                                          setIds([...ids, product.id])
                                        } else {
                                          setIds(ids.filter((_) => _ !== product.id))
                                          product.id === welfareProductId && setWelfareProductId('')
                                        }
                                      }}
                                    />
                                  )
                                }}
                              </Render>
                            </div>
                            <span style={{ display: 'inline-block', width: 0, flex: 1, paddingLeft: 8 }}>
                              {product.name}
                            </span>
                            <span style={{ display: 'inline-block', width: 150, paddingLeft: 8 }}>{`¥ ${
                              product.price / 100
                            }`}</span>
                            {isSup && (
                              <Radio
                                style={{ width: 150, paddingLeft: 8, color: '#fff' }}
                                checked={product.id === welfareProductId}
                                value={product.id}
                                onClick={(_) => {
                                  setWelfareProductId(product.id)
                                  !ids.includes(product.id) && setIds([...ids, product.id])
                                }}
                              >
                                福利品
                              </Radio>
                            )}
                          </div>
                        )
                      }}
                    </Render>
                  </DndItem>
                ))}
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
              <Button
                key="cancel"
                onClick={() => {
                  if (loading) return
                  setSegments([...ss])
                  onCancel()
                }}
              >
                取消
              </Button>
              <Button
                loading={loading}
                key="confirm"
                type="primary"
                style={{ marginLeft: 8 }}
                onClick={async () => {
                  if (isSup && !welfareProductId) {
                    message.warning('请至少选择一个福利商品')
                    return
                  }
                  setLoading(true)
                  try {
                    const idToSegment: [string, ScriptSegment][] = []
                    ss.forEach((s) => {
                      const id = s.attributes?.find((a) => a?.product?.product_id)?.product?.product_id
                      if (id && s.groupInfo?.key === groupInfo.key) idToSegment.push([id, s])
                    })
                    const currentIds = idToSegment.map(([id]) => id)
                    const requestingIds = ids.filter((i) => !currentIds.includes(i))
                    const removingSegs = idToSegment.filter(([id]) => !ids.includes(id)).map(([, s]) => s)
                    const unsortedSegs: (ScriptSegment | undefined)[] = ss.filter((s) => !removingSegs.includes(s))
                    const unsortedSegsCopy = [...unsortedSegs]
                    const retainingSegs = idToSegment.filter(([id]) => ids.includes(id))
                    const sortedProducts = localProducts.filter((p) => ids.includes(p.id))
                    const sortedRetainingProducts = sortedProducts.filter((p) =>
                      idToSegment.some(([id]) => id === p.id)
                    )
                    retainingSegs.forEach(([productId, seg], index) => {
                      const segIndex = unsortedSegs.indexOf(seg)
                      if (segIndex >= 0) {
                        const id = sortedRetainingProducts[index]?.id
                        unsortedSegsCopy[segIndex] = id ? idToSegment.find(([i]) => i === id)?.[1] : undefined
                      }
                    })
                    const segs = unsortedSegsCopy.filter(Boolean).map((_) => _!)
                    const ls = [...(layersSubj.value || [])]
                    const addingSegs = await Promise.all(
                      requestingIds.map(async (id) => {
                        if (groupInfo) {
                          if (
                            segs.some((s) =>
                              s.attributes?.some(
                                (a) => a.product?.product_id === id && s.groupInfo?.key === groupInfo.key
                              )
                            )
                          )
                            return
                        } else {
                          if (segs.some((s) => s.attributes?.some((a) => a.product?.product_id === id))) return
                        }

                        const product = localProducts?.find((p) => p.id === id)
                        if (!product) return
                        const key = uuid.v4()
                        const path = `/products/${id}/resources`
                        const resources = await queryClient.fetchQuery<Resource[]>({
                          queryKey: [path],
                          queryFn: async () => (await api.get<{ resources: Resource[] }>(path)).data?.resources || [],
                          initialData: () => []
                        })
                        if (resources?.length) {
                          ls.push(
                            ...(
                              await Promise.all(
                                resources.map(
                                  (r, index) =>
                                    new Promise<Layer | undefined>((resolve) => {
                                      let image_key = ''
                                      let extra: any
                                      if (r.type === 'images') {
                                        image_key = r.key
                                        extra = { type: 'image' }
                                      } else if (r.type === 'videos') {
                                        image_key = r.key + '?x-oss-process=video/snapshot,t_0,m_fast'
                                        extra = { type: 'video', muted: false, video_key: r.key }
                                      }
                                      if (!extra) return resolve(undefined)
                                      const image = new Image()
                                      image.onload = (e) => {
                                        const img = e.currentTarget as HTMLImageElement
                                        const w = img.naturalWidth
                                        const h = img.naturalHeight
                                        const scale = Math.min(maxW / h, maxH / w)
                                        resolve({
                                          key: uuid.v4(),
                                          image_key,
                                          x: index * 50,
                                          y: index * 50,
                                          w,
                                          h,
                                          sx: scale,
                                          sy: scale,
                                          ...extra,
                                          range: {
                                            key,
                                            range: {
                                              start: 0,
                                              end: 999
                                            }
                                          }
                                        })
                                      }
                                      image.src = url(image_key)
                                    })
                                )
                              )
                            )
                              .filter(Boolean)
                              .map((_) => _!)
                          )
                        }
                        return {
                          title: product.name,
                          key,
                          line_contents: product.line_contents || [],
                          attributes: [{ product: { product_id: id, slot: '' } }],
                          groupInfo
                        }
                      })
                    )

                    addingSegs.forEach((seg, index) => {
                      if (!seg) return
                      const productId = requestingIds[index]
                      const productIndex = sortedProducts.findIndex((p) => p.id === productId)
                      if (productIndex === 0) {
                        segs.splice(0, 0, seg)
                        return
                      }
                      const prevProductId = sortedProducts[productIndex - 1].id
                      const insertingId =
                        segs.findIndex((s) => s.attributes?.some((a) => a?.product?.product_id === prevProductId)) + 1
                      segs.splice(insertingId, 0, seg)
                    })

                    if (isSup) {
                      const pId = ss[3]?.key
                      if (pId !== welfareProductId) {
                        const product = localProducts?.find((p) => p.id === welfareProductId)
                        if (product) {
                          const gptContent = await generateProductWelfareScript(api, product)
                          const data = {
                            product_id: welfareProductId,
                            content: gptContent
                          }
                          setChatGPT([data])
                        }
                      }
                    }
                    setSegments(segs)
                    setGptProductList(localProducts.filter((t) => !t.platform && requestingIds.includes(t.id)))
                    layersSubj.next(ls)
                    onConfirm()
                  } catch (e: any) {
                    notification.error(e)
                  } finally {
                    setLoading(false)
                  }
                }}
              >
                {loading ? 'AI 智能生成中...' : 'AI 智能生成'}
              </Button>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 12 }}>
              商品模块将由AI根据商品智能生成，生成所需时间约为1分钟
            </div>
          </>
        </div>
      </Modal>
      <GptStreaming />
    </>
  )
}
