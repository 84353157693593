import { Affix, Button, message } from 'antd'
import { FC, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { BehaviorSubject } from 'rxjs'
import * as uuid from 'uuid'
import { PlusOutlined, SaveOutlined } from '@ant-design/icons'
import { useAPI, useGet } from '../api'
import { BaseModal } from '../components/base/modal'
import { PatternReplyItem, ProductReply, productReplySubj } from '../components/replay'
import { Providing } from '../global-vars'
import { getSaveOrPlayInfo } from '../lib/common'
import { recordingRow } from '../script-and-layer/script-card'
import { tuple } from '../types'
import { Product } from './product_center_page'

export function useProductReply(productId: number) {
  let data = useGet<{ Data?: ProductReply }>(`/products/${productId}/reply`, {
    suspense: true,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  }).data?.Data as ProductReply
  if (!data || !data.id) {
    data = {
      id: productId + '',
      category: 'product',
      replies: []
    }
  }
  const [productReply, setProductReply] = useState<ProductReply>(data)
  return tuple(productReply, setProductReply)
}

export const ProductReplyList: FC<{}> = () => {
  const api = useAPI()
  const location = useLocation()
  const product = location.state as Product
  const [productReply, setProductReply] = useProductReply(parseInt(product.id))
  const subj = useMemo(() => new BehaviorSubject(productReply || ({} as ProductReply)), [productReply])
  const recordingRowSubj = useMemo(() => new BehaviorSubject<any>(undefined), [])
  const [baseModalInfo, setBaseModalInfo] = useState<any>()
  const [onOk] = useState<any>()
  const navigator = useNavigate()

  const onAdd = () => {
    productReply.replies.push({
      key: uuid.v4(),
      pattern: '这里填写问题',
      replies: [],
      conditions: []
    })
    setProductReply({ ...productReply })
  }
  const onSave = async () => {
    try {
      await Promise.all([api.post(`/products/${product.id}/reply`, subj.value, { refreshMode: 'disabled' } as any)])
      message.success('保存成功')
      navigator(-1)
    } catch (e: any) {
      const status = e?.response?.status
      const info = getSaveOrPlayInfo(status)
      setBaseModalInfo(info)
    }
  }
  return (
    <div style={{ padding: '84px 44px 0px 44px', fontSize: '0.9em' }}>
      <BaseModal info={baseModalInfo} onOk={onOk} />
      <div className="section-title" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        [{product.name}]商品问答库管理
        <Affix offsetTop={10}>
          <div>
            <Button icon={<PlusOutlined />} onClick={onAdd}>
              添加问答
            </Button>{' '}
            {/* <Button icon={<AimOutlined />} onClick={onTest}>
              测试一下
            </Button>{' '} */}
            <Button icon={<SaveOutlined />} type="primary" onClick={onSave}>
              保存
            </Button>
          </div>
        </Affix>
      </div>
      <Providing
        _={(p) => {
          p(productReplySubj, subj)
          p(recordingRow, recordingRowSubj)
        }}
      >
        {subj.value.replies.map((replyItem) => (
          <PatternReplyItem key={replyItem.key} id={replyItem.key} />
        ))}
      </Providing>
    </div>
  )
}
