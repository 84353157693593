import qrCode from '../res/qr.png'

export const getSaveOrPlayInfo = (status: number | string, data?: any) => {
  if (status === 430) {
    return {
      title: '部分资源已调整',
      desc: '由于[声音/形象]部分资源调整，当前账号不可用，已为您自动更换. 请您检查无误后再开播',
      confirmBtnext: '已知晓'
    }
  } else if (status === 431) {
    return {
      title: '套餐等级不够，部分资源不可用',
      desc: '由于[声音/形象]部分资源为更高级别可用，当前账号等级不够，请您前往配置页面调整为可用资源或升级套餐',
      confirmBtnext: '已知晓'
    }
  } else if (status === 432) {
    return {
      title: '套餐已过期',
      desc: (
        <div className="detail">
          套餐已过期，请联系商务续期
          <img src={qrCode} alt="qr" />
        </div>
      ),
      confirmBtnext: '去订阅'
    }
  } else if (status === 433 || status === 403) {
    return {
      title: '套餐当月可开播时长已用完',
      desc: (
        <div className="detail">
          当前套餐当月可开播时长已用完，请联系商务充值时长
          <img src={qrCode} alt="qr" />
        </div>
      )
    }
  } else if (status === 434) {
    return {
      title: '直播时长即将用尽',
      desc: (
        <div className="detail">
          直播时长即将用尽，为了不影响连续直播，建议联系商务充值时长后开播
          <img src={qrCode} alt="qr" />
        </div>
      ),
      confirmBtnext: '继续直播'
    }
  } else if (status === 450) {
    return {
      title: 'VIP用户专享',
      desc: (
        <div className="detail">
          订阅套餐后可以使用AI创作功能，请联系商务充值套餐
          <img src={qrCode} alt="qr" />
        </div>
      ),
      confirmBtnext: '去订阅'
    }
  } else if (status === 'live-time-check') {
    if (data) {
      const time = Math.floor(data.single_live_limit / 3600)
      if (data.result === 0) {
        return {
          title: `到达单场开播时长上限（${time}小时），直播自动结束`,
          desc: (
            <div>{`本次开播到达单场开播时长上限（${time}小时），直播已自动结束，如需继续直播，请重新点击“开始直播”`}</div>
          ),
          confirmBtnext: '已知晓'
        }
      } else {
        return {
          title: `即将到达单场开播时长上限（${time}小时）`,
          desc: (
            <div>
              <div>{`${data.result}分钟后到达开播时长上限（${time}小时），到时会自动结束直播，为避免影响到您的正常直播，请尽快结束相关平台的直播`}</div>
              <div>如需继续开播，请在飞影AI平台本场直播结束后，重新点击“开始直播”</div>
            </div>
          ),
          confirmBtnext: '已知晓'
        }
      }
    }
  }
  return null
}

export function getMsgTypeText(type: string) {
  if (type === 'comment') return '评论'
  else if (type === 'like') return '点赞'
  else if (type === 'social') return '分享'
  else if (type === 'enter') return '进入'
  else if (type === 'gift') return '礼物'
  return ''
}
