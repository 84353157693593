import axios from 'axios'
import { useContext, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useAuthAPI, useGet } from '../api'
import { resourceUrl } from '../global-vars'

export type UserInfo = {
  uid?: number
  user_id?: string
  nickname?: string
  phone?: string
  avatar?: string
  email?: string
  password_init?: number
  certified?: number
  created_at?: number
}

const empty = {}

export function useUserInfo(): UserInfo {
  const authApi = useAuthAPI()
  const path = '/api/v1/meta/users/info/get'
  return (
    useQuery({
      queryKey: [`/user${path}`],
      suspense: true,
      refetchInterval: 30000,
      queryFn: async () => {
        const userInfo = (await authApi.get<{ userinfo: UserInfo }>(path)).data?.userinfo || {}
        userInfo.user_id = userInfo.uid?.toString()
        return userInfo
      }
    }).data || empty
  )
}

export function useAgents() {
  const url = useContext(resourceUrl)
  const config = useQuery({
    queryKey: ['/agents'],
    suspense: true,
    queryFn: async () => {
      return (await axios.get(url('agents.json'))).data
    }
  }).data
  return config
}

export function useUserTimeBlacklist() {
  const url = useContext(resourceUrl)
  const config = useQuery({
    queryKey: ['/time-blacklist'],
    suspense: true,
    queryFn: async () => {
      return (await axios.get(url(`time-blacklist.json?v=${Date.now()}`))).data
    }
  }).data
  return config
}

export function useUserRBAC() {
  const url = useContext(resourceUrl)
  const config = useQuery({
    queryKey: ['/rbac'],
    suspense: true,
    queryFn: async () => {
      return (await axios.get(url(`rbac.json?v=${Date.now()}`))).data
    }
  }).data
  return config
}

export type AvailableTime = {
  available_live_time: number
  oil_bag_surplus: number
  current_month_end_time: Date
}

export function useAvailableLiveTime(): AvailableTime | undefined {
  const at = useGet<AvailableTime>('/vip/available_live_time', { suspense: true }).data
  return useMemo(
    () =>
      at && {
        available_live_time: at.available_live_time,
        oil_bag_surplus: at.oil_bag_surplus,
        current_month_end_time:
          typeof at.current_month_end_time === 'string'
            ? new Date(Date.parse(at.current_month_end_time))
            : at.current_month_end_time
      },
    [at]
  )
}
