import { Input } from 'antd/lib'
import React from 'react'

interface NumericInputProps {
  style?: React.CSSProperties
  className?: string
  disabled?: boolean
  value: number
  onChange: (value: number) => void
}

export const NumericInput = (props: NumericInputProps) => {
  const { onChange } = props
  return (
    <Input
      {...props}
      onChange={(e) => {
        const { value: inputValue } = e.target
        const reg = /^-?\d*(\.\d*)?$/
        if (reg.test(inputValue)) {
          onChange(Number(inputValue))
        }
      }}
      placeholder="Input a number"
      maxLength={16}
    />
  )
}
