import { Button } from 'antd'
import { FC, PropsWithChildren, Suspense, useContext, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { BehaviorSubject, filter, take } from 'rxjs'
import * as Sentry from '@sentry/react'
import { useQueryClient } from '@tanstack/react-query'
import { userState } from '../auth/states'
import { BaseModal } from '../components/base/modal'
import { RetryOnError } from '../errors'
import {
  legacyRoomFacade,
  LegacyRoomFacades,
  LivePanels,
  panel,
  PersonalPanels,
  Providing,
  roomFacade,
  roomId,
  socketContext,
  socketTransings
} from '../global-vars'
import { useUserPackage } from '../plan/states'
import { ReactComponent as ChevronLeft } from '../res/chevron-left.svg'
import { ReactComponent as CogSquare } from '../res/cog-square.svg'
import { ReactComponent as Lock } from '../res/lock.svg'
import { ReactComponent as LogoMedium } from '../res/logo-medium.svg'
import { ReactComponent as Logo } from '../res/logo.svg'
import { ReactComponent as Logout } from '../res/logout.svg'
import { ReactComponent as Material } from '../res/material.svg'
import { ReactComponent as Monitor } from '../res/monitor.svg'
import { ReactComponent as Product } from '../res/product.svg'
import { ReactComponent as SettingsSquare } from '../res/settings-square.svg'
import { ReactComponent as UserIcon } from '../res/user.svg'
import { saving, useRooms } from '../room/states'
import { Render } from '../types'
import { useAvailableLiveTime, useUserInfo, useUserTimeBlacklist } from '../user-center/states'

export const MainPanel: FC<PropsWithChildren> = ({ children }) => {
  const p = useContext(panel)
  const userSubj = useContext(userState)

  return (
    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%' }}>
      <div
        style={{
          width: 200,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#18181A',
          position: 'fixed'
        }}
      >
        <div
          style={{
            color: 'white',
            paddingLeft: 20,
            paddingTop: 40,
            marginBottom: 24,
            fontSize: 22,
            fontWeight: 'bolder',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <LogoMedium style={{ marginRight: 8 }} />
          飞影 AI 直播
        </div>
        <div className="sub-section-title" style={{ paddingLeft: 24, marginTop: 56, marginBottom: 20, fontSize: 12 }}>
          直播中心
        </div>
        <Item current={p} path="templates">
          <Logo className="icon" width={24} />
          首页
        </Item>
        <Item current={p} path="rooms">
          <Monitor className="icon" />
          直播管理
        </Item>
        <Item current={p} path="products">
          <Product className="icon" />
          商品管理
        </Item>
        <Item current={p} path="materials">
          <Material className="icon" />
          素材管理
        </Item>
        <div className="sub-section-title" style={{ paddingLeft: 24, marginTop: 20, marginBottom: 20, fontSize: 12 }}>
          个人中心
        </div>
        <Item current={p} path="account">
          <UserIcon className="icon" />
          账户信息
        </Item>

        <Item current={p} path="safe">
          <Lock className="icon" />
          安全中心
        </Item>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'end',
            color: 'white',
            height: 0,
            flex: 1,
            width: '100%',
            paddingLeft: 24,
            paddingBottom: 40
          }}
        >
          <Suspense>
            <Render>
              {function UserInfo() {
                return <div>{useUserInfo().phone}</div>
              }}
            </Render>
          </Suspense>
          <Sentry.ErrorBoundary fallback={<></>}>
            <Suspense>
              <Render>
                {function Plan() {
                  const availableTimeSeconds = useAvailableLiveTime()?.available_live_time || 0
                  const userPackage = useUserPackage()
                  const product = userPackage.product
                  const monthlyDurationHours = product?.permission?.monthly_duration || 0
                  const { phone } = useUserTimeBlacklist()
                  const userInfo = useUserInfo()

                  if (!product) return <></>
                  return (
                    <>
                      <div style={{ marginTop: 12, fontWeight: 'bolder' }}>{product.name}</div>
                      {!phone.includes(userInfo.phone) && (
                        <div style={{ marginTop: 8 }}>
                          时长剩余 {Math.ceil(availableTimeSeconds / 3600)}/{monthlyDurationHours} 小时
                        </div>
                      )}
                    </>
                  )
                }}
              </Render>
            </Suspense>
          </Sentry.ErrorBoundary>
          <div
            style={{ color: '#6B83FF', display: 'flex', alignItems: 'center', marginTop: 20, cursor: 'pointer' }}
            onClick={() => userSubj.next(undefined)}
          >
            登出
            <Logout />
          </div>
        </div>
      </div>
      <div style={{ width: 'calc(100vw - 206px)', marginLeft: '206px', height: '100%' }}>
        <RetryOnError>
          <Suspense
            fallback={
              <div style={{ marginTop: 84, marginLeft: 44 }}>
                <div className="section-title">{{ ...PersonalPanels, ...LivePanels }[p]}</div>
              </div>
            }
          >
            {children}
          </Suspense>
        </RetryOnError>
      </div>
    </div>
  )
}

export const RoomFacade: FC<PropsWithChildren> = ({ children }) => {
  const queryClient = useQueryClient()
  const savingSubj = useMemo(() => new BehaviorSubject(false), [])
  const rid = useContext(roomId)
  const ws = useContext(socketContext)
  const wsTransing = useContext(socketTransings)
  const [info, setInfo] = useState<any>()
  const navigate = useNavigate()

  const showConfirmModal = (callback: () => void) => {
    setInfo({
      desc: (
        <div>
          <p>当前页面有音频处理任务尚未完成，离开页面会导致任务中断。</p>
        </div>
      ),
      footer: [
        <div>
          <Button
            onClick={() => {
              setInfo(undefined)
              ws.value.close()
              ws.next(undefined)
              wsTransing.next([])
              callback()
            }}
          >
            不保存，离开页面
          </Button>
        </div>,
        <Button style={{ marginLeft: 30 }} type="primary" onClick={() => setInfo(undefined)}>
          继续等待任务完成
        </Button>
      ]
    })
  }

  return (
    <div style={{ height: '100%', width: '100%', flexDirection: 'column', display: 'flex' }}>
      <div style={{ display: 'flex', paddingLeft: 24, paddingTop: 24, paddingRight: 24 }} className="room-header">
        <Link
          to="/rooms"
          replace
          style={{
            color: '#6B83FF',
            marginRight: 12,
            display: 'inline-flex',
            alignContent: 'center',
            alignItems: 'center',
            textDecoration: 'none'
          }}
          onClick={(e) => {
            if (ws.value && wsTransing.value?.length) {
              e.preventDefault()
              showConfirmModal(() => {
                navigate('/room')
              })
            }
          }}
        >
          <ChevronLeft style={{ marginRight: 8 }} />
          返回
        </Link>
        <div style={{ color: '#6D6D6D', display: 'inline-flex', alignItems: 'center', marginRight: 8 }}>
          <Monitor
            style={{
              height: 20,
              boxSizing: 'border-box',
              width: 18,
              marginRight: 4
            }}
          />
          直播管理 / 工作台 /
        </div>
        <div className="ellipsis" style={{ flex: 1, color: 'white', maxWidth: 130, lineHeight: '32px' }}>
          <Suspense fallback={<></>}>
            <Render>
              {function Description() {
                const rid = useContext(roomId)
                const rooms = useRooms()
                const room = rooms.find((r) => r.id === rid)
                return <>{room?.name || '未命名'}</>
              }}
            </Render>
          </Suspense>
        </div>
        <Render>
          {function Tabs() {
            const facade = useContext(roomFacade)

            const toLive = () => {
              savingSubj
                .pipe(
                  filter((_) => !_),
                  take(1)
                )
                .subscribe(() => {
                  queryClient.invalidateQueries({ queryKey: [`/rooms/${rid}/reaction`] })
                  queryClient.invalidateQueries({ queryKey: [`/rooms/${rid}/setting`] })
                })
            }

            return (
              <>
                <Link to="../setting" style={{ marginLeft: 'auto' }}>
                  <Button
                    className={['border-button', facade === 'setting' ? 'selected' : undefined]
                      .filter(Boolean)
                      .join(' ')}
                    onClick={(e) => {
                      if (facade === 'setting') {
                        e.preventDefault()
                      }
                    }}
                    icon={<CogSquare />}
                  >
                    直播间配置
                  </Button>
                </Link>
                <Link to="../live">
                  <Button
                    className={['border-button', facade === 'live' ? 'selected' : undefined].filter(Boolean).join(' ')}
                    style={{ marginLeft: 8 }}
                    onClick={(e) => {
                      if (facade === 'live') {
                        e.preventDefault()
                        return
                      }

                      if (ws.value && wsTransing.value?.length) {
                        e.preventDefault()
                        showConfirmModal(() => {
                          toLive()
                          navigate('../live')
                        })
                      } else {
                        toLive()
                      }
                    }}
                    icon={<SettingsSquare />}
                  >
                    直播控制台
                  </Button>
                </Link>
              </>
            )
          }}
        </Render>
      </div>
      <BaseModal info={info} />
      <div style={{ width: '100%', height: 0, flex: 1 }}>
        <Providing _={(_) => _(saving, savingSubj)}>
          <RetryOnError>
            <Suspense fallback={<></>}>{children}</Suspense>
          </RetryOnError>
        </Providing>
      </div>
    </div>
  )
}
export const LegacyRoomFacade: FC<PropsWithChildren> = ({ children }) => {
  const facade = useContext(legacyRoomFacade)
  const navigate = useNavigate()
  return (
    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%' }}>
      <div style={{ width: 200, height: '100%', backgroundColor: '#18181A' }}>
        <div style={{ padding: 24, boxSizing: 'border-box' }}>
          <div style={{ color: '#6D6D6D', fontSize: 12, marginTop: 4 }}>
            <Monitor
              style={{
                height: 20,
                boxSizing: 'border-box',
                width: 18,
                marginRight: 4,
                transform: 'translate(0, 5px)'
              }}
            />
            直播管理 / 工作台
          </div>
          <Button style={{ marginTop: 20, marginBottom: 20 }} type="primary" onClick={() => navigate('/rooms')}>
            返回
          </Button>
          <Suspense fallback={<></>}>
            <Render>
              {function Description() {
                const rid = useContext(roomId)
                const room = useRooms().find((r) => r.id === rid)
                if (!room) return <></>
                return (
                  <>
                    <div
                      style={{
                        color: 'white',
                        fontWeight: 'bold',
                        fontSize: 18,
                        marginBottom: 14,
                        wordBreak: 'break-all'
                      }}
                    >
                      {room.name}
                    </div>
                    <div
                      style={{
                        color: '#B4B4B4',
                        wordBreak: 'break-all'
                      }}
                    >
                      {room.description}
                    </div>
                  </>
                )
              }}
            </Render>
          </Suspense>
        </div>
        <div className="horizontal-separator" />
        {Object.entries(LegacyRoomFacades).map(([f, text]) => (
          <Item current={facade} key={f} path={f}>
            {text}
          </Item>
        ))}
      </div>
      <div style={{ width: 'calc(100vw - 206px)', height: '100%' }}>
        <Suspense>{children}</Suspense>
      </div>
    </div>
  )
}
const Item: FC<PropsWithChildren<{ current: string; path: string }>> = ({ children, current, path }) => {
  return (
    <Link to={`../${path}`} style={{ textDecoration: 'none' }}>
      <div className={['panel', current === path ? 'selected' : undefined].filter(Boolean).join(' ')}>{children}</div>
    </Link>
  )
}
