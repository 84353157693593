import { createContext, useContext, useMemo } from 'react'
import { BehaviorSubject } from 'rxjs'
import { useGet } from '../api'
import { useBehaviorSubject } from '../react-rx'

export type User = {
  token: string
  refresh_token: string
}

const Permissions = ['live', 'admin'] as const
export type Permission = Partial<Record<(typeof Permissions)[number], boolean>>
export const currentUser = createContext<User>(undefined as any)
export const userState = createContext<BehaviorSubject<User | undefined>>(undefined as any)

export const permissionOverride = createContext<BehaviorSubject<Permission>>(new BehaviorSubject<Permission>({}))

export function useRemotePermission(): Permission {
  return useGet<Permission>('/permission', { refetchInterval: 30000, suspense: true }).data || {}
}

export function usePermission(): Permission {
  const [override] = useBehaviorSubject(useContext(permissionOverride))
  const remote = useRemotePermission()
  return useMemo(() => ({ ...remote, ...override }), [override, remote])
}
