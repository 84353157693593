import './index.scss'
import { Button, Modal } from 'antd'
import React, { FC, ReactElement, useEffect, useState } from 'react'
import { ReactComponent as Bang } from '../../../res/bang.svg'

interface IProps {
  info?: {
    width?: number
    title?: string | ReactElement
    desc?: string | ReactElement
    confirmBtnext?: string | ReactElement
    footer?: React.ReactNode
  }
  onCancel?: (e: React.MouseEvent<HTMLButtonElement>) => void
  onOk?: () => void
}

export const BaseModal: FC<IProps> = (props) => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(!!props.info)
  }, [props.info])

  if (!props.info) {
    return null
  }

  const onCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    props.onCancel ? props.onCancel(e) : setOpen(false)
  }

  const onOk = () => {
    props.onOk ? props.onOk() : setOpen(false)
  }

  return (
    <Modal
      title="提示"
      width={props.info.width || 600}
      open={open}
      onCancel={onCancel}
      footer={
        props.info.footer
          ? props.info.footer
          : [
              <Button type="primary" onClick={onOk}>
                {props.info?.confirmBtnext || '确定'}
              </Button>
            ]
      }
      className="base-modal"
    >
      <div className="base-modal-content">
        <Bang />
        <div className="base-modal-title">{props.info?.title}</div>
        <div className="base-modal-desc">{props.info?.desc}</div>
      </div>
    </Modal>
  )
}
