import './index.scss'
import { Button, Input, message, Modal, Popconfirm, Progress, Spin, Switch, Tabs } from 'antd'
/* eslint-disable jsx-a11y/anchor-is-valid */
import { createContext, FC, Suspense, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import {
  BehaviorSubject,
  concatMap,
  connectable,
  debounce,
  distinctUntilChanged,
  filter,
  map,
  retry,
  Subject,
  Subscription,
  timer,
  withLatestFrom
} from 'rxjs'
import { fromPromise } from 'rxjs/internal/observable/innerFrom'
import * as uuid from 'uuid'
import { PlusCircleOutlined } from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import { useAPI } from '../api'
import { currentUser, usePermission } from '../auth/states'
import { BaseModal } from '../components/base/modal'
import { RetryOnError } from '../errors'
import { devDomains, prodDomains, Providing, resourceUrl, roomId, targetViewPort, wsContext } from '../global-vars'
import { getSaveOrPlayInfo } from '../lib/common'
import { getTimeDesc } from '../lib/util'
import { LiveLimitModal } from '../live'
import { useSessionConnection } from '../live/websocket'
import { PlansModal } from '../plan/plans-modal'
import { Product } from '../plan/states'
import { Product as PProduct } from '../product-center/product_center_page'
import { useBehaviorSubject } from '../react-rx'
import { ReactComponent as Check } from '../res/check.svg'
import { ReactComponent as Play } from '../res/play.svg'
import { ReactComponent as Spinner } from '../res/spin.svg'
import { ReactComponent as Trash } from '../res/trash.svg'
import { ReactComponent as TriangleDown } from '../res/triangle-down.svg'
import { ReactComponent as TriangleRight } from '../res/triangle-right.svg'
import { gptStreamingList } from '../script-and-layer/components/gpt-streaming'
import { IntelligentUniversalScriptEditor } from '../script-and-layer/intelligent-universal-script-editor'
import { LayerPreview } from '../script-and-layer/ranged-layers'
import {
  asrTag,
  chatGPTContext,
  copyLines,
  multipleSectionKey,
  multipleVideos,
  PlainTextScriptEditor,
  plainTextScriptEditorConfig,
  randomLineIndex,
  recordingRow,
  scriptCreateRandomLine,
  scriptTemplateTypes,
  scriptType,
  scriptTypeFromTemplateType,
  sections,
  segmentCreateRandomLine,
  segments,
  segmentsChange,
  segmentsIndex
} from '../script-and-layer/script-card'
import { ScriptTemplateList } from '../script-and-layer/script-template-list'
import {
  addLayer,
  addLayerSignal,
  background,
  bgImg,
  editingLayer,
  layers,
  layersChange,
  LineContent,
  save,
  ScriptSegment,
  selectedRange,
  SelectedRange,
  selectedRangeChange,
  useCreateExplosiveProductConfig,
  useIntelligentUniversalConfig,
  useSaveUpPopularityConfig,
  useSecKillConfig,
  useUniversalPlainTextConfig,
  voiceName,
  w2lVid,
  w2lVideo
} from '../script-and-layer/states'
import { flatten, Render } from '../types'
import { useUserInfo } from '../user-center/states'
import { ReactionRuleEditor, reactionSetting } from './reaction'
import { BackgroundList, DecorList, resourceEditable, UploadModal, W2lVideoList } from './room-setting'
import {
  Layer,
  ReactionSetting,
  Resource,
  roomEditorPanel,
  RoomSetting,
  saving,
  scriptTab,
  SectionType,
  useReactionSetting,
  useRoomSetting,
  useSession,
  useW2lVideos
} from './states'

function asLayer(f: RoomSetting['frames'][number]) {
  return {
    key: f.key,
    x: f.x || 0,
    y: f.y || 0,
    w: f.w || 1,
    h: f.h || 1,
    sx: 1,
    sy: 1,
    range: f.range && {
      key: f.range.key,
      range: {
        start: f.range.line_start || 0,
        end: f.range.line_end || 0
      }
    }
  }
}

export const RoomEditor: FC = () => {
  const [tab, setTab] = useState('video' as 'video' | 'background' | 'decors' | 'script')
  const rid = useContext(roomId)
  const reaction = useReactionSetting()?.reaction
  const setting = useRoomSetting(true)
  const [version, setVersion] = useBehaviorSubject(useMemo(() => new BehaviorSubject(setting.version || ''), [setting]))
  const { frames, script, voice_name, ratio } = useRoomSetting()?.setting || {}
  const sectionsSubj = useMemo(() => new BehaviorSubject<SectionType[]>(script?.sections || []), [script])
  const multipleVideosSubj = useMemo(() => new BehaviorSubject<boolean>(!!script?.multiple_w2l_videos), [script])
  const multipleSectionKeySubj = useMemo(() => new BehaviorSubject<string>(''), [])
  const segmentsSubj = useMemo(() => new BehaviorSubject<ScriptSegment[]>(script?.segments || []), [script])
  const scriptTypeSubj = useMemo(
    () => new BehaviorSubject<(typeof scriptTemplateTypes)[number]>(script?.script_template_type || 'plain_text'),
    [script]
  )
  const asrTagSubj = useMemo(() => new BehaviorSubject<boolean>(false), [])
  const copyLinesSubj = useMemo(() => new BehaviorSubject<LineContent[]>(undefined as any), [])
  const chatGPTContextSubj = useMemo(() => new BehaviorSubject<{ content: string[]; product_id: string }[]>([]), [])
  const segmentsChangeSubj = useMemo(() => new Subject<ScriptSegment[]>(), [])
  const rangeSubj = useMemo(() => new BehaviorSubject(undefined as SelectedRange | undefined), [])
  const rangeChangeSubj = useMemo(() => new Subject<SelectedRange | undefined>(), [])
  const recordingRowSubj = useMemo(() => new BehaviorSubject<any>(undefined), [])
  const randomLineIndexSubj = useMemo(() => new BehaviorSubject<number>(undefined as any), [])
  const segmentsIndexSubj = useMemo(() => new BehaviorSubject<number>(undefined as any), [])
  const scriptCreateRandomLineSubj = useMemo(() => new BehaviorSubject<boolean>(false as any), [])
  const segmentCreateRandomLineSubj = useMemo(() => new BehaviorSubject<string[]>([] as any), [])
  const gptStreamingListSubj = useMemo(() => new BehaviorSubject<PProduct[]>([] as any), [])
  const w2lVideos = useW2lVideos()
  const videoPreview = useMemo(() => {
    const videoId = frames?.find((f) => !!f.w2l_video?.video_id && f.key === 'video')?.w2l_video?.video_id
    return w2lVideos?.videos.find((v) => v.video_id === videoId)?.preview
  }, [frames, w2lVideos])
  const layersSubj = useMemo(
    () =>
      new BehaviorSubject<Layer[]>(
        frames?.flatMap<Layer>((f) => {
          switch (true) {
            case f.key === 'background':
              return []
            case !!f.image?.key:
              return [{ ...asLayer(f), type: 'image', image_key: f.image!.key }]
            case !!f.w2l_video?.user_repo_key:
              return [
                {
                  ...asLayer(f),
                  type: 'multiple_w2l_video',
                  user_repo_key: f.w2l_video?.user_repo_key as string,
                  image_key:
                    f.w2l_video?.user_repo_key +
                    (f.w2l_video?.user_repo_key?.includes('.png') || f.w2l_video?.user_repo_key?.includes('.jpg')
                      ? ''
                      : '?x-oss-process=video/snapshot,t_0,m_fast'),
                  disable_alpha_channel: f.w2l_video!.disable_alpha_channel ?? false
                }
              ]
            case !!f.w2l_video?.video_id:
              return f.key === 'video'
                ? [
                    {
                      ...asLayer(f),
                      type: 'w2l_video',
                      video_id: f.w2l_video!.video_id,
                      image_key:
                        videoPreview ||
                        `wl/${f.w2l_video?.video_id}/preview.${
                          w2lVideos?.videos.find((v) => v.video_id === f.w2l_video?.video_id)?.no_alpha ? 'jpg' : 'png'
                        }`,
                      disable_alpha_channel: f.w2l_video!.disable_alpha_channel ?? false
                    }
                  ]
                : [
                    {
                      ...asLayer(f),
                      type: 'multiple_w2l_video',
                      user_repo_key: `wl/${f.w2l_video?.video_id}/preview.${
                        w2lVideos?.videos.find((v) => v.video_id === f.w2l_video?.video_id)?.no_alpha ? 'jpg' : 'png'
                      }`,
                      image_key: `wl/${f.w2l_video?.video_id}/preview.${
                        w2lVideos?.videos.find((v) => v.video_id === f.w2l_video?.video_id)?.no_alpha ? 'jpg' : 'png'
                      }`,
                      disable_alpha_channel: f.w2l_video!.disable_alpha_channel ?? false
                    }
                  ]

            case !!f.loop_video?.key:
              return [
                {
                  ...asLayer(f),
                  type: 'video',
                  video_key: f.loop_video!.key,
                  image_key: f.loop_video!.key + '?x-oss-process=video/snapshot,t_0,m_fast',
                  muted: !!f?.loop_video?.muted
                }
              ]

            case !!f.intro_video:
              return [
                {
                  ...asLayer(f),
                  type: 'intro_video',
                  intro_video: {
                    key: f.intro_video?.key as string,
                    alpha_key: f.intro_video?.alpha_key as string,
                    prev_overlap_msec: (f.intro_video?.prev_overlap_msec || 0) / 1000,
                    current_overlap_msec: (f.intro_video?.current_overlap_msec || 0) / 1000
                  },
                  image_key: (f.intro_video?.key + '?x-oss-process=video/snapshot,t_0,m_fast') as string
                }
              ]
          }
          return []
        }) || []
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [frames, videoPreview]
  )
  const addLayerSubj = useMemo(() => new Subject<addLayerSignal>(), [])
  const layersChangedSubj = useMemo(() => new Subject<void>(), [])
  const editingLayerKey = useMemo(() => new BehaviorSubject<string>(''), [])
  const backgroundSubj = useMemo(
    () =>
      new BehaviorSubject<bgImg | undefined>(
        ((f?: RoomSetting['frames'][number]) => {
          if (!f?.image?.key) return
          return {
            key: f.image.key,
            w: f.w,
            h: f.h
          }
        })(frames?.find((f) => f.key === 'background'))
      ),
    [frames]
  )
  const w2lVideoSubj = useMemo(
    () =>
      new BehaviorSubject<w2lVid | undefined>(
        ((f?: RoomSetting['frames'][number]) => {
          if (f?.w2l_video?.video_id && f.key === 'video') {
            return {
              video_id: f.w2l_video.video_id,
              w: f.w,
              h: f.h,
              no_alpha: w2lVideos?.videos.find((v) => v.video_id === f.w2l_video?.video_id)?.no_alpha,
              image_key:
                w2lVideos?.videos.find((v) => v.video_id === f.w2l_video?.video_id)?.preview ||
                `wl/${f.w2l_video?.video_id}/preview.${
                  w2lVideos?.videos.find((v) => v.video_id === f.w2l_video?.video_id)?.no_alpha ? 'jpg' : 'png'
                }`
            }
          }
          return
        })(frames?.find((f) => !!f.w2l_video?.video_id && f.key === 'video'))
      ),
    [frames, w2lVideos]
  )
  const voiceNameSubj = useMemo(() => new BehaviorSubject<string>(voice_name || ''), [voice_name])
  const saveSubj = useMemo(() => new Subject<boolean>(), [])
  const reactionSubj = useMemo(() => new BehaviorSubject(reaction || ({} as ReactionSetting)), [reaction])
  const scriptTabSubj = useMemo(
    () =>
      new BehaviorSubject<
        'script' | 'resource' | 'script_type' | 'resource_change' | 'multiple_videos' | 'intro_video'
      >('script'),
    []
  )
  const reselectSubj = useMemo(() => new Subject<void>(), [])
  const api = useAPI()
  const [spinning, setSpinning] = useState(false)
  const savingSubj = useContext(saving)
  const [isSaving, setIsSaving] = useBehaviorSubject(savingSubj)
  const [baseModalInfo, setBaseModalInfo] = useState<any>()
  const [, ws] = useSessionConnection()
  const session = useSession()

  useEffect(() => {
    const subscription = new Subscription()
    const editingLayer = connectable(
      editingLayerKey.pipe(
        distinctUntilChanged((l, r) => !l && !r),
        filter(Boolean),
        withLatestFrom(layersSubj),
        map(([key, layers]) => layers?.find((l) => l.key === key))
        // distinctUntilChanged((l, r) => l?.key === r?.key && rangeEquals(l?.range, r?.range)),
      )
    )

    subscription.add(
      editingLayer
        .pipe(
          map((l) => l?.range),
          filter(Boolean)
        )
        .subscribe(rangeChangeSubj)
    )

    subscription.add(editingLayer.connect())
    return () => subscription.unsubscribe()
  }, [editingLayerKey, layersSubj, rangeChangeSubj, rangeSubj])
  useEffect(() => {
    const subscription = w2lVideoSubj.pipe(filter(Boolean)).subscribe(({ video_id, w, h, image_key }) => {
      const ls = layersSubj.value || []
      const currentIndex = ls.findIndex((l) => l.type === 'w2l_video' && l.key === 'video')
      if (currentIndex >= 0) {
        if (flatten(ls[currentIndex]).video_id === video_id) return
        ls.splice(currentIndex, 1, {
          ...ls[currentIndex],
          type: 'w2l_video',
          w,
          h,
          image_key,
          video_id
        })
      } else {
        ls.unshift({
          type: 'w2l_video',
          key: 'video',
          x: 0,
          y: 0,
          sx: 1,
          sy: 1,
          w,
          h,
          image_key,
          video_id
        })
      }
      layersSubj.next([...ls])
    })
    return () => subscription.unsubscribe()
  }, [layersSubj, w2lVideoSubj])
  useEffect(() => {
    const subscription = addLayerSubj
      .pipe(withLatestFrom(rangeSubj))
      .subscribe(
        ([
          { w, h, resource_key, type, persistent, section_key, alpha_key, current_overlap_msec, prev_overlap_msec },
          range
        ]) => {
          const key = uuid.v4()
          let copy = [...(layersSubj.value || [])]
          switch (type) {
            case 'image':
              copy.push({
                type,
                key,
                x: 0,
                y: 0,
                w,
                h,
                sx: 1,
                sy: 1,
                image_key: resource_key,
                range: persistent ? undefined : range
              })
              break
            case 'video':
              copy.push({
                type: 'video',
                key,
                x: 0,
                y: 0,
                w,
                h,
                sx: 1,
                sy: 1,
                image_key: resource_key + '?x-oss-process=video/snapshot,t_0,m_fast',
                video_key: resource_key,
                range: persistent ? undefined : range,
                muted: false
              })
              break
            case 'multiple_w2l_video':
              const isVideo = !(resource_key.includes('.png') || resource_key.includes('.jpg'))
              if (copy.some((c) => c.key === section_key)) {
                copy = copy.map((c) => {
                  return c.key === section_key
                    ? {
                        ...c,
                        w,
                        h,
                        image_key: `${resource_key}${isVideo ? '?x-oss-process=video/snapshot,t_0,m_fast' : ''}`,
                        user_repo_key: resource_key
                      }
                    : c
                })
              } else {
                const index = copy.findIndex((c) => c.key === 'video')
                copy.splice(index > -1 ? index : copy.length, 0, {
                  type: 'multiple_w2l_video',
                  key: section_key || '',
                  x: 0,
                  y: 0,
                  w,
                  h,
                  sx: 1,
                  sy: 1,
                  image_key: `${resource_key}${isVideo ? '?x-oss-process=video/snapshot,t_0,m_fast' : ''}`,
                  user_repo_key: resource_key
                })
              }
              break
            case 'intro_video':
              if (copy.some((c) => c.key === section_key)) {
                copy = copy.map((c) => {
                  return c.key === section_key
                    ? {
                        ...c,
                        image_key: resource_key + '?x-oss-process=video/snapshot,t_0,m_fast',
                        intro_video: {
                          prev_overlap_msec: prev_overlap_msec || 1,
                          current_overlap_msec: current_overlap_msec || 1,
                          key: resource_key,
                          alpha_key: alpha_key || ''
                        }
                      }
                    : c
                })
              } else {
                copy.push({
                  type: 'intro_video',
                  key: section_key || '',
                  x: 0,
                  y: 0,
                  w,
                  h,
                  sx: 1,
                  sy: 1,
                  image_key: resource_key + '?x-oss-process=video/snapshot,t_0,m_fast',
                  intro_video: {
                    key: resource_key,
                    alpha_key: alpha_key || '',
                    prev_overlap_msec: prev_overlap_msec || 1,
                    current_overlap_msec: current_overlap_msec || 1
                  }
                })
              }
              break
          }
          layersSubj.next(copy)
          editingLayerKey.next(section_key || key)
        }
      )
    return () => subscription.unsubscribe()
  }, [addLayerSubj, editingLayerKey, layersSubj, rangeSubj])
  useEffect(() => {
    const subscription = segmentsChangeSubj.subscribe(segmentsSubj)
    return () => subscription.unsubscribe()
  }, [segmentsSubj, segmentsChangeSubj])
  useEffect(() => {
    const subscription = layersChangedSubj.pipe(map(() => false)).subscribe(saveSubj)
    return () => subscription.unsubscribe()
  }, [layersChangedSubj, saveSubj])
  useEffect(() => {
    const subscription = rangeChangeSubj
      // .pipe(filter(Boolean))
      .subscribe(rangeSubj)
    return () => subscription.unsubscribe()
  }, [rangeSubj, rangeChangeSubj])
  const doSave = useCallback(async () => {
    try {
      const voices = (await api.get('/voices'))?.data?.voices
      const cur = voices.find((v: any) => v.name === voiceNameSubj.value)
      const parseSegments = segmentsSubj.value?.map((l) => {
        delete l.id
        return {
          ...l,
          line_contents: l.line_contents?.map((c) => {
            return c.line_mark
              ? c
              : {
                  ...c,
                  line_mark: uuid.v4()
                }
          }),
          content: [],
          split_voice_keys: []
        }
      })
      const videoLayer = layersSubj.value?.find((l) => l.key === 'video' && l.type === 'w2l_video')
      const setting: RoomSetting = {
        ratio: ratio || 1,
        voice_name: voiceNameSubj.value,
        markup: {
          style: cur?.user_markup?.Style || '',
          pitch: cur?.user_markup?.Pitch || '',
          rate: cur?.user_markup?.Rate || '',
          volume: cur?.user_markup?.Volume || ''
        },
        script: {
          script_template_type: scriptTypeSubj.value,
          script_type: scriptTypeFromTemplateType(scriptTypeSubj.value),
          sections: sectionsSubj.value.map((section) => {
            return {
              ...section,
              children: undefined,
              segments: parseSegments
                .filter((s) => s.groupInfo?.key === section.key)
                ?.map((g) => {
                  delete g.groupInfo
                  return g
                })
            }
          }),
          multiple_w2l_videos: multipleVideosSubj.value,
          segments: []
        },
        frames: [
          backgroundSubj.value && {
            key: 'background',
            x: 0,
            y: 0,
            w: backgroundSubj.value.w,
            h: backgroundSubj.value.h,
            image: { key: backgroundSubj.value.key }
          },
          ...(layersSubj.value?.map((l) => {
            const base = {
              key: l.key,
              x: l.x,
              y: l.y,
              w: l.w * l.sx,
              h: l.h * l.sy,
              range: l.range?.range && {
                key: l.range.key,
                line_start: l.range.range.start,
                line_end: l.range.range.end
              }
            }
            switch (l.type) {
              case 'image':
                return {
                  ...base,
                  image: { key: l.image_key }
                }
              case 'video':
                return {
                  ...base,
                  loop_video: {
                    key: l.video_key,
                    muted: l.muted,
                    duration_sec: 86400 * 365
                  }
                }
              case 'w2l_video':
                if (!l.video_id) {
                  return false as any
                }
                return {
                  ...base,
                  w2l_video: {
                    video_id: l.video_id,
                    disable_alpha_channel: l.disable_alpha_channel
                  }
                }
              case 'multiple_w2l_video':
                if (!l.user_repo_key) {
                  return false as any
                }
                if (l.user_repo_key.includes('.png') || l.user_repo_key.includes('.jpg')) {
                  return {
                    ...base,
                    w2l_video: {
                      video_id: l.user_repo_key?.split('/')?.[1],
                      disable_alpha_channel:
                        videoLayer?.type === 'w2l_video' ? videoLayer?.disable_alpha_channel : false
                    }
                  }
                } else {
                  return {
                    ...base,
                    w2l_video: {
                      video_id: l.user_repo_key?.split('/')?.[2],
                      user_repo_key: l.user_repo_key,
                      disable_alpha_channel:
                        videoLayer?.type === 'w2l_video' ? videoLayer?.disable_alpha_channel : false
                    }
                  }
                }

              case 'intro_video':
                if (!l.intro_video) {
                  return false as any
                }
                return {
                  ...base,
                  intro_video: {
                    key: l.intro_video.key,
                    alpha_key: l.intro_video.alpha_key,
                    prev_overlap_msec: (l.intro_video.prev_overlap_msec || 0) * 1000,
                    current_overlap_msec: (l.intro_video.current_overlap_msec || 0) * 1000
                  }
                }
            }
            // eslint-disable-next-line array-callback-return
            return
          }) || [])
        ]
          .filter(Boolean)
          .map((_) => _!)
      }
      try {
        const reaction = reactionSubj.value
        const doneAsrList = ((await api.get(`/rooms/${rid}/async_asr/unfinished_list`)) as any)?.data?.tasks
          ?.filter((d: any) => d.done)
          .map((d: any) => ({
            card_key: d.card_key,
            card_level: d.card_level
          }))

        if (doneAsrList?.length) {
          api.post(`/rooms/${rid}/async_asr/confirm_used`, doneAsrList)
        }

        const [
          ,
          {
            data: { version }
          }
        ] = await Promise.all([
          api.post(`/rooms/${rid}/setting`, setting, { refreshMode: 'disabled' } as any),
          api.post<{ version: string }>(`/rooms/${rid}/reaction`, reaction, { refreshMode: 'disabled' } as any)
        ])
        setVersion(version || '')
      } catch (e: any) {
        const status = e?.response?.status
        const info = getSaveOrPlayInfo(status)
        setBaseModalInfo(info)
      }
    } catch (e: any) {
      message.error(e.message)
    }
  }, [
    api,
    segmentsSubj.value,
    ratio,
    voiceNameSubj.value,
    scriptTypeSubj.value,
    sectionsSubj.value,
    multipleVideosSubj.value,
    backgroundSubj.value,
    layersSubj.value,
    reactionSubj.value,
    rid,
    setVersion
  ])
  const queryClient = useQueryClient()
  useEffect(() => {
    const subscription = saveSubj
      .pipe(
        debounce(() => timer(10)),
        concatMap((spin) =>
          fromPromise(
            (async () => {
              setIsSaving(true)
              if (spin) setSpinning(true)
              try {
                await doSave()
                if (spin) {
                  await Promise.all([
                    queryClient.invalidateQueries({ queryKey: [`/rooms/${rid}/reaction`] }),
                    queryClient.invalidateQueries({ queryKey: [`/rooms/${rid}/setting`] })
                  ])
                }
              } finally {
                setIsSaving(false)
                if (spin) setSpinning(false)
              }
            })()
          )
        ),
        retry()
      )
      .subscribe()
    return () => subscription.unsubscribe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doSave, saveSubj, setIsSaving, rid])

  const onSync = async (type: string) => {
    await doSave()
    ws &&
      ws((_) =>
        _.send(
          JSON.stringify({
            type
          })
        )
      )
    message.success('同步成功')
    return true
  }

  return (
    <Providing
      _={(_) =>
        _(chatGPTContext, chatGPTContextSubj)(scriptType, scriptTypeSubj)(sections, sectionsSubj)(
          multipleVideos,
          multipleVideosSubj
        )(multipleSectionKey, multipleSectionKeySubj)(segments, segmentsSubj)(segmentsChange, segmentsChangeSubj)(
          selectedRange,
          rangeSubj
        )(selectedRangeChange, rangeChangeSubj)(layers, layersSubj)(addLayer, addLayerSubj)(
          layersChange,
          layersChangedSubj
        )(background, backgroundSubj)(editingLayer, editingLayerKey)(w2lVideo, w2lVideoSubj)(voiceName, voiceNameSubj)(
          reactionSetting,
          reactionSubj
        )(save, saveSubj)(scriptTab, scriptTabSubj)(reselect, reselectSubj)(roomEditorPanel, tab)(
          recordingRow,
          recordingRowSubj
        )(randomLineIndex, randomLineIndexSubj)(segmentsIndex, segmentsIndexSubj)(wsContext, ws)(
          gptStreamingList,
          gptStreamingListSubj
        )(scriptCreateRandomLine, scriptCreateRandomLineSubj)(segmentCreateRandomLine, segmentCreateRandomLineSubj)(
          copyLines,
          copyLinesSubj
        )(asrTag, asrTagSubj)
      }
    >
      <Spin style={{ height: '100%' }} spinning={spinning} indicator={<Spinner className="spin" />}>
        <div style={{ display: 'flex', height: '100%', width: '100%', overflowX: 'hidden' }}>
          <div
            style={{
              width: 0,
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: '#18181A',
              marginTop: 20,
              marginLeft: 24,
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8
            }}
          >
            <div
              style={{
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
                color: 'white',
                fontWeight: 'bold',
                width: '100%',
                padding: 14,
                textAlign: 'center',
                position: 'relative',
                boxSizing: 'border-box',
                backgroundColor: '#2F302C'
              }}
            >
              直播间配置区
              <div
                style={{
                  position: 'absolute',
                  right: 8,
                  top: 6,
                  fontSize: 12,
                  fontWeight: 'normal',
                  color: '#B4B4B4'
                }}
              >
                {isSaving && '保存中...'}
                {!isSaving && `已保存 ${versionText(version)}`}
                <Button type="primary" style={{ marginLeft: 8 }} onClick={() => saveSubj.next(true)}>
                  保存
                </Button>
                {session?.room_id && (
                  <Button
                    type="primary"
                    style={{ marginLeft: 8 }}
                    onClick={() => {
                      setBaseModalInfo({
                        title: '当前直播间所有修改配置将直接应用于当前直播',
                        desc: '同步修改会将最新的直播间配置应用于当前正在直播中的直播',
                        footer: [
                          <div>
                            <Button onClick={() => setBaseModalInfo(null)}>取消</Button>
                          </div>,
                          <Button
                            style={{ marginLeft: 10 }}
                            type="primary"
                            onClick={() => {
                              setBaseModalInfo(null)
                              onSync('sync_room_immediately')
                            }}
                          >
                            立即生效
                          </Button>
                        ]
                      })
                    }}
                  >
                    同步修改
                  </Button>
                )}
                <Button
                  onClick={async () => {
                    const data = JSON.parse(JSON.stringify(segmentsSubj.value))
                    segmentsChangeSubj.next([] as any)
                    setTimeout(() => {
                      segmentsChangeSubj.next(data)
                    }, 1000)
                  }}
                  style={{ marginRight: 16, width: 94, display: 'none', marginLeft: 20 }}
                >
                  修复异常
                </Button>
              </div>
            </div>
            <div
              style={{
                boxSizing: 'border-box',
                height: 0,
                flex: 1,
                width: '100%',
                paddingLeft: 24,
                paddingRight: 24,
                paddingBottom: 24
              }}
            >
              <Tabs
                id="room-setting-tab"
                style={{ height: '100%' }}
                activeKey={tab}
                onChange={(_) => setTab(_ as any)}
                centered
                items={[
                  {
                    label: '主播配置',
                    key: 'video',
                    children: (
                      <Suspense>
                        <RetryOnError>
                          <W2lVideoList />
                        </RetryOnError>
                      </Suspense>
                    )
                  },
                  {
                    label: '背景配置',
                    key: 'background',
                    children: (
                      <Suspense>
                        <RetryOnError>
                          <BackgroundList />
                        </RetryOnError>
                      </Suspense>
                    )
                  },
                  {
                    label: '装饰素材',
                    key: 'decors',
                    children: (
                      <Suspense>
                        <RetryOnError>
                          <DecorList />
                        </RetryOnError>
                      </Suspense>
                    )
                  },
                  {
                    label: '剧本配置',
                    key: 'script',
                    children: (
                      <Suspense>
                        <RetryOnError>
                          <ScriptAndReactionEditor
                            onSync={onSync}
                            onChangeTab={() => {
                              setTab('script')
                            }}
                            tab={tab}
                          />
                        </RetryOnError>
                      </Suspense>
                    )
                  }
                ]}
              />
            </div>
          </div>
          <div
            style={{
              width: 440,
              backgroundColor: '#18181A',
              marginTop: 20,
              marginLeft: 24,
              marginRight: 20,
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8
            }}
          >
            <div
              style={{
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
                color: 'white',
                padding: 14,
                textAlign: 'center',
                backgroundColor: '#2F302C',
                fontWeight: 'bold'
              }}
            >
              画面编辑区
            </div>
            <BaseModal info={baseModalInfo} />
            <div
              style={{
                paddingTop: 40,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'relative'
              }}
            >
              <LayerPreview />
              <Render>
                {function PlayButton() {
                  const [limitModalOpen, setLimitModalOpen] = useState(false)
                  const navigate = useNavigate()
                  const session = useSession()
                  const [promotingProduct, setPromotingProduct] = useState<Product>()
                  const [loading, setLoading] = useState(false)

                  const goPlay = async () => {
                    //check_available_time无法区分新用户和套餐过期用户
                    setLoading(true)
                    saveSubj.next(false)
                    setTimeout(() => {
                      setLoading(false)
                      navigate('../live')
                    }, 500)
                  }

                  return (
                    <div style={{ display: 'flex', justifyItems: 'center', marginTop: 12 }}>
                      <LiveLimitModal
                        open={limitModalOpen}
                        onCancel={() => setLimitModalOpen(false)}
                        onOk={() => setLimitModalOpen(false)}
                      />
                      <PlansModal
                        product={promotingProduct}
                        onConfirm={() => setPromotingProduct(undefined)}
                        onCancel={() => setPromotingProduct(undefined)}
                      />

                      <div style={{ position: 'absolute', top: -40, right: 0 }}>
                        {!session?.room_id ? (
                          <Button
                            className="highlight-contentful pd0"
                            onClick={goPlay}
                            style={{ height: 32, width: 80 }}
                            loading={loading}
                          >
                            开播
                          </Button>
                        ) : (
                          <>
                            <span
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                color: '#B4B4B4',
                                height: 32,
                                width: 80
                              }}
                            >
                              直播中
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  )
                }}
              </Render>
            </div>
          </div>
        </div>
      </Spin>
    </Providing>
  )
}

function versionText(s: string) {
  const segs = s?.split('_')
  if (segs?.length !== 6) return s
  const date = new Date()
  date.setUTCFullYear(parseInt(segs[0]), parseInt(segs[1]) - 1, parseInt(segs[2]))
  date.setUTCHours(parseInt(segs[3]), parseInt(segs[4]), parseInt(segs[5]))
  return `${date.getMonth() + 1}/${date.getDate()} ${padded(date.getHours())}:${padded(date.getMinutes())}:${padded(
    date.getSeconds()
  )}`

  function padded(n: number) {
    if (n < 10) return '0' + n
    return '' + n
  }
}

export const reselect = createContext<Subject<void>>(undefined as any)
const ScriptEditor: FC = () => {
  const scriptTabSubj = useContext(scriptTab)
  const [tab] = useBehaviorSubject(scriptTabSubj)
  const [stype] = useBehaviorSubject(useContext(scriptType))
  const editing = useContext(editingLayer)
  const rangeChange = useContext(selectedRangeChange)
  const multipleSectionKeySubj = useContext(multipleSectionKey)
  const [previewVideoKey, setPreviewVideoKey] = useState('')
  const videoRef = useRef<HTMLVideoElement>(null)
  const url = useContext(resourceUrl)
  const [uploadType, setUploadType] = useState<any>()
  const userInfo = useUserInfo()

  return (
    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
      {
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            display: tab === 'script' ? 'block' : 'none'
          }}
        >
          {stype === 'plain_text' && <PlainTextScriptEditor />}
          {stype === 'universal_plain_text' && (
            <Providing _={(_) => _(plainTextScriptEditorConfig, useUniversalPlainTextConfig)}>
              <PlainTextScriptEditor />
            </Providing>
          )}
          {stype === 'intelligent_universal' && (
            <IntelligentUniversalScriptEditor useConfig={useIntelligentUniversalConfig} />
          )}
          {stype === 'create_explosive_product' && (
            <IntelligentUniversalScriptEditor useConfig={useCreateExplosiveProductConfig} />
          )}
          {stype === 'seckill' && <IntelligentUniversalScriptEditor useConfig={useSecKillConfig} />}
          {stype === 'save_up_popularity' && <IntelligentUniversalScriptEditor useConfig={useSaveUpPopularityConfig} />}
        </div>
      }
      {
        <div
          style={{
            position: 'absolute',
            backgroundColor: '#28282C',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            display: tab === 'resource' ? 'block' : 'none',
            flexDirection: 'column',
            boxSizing: 'border-box',
            padding: 24,
            overflowY: 'auto'
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
            <span style={{ fontSize: 18, fontWeight: 'bolder', color: 'white', marginBottom: 12 }}>素材选择</span>
            <Button
              onClick={() => {
                scriptTabSubj.next('script')
                if (!editing.value) {
                  rangeChange.next(undefined)
                }
              }}
              type="primary"
            >
              完成
            </Button>
          </div>
          <RangeDescription />
          <div style={{ width: '100%', height: 1, backgroundColor: '#434343', marginTop: 12, marginBottom: 12 }} />
          <Render>
            {function Resources() {
              const add = useContext(addLayer)
              const localAdd = useMemo(() => new Subject<addLayerSignal>(), [])
              useEffect(() => {
                const subscription = new Subscription()
                subscription.add(localAdd.pipe(map((s) => ({ ...s, persistent: undefined }))).subscribe(add))
                // subscription.add(localAdd.pipe(map(() => 'script' as const)).subscribe(scriptTabSubj))
                return () => subscription.unsubscribe()
              }, [add, localAdd])
              return (
                <Providing _={(_) => _(addLayer, localAdd)(resourceEditable, false)}>
                  <DecorList />
                </Providing>
              )
            }}
          </Render>
        </div>
      }
      {tab === 'script_type' && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            display: 'flex',
            flexDirection: 'column',
            boxSizing: 'border-box'
          }}
        >
          <ScriptTemplateList />
        </div>
      )}
      {tab === 'resource_change' && (
        <div
          style={{
            position: 'absolute',
            backgroundColor: '#28282C',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            display: 'flex',
            flexDirection: 'column',
            boxSizing: 'border-box',
            padding: 24
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
            <span style={{ fontSize: 18, fontWeight: 'bolder', color: 'white', marginBottom: 12 }}>素材替换</span>
            <Button
              onClick={() => {
                scriptTabSubj.next('script')
                if (!editing.value) {
                  rangeChange.next(undefined)
                }
              }}
              type="primary"
            >
              取消
            </Button>
          </div>
          <div style={{ width: '100%', height: 1, backgroundColor: '#434343', marginTop: 12, marginBottom: 12 }} />
          <Render>
            {function Resources() {
              const add = useContext(addLayer)
              const localAdd = useMemo(() => new Subject<addLayerSignal>(), [])
              useEffect(() => {
                const subscription = new Subscription()
                subscription.add(localAdd.pipe(map((s) => ({ ...s, persistent: undefined }))).subscribe(add))
                return () => subscription.unsubscribe()
              }, [add, localAdd])
              return (
                <Providing _={(_) => _(addLayer, localAdd)(resourceEditable, false)}>
                  <DecorList />
                </Providing>
              )
            }}
          </Render>
        </div>
      )}

      {tab === 'multiple_videos' && (
        <div className="script-multiple">
          <div
            className="right-top"
            onClick={() => {
              scriptTabSubj.next('script')
              multipleSectionKeySubj.next('')
            }}
          >
            <Button type="primary">确定</Button>
          </div>
          <Render>
            {function MultipleVideos() {
              const videos = useW2lVideos()
              const permission = usePermission()
              const add = useContext(addLayer)
              const { tw, th } = useContext(targetViewPort)
              const [multipleSK] = useBehaviorSubject(useContext(multipleSectionKey))
              // const [sec] = useBehaviorSubject(useContext(multipleSectionKey))
              const [ls] = useBehaviorSubject(useContext(layers))
              const sectionsSubj = useContext(sections)
              const [syncVideos, setSyncVideos] = useState<any[]>([])
              const [editId, setEditId] = useState()

              const api = useAPI()
              const groups = videos?.groups
                ?.map((g) => {
                  return {
                    ...g,
                    List: (g.List || []).filter(
                      (v) => userInfo.phone && (permission.admin || v.free || v.phones?.indexOf(userInfo.phone) > -1)
                    )
                  }
                })
                .filter((v) => !!v.List?.length)

              const [videoKey, setVidoKey] = useState('')

              const currentGroup = useMemo(() => {
                return groups.find((g) => g.List.some((v) => v.preview === videoKey))
              }, [groups, videoKey])

              useEffect(() => {
                getSyncVideos()
                // eslint-disable-next-line react-hooks/exhaustive-deps
              }, [])

              useEffect(() => {
                const l = ls.some((l) => l.type === 'multiple_w2l_video' && l.user_repo_key === videoKey)
                if (!l && videoKey) {
                  sectionsSubj.next(
                    sectionsSubj.value.map((s) => {
                      return s.key === multipleSK
                        ? {
                            ...s,
                            w2l_video_frame_key: 'video'
                          }
                        : s
                    })
                  )
                }
              }, [ls, multipleSK, sectionsSubj, videoKey])

              useEffect(() => {
                if (multipleSK) {
                  const section = sectionsSubj.value.find((s) => s.key === multipleSK)
                  const l = ls.find((l) => l.key === section?.w2l_video_frame_key)
                  if (l && l.type === 'multiple_w2l_video') {
                    setVidoKey(l.user_repo_key)
                  }
                  if (l && l.type === 'w2l_video') {
                    setVidoKey(l.image_key)
                  }
                }
              }, [ls, multipleSK, sectionsSubj.value])

              const getSyncVideos = async () => {
                const res: any = await api.get('/user_synthesizable_videos')
                setSyncVideos(res.data.videos || [])
              }

              const onValueChange = (e: any) => {
                setSyncVideos(
                  syncVideos.map((d) => {
                    return d.id === editId
                      ? {
                          ...d,
                          name: e.target.value
                        }
                      : d
                  })
                )
              }

              const saveChange = (v: any) => {
                setEditId(undefined)
                if (v.name?.trim()) {
                  api.put(`/user_synthesizable_videos/${v.id}`, {
                    name: v.name
                  })
                } else {
                  getSyncVideos()
                }
              }

              return (
                <>
                  <div className="multiple-picture">
                    <div className="left">
                      <div className="section-title">形象选择</div>
                      <div className="picture-list">
                        {groups.map((v) => (
                          <>
                            {
                              <Render>
                                {function List() {
                                  const [size, setSize] = useState<Record<'w' | 'h', number> | undefined>()
                                  const actived = v.group_id === currentGroup?.group_id
                                  return (
                                    <div
                                      className={`img ${actived ? 'actived' : ''}`}
                                      key={v.id}
                                      onClick={
                                        size && !actived
                                          ? () => {
                                              const scale = Math.min(th / size.h, tw / size.w)
                                              const key = v.List?.[0].preview
                                              const section = sectionsSubj.value.find((s) => s.key === multipleSK)
                                              const sk =
                                                section?.w2l_video_frame_key && section?.w2l_video_frame_key !== 'video'
                                                  ? section?.w2l_video_frame_key
                                                  : uuid.v4()
                                              setVidoKey(key)

                                              add?.next({
                                                type: 'multiple_w2l_video',
                                                resource_key: key,
                                                section_key: sk,
                                                w: size.w * scale,
                                                h: size.h * scale
                                              })

                                              sectionsSubj.next(
                                                sectionsSubj.value.map((section) => {
                                                  return section.key === multipleSK
                                                    ? {
                                                        ...section,
                                                        w2l_video_frame_key: sk
                                                      }
                                                    : section
                                                })
                                              )
                                            }
                                          : undefined
                                      }
                                    >
                                      <img
                                        src={url(v.List?.[0]?.preview)}
                                        alt="preview"
                                        style={{ position: 'absolute', visibility: 'hidden', height: 0, width: 0 }}
                                        onLoad={(e) =>
                                          setSize({
                                            w: e.currentTarget.naturalWidth,
                                            h: e.currentTarget.naturalHeight
                                          })
                                        }
                                      />
                                      <img src={url(v.avatar || v.defaultAvatar)} alt={v.name} />
                                      {actived && <Check className="icon-check" />}
                                    </div>
                                  )
                                }}
                              </Render>
                            }
                          </>
                        ))}
                      </div>
                    </div>
                    <div className="right">
                      <div className="section-title" style={{ visibility: 'hidden' }}>
                        动作
                      </div>
                      <div className="picture-preview-list">
                        {currentGroup?.List?.map((v) => (
                          <>
                            {
                              <Render>
                                {function List() {
                                  const [size, setSize] = useState<Record<'w' | 'h', number> | undefined>()
                                  return (
                                    <div
                                      className={`img ${v.preview === videoKey ? 'actived' : ''}`}
                                      key={v.id}
                                      onClick={
                                        size
                                          ? () => {
                                              const scale = Math.min(th / size.h, tw / size.w)
                                              const section = sectionsSubj.value.find((s) => s.key === multipleSK)
                                              setVidoKey(v.preview)
                                              const sk =
                                                section?.w2l_video_frame_key && section?.w2l_video_frame_key !== 'video'
                                                  ? section?.w2l_video_frame_key
                                                  : uuid.v4()
                                              add?.next({
                                                type: 'multiple_w2l_video',
                                                resource_key: v.preview,
                                                section_key: sk,
                                                w: size.w * scale,
                                                h: size.h * scale
                                              })
                                              sectionsSubj.next(
                                                sectionsSubj.value.map((section) => {
                                                  return section.key === multipleSK
                                                    ? {
                                                        ...section,
                                                        w2l_video_frame_key: sk
                                                      }
                                                    : section
                                                })
                                              )
                                            }
                                          : undefined
                                      }
                                    >
                                      <img
                                        src={url(v.preview)}
                                        alt={v.name}
                                        onLoad={(e) =>
                                          setSize({
                                            w: e.currentTarget.naturalWidth,
                                            h: e.currentTarget.naturalHeight
                                          })
                                        }
                                      />
                                      {v.preview === videoKey && <Check className="icon-check" />}
                                      <p className="name">{v.name}</p>
                                    </div>
                                  )
                                }}
                              </Render>
                            }
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="multiple-videos">
                    <div className="section-title">
                      视频选择
                      <Button className="upload" type="primary" onClick={() => setUploadType('video')}>
                        上传
                      </Button>
                    </div>
                    <div className="multiple-videos-list">
                      {syncVideos.map((v) => (
                        <>
                          <Render>
                            {function List() {
                              const [size, setSize] = useState<Record<'w' | 'h', number> | undefined>()
                              const actived = v.key === videoKey

                              if (userInfo.user_id !== v.user_id) return null
                              return (
                                <div className={`list-item ${actived ? 'actived' : ''}`} key={v.id}>
                                  <img
                                    src={url(v.key) + '?x-oss-process=video/snapshot,t_0,m_fast'}
                                    alt="decoration"
                                    onLoad={(e) =>
                                      setSize({ w: e.currentTarget.naturalWidth, h: e.currentTarget.naturalHeight })
                                    }
                                    onClick={
                                      size
                                        ? () => {
                                            const scale = Math.min(th / size.h, tw / size.w)
                                            const section = sectionsSubj.value.find((s) => s.key === multipleSK)
                                            setVidoKey(v.key)
                                            const sk =
                                              section?.w2l_video_frame_key && section?.w2l_video_frame_key !== 'video'
                                                ? section?.w2l_video_frame_key
                                                : uuid.v4()
                                            add?.next({
                                              type: 'multiple_w2l_video',
                                              resource_key: v.key,
                                              section_key: sk,
                                              w: size.w * scale,
                                              h: size.h * scale
                                            })
                                            sectionsSubj.next(
                                              sectionsSubj.value.map((section) => {
                                                return section.key === multipleSK
                                                  ? {
                                                      ...section,
                                                      w2l_video_frame_key: sk
                                                    }
                                                  : section
                                              })
                                            )
                                          }
                                        : undefined
                                    }
                                  />
                                  {actived && <Check className="icon-check" />}
                                  {editId === v.id ? (
                                    <Input
                                      value={v.name}
                                      autoFocus
                                      onChange={onValueChange}
                                      onBlur={() => saveChange(v)}
                                    />
                                  ) : (
                                    <p className="name" onClick={() => setEditId(v.id)}>
                                      {v.name}
                                    </p>
                                  )}

                                  <Popconfirm
                                    title="删除"
                                    description={`确认删除视频 ?`}
                                    okText="确定"
                                    cancelText="取消"
                                    onConfirm={async () => {
                                      await api.delete(`/user_synthesizable_videos/${v.id}`)
                                      getSyncVideos()
                                    }}
                                  >
                                    <Trash className="hidden-button delete" />
                                  </Popconfirm>

                                  <Play className="hidden-button play" onClick={() => setPreviewVideoKey(v.key)} />
                                </div>
                              )
                            }}
                          </Render>
                        </>
                      ))}
                    </div>
                    <UploadModal
                      uploadType={uploadType}
                      onClose={() => {
                        getSyncVideos()
                        setUploadType(undefined)
                      }}
                      title="上传视频"
                      uploadUrl={'/user_synthesizable_videos'}
                    />
                  </div>
                </>
              )
            }}
          </Render>
        </div>
      )}

      {tab === 'intro_video' && (
        <div className="script-multiple">
          <div className="right-top">
            <Button type="primary" onClick={() => setUploadType('video')}>
              上传
            </Button>
            <Button
              type="primary"
              onClick={() => {
                scriptTabSubj.next('script')
                multipleSectionKeySubj.next('')
              }}
            >
              确定
            </Button>
          </div>
          <Render>
            {function Videos() {
              const [videos, setVideos] = useState<any[]>([])
              const url = useContext(resourceUrl)
              const add = useContext(addLayer)
              const api = useAPI()
              const sectionsSubj = useContext(sections)
              const [multipleSK] = useBehaviorSubject(useContext(multipleSectionKey))
              const { tw, th } = useContext(targetViewPort)
              const [ls, setLayers] = useBehaviorSubject(useContext(layers))
              const [editId, setEditId] = useState()

              useEffect(() => {
                getVideos()
                // eslint-disable-next-line react-hooks/exhaustive-deps
              }, [])

              const getVideos = async () => {
                const res: any = await api.get('/resources/cut_scenes')
                setVideos(res.data.cut_scenes || [])
              }

              const onValueChange = (e: any) => {
                setVideos(
                  videos.map((d) => {
                    return d.id === editId
                      ? {
                          ...d,
                          name: e.target.value
                        }
                      : d
                  })
                )
              }

              const saveChange = (v: any) => {
                setEditId(undefined)
                if (v.name?.trim()) {
                  api.put(`/resources/cut_scenes/${v.id}`, {
                    name: v.name
                  })
                } else {
                  getVideos()
                }
              }

              return (
                <div className="intro-videos">
                  <div className="section-title">入场选择</div>
                  <div className="intro-videos-list">
                    {videos.map((v) => (
                      <>
                        <Render>
                          {function List() {
                            const [size, setSize] = useState<Record<'w' | 'h', number> | undefined>()
                            const section = sectionsSubj.value.find((s) => s.key === multipleSK)
                            const actived = ls.some(
                              (l) =>
                                l.type === 'intro_video' &&
                                l.key === section?.intro?.frame_key &&
                                l.intro_video.key === v.key
                            )

                            return (
                              <div className={`list-item ${actived ? 'actived' : ''}`} key={v.id}>
                                <img
                                  src={url(v.key) + '?x-oss-process=video/snapshot,t_0,m_fast'}
                                  alt="decoration"
                                  onClick={
                                    size
                                      ? () => {
                                          const description = v?.description.Data
                                          const frame_key = section?.intro?.frame_key
                                            ? section?.intro?.frame_key
                                            : uuid.v4()
                                          const scale = Math.min(th / size.h, tw / size.w)

                                          if (!actived) {
                                            add?.next({
                                              type: 'intro_video',
                                              resource_key: description?.video_key,
                                              alpha_key: description?.alpha_video_key,
                                              section_key: frame_key,
                                              w: size.w * scale,
                                              h: size.h * scale,
                                              prev_overlap_msec: 1,
                                              current_overlap_msec: 1
                                            })
                                          } else {
                                            setLayers(ls.filter((l) => l.key !== section?.intro?.frame_key))
                                          }
                                          sectionsSubj.next(
                                            sectionsSubj.value.map((s) => {
                                              return s.key === section?.key
                                                ? {
                                                    ...s,
                                                    intro: actived
                                                      ? undefined
                                                      : {
                                                          frame_key
                                                        }
                                                  }
                                                : s
                                            })
                                          )
                                        }
                                      : undefined
                                  }
                                  onLoad={(e) =>
                                    setSize({ w: e.currentTarget.naturalWidth, h: e.currentTarget.naturalHeight })
                                  }
                                />
                                {actived && <Check className="icon-check" />}

                                {editId === v.id ? (
                                  <Input
                                    value={v.name}
                                    autoFocus
                                    onChange={onValueChange}
                                    onBlur={() => saveChange(v)}
                                  />
                                ) : (
                                  <p
                                    className="name"
                                    onClick={() => (userInfo.user_id === v.user_id ? setEditId(v.id) : null)}
                                  >
                                    {v.name}
                                  </p>
                                )}

                                {userInfo.user_id === v.user_id && (
                                  <Popconfirm
                                    title="删除"
                                    description={`确认删除入场视频 ?`}
                                    okText="确定"
                                    cancelText="取消"
                                    onConfirm={async (e) => {
                                      e?.stopPropagation()
                                      await api.delete(`/resources/cut_scenes/${v.id}`)
                                      getVideos()
                                    }}
                                    onCancel={(e) => {
                                      e?.stopPropagation()
                                    }}
                                  >
                                    <Trash
                                      className="hidden-button delete"
                                      onClick={(e) => {
                                        e?.stopPropagation()
                                      }}
                                    />
                                  </Popconfirm>
                                )}

                                <Play
                                  className="hidden-button play"
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    setPreviewVideoKey(v.key)
                                  }}
                                />
                              </div>
                            )
                          }}
                        </Render>
                      </>
                    ))}
                  </div>
                  <UploadModal
                    uploadType={uploadType}
                    onClose={() => {
                      getVideos()
                      setUploadType(undefined)
                    }}
                    onUploadSucces={async (resource: Resource) => {
                      await api.post('/resources/cut_scenes', {
                        name: resource.name,
                        video_key: resource.key
                      })
                      getVideos()
                    }}
                    title="上传入场视频"
                    uploadUrl={'/upload'}
                  />
                </div>
              )
            }}
          </Render>
        </div>
      )}
      <Modal
        title="预览"
        open={!!previewVideoKey}
        width={600}
        footer={null}
        onCancel={() => {
          videoRef.current?.pause()
          setPreviewVideoKey('')
        }}
      >
        <video
          width="100%"
          height={400}
          ref={videoRef}
          autoPlay
          controls
          controlsList="nodownload noplaybackrate"
          src={url(previewVideoKey)}
        />
      </Modal>
    </div>
  )
}

const RangeDescription: FC = () => {
  const [ss] = useBehaviorSubject(useContext(segments))
  const [range] = useBehaviorSubject(useContext(selectedRange))
  const [detailed, setDetailed] = useState(true)
  const segment = useMemo(() => ss?.find((s) => s?.key === range?.key), [range, ss])
  const text = useMemo(
    () => range?.range && segment?.line_contents?.slice(range.range.start, range.range.end),
    [range, segment]
  )
  if (!segment) return <></>
  return (
    <div style={{ color: 'white' }}>
      <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => setDetailed(!detailed)}>
        <span style={{ marginRight: 4 }}>{segment.title}</span>
        {detailed ? <TriangleDown /> : <TriangleRight />}
      </div>
      {detailed &&
        (text || []).map((t, index) => (
          <div key={index} style={{ color: '#B4B4B4', paddingTop: 4 }}>
            {t?.text}
          </div>
        ))}
    </div>
  )
}

const ScriptAndReactionEditor: FC<{
  onSync: (type: string) => void
  onChangeTab: () => void
  tab: 'video' | 'background' | 'decors' | 'script'
}> = (props) => {
  const [tab, setTab] = useState<'content' | 'reaction'>('content')
  const session = useSession()
  const [baseModalInfo, setBaseModalInfo] = useState<any>()
  const [scriptTabSubj, setScriptTabSubj] = useBehaviorSubject(useContext(scriptTab))
  const sectionsSubj = useContext(sections)
  const [s] = useBehaviorSubject(useContext(segments))
  const [multipleV, setMultipleV] = useBehaviorSubject(useContext(multipleVideos))

  const textNum: number = useMemo(() => {
    return s.reduce((pre, next) => {
      const num =
        next.line_contents?.reduce((p, n) => {
          return p + (n.text?.trim().length || 0)
        }, 0) || 0

      return pre + num
    }, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [s])

  const textTime = useMemo(() => {
    const num = (textNum || 0) * 0.15
    return getTimeDesc(num)
  }, [textNum])

  useEffect(() => {
    if (scriptTabSubj === 'resource_change') {
      setTab('content')
      props.onChangeTab()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scriptTabSubj])

  useEffect(() => {
    if (props.tab !== 'script') {
      setScriptTabSubj('script')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tab])

  const syncTemplates = () => {
    setBaseModalInfo({
      title: '当前互动配置将直接应用于当前直播',
      desc: '同步配置会将最新保存的互动配置应用于当前正在直播中的直播',
      footer: [
        <div>
          <Button onClick={() => setBaseModalInfo(null)}>取消</Button>
        </div>,
        <Button style={{ marginLeft: 10 }} type="primary" onClick={() => applySync('sync_reaction_setting')}>
          立即生效
        </Button>
      ]
    })
  }

  const applySync = async (type: string) => {
    await props.onSync(type)
    setBaseModalInfo(null)
  }

  const addSection = () => {
    sectionsSubj.next(
      sectionsSubj.value.concat({
        title: '新模块',
        key: uuid.v4(),
        segments: [],
        w2l_video_frame_key: 'video'
      })
    )

    setTimeout(() => {
      const doms = document.querySelectorAll('.segment-card')
      doms[doms.length - 1]?.scrollIntoView({ behavior: 'smooth' })
    })
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'inline-flex', width: '100%' }}>
          <Button
            className={['border-button', tab === 'content' ? 'selected' : undefined].filter(Boolean).join(' ')}
            onClick={() => setTab('content')}
          >
            内容配置
          </Button>
          <Button
            className={['border-button', tab === 'reaction' ? 'selected' : undefined].filter(Boolean).join(' ')}
            style={{ marginLeft: 8 }}
            onClick={() => {
              setTab('reaction')
              setScriptTabSubj('script')
            }}
          >
            互动配置
          </Button>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              color: '#fff',
              opacity: '0.7',
              marginLeft: 40
            }}
            className="ellipsis"
          >
            <label>总字数：{textNum}</label>
            <label style={{ position: 'relative', top: '-0.5px', padding: '0 6px' }}>|</label>
            <label>预估总时长：{textTime || 0}</label>
          </div>

          {tab === 'content' && (
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
              <div
                className="ellipsis"
                style={{ display: 'flex', alignItems: 'center', color: '#fff', marginRight: 16 }}
              >
                <Switch
                  checked={multipleV}
                  className="card-switch"
                  style={{ margin: '0 8px 0 4px' }}
                  onChange={(checked) => {
                    setMultipleV(checked)
                  }}
                />
                <label>多主播模式</label>
              </div>
              <CreateAllRandomLine />
              <Button onClick={addSection} type="primary" style={{ marginLeft: 20 }} icon={<PlusCircleOutlined />}>
                新增剧本模块
              </Button>
            </div>
          )}

          {session?.room_id && tab === 'reaction' && (
            <button className="link-button" style={{ marginLeft: 'auto' }} onClick={syncTemplates}>
              同步互动配置
            </button>
          )}
        </div>
      </div>
      <div style={{ height: 0, flex: 1, paddingTop: 20 }}>
        {tab === 'reaction' && <ReactionRuleEditor />}
        {tab === 'content' && <ScriptEditor />}
      </div>
      <BaseModal info={baseModalInfo} />
    </div>
  )
}

export const CreateAllRandomLine: FC<{
  segKey?: string
}> = (props) => {
  const randomLineQueue = useRef<any>()
  const wsRef = useRef<any>()
  const timerRef = useRef<any>()
  const segmentsSubj = useContext(segments)
  const [s] = useBehaviorSubject(useContext(segments))
  const isDev = useMemo(() => !!window.location.host.match(/dev|localhost/), [])
  const domains = useMemo(() => (isDev ? devDomains : prodDomains), [isDev])
  const user = useContext(currentUser)
  const [percent, setPercent] = useState(-1)
  const model = useRef<any>()
  const [info, setInfo] = useState<any>()
  const [scriptCreate, setScriptCreate] = useBehaviorSubject(useContext(scriptCreateRandomLine))
  const [segmentCreate, setSegmentCreate] = useBehaviorSubject(useContext(segmentCreateRandomLine))
  const segmentCreateRef = useRef<any>()

  useEffect(() => {
    return () => {
      model.current = undefined
      setPercent(-1)
    }
  }, [])

  useEffect(() => {
    segmentCreateRef.current = segmentCreate
  }, [segmentCreate])

  const sendGpt = (content: string) => {
    wsRef.current.send(
      JSON.stringify({
        type: 'stream_chat',
        message: [
          {
            content: `将“${content}”改写成5种不同说法, 你最多只能修改其中字数的20%. 整体输出为五个段落`,
            role: 'user'
          }
        ],
        context: 'new'
      })
    )
  }

  const closeWs = () => {
    if (wsRef.current) {
      wsRef.current.close()
      wsRef.current = null
    }
    if (timerRef) {
      clearInterval(timerRef.current)
      timerRef.current = null
    }
  }

  const createConfirm = () => {
    setInfo({
      title: '请选择随机话术生成方式',
      desc: (
        <div style={{ textAlign: 'left' }}>
          <p>1.覆盖添加: 原有话术的已有随机话术会被清空，然后添加新的随机话术</p>
          <p>2.查漏补缺: 找出未添加随机话术的原有话术，给其添加随机话术</p>
          <p>3.增量添加: 原有话术的已有随机话术会被保留，然后补充新的随机话术</p>
        </div>
      ),
      footer: [
        <div>
          <Button
            type="primary"
            onClick={() => {
              model.current = 'cover'
              setInfo(undefined)
              createAllRandomLine()
            }}
          >
            覆盖添加
          </Button>
          <Button
            style={{ marginLeft: 30 }}
            type="primary"
            onClick={() => {
              model.current = 'tofel'
              setInfo(undefined)
              createAllRandomLine()
            }}
          >
            查漏补缺
          </Button>
          <Button
            style={{ marginLeft: 30 }}
            type="primary"
            onClick={() => {
              model.current = 'push'
              setInfo(undefined)
              createAllRandomLine()
            }}
          >
            增量添加
          </Button>
          <div
            style={{ position: 'absolute', right: 24, top: 60, color: '#293be3', cursor: 'pointer' }}
            onClick={delConfirm}
          >
            一键删除
          </div>
        </div>
      ]
    })
  }

  const delConfirm = () => {
    setInfo({
      title: '确认删除随机话术？',
      desc: (
        <div>
          <p>找出添加随机话术的原有话术，删除其前三句随机话术</p>
        </div>
      ),
      footer: [
        <div>
          <Button
            onClick={() => {
              setInfo(undefined)
            }}
          >
            取消
          </Button>
        </div>,
        <Button
          style={{ marginLeft: 30 }}
          type="primary"
          onClick={() => {
            const seg = segmentsSubj.value.map((r) => {
              return {
                ...r,
                line_contents: r.line_contents?.map((l) => {
                  return l.alternatives?.length
                    ? {
                        ...l,
                        alternatives: l.alternatives.slice(3)
                      }
                    : l
                })
              }
            })
            segmentsSubj.next(seg)
            setInfo(undefined)
            message.success('删除成功')
          }}
        >
          确定
        </Button>
      ]
    })
  }

  const createAllRandomLine = (data?: any[]) => {
    let queue: any[] = data || []
    let alternatives: any[] = []
    if (!data?.length) {
      s.forEach((r) => {
        if (props.segKey === r.key || !props.segKey) {
          r.line_contents?.forEach((q, index) => {
            if (q.text && (model.current !== 'tofel' || (model.current === 'tofel' && !q.alternatives?.length))) {
              queue.push({
                key: r.key,
                index,
                text: q.text
              })
            }
          })
        }
      })
      if (!queue.length) {
        message.warning('所有剧本都已添加随机话术')
        return
      }
      setPercent(0)
      randomLineQueue.current = [...queue]
    }

    if (!queue.length) {
      message.warning('所有剧本都已添加随机话术')
      return
    }

    if (props.segKey) {
      if (segmentCreateRef.current?.length >= 3) {
        message.warning('最多同时支持三个模块随机话术生成，请等待其他模块生成完成！')
        return
      }
      setSegmentCreate([...(segmentCreateRef.current || []), props.segKey])
    } else {
      setScriptCreate(true)
    }

    const ws = new WebSocket(`wss:${domains.cms}/ai/chatGPT_stream?token=${user.token}`)

    timerRef.current = setInterval(() => {
      wsRef.current?.send(JSON.stringify({ type: 'healthcheck-client' }))
    }, 20000)

    ws.onopen = () => {
      sendGpt(queue[0].text)
    }

    ws.onmessage = (msg) => {
      const data = JSON.parse(msg.data)
      let { content, error, type } = data
      const isLineEnd = content?.includes('||') || content?.includes('\n')

      if (type === 'stream_chat_ack') {
        if (!error && content !== 'io.EOF') {
          if (isLineEnd || !alternatives.length) {
            alternatives.push({
              text: isLineEnd ? '' : content
            })
          } else {
            alternatives = alternatives?.map((l, index) =>
              index === alternatives.length - 1
                ? {
                    ...l,
                    text: `${l.text}${content}`.replace(/^\d+\./, '')
                  }
                : l
            )
          }
          const textLength = alternatives.reduce((pre, next) => {
            return pre + (next.text.length || 0)
          }, 0)
          const randomTextLength = randomLineQueue.current?.reduce((pre: any, next: any) => {
            return pre + (next.text.length || 0)
          }, 0)
          const rl = randomLineQueue.current?.length
          const index = rl - queue.length + 1
          const maxPercent = Math.floor((index / rl) * 100)
          const currentPercent = Math.floor((textLength * 100) / (randomTextLength * 5))
          setPercent((p) => Math.min(99, maxPercent, currentPercent + Math.floor(((index - 1) / rl) * 100)))
        }
        if (content === 'io.EOF') {
          const current = queue[0]
          const seg = segmentsSubj.value.map((r) =>
            r.key === current.key
              ? {
                  ...r,
                  line_contents: r.line_contents?.map((l, index) =>
                    index === current.index
                      ? {
                          ...l,
                          alternatives:
                            model.current === 'cover' ? alternatives : [...(l.alternatives || []), ...alternatives]
                        }
                      : l
                  )
                }
              : r
          )
          segmentsSubj.next(seg)
          alternatives = []
          queue.splice(0, 1)
          const p = Math.floor((1 - queue.length / randomLineQueue.current.length) * 100)
          setPercent(p)
          if (!queue.length) {
            if (props.segKey) {
              setSegmentCreate(segmentCreateRef.current?.filter((s: string) => s !== props.segKey))
            } else {
              setSegmentCreate([])
              setScriptCreate(false)
            }
            setInfo(undefined)
            message.success('随机话术已生成')
            setTimeout(() => {
              setPercent(-1)
            }, 500)
            closeWs()
          } else {
            sendGpt(queue[0].text)
          }
        }

        if (error && content === 'stream error') {
          sendGpt(queue[0].text)
        }

        if (error && content !== 'stream error') {
          queue = []
          setSegmentCreate([])
          setScriptCreate(false)
          closeWs()
          setPercent(-1)
          message.warning('本月智能生成剧本次数已达上限')
        }
      }
    }

    ws.onclose = (e) => {
      console.log('ws-close', e)
      if (e.code === 1006) {
        queue.length && createAllRandomLine(queue)
      }
    }

    ws.onerror = (e) => {
      console.log('ws-error', e)
      setPercent(-1)
    }

    wsRef.current = ws
  }

  return (
    <>
      {percent >= 0 ? (
        <div style={{ display: 'flex', alignItems: 'centet', marginLeft: 'auto', color: '#fff' }}>
          <span style={{ position: 'relative', top: 2 }}>生成进度</span>
          <Progress percent={percent} style={{ width: 200, marginLeft: 16 }} />
        </div>
      ) : (
        <div style={{ marginLeft: 'auto' }}>
          <Button
            disabled={!!(!props.segKey && segmentCreate?.length) || !!(props.segKey && scriptCreate)}
            type="primary"
            onClick={createConfirm}
            style={{ marginLeft: 20 }}
          >
            一键生成随机话术
          </Button>
        </div>
      )}
      <BaseModal info={info} />
    </>
  )
}
