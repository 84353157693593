import { RcFile } from 'antd/es/upload'
import {
  Context,
  createContext,
  FC,
  PropsWithChildren,
  ReactNode,
  Suspense,
  useCallback,
  useContext,
  useEffect
} from 'react'
import { BehaviorSubject, EMPTY, Observable } from 'rxjs'
import * as uuid from 'uuid'
import { useQueryClient } from '@tanstack/react-query'
import { useAPI } from './api'
import { usePermission, useRemotePermission } from './auth/states'
import { Resource } from './room/states'
import { Render, tuple } from './types'

export const roomId = createContext<string>('')
export const wsContext = createContext<((_: (_: WebSocket) => void) => void) | undefined>(undefined)
//场控ws消息
export const wsMessages = createContext<Observable<string>>(EMPTY)
export const templateId = createContext<string>('')
export const LegacyRoomFacades = {
  setting: '直播间配置',
  script: '剧本管理',
  reaction: '互动管理',
  live: '开播设置'
} as const
export const legacyRoomFacade = createContext<keyof typeof LegacyRoomFacades>('setting')

export const RoomFacades = ['setting', 'live'] as const
export const roomFacade = createContext<(typeof RoomFacades)[number]>('setting')
export const LivePanels = {
  templates: '首页',
  rooms: '直播管理',
  products: '商品管理',
  materials: '素材管理'
} as const
export const PersonalPanels = { account: '账户信息', plans: 'VIP 套餐', safe: '安全中心' } as const

export type PanelType = keyof typeof LivePanels | keyof typeof PersonalPanels

export const panelTypes: PanelType[] = [...Object.keys(LivePanels), ...Object.keys(PersonalPanels)] as PanelType[]
export const panel = createContext<PanelType>('rooms')

export const devDomains = {
  cdn: '//fantasy-cdn-dev.lingverse.co',
  cms: '//fantasy-cms-api-dev.lingverse.co',
  auth: '//meta-dev.lingverse.co',
  order: '//fantasy-order-dev.lingverse.co',
  pay: '//plutus-dev.lingverse.co',
  live: '//live-message-dev.lingverse.co'
}
export const prodDomains = {
  cdn: '//fantasy-cdn.lingverse.co',
  cms: '//fantasy-cms-api.lingverse.co',
  auth: '//meta-prod.lingverse.co',
  order: '//fantasy-order.lingverse.co',
  pay: '//plutus-prod.lingverse.co',
  live: '//live-message-prod.lingverse.co'
}
export const apiDomains = createContext(devDomains)

export const targetViewPort = createContext({ tw: 720, th: 1280 })
export const resourceUrl = createContext((key: string) => `https://fantasy-cdn-dev.lingverse.co/${key}`)

export const mouseDown = createContext<BehaviorSubject<boolean>>(undefined as any)

export function useUploadResource(): (
  file: RcFile,
  tag: string,
  options?: any,
  uploadUrl?: string
) => Promise<Resource> {
  const api = useAPI()
  const client = useQueryClient()
  return useCallback(
    async (file, tag, options, uploadUrl) => {
      const path = uploadUrl || `/resources`
      const { onSuccess, onError, onProgress } = options || {}
      try {
        const segs = (file.name || '').split(/\./)
        let params: any = {
          extension: segs[segs.length - 1],
          tag,
          name: segs[0]
        }
        if (uploadUrl === '/upload') {
          delete params.name
        } else if (uploadUrl && !uploadUrl.includes('avatar_upload')) {
          params = {
            name: segs[0]
          }
        }

        const { resource, upload_url, avatar } =
          (
            await api.post(path, params, {
              refreshMode: 'disabled'
            } as any)
          ).data || {}
        if (!upload_url) {
          throw new Error('failed to upload file')
        }
        await api.put(upload_url.replace(/^http:\/\//, 'https://').replace('-internal', ''), file, {
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: (event) => {
            onProgress?.({
              lengthComputable: true,
              ...event,
              percent: (event.loaded / (event.total || file.size)) * 100
            })
          }
        })
        await client.invalidateQueries({ queryKey: [path] })
        onSuccess?.()
        resource.name = resource.name || segs[0]
        if (uploadUrl?.includes('avatar_upload')) {
          return avatar
        } else {
          return resource
        }
      } catch (err: any) {
        onError?.({ err })
        throw err
      }
    },
    [api, client]
  )
}

type provide = <T>(c: Context<T>, value: T) => provide
export const Providing: FC<PropsWithChildren<{ _: (f: provide) => void }>> = ({ children, _ }) => {
  let element = <>{children}</>
  _(function provide(C, value) {
    element = <C.Provider value={value}>{element}</C.Provider>
    return provide
  })
  return element
}

export const badges = createContext<BehaviorSubject<[string, ReactNode][]>>(undefined as any)

export const Badge: FC<PropsWithChildren> = ({ children }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const stable = useMemo(() => children, [])
  const badgesSubj = useContext(badges)
  useEffect(() => {
    const id = uuid.v4()
    badgesSubj.next([...(badgesSubj.value || []), tuple(id, children)])
    return () => badgesSubj.next(badgesSubj.value?.filter(([i]) => i !== id))
  }, [badgesSubj, children])
  return null
}

export const WithPermission: FC<PropsWithChildren<{ permission: string | string[]; fallback?: ReactNode }>> = ({
  children,
  permission,
  fallback
}) => {
  return (
    <Suspense>
      <Render>
        {function Permission() {
          const availablePermissions = usePermission() as any
          const hasPermission =
            typeof permission === 'string'
              ? !!availablePermissions?.[permission]
              : permission.every((p) => !!availablePermissions?.[p])
          if (hasPermission) return <>{children}</>
          return <>{fallback}</>
        }}
      </Render>
    </Suspense>
  )
}
export const WithRemotePermission: FC<PropsWithChildren<{ permission: string | string[]; fallback?: ReactNode }>> = ({
  children,
  permission,
  fallback
}) => {
  return (
    <Suspense>
      <Render>
        {function Permission() {
          const availablePermissions = useRemotePermission() as any
          const hasPermission =
            typeof permission === 'string'
              ? !!availablePermissions?.[permission]
              : permission.every((p) => !!availablePermissions?.[p])
          if (hasPermission) return <>{children}</>
          return <>{fallback}</>
        }}
      </Render>
    </Suspense>
  )
}

export const socketContext = createContext<BehaviorSubject<any>>(undefined as any)
export const socketTransings = createContext<BehaviorSubject<string[]>>([] as any)
