import { useLayoutEffect, useMemo, useRef } from 'react'

export function useEventCallback<F extends Function>(fn: F): F {
  let ref = useRef(fn)
  useLayoutEffect(() => {
    ref.current = fn
  })
  return useMemo(
    () =>
      ((...args: any) => {
        return ref.current(...args)
      }) as unknown as F,
    []
  )
}
