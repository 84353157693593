export function addClassName(current: string | undefined, className: string): string {
  if (!className) return current || ''
  if (!current) return className
  if (current === className) return current
  if (current.includes(' ' + className)) return current
  return current + ' ' + className
}

export function getTimeDesc(num: number) {
  const hours = Math.floor(num / 3600)
  const minutes = Math.floor((num % 3600) / 60)
  const seconds = Math.ceil((num % 3600) % 60)
  return (hours > 0 ? `${hours}时` : '') + (minutes > 0 ? `${minutes}分` : '') + (seconds > 0 ? `${seconds}秒` : '')
}

export const debounce = (func: any, time: number, immediate = false) => {
  let timer: number | null = null
  return (...args: any) => {
    if (timer) clearInterval(timer)
    if (immediate) {
      if (!timer) func.apply(this, args)
      timer = window.setTimeout(() => {
        timer = null
      }, time)
    } else {
      timer = window.setTimeout(() => {
        func.apply(this, args)
      }, time)
    }
  }
}
