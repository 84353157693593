import { $createTextNode, EditorConfig, LexicalNode, TextNode } from 'lexical'
import { FC, useEffect, useMemo } from 'react'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { addClassName } from '../../lib/util'
import { SensitiveRule, useSensitiveCheckRulesConfig } from './states'

/**
 *
 * @param text 敏感词，多个时以逗号分隔
 * @param className 检测敏感词后增加class，默认为sensitive
 * @param tip hover到敏感词时的popover信息
 * @param rules 敏感词规则列表[{"text":"","tip":""}]，如果为空，则从远端获取
 * @returns
 */
export const SensitiveWordsPlugin: FC<{
  text?: string
  className?: string
  tip?: string
  rules?: Array<SensitiveRule>
}> = ({ text, className, tip, rules }) => {
  const __className = useMemo(() => className || 'sensitive', [className])
  tip = tip || ''
  text = text || ''
  const remoteRules = useSensitiveCheckRulesConfig()
  const __rules = useMemo(() => (!rules ? remoteRules : rules?.filter((rule) => rule.text) || []), [rules, remoteRules])
  if (text) {
    __rules.push({ tip, text })
  }
  const [editor] = useLexicalComposerContext()
  useEffect(
    () =>
      [
        editor.registerNodeTransform(TextNode, (node) => {
          let pop = document.getElementById('sensitive-pop')
          if (pop && pop.style.display !== 'none') {
            pop.style.display = 'none'
          }
          const norepeatRules: any = []
          __rules.forEach((rule) => {
            const texts = Array.from(new Set(rule.text.trim().split(/[,，、；|]/)))
            norepeatRules.push({
              ...rule,
              text: texts.filter((t) => !norepeatRules.some((r: any) => r.text?.includes(t)))
            })
          })
          norepeatRules.forEach((rule: any) => {
            rule.text.forEach((text: any) => {
              const index = node.getTextContent().indexOf(text)
              if (index < 0) return
              const target = node.splitText(index, index + text.length)[node.getTextContent() === text ? 0 : 1]
              target?.replace($createSensitiveWordsNode(text, __className, rule.tip))
            })
          })
        }),
        editor.registerNodeTransform(SensitiveWordsNode, (node) => {
          if (node.getTextContent() !== node.__target) node.replace($createTextNode(node.getTextContent()))
        })
      ].reduce(
        (l, r) => () => {
          l()
          r()
        },
        () => void 0
      ),
    [__className, editor, text, __rules]
  )
  return null
}

export class SensitiveWordsNode extends TextNode {
  __className: string
  __target: string
  __tip: string

  constructor(text: string, target: string, className: string, tip: string) {
    super(text)
    this.__target = target
    this.__className = className
    this.__tip = tip
  }

  isUnmergeable(): boolean {
    return true
  }

  static getType() {
    return 'SensitiveWordsNode'
  }

  exportJSON() {
    return {
      ...super.exportJSON(),
      type: 'SensitiveWordsNode',
      SensitiveWords_target: this.__target,
      SensitiveWords_class_name: this.__className,
      SensitiveWords_tip: this.__tip,
      version: 1
    }
  }

  static importJSON(serializedNode: any): SensitiveWordsNode {
    return new SensitiveWordsNode(
      TextNode.importJSON(serializedNode).getTextContent(),
      serializedNode.SensitiveWords_target || '',
      serializedNode.SensitiveWords_class_name || '',
      serializedNode.SensitiveWords_tip || ''
    )
  }

  static clone(node: SensitiveWordsNode) {
    return new SensitiveWordsNode(node.__text, node.__target, node.__className, node.__tip)
  }

  createDOM(config: EditorConfig): HTMLElement {
    const node = super.createDOM(config)
    node.className = addClassName(node.className, this.__className)
    if (this.__tip) {
      node.addEventListener('mouseover', (rawEvent: Event) => {
        let pop = document.getElementById('sensitive-pop'),
          e = rawEvent as MouseEvent
        if (!pop) {
          pop = document.createElement('div')
          pop.id = 'sensitive-pop'
          document.body.appendChild(pop)
        }
        pop.innerText = this.__tip
        pop.style.left = e.clientX - 60 + 'px'
        pop.style.top = e.clientY - 50 + 'px'
        pop.style.display = 'block'
        pop.style.backgroundColor = '#18181A'
        pop.style.zIndex = '9999'
      })
      node.addEventListener('mouseout', (rawEvent: Event) => {
        let pop = document.getElementById('sensitive-pop')
        if (pop) {
          pop.style.display = 'none'
        }
      })
    }
    return node
  }
}

function $createSensitiveWordsNode(text: string, className: string, tip: string) {
  return new SensitiveWordsNode(text, text, className, tip)
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function $isSensitiveWordsNode(node: LexicalNode): boolean {
  return node instanceof SensitiveWordsNode
}
