import { ReactElement, ReactNode, useCallback, useMemo } from 'react'
import { BehaviorSubject } from 'rxjs'
import { useBehaviorSubject } from './react-rx'

export function intersection<A, B>(a: A): A & B {
  return a as A & B
}

export function flatten<T>(
  t: T
): (T extends any ? (_: Partial<T>) => void : never) extends (_: infer U) => void ? U : never {
  return t as any
}

export function runCatching<T>(f: () => T): { status: 'success'; value: T } | { status: 'failure'; error: any } {
  try {
    return { status: 'success', value: f() }
  } catch (error) {
    return { status: 'failure', error }
  }
}

export const Render: (_: { children: () => ReactNode }) => ReactElement = ({ children }) => <>{children()}</>

export function tuple<T extends any[]>(...elements: T) {
  return elements
}

export function useForceRender(): [() => void, number] {
  const subj = useMemo(() => new BehaviorSubject(0), [])
  const [token] = useBehaviorSubject(subj)
  const forceRender = useCallback(() => subj.next(subj.value + 1), [subj])
  return [forceRender, token]
}
