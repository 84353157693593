import { useCallback, useEffect, useState } from 'react'
import { BehaviorSubject, Observable } from 'rxjs'

export function useObservable<T>(o: Observable<T>, initial: T | (() => T), deps: any[] = []): [T] {
  const [state, setState] = useState(initial)
  useEffect(() => {
    const subscription = o.subscribe(setState)
    return () => subscription.unsubscribe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [o, ...deps])
  return [state]
}

export function useBehaviorSubject<T>(o: BehaviorSubject<T>): [T, (_: T) => void] {
  const setter = useCallback((v: T) => o.next(v), [o])
  return [...useObservable(o, o.getValue.bind(o)), setter]
}
