import { Button, Modal, Typography } from 'antd'
import React, { FC, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { BehaviorSubject, debounce, map, Subject, Subscription, timer } from 'rxjs'
import * as uuid from 'uuid'
import { Providing, resourceUrl } from '../global-vars'
import { useBehaviorSubject } from '../react-rx'
import { Layer, scriptTab } from '../room/states'
import { Render, tuple } from '../types'
import { PlainTextProductList } from './plain-text-product-list'
import { chatGPTContext, scriptType, segmentAttributes, segments } from './script-card'
import {
  intelligentUniversalConfig,
  layers,
  plainTextParts,
  save,
  ScriptSegment,
  scriptSegmentConfig,
  scriptTemplateConfig,
  useCreateExplosiveProductConfig,
  useIntelligentUniversalConfig,
  usePlainTextConfig,
  useSecKillConfig,
  useUniversalPlainTextConfig
} from './states'

const { Text } = Typography

export const ScriptTemplateList: FC = () => {
  const saveSubj = useContext(save)
  const [stype, setScriptType] = useBehaviorSubject(useContext(scriptType))
  const [, setTab] = useBehaviorSubject(useContext(scriptTab))
  return (
    <div
      style={{
        boxSizing: 'border-box',
        height: '100%',
        width: '100%'
      }}
    >
      <Render>
        {function PlainText() {
          const segmentSubj = useContext(segments)
          return (
            <Template
              {...usePlainTextConfig()}
              clickUse={() => {
                if (stype !== 'plain_text') setScriptType('plain_text')
                setTab('script')
                segmentSubj.next([])
                saveSubj.next(false)
              }}
            />
          )
        }}
      </Render>
      <Render>
        {function Universal() {
          const segmentSubj = useContext(segments)
          const layerSubj = useContext(layers)
          const localSegmentSubj = useMemo(() => new BehaviorSubject<ScriptSegment[]>([]), [])
          const localLayerSubj = useMemo(() => new BehaviorSubject<Layer[]>([]), [])
          const openSubj = useMemo(() => new Subject<void>(), [])
          const [productOpen, setProductOpen] = useState(false)
          const config = useUniversalPlainTextConfig()
          useEffect(() => {
            const subscription = new Subscription()
            subscription.add(
              openSubj
                .pipe(map(() => segmentSubj.value?.filter((p) => p.attributes?.some((a) => !!a.product))))
                .subscribe(localSegmentSubj)
            )
            subscription.add(
              openSubj
                .pipe(
                  map(() =>
                    layerSubj.value?.filter(
                      (l) =>
                        !l.range ||
                        segmentSubj.value?.find((s) => s.key === l.range?.key && s.attributes?.some((a) => !!a.product))
                    )
                  )
                )
                .subscribe(localLayerSubj)
            )
            subscription.add(
              openSubj
                .pipe(
                  debounce(() => timer(300)),
                  map(() => true)
                )
                .subscribe(setProductOpen)
            )
            return () => subscription.unsubscribe()
          }, [layerSubj, localLayerSubj, localSegmentSubj, openSubj, segmentSubj])
          return (
            <Providing _={(_) => _(segments, localSegmentSubj)(layers, localLayerSubj)}>
              <PlainTextProductList
                title="为模版添加商品"
                open={productOpen}
                onCancel={() => setProductOpen(false)}
                onConfirm={() => {
                  if (config) {
                    const adding = plainTextParts
                      .flatMap((t) => (config.script[t] || []).map((item) => tuple(t, item)))
                      .filter(([, t]) => !localSegmentSubj.value.some((s) => s.title === t.title))
                      .filter(([, t]) => t.candidates.length)
                      .map(([slot, t]) => {
                        const candidates: any = t.candidates
                        return {
                          key: uuid.v4(),
                          title: t.title,
                          line_contents: [
                            {
                              text: candidates[Math.floor(Math.random() * candidates.length)]
                            }
                          ],
                          attributes: [{ slot: { slot } }, ...segmentAttributes(t)]
                        }
                      })
                    localSegmentSubj.next([...localSegmentSubj.value, ...adding])
                  }
                  setScriptType('universal_plain_text')
                  segmentSubj.next(localSegmentSubj.value)
                  layerSubj.next(localLayerSubj.value)
                  setProductOpen(false)
                  setTab('script')
                  saveSubj.next(false)
                }}
              />
              <Template
                {...config}
                clickUse={() => {
                  if (stype === 'universal_plain_text') {
                    setTab('script')
                    return
                  }
                  openSubj.next()
                }}
              />
            </Providing>
          )
        }}
      </Render>
      <IntelligentUniversalTemplate
        useConfig={useIntelligentUniversalConfig}
        onConfirm={() => {
          setScriptType('intelligent_universal')
          setTab('script')
          saveSubj.next(false)
        }}
      />
      <IntelligentUniversalTemplate
        useConfig={useCreateExplosiveProductConfig}
        onConfirm={() => {
          setScriptType('create_explosive_product')
          setTab('script')
          saveSubj.next(false)
        }}
      />
      <IntelligentUniversalTemplate
        useConfig={useSecKillConfig}
        onConfirm={() => {
          setScriptType('seckill')
          setTab('script')
          saveSubj.next(false)
        }}
      />
      {/* <IntelligentUniversalTemplate
      useConfig={useSaveUpPopularityConfig}
      dType="save_up_popularity"
      onConfirm={() => {
        setScriptType('save_up_popularity')
        setTab('script')
        saveSubj.next(false)
      }}/> */}
    </div>
  )
}

export const IntelligentUniversalTemplate: FC<{
  useConfig: () => scriptTemplateConfig<intelligentUniversalConfig>
  onConfirm: () => void
  dType?: string
}> = ({ useConfig, onConfirm, dType }) => {
  const segmentSubj = useContext(segments)
  const layerSubj = useContext(layers)
  const config = useConfig()
  const localSegmentSubj = useMemo(() => new BehaviorSubject<ScriptSegment[]>([]), [])
  const localLayerSubj = useMemo(() => new BehaviorSubject<Layer[]>([]), [])
  const openSubj = useMemo(() => new Subject<void>(), [])
  const [productOpen, setProductOpen] = useState(false)
  const chatGPT = useContext(chatGPTContext)

  useEffect(() => {
    const subscription = new Subscription()
    subscription.add(
      openSubj
        .pipe(map(() => segmentSubj.value?.filter((p) => p.attributes?.some((a) => !!a.product)) || []))
        .subscribe(localSegmentSubj)
    )
    subscription.add(
      openSubj
        .pipe(
          map(() =>
            layerSubj.value?.filter(
              (l) =>
                !l.range ||
                segmentSubj.value?.find((s) => s.key === l.range?.key && s.attributes?.some((a) => !!a.product))
            )
          )
        )
        .subscribe(localLayerSubj)
    )
    subscription.add(
      openSubj
        .pipe(
          debounce(() => timer(300)),
          map(() => true)
        )
        .subscribe(setProductOpen)
    )
    return () => subscription.unsubscribe()
  }, [layerSubj, localLayerSubj, localSegmentSubj, openSubj, segmentSubj, config])
  return (
    <Providing _={(_) => _(segments, localSegmentSubj)(layers, localLayerSubj)}>
      <PlainTextProductList
        title="为模版添加商品"
        dType={dType}
        open={productOpen}
        onCancel={() => setProductOpen(false)}
        onConfirm={() => {
          const script = config.script

          if (dType === 'save_up_popularity') {
            let lurking = script.lurking?.map(evalSegmentConfig(segmentSubj.value)).map(withSlotNew('lurking')) || []
            let save_up_1 =
              script.save_up_1?.map(evalSegmentConfig(segmentSubj.value)).map(withSlotNew('save_up_1')) || []
            let save_up_2 =
              script.save_up_2?.map(evalSegmentConfig(segmentSubj.value)).map(withSlotNew('save_up_2')) || []
            let welfare = script.welfare?.map(evalSegmentConfig(segmentSubj.value)).map(withSlotNew('welfare')) || []
            lurking = [
              ...lurking,
              ...localSegmentSubj.value.map((r) => {
                return {
                  ...r,
                  attributes: [{ slot: { slot: 'lurking' } }]
                }
              })
            ]

            if (chatGPT.value.length) {
              const welfareContent = chatGPT.value[0].content
              let content: any[] = []
              if (script.lurking) {
                const l = script.lurking[3]
                content = [l.candidates[Math.floor(Math.random() * l.candidates.length)]]
              }

              lurking[3].key = chatGPT.value[0].product_id
              const lc = [...content, ...welfareContent].map((t: string) => {
                return {
                  text: t
                }
              })
              lurking[3].line_contents = lc
              save_up_1[1].line_contents = lc
              save_up_2[0].line_contents = lc
              welfare[2].line_contents = lc
              welfare[8].line_contents = lc
            }

            segmentSubj.next([
              ...lurking,
              ...save_up_1,
              ...save_up_2,
              ...welfare,
              ...(script.before_all_products
                ?.map(evalSegmentConfig(segmentSubj.value))
                .map(withSlotNew('before_all_products')) || []),
              ...(script.before_each_product
                ?.map(evalSegmentConfig(segmentSubj.value))
                .map(withSlotNew('before_each_product')) || []),
              ...(localSegmentSubj.value || []),
              ...(script.after_each_product
                ?.map(evalSegmentConfig(segmentSubj.value))
                .map(withSlotNew('after_each_product')) || []),
              ...(script.after_all_products
                ?.map(evalSegmentConfig(segmentSubj.value))
                .map(withSlotNew('after_all_products')) || [])
            ])
          } else {
            segmentSubj.next([
              ...(script.intro?.map(evalSegmentConfig(segmentSubj.value)).map(withSlot('intro')) || []),
              ...(script.lurking?.map(evalSegmentConfig(segmentSubj.value)).map(withSlot('lurking')) || []),
              ...(script.save_up_1?.map(evalSegmentConfig(segmentSubj.value)).map(withSlot('save_up_1')) || []),
              ...(script.save_up_2?.map(evalSegmentConfig(segmentSubj.value)).map(withSlot('save_up_2')) || []),
              ...(script.welfare?.map(evalSegmentConfig(segmentSubj.value)).map(withSlot('welfare')) || []),
              ...(script.before_all_products
                ?.map(evalSegmentConfig(segmentSubj.value))
                .map(withSlot('before_all_products')) || []),
              ...(script.before_each_product
                ?.map(evalSegmentConfig(segmentSubj.value))
                .map(withSlot('before_each_product')) || []),
              ...(localSegmentSubj.value || []),
              ...(script.after_each_product
                ?.map(evalSegmentConfig(segmentSubj.value))
                .map(withSlot('after_each_product')) || []),
              ...(script.after_all_products
                ?.map(evalSegmentConfig(segmentSubj.value))
                .map(withSlot('after_all_products')) || [])
            ])
          }

          layerSubj.next(localLayerSubj.value)
          setProductOpen(false)
          onConfirm()
        }}
      />
      <Template
        {...config}
        clickUse={() => {
          openSubj.next()
        }}
      />
    </Providing>
  )
}

export const evalSegmentConfig =
  (segments: ScriptSegment[]) =>
  (config: scriptSegmentConfig): ScriptSegment => {
    const current = segments?.find((s) => s.title === config.title)
    if (current) return { ...current, key: uuid.v4() }
    return {
      key: uuid.v4(),
      title: config.title,
      line_contents: [
        {
          text: config.candidates[Math.floor(Math.random() * config.candidates.length)],
          voice_key: ''
        }
      ]
    }
  }

export const withSlot =
  (slot: string) =>
  (segment: ScriptSegment): ScriptSegment => ({
    ...segment,
    attributes: [...(segment.attributes?.filter((a) => a.slot?.slot !== slot) || []), { slot: { slot } }]
  })

export const withSlotNew =
  (slot: string) =>
  (segment: ScriptSegment): ScriptSegment => {
    return {
      ...segment,
      attributes: [{ slot: { slot } }]
    }
  }

const Template: FC<{
  title: string
  features?: string[]
  description?: string
  detail?: {
    features?: { title: string; description: string }[]
    video_key?: string
  }
  clickUse: () => void
}> = ({ title, features, description, detail, clickUse }) => {
  const url = useContext(resourceUrl)
  const videoRef = useRef<HTMLVideoElement>(null)
  const [detailOpen, setDetailOpen] = useState(false)
  useEffect(() => {
    if (!detailOpen) videoRef?.current?.pause()
  }, [videoRef, detailOpen])
  return (
    <div
      style={{
        backgroundColor: '#28282C',
        borderRadius: 2,
        padding: 16,
        height: 173,
        width: 300,
        display: 'inline-flex',
        flexDirection: 'column',
        marginRight: 20,
        marginBottom: 20,
        boxSizing: 'border-box',
        flexShrink: 0
      }}
    >
      <Modal open={detailOpen} title="模版详情" footer={null} width={800} onCancel={() => setDetailOpen(false)}>
        <div style={{ display: 'flex', width: '100%', padding: 20, boxSizing: 'border-box' }}>
          <video
            ref={videoRef}
            style={{ width: 270, height: 540, borderRadius: 12 }}
            loop
            autoPlay
            controls
            controlsList="nofullscreen nodownload noremoteplayback noplaybackrate"
            src={url(detail?.video_key || '')}
          />
          <div style={{ width: 0, flex: 1, minHeight: 540, display: 'flex', flexDirection: 'column', paddingLeft: 28 }}>
            <div style={{ fontWeight: 'bolder', color: 'white', fontSize: 24 }}>{title}</div>
            <div style={{ color: '#B4B4B4', marginBottom: 20 }}>{description}</div>
            {detail?.features?.map(({ title, description }, index) => (
              <>
                <div
                  key={`title_${index}`}
                  style={{ fontWeight: 'bolder', color: '#D4FF00', fontSize: 18, marginBottom: 4 }}
                >
                  {title}
                </div>
                <div key={`description_${index}`} style={{ marginBottom: 20 }}>
                  {description}
                </div>
              </>
            ))}
            <div style={{ height: 0, flex: 1, display: 'flex', alignItems: 'end' }}>
              <Button onClick={() => setDetailOpen(false)}>取消</Button>
              <Button
                type="primary"
                style={{ marginLeft: 12 }}
                onClick={() => {
                  setDetailOpen(false)
                  clickUse()
                }}
              >
                使用
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <div style={{ fontSize: 14, fontWeight: 600, color: 'white' }}>{title}</div>
      {!!features?.length && (
        <div style={{ marginTop: 8 }}>
          {features?.map((f) => (
            <span
              style={{
                fontSize: 12,
                fontWeight: 400,
                color: '#D4FF00',
                backgroundColor: '#30370C',
                padding: '2px 8px',
                marginRight: 4
              }}
            >
              {f}
            </span>
          ))}
        </div>
      )}
      {!!description && (
        <Text style={{ fontSize: 12, fontWeight: 400, color: '#B4B4B4', marginTop: 8 }}>{description}</Text>
      )}
      <div
        style={{
          height: 0,
          flex: 1,
          justifyContent: detail ? 'space-between' : 'end',
          display: 'flex',
          alignItems: 'end'
        }}
      >
        {!!detail && (
          <div
            style={{ fontWeight: 600, color: '#6B83FF', display: 'inline-block', cursor: 'pointer' }}
            onClick={() => setDetailOpen(true)}
          >
            详情
          </div>
        )}
        <Button type="primary" onClick={clickUse}>
          使用
        </Button>
      </div>
    </div>
  )
}
