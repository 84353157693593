import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $createTextNode, EditorConfig, LexicalNode, TextNode } from "lexical";
import { FC, useEffect, useMemo } from "react";
import { addClassName } from "../../lib/util";

export const HighlightPlugin: FC<{ text: string; className?: string }> = ({ text, className }) => {
  const __className = useMemo(() => className || 'highlighted', [className])
  const [editor] = useLexicalComposerContext()
  useEffect(
    () =>
      [
        editor.registerNodeTransform(TextNode, (node) => {
          const index = node.getTextContent().indexOf(text)
          if (index < 0) return
          const target = node.splitText(index, index + text.length)[node.getTextContent() === text ? 0 : 1]
          target?.replace($createHighlightNode(text, __className))
        }),
        editor.registerNodeTransform(HighlightNode, (node) => {
          if (node.getTextContent() !== node.__target) node.replace($createTextNode(node.getTextContent()))
        })
      ].reduce(
        (l, r) => () => {
          l()
          r()
        },
        () => void 0
      ),
    [__className, editor, text]
  )
  return null
}

export class HighlightNode extends TextNode {
  __className: string
  __target: string

  constructor(text: string, target: string, className: string) {
    super(text)
    this.__target = target
    this.__className = className
  }

  isUnmergeable(): boolean {
    return true
  }

  static getType() {
    return 'highlightNode'
  }

  exportJSON() {
    return {
      ...super.exportJSON(),
      type: 'highlightNode',
      highlight_target: this.__target,
      highlight_class_name: this.__className,
      version: 1
    }
  }

  static importJSON(serializedNode: any): HighlightNode {
    return new HighlightNode(
      TextNode.importJSON(serializedNode).getTextContent(),
      serializedNode.highlight_target || '',
      serializedNode.highlight_class_name || ''
    )
  }

  static clone(node: HighlightNode) {
    return new HighlightNode(node.__text, node.__target, node.__className)
  }

  createDOM(config: EditorConfig): HTMLElement {
    const node = super.createDOM(config)
    node.className = addClassName(node.className, this.__className)
    return node
  }
}

function $createHighlightNode(text: string, className: string) {
  return new HighlightNode(text, text, className)
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function $isHighlightNode(node: LexicalNode): boolean {
  return node instanceof HighlightNode
}