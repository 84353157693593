/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Button,
  Input,
  message,
  Modal,
  Popconfirm,
  Popover,
  Select,
  Spin,
  Switch,
  Tooltip,
  Tree,
  TreeProps
} from 'antd'
import { AxiosInstance } from 'axios'
import {
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  $getSelection,
  $isRangeSelection,
  EditorState,
  TextNode
} from 'lexical'
import { createContext, FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { BehaviorSubject, combineLatestWith, debounce, interval, map, of, Subject } from 'rxjs'
import * as uuid from 'uuid'
import { CopyOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { useAPI } from '../api'
import { currentUser } from '../auth/states'
import { SensitiveWordsNode, SensitiveWordsPlugin } from '../components/lexical/sensitive-words-plugin'
import { devDomains, mouseDown, prodDomains, Providing, roomId, wsContext } from '../global-vars'
import { getTimeDesc } from '../lib/util'
import { Product, useProducts } from '../product-center/product_center_page'
import { useBehaviorSubject, useObservable } from '../react-rx'
import { ReactComponent as Trash } from '../res/trash.svg'
import { ReactComponent as TriangleDown } from '../res/triangle-down.svg'
import { ReactComponent as TriangleRight } from '../res/triangle-right.svg'
import { CreateAllRandomLine } from '../room/room-editor'
import { Layer, roomEditorPanel, scriptTab, SectionType } from '../room/states'
import { Render } from '../types'
import VoiceUpload from './components/voice-upload'
import RecordingRowModal from './components/voice-upload/recording-row-modal'
import { UploadVoiceSplotModal } from './components/voice-upload/voice-upload-split-modal'
import { DndItem, DndType } from './draggable'
import {
  $paragraphContent,
  $resetParagraphSelectionNodes,
  $selectedParagraphRange,
  ParagraphLinkPlugin,
  ParagraphNewLinePlugin,
  ParagraphSelectionNode,
  RandomLineNode,
  RandomLinePlugin,
  rangeSelection,
  SelectParagraphPlugin
} from './paragraph-selection'
import { PlainTextProductList } from './plain-text-product-list'
import {
  AsrTask,
  layers,
  LineContent,
  plainTextParts,
  save,
  ScriptSegment,
  scriptSegmentConfig,
  segmentKey,
  selectedRange,
  selectedRangeChange,
  usePlainTextConfig
} from './states'

export const asrTag = createContext<BehaviorSubject<boolean>>(undefined as any)
export const copyLines = createContext<BehaviorSubject<LineContent[]>>(undefined as any)
export const sections = createContext<BehaviorSubject<SectionType[]>>(undefined as any)
export const multipleVideos = createContext<BehaviorSubject<boolean>>(false as any)
export const multipleSectionKey = createContext<BehaviorSubject<string>>(undefined as any)
export const segments = createContext<BehaviorSubject<ScriptSegment[]>>(undefined as any)
export const scriptTemplateTypes = [
  'plain_text',
  'universal_plain_text',
  'intelligent_universal',
  'create_explosive_product',
  'seckill',
  'raw_script',
  'save_up_popularity'
] as const
export const scriptTypes = ['plain_text', 'intelligent_universal', 'raw_script', 'save_up_popularity'] as const
export const scriptType = createContext<BehaviorSubject<(typeof scriptTemplateTypes)[number]>>(undefined as any)
export const segmentsChange = createContext<Subject<ScriptSegment[]>>(undefined as any)
export const mouseInTextEditor = createContext<BehaviorSubject<boolean>>(undefined as any)
export const recordingRow = createContext<BehaviorSubject<any>>(undefined as any)
export const randomLineIndex = createContext<BehaviorSubject<number>>(undefined as any)
export const segmentsIndex = createContext<BehaviorSubject<number>>(undefined as any)
export const scriptCreateRandomLine = createContext<BehaviorSubject<boolean>>(false as any) // 剧本一键生成随机话术
export const segmentCreateRandomLine = createContext<BehaviorSubject<string[]>>([] as any) // 段落一键生成随机话术

export const plainTextScriptEditorConfig = createContext(usePlainTextConfig)

export function scriptTypeFromTemplateType(t: (typeof scriptTemplateTypes)[number]): (typeof scriptTypes)[number] {
  switch (t) {
    case 'plain_text':
    case 'universal_plain_text':
      return 'plain_text'
    case 'intelligent_universal':
    case 'create_explosive_product':
    case 'seckill':
      return 'intelligent_universal'
    case 'raw_script':
      return 'raw_script'
    case 'save_up_popularity':
      return 'save_up_popularity'
  }
}

export const PlainTextScriptEditor: FC = () => {
  const [s] = useBehaviorSubject(useContext(segments))
  const segmentsChangeSubj = useContext(segmentsChange)

  const mouseInTextEditorSubj = useMemo(() => new BehaviorSubject(false), [])
  const [, setTab] = useBehaviorSubject(useContext(scriptTab))
  const useConfig = useContext(plainTextScriptEditorConfig)
  const config = useConfig()
  const ws = useContext(wsContext)
  const [sectionsList] = useBehaviorSubject(useContext(sections))
  const sectionsSubj = useContext(sections)
  const [meunCollapsed, setMeunCollapsed] = useState(true)
  const [menuKey, setMenuKey] = useState<any>()
  const segmentsList = useMemo(() => {
    const sections = sectionsSubj.value || []
    return sections.map((section) => ({
      ...section,
      title: section.title,
      key: section.key,
      children: s
        .filter((p) => p.groupInfo?.key === section.key)
        ?.map((s) => ({
          ...s,
          label: s.title,
          key: s.key,
          parentId: section.key
        }))
    }))
  }, [s, sectionsSubj.value])
  const menuEndList = useRef<HTMLDivElement>(null)
  const segmentsSubj = useContext(segments)
  const [multipleV] = useBehaviorSubject(useContext(multipleVideos))
  const scriptTabSubj = useContext(scriptTab)
  const multipleSectionKeySubj = useContext(multipleSectionKey)
  const [asrList, setAsrList] = useState<AsrTask[]>([])
  const rid = useContext(roomId)
  const api = useAPI()
  const saveSubj = useContext(save)
  const [asr, setAsr] = useBehaviorSubject(useContext(asrTag))

  const runningAsrList = useMemo(() => {
    return asrList.filter((a) => !a.done)
  }, [asrList])

  useEffect(() => {
    getAsrList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asr])

  useEffect(() => {
    const timer = setInterval(() => {
      getAsrList()
    }, 10000)
    return () => clearInterval(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const doneList = asrList.filter((asr) => asr.done)
    if (doneList.length) {
      const changeSeg = s.filter((b) => doneList.find((d) => d.card_key !== b.groupInfo?.key))
      doneList.forEach((d) => {
        if (d.card_level === 1) {
          if (d.failed_reason) {
            message.error(d.failed_reason)
          }
          changeSeg.push(
            ...(d.section?.segments || []).map((m) => ({
              ...m,
              groupInfo: {
                title: d.section.title,
                key: d.section.key
              }
            }))
          )
        }
      })

      segmentsChangeSubj.next(
        changeSeg.map((c) => {
          const r = doneList.find((d) => d.card_key === c.key)
          if (r) {
            if (r.failed_reason) {
              message.error(r.failed_reason)
            }
            return {
              ...c,
              ...(r.segment || {}),
              line_contents: r.segment?.line_contents || [],
              key: uuid.v4()
            }
          } else {
            return c
          }
        })
      )
      saveSubj.next(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asrList])

  const getAsrList = async () => {
    const res = await api.get(`/rooms/${rid}/async_asr/unfinished_list`)
    setAsrList(res?.data?.tasks || [])
  }

  const onDrop: TreeProps['onDrop'] = (info) => {
    const dropKey = info.node.key
    const dragKey = info.dragNode.key
    const dragPos = info.dragNode.pos.split('-')
    const dropPos = info.node.pos.split('-')
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1])

    if (dragPos.length > dropPos.length && dragPos?.[1] !== dropPos?.[1] && info.dropToGap) return

    const loop = (data: any[], key: React.Key, callback: (node: any, i: number, data: any[]) => void) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data)
        }
        if (data[i].children) {
          loop(data[i].children!, key, callback)
        }
      }
    }
    const data: any[] = [...segmentsList]

    let dragObj: any
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1)
      dragObj = item
    })

    if (!info.dropToGap) {
      loop(data, dropKey, (item) => {
        item.children = item.children || []
        item.children.unshift(dragObj)
      })
    } else if (
      ((info.node as any).props.children || []).length > 0 &&
      (info.node as any).props.expanded &&
      dropPosition === 1
    ) {
      loop(data, dropKey, (item) => {
        item.children = item.children || []
        item.children.unshift(dragObj)
      })
    } else {
      let ar: any[] = []
      let i: number
      loop(data, dropKey, (_item, index, arr) => {
        ar = arr
        i = index
      })
      if (dropPosition === -1) {
        ar.splice(i!, 0, dragObj!)
      } else {
        ar.splice(i! + 1, 0, dragObj!)
      }
    }

    const changeSubj = data.reduce((pre, next) => {
      const flatChildren = next.children.reduce((p: any[], n: any) => {
        if (n?.groupInfo?.key) {
          p.push({
            ...n,
            children: []
          })
        }
        if (n.children?.[0].children?.length) {
          return p.concat(n.children[0].children)
        } else if (n.children?.length) {
          return p.concat(n.children?.filter((c: any) => c.groupInfo?.key))
        }
        return p
      }, [])
      return pre.concat(
        (flatChildren || []).map((n: any) => {
          return {
            ...s.find((f) => f.key === n.key),
            groupInfo: {
              key: next.key,
              title: next.title
            }
          } as ScriptSegment
        }) || []
      )
    }, [] as ScriptSegment[])
    segmentsChangeSubj.next(changeSubj)
    sectionsSubj.next(data)
  }

  return (
    <Providing
      _={(p) => {
        p(mouseInTextEditor, mouseInTextEditorSubj)
      }}
    >
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          position: 'relative'
        }}
      >
        <a
          title="目录"
          onClick={() => setMeunCollapsed(!meunCollapsed)}
          style={{ position: 'absolute', top: -8, left: -44 }}
        >
          {meunCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </a>
        <div className={`list-menu s-card ${meunCollapsed ? 'collapsed' : ''}`}>
          <Tree
            className="draggable-tree"
            draggable
            blockNode
            defaultExpandAll
            onSelect={(keys) => {
              setMenuKey(keys[0])
            }}
            onDrop={onDrop}
            treeData={segmentsList}
          />
        </div>
        <DndType>
          <div
            style={{
              position: 'relative',
              width: 0,
              height: '100%',
              borderRadius: 4,
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'scroll',
              paddingRight: 24,
              flex: 1
            }}
          >
            {sectionsList?.map((section, index) => {
              return (
                <Render>
                  {function Section() {
                    const [collapsed, setCollapsed] = useState(false)
                    const subSegments = s.filter((f) => f.groupInfo?.key === section.key)
                    const endOfList = useRef<HTMLDivElement>(null)
                    const types = plainTextParts.flatMap((p) => config?.options[p] || []).map((s) => s.title)
                    const [type, setType] = useState('商品介绍')
                    const [productOpen, setProductOpen] = useState(false)
                    const [uploadType, setUploadType] = useState<string>()
                    const subTextNum: number = useMemo(() => {
                      return subSegments.reduce((pre, next) => {
                        const num =
                          next.line_contents?.reduce((p, n) => {
                            return p + (n.text?.trim().length || 0)
                          }, 0) || 0

                        return pre + num
                      }, 0)
                    }, [subSegments])

                    const subTextTime = useMemo(() => {
                      const num = (subTextNum || 0) * 0.15
                      return getTimeDesc(num)
                    }, [subTextNum])

                    useEffect(() => {
                      const inSub = subSegments.some((s) => s.key === menuKey)
                      if (inSub) {
                        setCollapsed(false)
                      }
                      setTimeout(() => {
                        menuEndList.current?.scrollIntoView({ behavior: 'smooth' })
                      })
                      // eslint-disable-next-line react-hooks/exhaustive-deps
                    }, [menuKey])

                    const onUploadSuccess = async (resource: any) => {
                      setUploadType(undefined)
                      await api.post(`/rooms/${rid}/async_asr/submit`, {
                        audio_key: resource?.key,
                        card_level: 1,
                        card_key: section.key,
                        split_by_hand: resource.splitText,
                        split_by_ai: false
                      })
                      setAsr(!asr)
                    }

                    return (
                      <div
                        className="segment-card"
                        style={{
                          display: 'flex',
                          width: '100%',
                          position: 'relative',
                          marginBottom: 24
                        }}
                      >
                        <div
                          key={section.key}
                          style={{
                            backgroundColor: '#28282C',
                            paddingBottom: 16,
                            flex: 1
                          }}
                        >
                          {menuKey === section.key && <div ref={menuEndList}></div>}
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              paddingLeft: 0,
                              paddingRight: 24,
                              paddingTop: 24,
                              paddingBottom: 8
                            }}
                          >
                            <div style={{ display: 'inline-flex', color: 'white', alignItems: 'center' }}>
                              <div
                                className="collapse-handle"
                                style={{
                                  width: 18,
                                  height: 32,
                                  display: 'inline-flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  margin: '0 6px 0 6px',
                                  cursor: 'pointer'
                                }}
                                onClick={(e) => {
                                  e.preventDefault()
                                  setCollapsed(!collapsed)
                                }}
                              >
                                {collapsed ? <TriangleRight /> : <TriangleDown />}
                              </div>
                              <Tooltip
                                placement="right"
                                title={
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      color: '#fff',
                                      opacity: '0.7'
                                    }}
                                    className="ellipsis"
                                  >
                                    <label>字数：{subTextNum}</label>
                                    <label style={{ position: 'relative', top: '-0.5px', padding: '0 6px' }}>|</label>
                                    <label>预估时长：{subTextTime || 0}</label>
                                  </div>
                                }
                              >
                                <input
                                  style={{
                                    width: 130,
                                    backgroundColor: 'transparent',
                                    outline: 'none',
                                    boxShadow: 'none',
                                    border: 'none',
                                    color: 'white',
                                    fontWeight: 'bold',
                                    fontSize: '19px'
                                  }}
                                  value={section.title || ''}
                                  onChange={(e) => {
                                    sectionsSubj.next(
                                      (sectionsSubj.value || []).map((sub) =>
                                        sub.key === section.key
                                          ? {
                                              ...sub,
                                              title: e.target.value
                                            }
                                          : sub
                                      )
                                    )
                                  }}
                                />
                              </Tooltip>
                              <span
                                style={{ display: 'none', color: '#6B83FF', marginLeft: 8, cursor: 'pointer' }}
                                onClick={() => setTab('script_type')}
                              >
                                重选
                              </span>
                            </div>
                            {multipleV && <div style={{ marginLeft: 'auto', marginRight: 16 }}></div>}

                            {multipleV && (
                              <div className="ellipsis" style={{ marginLeft: 'auto', marginRight: 16 }}>
                                <Button
                                  type={section.intro?.frame_key ? 'primary' : 'default'}
                                  onClick={async () => {
                                    scriptTabSubj.next('intro_video')
                                    multipleSectionKeySubj.next(section.key)
                                  }}
                                  style={{ marginRight: 16, width: 94 }}
                                >
                                  {section.intro?.frame_key ? '已选择入场' : '选择入场'}
                                </Button>
                                <Button
                                  type={
                                    section.w2l_video_frame_key && section.w2l_video_frame_key !== 'video'
                                      ? 'primary'
                                      : 'default'
                                  }
                                  onClick={() => {
                                    scriptTabSubj.next('multiple_videos')
                                    multipleSectionKeySubj.next(section.key)
                                  }}
                                  style={{ width: 94 }}
                                >
                                  {section.w2l_video_frame_key && section.w2l_video_frame_key !== 'video'
                                    ? '已更换主播'
                                    : '默认主播'}
                                </Button>
                              </div>
                            )}

                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <PlainTextProductList
                                title="添加商品"
                                open={productOpen}
                                onCancel={() => setProductOpen(false)}
                                onConfirm={() => setProductOpen(false)}
                                groupInfo={{
                                  key: section.key,
                                  title: section.title
                                }}
                              />
                              <Button
                                disabled={runningAsrList?.some((a) => subSegments.some((s) => s.key === a.card_key))}
                                type="primary"
                                style={{ marginRight: 10 }}
                                onClick={() => setUploadType('bgm')}
                              >
                                上传音频
                              </Button>
                              <Select
                                className="fantasy-select"
                                popupClassName="fantasy-popup"
                                style={{ backgroundColor: '#171719', minWidth: 140, marginRight: 10 }}
                                value={type}
                                onChange={setType}
                                options={[
                                  { value: '商品介绍', label: '商品介绍' },
                                  ...types.map((t) => ({ value: t, label: t }))
                                ]}
                              />

                              <Button
                                type="primary"
                                onClick={() => {
                                  if (type === '商品介绍') {
                                    setProductOpen(true)
                                    return
                                  }
                                  const slot = plainTextParts.find((s) =>
                                    config?.options?.[s]?.some((t) => t.title === type)
                                  )
                                  if (!slot) return
                                  const item = config?.options?.[slot]?.find((c) => c.title === type)
                                  const candidates = item?.candidates
                                  if (!item || !candidates?.length) return
                                  const newSegs: ScriptSegment[] = JSON.parse(JSON.stringify(segmentsSubj.value || []))
                                  const insertIndex =
                                    newSegs.findIndex((n) => n.groupInfo?.key === section.key) +
                                    newSegs.filter((n) => n.groupInfo?.key === section.key)?.length

                                  newSegs.splice(insertIndex, 0, {
                                    key: uuid.v4(),
                                    title: type,
                                    line_contents: [
                                      {
                                        text: candidates[Math.floor(Math.random() * candidates.length)],
                                        voice_key: ''
                                      }
                                    ],
                                    attributes: [{ slot: { slot } }, ...segmentAttributes(item)],
                                    groupInfo: {
                                      key: section.key,
                                      title: section.title
                                    }
                                  })

                                  segmentsChangeSubj.next(newSegs)
                                  setTimeout(() => endOfList.current?.scrollIntoView({ behavior: 'smooth' }))
                                }}
                                style={{ width: 110 }}
                              >
                                插入创作模块
                              </Button>
                              <UploadVoiceSplotModal
                                uploadType={uploadType}
                                showTips={true}
                                onClose={() => setUploadType(undefined)}
                                onUploadSuccess={onUploadSuccess}
                              />
                              <Popconfirm
                                title="删除"
                                description={`确认删除 ${section.title} 模块?`}
                                okText="确定"
                                cancelText="取消"
                                onConfirm={() => {
                                  segmentsSubj.next(
                                    (segmentsSubj.value || []).filter((seg) => seg.groupInfo?.key !== section.key)
                                  )
                                  sectionsSubj.next(sectionsSubj.value.filter((l) => l.key !== section.key))
                                }}
                              >
                                <Trash style={{ cursor: 'pointer', marginLeft: 16 }} />
                              </Popconfirm>
                            </div>
                          </div>
                          <div
                            style={{ width: '100%', display: collapsed ? 'none' : 'block' }}
                            onMouseEnter={() => mouseInTextEditorSubj.next(true)}
                            onMouseLeave={() => mouseInTextEditorSubj.next(false)}
                          >
                            <DndType>
                              {subSegments?.map((s) => (
                                <div
                                  className="script-card"
                                  key={s.key}
                                  style={{ position: 'relative', marginBottom: 20, marginRight: 20 }}
                                >
                                  {menuKey === s.key && <div ref={menuEndList}></div>}
                                  <Providing
                                    _={(p) => {
                                      p(segmentKey, s.key)
                                      p(wsContext, ws)
                                    }}
                                  >
                                    <ScriptCard reorderable deletable />
                                    {runningAsrList?.some((a) => a.card_key === s.key) && (
                                      <div className="module-mask">
                                        <Spin tip="音频切分中，请稍后" size="small"></Spin>
                                      </div>
                                    )}
                                  </Providing>
                                </div>
                              ))}
                            </DndType>
                            <div ref={endOfList} />
                          </div>
                        </div>
                        {runningAsrList?.some((a) => a.card_key === section.key) && (
                          <div className="module-mask">
                            <Spin tip="音频切分中，请稍后" size="small"></Spin>
                          </div>
                        )}
                      </div>
                    )
                  }}
                </Render>
              )
            })}
          </div>
        </DndType>
      </div>
    </Providing>
  )
}

export function segmentAttributes(config: scriptSegmentConfig): NonNullable<ScriptSegment['attributes']> {
  const attributes: NonNullable<ScriptSegment['attributes']> = []
  if (typeof config.probability === 'number') {
    attributes.push({ probability: { probability: config.probability } })
  }
  if (typeof config.member_count_increase === 'number') {
    attributes.push({ member_count_increase: { ratio: config.member_count_increase } })
  }
  return attributes
}

export async function generateProductScript(api: AxiosInstance, product: Product) {
  const history = [
    {
      role: 'user',
      content: `帮我写一段直播场景下商品介绍话术.我的商品叫${product.name}${
        product.description ? `商品详情是 ${product.description}` : ''
      }，商品售价是 ¥${product.price / 100}${product.long_description ? `，核心卖点是${product.long_description}` : ''}`
    }
  ]
  history.push({
    role: 'assistant',
    content:
      (
        await api.post<{ content: string }>(
          '/ai/chatGPT',
          { message: history },
          {
            timeout: 300000
          }
        )
      ).data?.content
        ?.replaceAll(/"/g, "'")
        ?.trim() || ''
  })
  history.push({ role: 'user', content: '我为什么要买你的产品,请说得详细一点' })
  history.push({
    role: 'assistant',
    content:
      (
        await api.post<{ content: string }>(
          '/ai/chatGPT',
          { message: history },
          {
            timeout: 300000
          }
        )
      ).data?.content
        .replaceAll(/"/g, "'")
        ?.trim() || ''
  })
  history.push({ role: 'user', content: '你再介绍一下产品的核心特色,详细一点' })
  history.push({
    role: 'assistant',
    content:
      (
        await api.post<{ content: string }>(
          '/ai/chatGPT',
          { message: history },
          {
            timeout: 300000
          }
        )
      ).data?.content
        .replaceAll(/"/g, "'")
        ?.trim() || ''
  })
  return history
    .filter(({ role }) => role === 'assistant')
    .flatMap(({ content }) =>
      content
        .split(/[。\n]/)
        .map((s) => s.trim())
        .filter(Boolean)
        .map((s) => (/.*[.。:：;；!！？?]$/.test(s) ? s : s + '。'))
    )
}

export async function generateProductWelfareScript(api: AxiosInstance, product: Product) {
  const history = [
    {
      role: 'user',
      content: `帮我写一段直播场景下福利商品介绍话术.我的商品叫${product.name},${
        product.description ? `商品详情是 ${product.description}` : ''
      }，商品售价是 ¥${product.price / 100}${product.long_description ? `，核心卖点是${product.long_description}` : ''}`
    }
  ]
  history.push({
    role: 'assistant',
    content:
      (
        await api.post<{ content: string }>(
          '/ai/chatGPT',
          { message: history },
          {
            timeout: 300000
          }
        )
      ).data?.content
        ?.replaceAll(/"/g, "'")
        ?.trim() || ''
  })
  history.push({ role: 'user', content: '1-2句话介绍产品特色，突出直播间价格优势' })
  history.push({
    role: 'assistant',
    content:
      (
        await api.post<{ content: string }>(
          '/ai/chatGPT',
          { message: history },
          {
            timeout: 300000
          }
        )
      ).data?.content
        .replaceAll(/"/g, "'")
        ?.trim() || ''
  })
  history.push({ role: 'user', content: '引导用户关注和分享直播间' })
  history.push({
    role: 'assistant',
    content:
      (
        await api.post<{ content: string }>(
          '/ai/chatGPT',
          { message: history },
          {
            timeout: 300000
          }
        )
      ).data?.content
        .replaceAll(/"/g, "'")
        ?.trim() || ''
  })
  return history
    .filter(({ role }) => role === 'assistant')
    .flatMap(({ content }) =>
      content
        .split(/[。\n]/)
        .map((s) => s.trim())
        .filter(Boolean)
        .map((s) => (/.*[.。:：;；!！？?]$/.test(s) ? s : s + '。'))
    )
}

export const chatGPTContext = createContext<BehaviorSubject<{ content: string[]; product_id: string }[]>>(
  undefined as any
)

export const resetParagraph = createContext(new Subject<void>())

export const ScriptCard: FC<{ reorderable?: boolean; deletable?: boolean }> = ({ reorderable, deletable }) => {
  const key = useContext(segmentKey)
  // const title = useContext(segmentTitle)
  const rangeSubj = useContext(selectedRange)
  const rangeChange = useContext(selectedRangeChange)
  const segmentsSubj = useContext(segments)
  const segmentsChangeSubj = useContext(segmentsChange)
  const [s] = useBehaviorSubject(segmentsSubj)
  const index = useMemo(() => s.findIndex((s) => s.key === key), [s, key])
  const change = useContext(segmentsChange)
  const moveItem = useMemo(
    () =>
      moveListItem<ScriptSegment>((_) => {
        return change.next(_(segmentsSubj.value))
      }),
    [segmentsSubj, change]
  )
  const childKeys = useRef<any>()
  const [ls, setLayers] = useBehaviorSubject(useContext(layers))

  //如果是因商品才初始化的单元，则直接使用此商品

  let product_id = ''
  s[index].attributes?.forEach((a) => (product_id = a.product?.product_id || ''))
  if (product_id) {
    if (!s[index].attributes?.filter((a) => !!a?.sku)?.length) {
      s[index].attributes?.push({ sku: { product_id } })
    } else {
      s[index].attributes?.map((a) =>
        a.sku
          ? {
              sku: { ...a.sku, product_id }
            }
          : a
      )
    }
  }

  if (index < 0) return null
  return (
    <DndItem id={key} index={index} moveItem={moveItem}>
      <Render>
        {function Editor() {
          const [collapsed, setCollapsed] = useState(false)
          const hasSelection = useMemo(() => new BehaviorSubject(false), [])
          const [offset, setOffset] = useState<[number, number]>()
          const tabSubj = useContext(scriptTab)
          const mouseDownSubj = useContext(mouseDown)
          const enabled = useContext(roomEditorPanel) === 'script'
          const selectionState = useMemo(
            () => new BehaviorSubject<{ start: number; end: number } | undefined>(undefined),
            []
          )
          const [, setRi] = useBehaviorSubject(useContext(randomLineIndex))
          const [sIndex, setSIndex] = useBehaviorSubject(useContext(segmentsIndex))
          const range = rangeSubj.value
          const rangeNum = (range?.range?.end || 0) - (range?.range?.start || 0)
          const [paste, setPaste] = useState(false)

          const updateRange = useCallback(
            (state: EditorState) => {
              const range = state.read($selectedParagraphRange)
              if (range) {
                rangeChange.next({ key: key, range })
              } else if (rangeSubj.value?.key === key && tabSubj.value !== 'resource') {
                rangeChange.next(undefined)
              }
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [rangeSubj, rangeChange, key]
          )
          useEffect(() => {
            const subscription = rangeSubj
              .pipe(map((r) => (r?.key === key ? r.range : undefined)))
              .subscribe(selectionState)
            return () => subscription.unsubscribe()
          }, [selectionState])
          const popoverOpenSubj = useMemo(
            () =>
              hasSelection.pipe(
                combineLatestWith(mouseDownSubj, tabSubj, rangeSubj),
                map(
                  ([hasSel, mouseDown, tab, range]) => hasSel && !mouseDown && tab === 'script' && range?.key === key
                ),
                debounce((v) => (v ? interval(500) : of(1)))
              ),
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [hasSelection, mouseDownSubj, tabSubj, rangeSubj]
          )
          const [popoverOpen] = useObservable(popoverOpenSubj, false)
          const products = useProducts()

          const textNum: number = useMemo(() => {
            return (
              s[index].line_contents?.reduce((pre, next) => {
                return pre + (next.text?.trim().length || 0)
              }, 0) || 0
            )
            // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [s[index]])

          const textTime = useMemo(() => {
            const num = (textNum || 0) * 0.15
            return getTimeDesc(num)
          }, [textNum])

          return (
            <div
              style={{
                // opacity: isDragging ? 0.5 : 1,
                display: 'flex',
                alignContent: 'center'
              }}
            >
              <div
                style={{
                  width: 0,
                  flex: 1,
                  backgroundColor: '#1C1D1E',
                  paddingTop: 18,
                  paddingRight: 18,
                  paddingBottom: 18,
                  marginLeft: 24
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                  <div
                    className="collapse-handle"
                    style={{
                      width: 18,
                      height: 32,
                      display: 'inline-flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      margin: '0 2px 0 6px',
                      cursor: 'pointer'
                    }}
                    onClick={(e) => {
                      e.preventDefault()
                      setCollapsed(!collapsed)
                    }}
                  >
                    {collapsed ? <TriangleRight /> : <TriangleDown />}
                  </div>
                  <input
                    style={{
                      width: 0,
                      flex: 1,
                      backgroundColor: 'transparent',
                      outline: 'none',
                      boxShadow: 'none',
                      border: 'none',
                      color: 'white',
                      fontWeight: 'bold',
                      fontSize: '15px'
                    }}
                    value={s?.[index]?.title || ''}
                    onChange={(e) => {
                      segmentsSubj.next(
                        (segmentsSubj.value || []).map((seg) =>
                          seg.key === key
                            ? {
                                ...seg,
                                title: e.target.value
                              }
                            : seg
                        )
                      )
                    }}
                  />

                  <div style={{ marginRight: 40 }}>
                    <CreateAllRandomLine segKey={s[index].key} />
                  </div>

                  {!collapsed && (
                    <Select
                      onChange={(v) => {
                        if (!s[index].attributes?.filter((a) => !!a?.sku)?.length) {
                          s[index].attributes?.push({ sku: {} })
                        }
                        segmentsSubj.next(
                          s.map((ss) =>
                            ss.key !== key
                              ? ss
                              : {
                                  ...ss,
                                  attributes: ss.attributes?.map((a) =>
                                    a.sku
                                      ? {
                                          sku: { ...a.sku, product_id: v }
                                        }
                                      : a
                                  )
                                }
                          )
                        )
                      }}
                      dropdownMatchSelectWidth={200}
                      style={{ width: 150, marginRight: 24 }}
                      allowClear
                      value={s[index].attributes?.find((a) => a.sku)?.sku?.product_id}
                      placeholder="关联商品"
                      size="small"
                      options={products.map((pd) => {
                        return { value: pd.id, label: pd.name }
                      })}
                    />
                  )}

                  {!collapsed && (
                    <Providing _={(p) => p(segments, segmentsSubj)(segmentsChange, segmentsChangeSubj)}>
                      <VoiceUpload key={key} />
                    </Providing>
                  )}

                  <Tooltip title="复制">
                    <CopyOutlined
                      style={{ color: '#fff', fontSize: 16, cursor: 'pointer' }}
                      onClick={() => {
                        const segs = JSON.parse(JSON.stringify(segmentsSubj.value || []))
                        const id = uuid.v4()
                        segs.splice(index + 1, 0, {
                          ...s[index],
                          key: id,
                          title: `${s[index].title}副本`
                        })
                        segmentsChangeSubj.next(segs)
                        message.success('复制成功')
                      }}
                    />
                  </Tooltip>

                  {deletable && (
                    <Trash
                      style={{ cursor: 'pointer', marginLeft: 16 }}
                      onClick={() => segmentsSubj.next((segmentsSubj.value || []).filter((seg) => seg.key !== key))}
                    />
                  )}
                </div>
                {!collapsed && (
                  <div
                    style={{
                      height: 1,
                      width: 'calc(100% - 32px)',
                      backgroundColor: '#434343',
                      marginTop: 16,
                      marginBottom: 16,
                      marginLeft: 26
                    }}
                  />
                )}
                {!collapsed && (
                  <Render>
                    {function EnterCount() {
                      const enterCount = s[index].attributes?.find((a) => a.enter_count)?.enter_count
                      return (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            width: 'calc(100% - 32px)',
                            marginLeft: 26,
                            marginBottom: 8,
                            paddingBottom: 16,
                            borderBottom: '1px solid #434343',
                            color: enterCount ? 'rgba(255, 255, 255, 0.8)' : 'rgba(255, 255, 255, 0.5)'
                          }}
                        >
                          <Switch
                            checked={!!enterCount}
                            className="card-switch"
                            onChange={(checked) => {
                              if (checked && !enterCount) {
                                segmentsSubj.next(
                                  s.map((ss) =>
                                    ss.key !== key
                                      ? ss
                                      : {
                                          ...ss,
                                          attributes: [
                                            ...(ss.attributes || []),
                                            {
                                              enter_count: {
                                                duration_seconds: 10,
                                                cool_down_duration_seconds: 20,
                                                count: 10
                                              }
                                            }
                                          ]
                                        }
                                  )
                                )
                              } else if (!checked && !!enterCount) {
                                segmentsSubj.next(
                                  s.map((ss) =>
                                    ss.key !== key
                                      ? ss
                                      : {
                                          ...ss,
                                          attributes: ss.attributes?.filter((a) => !a.enter_count)
                                        }
                                  )
                                )
                              }
                            }}
                          />
                          <div style={{ fontSize: 14, fontWeight: 'bold', marginLeft: 12 }}>自动触发</div>
                          <div style={{ display: 'flex', alignItems: 'center', marginLeft: 24 }}>
                            <label>当有</label>
                            <Input
                              className="input-with-hint small"
                              disabled={!enterCount}
                              value={enterCount?.count || 0}
                              onChange={(e) => {
                                const { value: inputValue } = e.target
                                const reg = /^-?\d*(\.\d*)?$/
                                if (reg.test(inputValue)) {
                                  segmentsSubj.next(
                                    s.map((ss) =>
                                      ss.key !== key
                                        ? ss
                                        : {
                                            ...ss,
                                            attributes: ss.attributes?.map((a) =>
                                              a.enter_count
                                                ? {
                                                    enter_count: { ...a.enter_count, count: Number(inputValue) }
                                                  }
                                                : a
                                            )
                                          }
                                    )
                                  )
                                }
                              }}
                              suffix="人"
                              maxLength={6}
                            />
                            <label>在</label>
                            <Input
                              className="input-with-hint small"
                              disabled={!enterCount}
                              value={enterCount?.duration_seconds || 0}
                              onChange={(e) => {
                                const { value: inputValue } = e.target
                                const reg = /^-?\d*(\.\d*)?$/
                                if (reg.test(inputValue)) {
                                  segmentsSubj.next(
                                    s.map((ss) =>
                                      ss.key !== key
                                        ? ss
                                        : {
                                            ...ss,
                                            attributes: ss.attributes?.map((a) =>
                                              a.enter_count
                                                ? {
                                                    enter_count: {
                                                      ...a.enter_count,
                                                      duration_seconds: Number(inputValue)
                                                    }
                                                  }
                                                : a
                                            )
                                          }
                                    )
                                  )
                                }
                              }}
                              suffix="秒"
                              maxLength={6}
                            />
                            <label>内进入直播间则自动触发，未满足条件则不触发。触发间隔不小于</label>
                            <Input
                              className="input-with-hint small"
                              disabled={!enterCount}
                              value={enterCount?.cool_down_duration_seconds || 0}
                              onChange={(e) => {
                                const { value: inputValue } = e.target
                                const reg = /^-?\d*(\.\d*)?$/
                                if (reg.test(inputValue)) {
                                  segmentsSubj.next(
                                    s.map((ss) =>
                                      ss.key !== key
                                        ? ss
                                        : {
                                            ...ss,
                                            attributes: ss.attributes?.map((a) =>
                                              a.enter_count
                                                ? {
                                                    enter_count: {
                                                      ...a.enter_count,
                                                      cool_down_duration_seconds: Number(inputValue)
                                                    }
                                                  }
                                                : a
                                            )
                                          }
                                    )
                                  )
                                }
                              }}
                              suffix="秒"
                              maxLength={6}
                            />
                          </div>
                        </div>
                      )
                    }}
                  </Render>
                )}

                <div style={{ display: !collapsed ? 'block' : 'none' }}>
                  <LexicalComposer
                    initialConfig={{
                      namespace: 'text-card',
                      onError(error, editor) {
                        console.error('editor error', error)
                      },
                      nodes: [ParagraphSelectionNode, SensitiveWordsNode, RandomLineNode],
                      editorState: () =>
                        $getRoot().append(
                          ...(s?.[index]?.line_contents?.map((t) =>
                            $createParagraphNode().append($createTextNode(t?.text))
                          ) || [])
                        )
                    }}
                  >
                    <ParagraphNewLinePlugin />
                    <Providing _={(p) => p(rangeSelection, selectionState)}>
                      <ParagraphLinkPlugin className="linked-line" />
                      <Render>
                        {function InputField() {
                          const [editor] = useLexicalComposerContext()
                          const reset = useContext(resetParagraph)
                          const copyLinesSubj = useContext(copyLines)
                          const classNames = s[index].voice_switch ? 'voice-on' : ''
                          editor.update(() => {
                            if (!childKeys.current) {
                              childKeys.current = $getRoot().getChildrenKeys()
                            }
                          })

                          useEffect(() => {
                            if (s[index].id) {
                              editor.setEditable(false)
                              editor.update(
                                () => {
                                  const roots = $getRoot().getChildren()
                                  const text = s[index].line_contents || []
                                  if (!text?.length) {
                                    roots.forEach((t: any) => {
                                      t.remove()
                                    })
                                  } else {
                                    const senstive = s[index].id?.includes('blank') ? '' : s[index].id
                                    if (roots.length === text?.length) {
                                      const cp = roots[roots.length - 1]
                                      const textNode = new TextNode(senstive || '')
                                      cp.clear()
                                      cp.append(textNode)
                                    } else if (!roots.length) {
                                      const root = $getRoot()
                                      const paragraphNode = $createParagraphNode()
                                      const textNode = new TextNode(senstive || '')
                                      paragraphNode.append(textNode)
                                      root.append(paragraphNode)
                                    } else {
                                      for (let i = roots.length - 1; i < text.length; i++) {
                                        if (i === roots.length - 1) {
                                          const cp = roots[roots.length - 1]
                                          const textNode = new TextNode(text[i].text || '')
                                          cp.clear()
                                          cp.append(textNode)
                                        } else {
                                          const root = $getRoot()
                                          const paragraphNode = $createParagraphNode()
                                          const textNode = new TextNode(text[i].text || '')
                                          paragraphNode.append(textNode)
                                          root.append(paragraphNode)
                                        }
                                      }
                                    }
                                  }
                                },
                                { tag: 'skip-scroll-into-view' }
                              )
                            } else {
                              editor.setEditable(true)
                            }
                            // eslint-disable-next-line react-hooks/exhaustive-deps
                          }, [s[index].id])

                          useEffect(() => {
                            if (paste) {
                              editor.update(
                                () => {
                                  const { end = 0 } = range?.range || {}
                                  const roots = $getRoot().getChildren()
                                  if (copyLinesSubj.value?.length) {
                                    const copyValue: LineContent[] = JSON.parse(JSON.stringify(copyLinesSubj.value))
                                    copyValue.reverse().forEach((c, i) => {
                                      const paragraphNode = $createParagraphNode()
                                      const textNode = new TextNode(c.text || '')
                                      paragraphNode.append(textNode)
                                      roots[end - 1].insertAfter(paragraphNode)
                                    })
                                  }
                                  setPaste(false)
                                },
                                { tag: 'skip-scroll-into-view' }
                              )
                            }
                            // eslint-disable-next-line react-hooks/exhaustive-deps
                          }, [paste])

                          useEffect(() => {
                            const subscription = reset
                              .pipe(debounce(() => interval(100)))
                              .subscribe(() => editor.update($resetParagraphSelectionNodes))
                            return () => subscription.unsubscribe()
                          }, [reset, editor])
                          const scriptTabSubj = useContext(scriptTab)
                          return (
                            <Popover
                              open={enabled && popoverOpen}
                              overlayInnerStyle={{ padding: 0 }}
                              placement="topLeft"
                              showArrow={false}
                              align={{ offset }}
                              content={
                                <div>
                                  <Button
                                    style={{ display: 'flex', alignItems: 'center' }}
                                    className="border-button selected"
                                  >
                                    <label
                                      style={{ cursor: 'pointer' }}
                                      onMouseDown={() => {
                                        scriptTabSubj.next('resource')
                                        setTimeout(() => rangeChange.next(range))
                                      }}
                                    >
                                      素材关联
                                    </label>
                                    <i
                                      style={{
                                        display: 'inline-block',
                                        width: '1px',
                                        height: '12px',
                                        borderRight: '1px solid #434343',
                                        margin: '0 10px'
                                      }}
                                    ></i>
                                    <label
                                      style={{ cursor: 'pointer' }}
                                      onMouseDown={() => {
                                        const index = range?.range?.start || 0
                                        const endIndex = range?.range?.end || 0
                                        const changeLength = (range?.range?.end || 0) - index
                                        const newLayers: Layer[] = []

                                        const changeLayers = ls.map((l) => {
                                          const start = l.range?.range?.start || 0
                                          const end = l.range?.range?.end || 0
                                          const inRange = Math.max(index, start) < Math.min(endIndex, end)

                                          if (index >= start && end > endIndex && l.range?.key === key) {
                                            newLayers.push({
                                              ...l,
                                              key: uuid.v4(),
                                              range: {
                                                key: l.range.key,
                                                range: {
                                                  start: index + changeLength,
                                                  end: end
                                                }
                                              }
                                            })
                                          }

                                          const layer =
                                            l.range?.key === key
                                              ? {
                                                  ...l,
                                                  range: inRange
                                                    ? {
                                                        key: l.range.key,
                                                        range: {
                                                          start: index === start ? start + changeLength : start,
                                                          end: endIndex === end ? end - changeLength : index
                                                        }
                                                      }
                                                    : {
                                                        key: l.range.key,
                                                        range: {
                                                          start,
                                                          end
                                                        }
                                                      }
                                                }
                                              : l

                                          if (
                                            (layer.range?.range?.start || 0) >= (layer.range?.range?.end || 0) &&
                                            l.range?.key === key
                                          ) {
                                            return null as any
                                          }

                                          return layer
                                        })
                                        setLayers([...changeLayers.filter((c) => c), ...newLayers])
                                      }}
                                    >
                                      删除关联素材
                                    </label>
                                    {rangeNum <= 1 && (
                                      <>
                                        <i
                                          style={{
                                            display: 'inline-block',
                                            width: '1px',
                                            height: '12px',
                                            borderRight: '1px solid #434343',
                                            margin: '0 10px'
                                          }}
                                        ></i>
                                        <label
                                          style={{ cursor: 'pointer' }}
                                          onMouseDown={() => {
                                            setRi(range?.range?.start || 0)
                                            setSIndex(index)
                                          }}
                                        >
                                          添加随机话术
                                        </label>
                                      </>
                                    )}
                                    <i
                                      style={{
                                        display: 'inline-block',
                                        width: '1px',
                                        height: '12px',
                                        borderRight: '1px solid #434343',
                                        margin: '0 10px'
                                      }}
                                    ></i>
                                    <label
                                      style={{ cursor: 'pointer' }}
                                      onMouseDown={() => {
                                        copyLinesSubj.next(
                                          s[index].line_contents?.filter((l, i) => {
                                            return i >= (range?.range?.start || 0) && i < (range?.range?.end || 0)
                                          }) as LineContent[]
                                        )
                                        message.success('已复制')
                                      }}
                                    >
                                      复制选中行
                                    </label>
                                    {copyLinesSubj.value && (
                                      <>
                                        <i
                                          style={{
                                            display: 'inline-block',
                                            width: '1px',
                                            height: '12px',
                                            borderRight: '1px solid #434343',
                                            margin: '0 10px'
                                          }}
                                        ></i>
                                        <label
                                          style={{ cursor: 'pointer' }}
                                          onMouseDown={() => {
                                            const { end = 0 } = range?.range || {}
                                            const segs = segmentsSubj.value.map((s) => {
                                              if (s.key !== key) {
                                                return s
                                              } else {
                                                const line_contents = JSON.parse(JSON.stringify(s.line_contents || []))
                                                line_contents?.splice(
                                                  end,
                                                  0,
                                                  ...copyLinesSubj.value.map((l) => ({ ...l, line_mark: uuid.v4() }))
                                                )
                                                return {
                                                  ...s,
                                                  line_contents
                                                }
                                              }
                                            })

                                            setTimeout(() => {
                                              segmentsChangeSubj.next(segs)
                                            }, 200)
                                            setPaste(true)
                                          }}
                                        >
                                          粘贴到下一行
                                        </label>
                                      </>
                                    )}
                                  </Button>
                                </div>
                              }
                            >
                              <RichTextPlugin
                                contentEditable={
                                  <ContentEditable
                                    className={`rich-text-content ${classNames}`}
                                    style={{ color: 'white', outline: 'none', marginLeft: 18 }}
                                    spellCheck={false}
                                  />
                                }
                                placeholder={<></>}
                                ErrorBoundary={LexicalErrorBoundary}
                              />
                            </Popover>
                          )
                        }}
                      </Render>
                    </Providing>
                    <HistoryPlugin />
                    {/*<ParagraphSelectionDotPlugin/>*/}
                    <SelectParagraphPlugin selection={selectionState} />
                    <OnChangePlugin
                      onChange={(state, editor) => {
                        const selected = state.read(() => {
                          const selection = $getSelection()
                          return $isRangeSelection(selection) ? selection.getTextContent() : undefined
                        })
                        const range = state.read($selectedParagraphRange)
                        if (selected?.length && range) {
                          const p = editor.getRootElement()?.children[range.start]
                          if (p instanceof HTMLElement) {
                            const parentRect = editor.getRootElement()?.getBoundingClientRect()
                            const pRect = p.getBoundingClientRect()
                            const cRect = document.getSelection()?.getRangeAt(0)?.getClientRects()?.[0]
                            if (parentRect && cRect && pRect) {
                              setOffset([cRect.width / 2 + cRect.left - pRect.left - 20, cRect.top - parentRect.top])
                              hasSelection.next(true)
                              return
                            }
                          }
                        }
                        return hasSelection.next(false)
                      }}
                    />
                    <OnChangePlugin
                      ignoreSelectionChange
                      onChange={(state) => {
                        state.read(() => {
                          const keys = $getRoot().getChildrenKeys()
                          if (!s[index].id) {
                            let diffIndexes: number[] = []
                            const cl = (childKeys.current && childKeys.current?.length) || 0
                            const kl = keys.length
                            const isAdd = cl < kl
                            if (cl !== kl) {
                              if (isAdd) {
                                diffIndexes = keys
                                  .filter((el) => {
                                    return !childKeys.current.includes(el)
                                  })
                                  .map((el) => {
                                    return keys.indexOf(el)
                                  })
                              } else {
                                diffIndexes = childKeys.current
                                  ?.filter((el: string) => {
                                    return !keys.includes(el)
                                  })
                                  .map((el: string) => {
                                    return childKeys.current.indexOf(el)
                                  })
                              }
                            }

                            if (diffIndexes?.length) {
                              const index = diffIndexes[0]
                              const changeLayers = ls.map((l) => {
                                const start = l.range?.range?.start || 0
                                const end = l.range?.range?.end || 0
                                const inRange = end >= index

                                const layer =
                                  l.range?.key === key
                                    ? {
                                        ...l,
                                        range: inRange
                                          ? {
                                              key: l.range.key,
                                              range: isAdd
                                                ? {
                                                    start,
                                                    end: end + diffIndexes.length
                                                  }
                                                : {
                                                    start: Math.max(
                                                      start > index ? start - diffIndexes.length : start,
                                                      0
                                                    ),
                                                    end: Math.max(index === end ? end : end - diffIndexes.length, 0)
                                                  }
                                            }
                                          : {
                                              key: l.range.key,
                                              range: {
                                                start,
                                                end
                                              }
                                            }
                                      }
                                    : l

                                if (
                                  (layer.range?.range?.start || 0) === (layer.range?.range?.end || 0) &&
                                  l.range?.key === key
                                ) {
                                  return null as any
                                }

                                return layer
                              })
                              setLayers(changeLayers.filter((c) => c))
                            }

                            if (!paste) {
                              const s = segmentsSubj.value.map((s) => {
                                if (s.key !== key) {
                                  return s
                                } else {
                                  let lc = s.line_contents
                                  const content = state.read($paragraphContent)

                                  return {
                                    ...s,
                                    line_contents: content.map((c, index) => {
                                      const i = childKeys.current?.indexOf(keys?.[index])
                                      if (i > -1) {
                                        return {
                                          ...lc?.[i],
                                          text: c
                                        }
                                      } else {
                                        return {
                                          text: c || '',
                                          voice_key: ''
                                        }
                                      }
                                    })
                                  }
                                }
                              })

                              segmentsChangeSubj.next(s)
                            }
                          }
                          childKeys.current = keys
                        })
                      }}
                    />
                    <OnChangePlugin onChange={updateRange} />

                    <RandomLinePlugin list={s[index].line_contents} />

                    {/*<OnFocusChangePlugin onChange={(focused) => {*/}
                    {/*  if (!focused && rangeSubj.value?.key === key) {*/}
                    {/*    // console.log('clear range')*/}
                    {/*    rangeChange.next(rangeSubj.value)*/}
                    {/*  }*/}
                    {/*}}/>*/}

                    <SensitiveWordsPlugin />
                  </LexicalComposer>
                </div>

                <div
                  style={{
                    display: collapsed ? 'none' : 'flex',
                    alignItems: 'center',
                    color: '#fff',
                    opacity: '0.7',
                    justifyContent: 'flex-end'
                  }}
                >
                  <label>字数：{textNum}</label>
                  <label style={{ position: 'relative', top: '-0.5px', padding: '0 6px' }}>|</label>
                  <label>预估时长：{textTime || 0}</label>
                </div>

                {index === sIndex && (
                  <RandomLineModal
                    lineContent={s[index].line_contents}
                    key={key}
                    onCancel={() => {
                      setRi('' as any)
                      setSIndex('' as any)
                    }}
                  />
                )}
              </div>
            </div>
          )
        }}
      </Render>
    </DndItem>
  )
}

export const RandomLineModal: FC<{
  lineContent?: any[]
  onCancel: () => void
}> = (props) => {
  const { lineContent, onCancel } = props
  const rIndex = useContext(randomLineIndex)?.value || 0
  const sIndex = useContext(segmentsIndex)?.value
  const visible = !!sIndex || sIndex === 0
  const alternatives = (lineContent || [])[rIndex]?.alternatives || []
  const selectionState = useMemo(() => new BehaviorSubject<{ start: number; end: number } | undefined>(undefined), [])
  const [randomLine, setRandomLine] = useState<any[]>([])
  const randomLineCur = useRef<any>()
  const key = useContext(segmentKey)
  const segmentsSubj = useContext(segments)
  const [s] = useBehaviorSubject(segmentsSubj)
  const cIndex = useMemo(() => s.findIndex((s) => s.key === key), [s, key])
  const classNames = s[cIndex].voice_switch ? 'voice-on' : ''
  const childKeys = useRef<any>()
  const [recordingRowModalVisble, setRecordingRowModalVisble] = useState(false)
  const [, setSRecording] = useBehaviorSubject(useContext(recordingRow))
  const [recordingContent] = useBehaviorSubject(useContext(recordingRow))
  const segmentsChangeSubj = useContext(segmentsChange)
  const isDev = useMemo(() => !!window.location.host.match(/dev|localhost/), [])
  const domains = useMemo(() => (isDev ? devDomains : prodDomains), [isDev])
  const user = useContext(currentUser)

  const rangeContent = useMemo(() => {
    if (visible) {
      setRandomLine(alternatives)
      return <div style={{ lineHeight: '30px', marginBottom: '5px' }}>{lineContent?.[rIndex].text}</div>
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rIndex, sIndex])

  useEffect(() => {
    randomLineCur.current = randomLine
    if (!recordingRowModalVisble) {
      const rowDoms = document.querySelectorAll('.rich-random-line p')
      rowDoms?.forEach((r: any, i: number) => {
        const c = [...(r.classList || [].filter((t: string) => t.includes('voice') && t.includes('voice-gray')))].join(
          ' '
        )
        if (randomLine[i]?.voice_key && r) {
          r.className = `${c} voice`
        } else if (r) {
          r.className = `${c} voice-gray`
        }
        r?.addEventListener('click', uploadClick)
      })
      return () => {
        rowDoms?.forEach((r: any) => {
          r?.removeEventListener('click', uploadClick)
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [randomLine, recordingRowModalVisble])

  const uploadClick = (e: any) => {
    if (e?.target?.className?.includes('voice')) {
      const i = Array.prototype.indexOf.call(e.target.parentNode.childNodes, e.target)
      setRecordingRowModalVisble(true)
      setSRecording({
        text: randomLine[i]?.text,
        voice: randomLine[i]?.voice_key,
        index: i
      })
    }
  }

  const onRecordingRowSuccess = (resource: any) => {
    setRecordingRowModalVisble(false)
    setSRecording(undefined)
    const { index } = recordingContent
    randomLine[index].voice_key = resource.key
    setRandomLine([...randomLine])
  }

  const confirmAdd = () => {
    onCancel()
    const ss = segmentsSubj.value.map((s, i: number) => {
      if (cIndex !== i) {
        return s
      } else {
        if (s.line_contents) {
          s.line_contents[rIndex].alternatives = [...randomLineCur.current.filter((r: any) => r.text)]
        }
        return {
          ...s
        }
      }
    })
    segmentsChangeSubj.next(ss)
  }

  return (
    <Modal
      title="添加随机话术"
      open={!!visible}
      onCancel={onCancel}
      footer={[
        <Button onClick={onCancel} key="cancle">
          取消
        </Button>,
        <Button type="primary" onClick={confirmAdd} key="confirm">
          确认添加
        </Button>
      ]}
      width={800}
      bodyStyle={{ padding: 20 }}
      className="random-line-modal"
      maskClosable={false}
    >
      {rangeContent}
      <LexicalComposer
        initialConfig={{
          namespace: 'random-line-card',
          onError(error) {
            console.error('editor error', error)
          },
          nodes: [SensitiveWordsNode],
          editorState: () => {
            return $getRoot().append(
              ...(alternatives?.map((t: any) => $createParagraphNode().append($createTextNode(t.text))) || [])
            )
          }
        }}
      >
        <SensitiveWordsPlugin />
        <Providing _={(p) => p(rangeSelection, selectionState)}>
          <Render>
            {function RandomLine() {
              const [editor] = useLexicalComposerContext()
              const start = useRef<any>()
              const wsRef = useRef<any>()
              const timerRef = useRef<any>()

              const closeWs = () => {
                if (wsRef.current) {
                  wsRef.current.close()
                  wsRef.current = null
                }
                if (timerRef) {
                  clearInterval(timerRef.current)
                  timerRef.current = null
                }
              }

              const handAnd = () => {
                editor.update(() => {
                  const root = $getRoot()
                  const paragraphNode = $createParagraphNode()
                  const textNode = new TextNode('')
                  paragraphNode.append(textNode)
                  root.append(paragraphNode)
                  editor.focus()
                  setTimeout(() => {
                    randomLineCur.current.push({
                      text: ''
                    })
                    setRandomLine([...randomLineCur.current])
                  }, 100)
                })
              }

              editor.update(() => {
                if (!childKeys.current) {
                  childKeys.current = $getRoot().getChildrenKeys()
                }
              })

              const sendGpt = () => {
                wsRef.current.send(
                  JSON.stringify({
                    type: 'stream_chat',
                    message: [
                      {
                        content: `将“${lineContent?.[rIndex].text}”改写成5种不同说法, 你最多只能修改其中字数的20%. 整体输出为五个段落`,
                        role: 'user'
                      }
                    ],
                    context: 'new'
                  })
                )
              }

              const aiCreate = () => {
                if (!wsRef.current) {
                  const ws = new WebSocket(`wss:${domains.cms}/ai/chatGPT_stream?token=${user.token}`)
                  wsRef.current = ws

                  timerRef.current = setInterval(() => {
                    wsRef.current?.send(JSON.stringify({ type: 'healthcheck-client' }))
                  }, 20000)

                  ws.onopen = () => {
                    sendGpt()
                  }

                  ws.onmessage = (msg) => {
                    const data = JSON.parse(msg.data)
                    let { content, error, type } = data
                    const isLineEnd = content?.includes('||') || content?.includes('\n')
                    if (type === 'stream_chat_ack') {
                      if (!error && content !== 'io.EOF') {
                        editor.update(() => {
                          let rc = randomLineCur.current
                          if (!start.current || isLineEnd) {
                            randomLineCur.current.push({
                              text: isLineEnd ? '' : content
                            })
                            const root = $getRoot()
                            const paragraphNode = $createParagraphNode()
                            const textNode = new TextNode(isLineEnd ? '' : content)
                            paragraphNode.append(textNode)
                            root.append(paragraphNode)
                            start.current = true
                          } else {
                            randomLineCur.current = rc.map((t: any, index: number) => {
                              if (rc.length - 1 === index) {
                                const text = `${t.text}${content}`.replace(/^\d+\./, '')
                                const roots = $getRoot().getChildren()
                                const cp = roots[roots.length - 1]
                                const textNode = new TextNode(text || '')
                                cp.clear()
                                cp.append(textNode)
                                return {
                                  ...t,
                                  text
                                }
                              }
                              return { ...t }
                            })
                          }
                        })
                      }
                      if (content === 'io.EOF') {
                        start.current = undefined
                        closeWs()
                        message.success('生成完成')
                      }

                      if (error && content !== 'stream error') {
                        closeWs()
                        message.warning('本月智能生成剧本次数已达上限')
                      }
                    }
                  }

                  ws.onerror = () => {
                    start.current = undefined
                  }

                  ws.onclose = () => {
                    start.current = undefined
                  }
                } else {
                  sendGpt()
                }
              }

              return (
                <>
                  <div style={{ marginBottom: '5px' }}>
                    <Button disabled={!!start.current} onClick={handAnd} className="btn-text" style={{ padding: 0 }}>
                      手动添加
                    </Button>
                    <i
                      className="divider"
                      style={{ display: 'inline-block', margin: '0 10px', height: 8, borderRight: '1px solid #434343' }}
                    />
                    <Button disabled={!!start.current} className="btn-text" style={{ padding: 0 }} onClick={aiCreate}>
                      由AI智能生成
                    </Button>
                  </div>
                  <RichTextPlugin
                    contentEditable={
                      <ContentEditable
                        className={`rich-random-line ${classNames}`}
                        style={{ color: 'white', outline: 'none', marginLeft: 18 }}
                        spellCheck={false}
                      />
                    }
                    placeholder={<></>}
                    ErrorBoundary={LexicalErrorBoundary}
                  />
                </>
              )
            }}
          </Render>
        </Providing>
        <OnChangePlugin
          ignoreSelectionChange
          onChange={(state) => {
            state.read(() => {
              const keys = $getRoot().getChildrenKeys()
              const content = state.read($paragraphContent)
              let lc = JSON.parse(JSON.stringify(randomLine)) as any[]

              lc = content.map((c, index) => {
                const i = childKeys.current?.indexOf(keys?.[index])
                if (i > -1) {
                  return {
                    ...lc?.[i],
                    text: c
                  }
                } else {
                  return {
                    text: c || '',
                    voice_key: ''
                  }
                }
              })

              if (lc.length === 1 && !lc[0]?.text && !lc[0]?.voice_key) {
                setRandomLine([])
              } else {
                setRandomLine([...lc])
              }
              childKeys.current = keys
            })
          }}
        />
      </LexicalComposer>
      <RecordingRowModal
        visible={recordingRowModalVisble}
        onCancel={() => {
          setRecordingRowModalVisble(false)
        }}
        onOk={onRecordingRowSuccess}
      />
    </Modal>
  )
}

export const moveListItem =
  <T,>(update: (_: (_: T[]) => T[]) => void) =>
  (from: number, to: number) => {
    if (from === to) return
    update((l) => {
      if (from < 0 || from >= l.length || to < 0 || to >= l.length) return l
      l = [...l]
      l.splice(to, 0, ...l.splice(from, 1))
      return l
    })
  }
