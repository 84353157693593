import { Button } from 'antd'
import axios, { AxiosInstance } from 'axios'
import React, { FC, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { BehaviorSubject } from 'rxjs'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { currentApi, currentAuthApi, currentOrderApi, currentPayApi } from '../api'
import { apiDomains, Badge, Providing, WithRemotePermission } from '../global-vars'
import { useBehaviorSubject } from '../react-rx'
import { flatten, runCatching } from '../types'
import { currentUser, permissionOverride, User, userState } from './states'

const key = '04DE7E39'

export const GlobalStates: FC<PropsWithChildren> = ({ children }) => {
  const userSubj = useMemo(
    () =>
      new BehaviorSubject<User | undefined>(
        flatten(runCatching(() => JSON.parse(localStorage.getItem(key) || ''))).value
      ),
    []
  )
  const [user] = useBehaviorSubject(userSubj)
  useEffect(() => {
    if (!user) {
      localStorage.removeItem(key)
    } else {
      localStorage.setItem(key, JSON.stringify(user))
    }
  }, [user])
  const prevClient = useRef<QueryClient>()
  const client = useMemo(() => {
    prevClient.current?.clear()
    return (prevClient.current = new QueryClient({
      defaultOptions: {
        queries: {
          onError: (e: any) => {
            const status = e?.response?.status
            if (status === 401) {
              userSubj.next(undefined)
            }
          },
          retry: (c, e: any) => {
            const status = e?.response?.status
            return c < 3 && ![401, 403, 404].includes(status)
          }
        }
      }
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.token, prevClient, userSubj])
  const { cms: cmsDomain, order: orderDomain, auth: authDomain, pay: payDomain } = useContext(apiDomains)
  const refreshToken = useRefreshToken(userSubj)
  const autoRefresh = useCallback(
    (api: AxiosInstance, authApi: AxiosInstance, methods: ('get' | 'post' | 'delete')[]) => {
      const { get, post, delete: deleteFn } = api
      if (methods.includes('post'))
        api.post = function (...args) {
          const [path, data, options] = args
          return (async () => {
            try {
              return await post.apply(this, args)
            } catch (e: any) {
              if (e?.response?.status === 401 && (await refreshToken(authApi))) {
                return api.post(path, data, {
                  ...options,
                  headers: {
                    ...options?.headers,
                    Authorization: `Bearer ${userSubj.value?.token}`
                  } as any
                })
              }
              throw e
            }
          })() as any
        }

      if (methods.includes('get'))
        api.get = function (...args) {
          const [path, options] = args
          return (async () => {
            try {
              return await get.apply(this, args)
            } catch (e: any) {
              if (e?.response?.status === 401 && (await refreshToken(authApi))) {
                return api.get(path, {
                  ...options,
                  headers: {
                    ...options?.headers,
                    Authorization: `Bearer ${userSubj.value?.token}`
                  } as any
                })
              }
              throw e
            }
          })() as any
        }
      if (methods.includes('delete'))
        api.delete = function (...args) {
          const [path, options] = args
          return (async () => {
            try {
              return await deleteFn.apply(this, args)
            } catch (e: any) {
              if (e?.response?.status === 401 && (await refreshToken(authApi))) {
                return api.delete(path, {
                  ...options,
                  headers: {
                    ...options?.headers,
                    Authorization: `Bearer ${userSubj.value?.token}`
                  } as any
                })
              }
              throw e
            }
          })() as any
        }
      return api
    },
    [userSubj, refreshToken]
  )

  const authApi = useMemo(() => {
    const authApi = axios.create({
      baseURL: authDomain,
      headers: user?.token
        ? {
            Authorization: `Bearer ${user.token}`
          }
        : undefined
    })
    const { get, post } = authApi
    authApi.get = async function (...args) {
      const resp = (await get.apply(this, args)) as any
      if (typeof resp?.data?.code === 'number' && resp.data.code !== 200) {
        throw resp.data
      }
      return resp
    }
    authApi.post = async function (...args) {
      const resp = (await post.apply(this, args)) as any
      if (typeof resp?.data?.code === 'number' && resp.data.code !== 200) {
        throw resp.data
      }
      return resp
    }
    return autoRefresh(authApi, authApi, ['get'])
  }, [autoRefresh, authDomain, user?.token])
  const orderApi = useMemo(() => {
    if (!user?.token) return
    return autoRefresh(
      axios.create({
        baseURL: orderDomain,
        headers: {
          Authorization: `Bearer ${user.token}`
        }
      }),
      authApi,
      ['get', 'post', 'delete']
    )
  }, [authApi, autoRefresh, orderDomain, user?.token])
  const payApi = useMemo(() => {
    if (!user?.token) return
    return autoRefresh(
      axios.create({
        baseURL: payDomain,
        headers: {
          Authorization: `Bearer ${user.token}`
        }
      }),
      authApi,
      ['get', 'post', 'delete']
    )
  }, [authApi, autoRefresh, payDomain, user?.token])
  const api = useMemo(() => {
    if (!user?.token) return
    const api = autoRefresh(
      axios.create({
        baseURL: cmsDomain,
        headers: {
          Authorization: `Bearer ${user.token}`
        }
      }),
      authApi,
      ['get', 'post', 'delete']
    )
    const { post, delete: deleteFn } = api
    api.post = function (...args) {
      const [path, data, options] = args
      const refreshMode = (options as any)?.refreshMode
      if (refreshMode === 'disabled') return post.apply(this, args)
      return (async () => {
        const result = await post.apply(this, args)
        if (refreshMode === 'local' && !path.match(/\/\d+$/)) {
          client.setQueriesData([path], data)
        } else {
          const invalidate = [client.invalidateQueries({ queryKey: [path] }).catch(() => {})]
          if (path.match(/\/\d+$/)) {
            invalidate.push(client.invalidateQueries({ queryKey: [path.replace(/\/\d+$/, '')] }).catch(() => {}))
          }
          await Promise.all(invalidate)
        }
        return result
      })() as any
    }
    api.delete = function (...args) {
      const [path, options] = args
      const refreshMode = (options as any)?.refreshMode
      if (refreshMode === 'disabled') return post.apply(this, args)
      return (async () => {
        const result = await deleteFn.apply(this, args)
        await client.invalidateQueries({ queryKey: [path.replace(/\/\d+$/, '')] }).catch(() => {})
        return result
      })() as any
    }
    return api
  }, [authApi, autoRefresh, user?.token, cmsDomain, client])
  const badge = (
    <WithRemotePermission permission="admin">
      <Badge>
        {useMemo(
          () => (
            <AdminBadge key="admin-badge" />
          ),
          []
        )}
      </Badge>
    </WithRemotePermission>
  )
  if (client) {
    children = (
      <QueryClientProvider client={client}>
        {api && badge}
        {children}
      </QueryClientProvider>
    )
  }
  return (
    <Providing
      _={(p) => {
        p(currentAuthApi, authApi)
        if (orderApi) p(currentOrderApi, orderApi)
        if (api) p(currentApi, api)
        if (user) p(currentUser, user)
        if (payApi) p(currentPayApi, payApi)
        p(userState, userSubj)
      }}
    >
      {children}
    </Providing>
  )
}

const AdminBadge: FC = () => {
  const [overrides, setOverrides] = useBehaviorSubject(useContext(permissionOverride))
  return (
    <Button
      key="admin-override"
      style={{ marginLeft: 4 }}
      type="primary"
      onClick={() => {
        if (overrides.admin === false) {
          delete overrides['admin']
          setOverrides({ ...overrides })
        } else {
          setOverrides({ ...overrides, admin: false })
        }
      }}
    >
      admin{overrides.admin === false ? ' off' : ''}
    </Button>
  )
}

function useRefreshToken(subj: BehaviorSubject<User | undefined>): (authApi: AxiosInstance) => Promise<boolean> {
  return useMemo(() => {
    let task: Promise<boolean> | undefined = undefined
    return async (authApi: AxiosInstance) => {
      if (task) return await task
      const current = subj.value as any
      if (current?.refresh_token || current?.refreshToken) {
        return await (task = (async () => {
          try {
            const newUser = (
              await authApi.post<User>('/api/v1/meta/refresh_token', {
                refresh_token: current?.refresh_token || current?.refreshToken
              })
            ).data
            subj.next(newUser)
            return !!newUser?.token
          } finally {
            task = undefined
          }
        })())
      }
      return false
    }
  }, [subj])
}
