import '../index.sass'
import { Button, Col, Modal, notification, Row, Space, Spin } from 'antd'
import React, { useContext, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAPI } from '../api'
import { resourceUrl, WithPermission } from '../global-vars'
import { ReactComponent as Check } from '../res/check_line.svg'
import { ReactComponent as Spinner } from '../res/spin.svg'
import { ReactComponent as Trash } from '../res/trash.svg'
import { useRoomTemplates } from '../script-and-layer/states'
import { Render } from '../types'
import { Room } from './states'

const allTemplates = '全部模版'

function RoomTemplateList() {
  const templates = useRoomTemplates()
  const availableCategories = useMemo(() => {
    const cs: string[] = []
    templates
      ?.map((_) => _?.category)
      ?.filter(Boolean)
      ?.forEach((c) => {
        if (!cs.includes(c)) cs.push(c)
      })
    return cs
  }, [templates])
  const [category, setCategory] = useState<string>(allTemplates)
  const visibleTemplates = useMemo(
    () =>
      category === allTemplates ? templates : templates.filter((t) => !t.category?.trim() || t.category === category),
    [category, templates]
  )
  const url = useContext(resourceUrl)
  const [creating, setCreating] = useState(false)
  const api = useAPI()
  const navigate = useNavigate()
  const [deletingTemplateId, setDeletingTemplateId] = useState<string>()
  return (
    <Spin style={{ height: '100%', width: '100%' }} spinning={creating} indicator={<Spinner className="spin" />}>
      <Render>
        {function Delete() {
          const [deleting, setDeleting] = useState(false)
          const api = useAPI()
          return (
            <Modal
              title="提示"
              open={!!deletingTemplateId}
              closable={!deleting}
              maskClosable={!deleting}
              onCancel={() => setDeletingTemplateId(undefined)}
              bodyStyle={{ padding: 20 }}
              footer={[
                <Button key="cancel" onClick={() => setDeletingTemplateId(undefined)}>
                  取消
                </Button>,
                <Button
                  key="confirm"
                  danger
                  loading={deleting}
                  onClick={async () => {
                    setDeleting(true)
                    try {
                      await api.delete(`/templates/${deletingTemplateId}`)
                      setDeletingTemplateId(undefined)
                    } catch (e: any) {
                      notification.error(e)
                    } finally {
                      setDeleting(false)
                    }
                  }}
                >
                  确认删除
                </Button>
              ]}
            >
              <p style={{ textAlign: 'center' }}>
                <Trash />
              </p>
              <h3 style={{ textAlign: 'center' }}>删除模版</h3>
              <p style={{ textAlign: 'center' }}>模版删除后不可恢复，请确认是否删除该模版</p>
            </Modal>
          )
        }}
      </Render>

      <div
        style={{
          padding: '84px 44px 0 44px',
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%'
        }}
      >
        <div className="section-title">
          创建直播间{' '}
          <span style={{ fontSize: 13, fontWeight: 400, color: 'white', marginLeft: 26 }}>快来创建你的直播间吧！</span>
        </div>
        <Space style={{ marginTop: 27, marginBottom: 32 }} size={10}>
          {[allTemplates, ...availableCategories].map((v) => (
            <Button
              className={'border-button' + (v === category ? ' selected' : '')}
              key={v}
              onClick={() => setCategory(v)}
            >
              {v}
              {v === category ? (
                <Check stroke="#D4FF00" style={{ marginTop: 5, marginLeft: 6, marginRight: 0 }} />
              ) : undefined}
            </Button>
          ))}
        </Space>
        <Row gutter={[24, 24]} style={{ height: 0, flex: 1, overflowX: 'hidden', overflowY: 'auto' }}>
          {visibleTemplates.map((t) => (
            <Col key={t.id}>
              <div
                style={{
                  backgroundColor: '#28282C',
                  padding: 12,
                  borderRadius: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                <div
                  style={{
                    fontSize: 16,
                    fontWeight: 600,
                    color: 'white',
                    marginBottom: 16
                  }}
                >
                  {t.name || t.room?.name}
                </div>
                <img
                  src={url(t.preview_key)}
                  alt={t.name}
                  width={180}
                  height={320}
                  style={{
                    borderRadius: 1,
                    marginBottom: 16
                  }}
                />
                <div style={{ width: '100%', display: 'flex' }}>
                  <Button
                    type="primary"
                    style={{
                      width: 0,
                      flex: 1
                    }}
                    onClick={async () => {
                      setCreating(true)
                      try {
                        const rid = (await api.post<Room>(`/templates/${t.id}/room`)).data?.id
                        navigate(`/rooms/${rid}`)
                      } catch (e: any) {
                        notification.error(e)
                      } finally {
                        setCreating(false)
                      }
                    }}
                  >
                    {t.button_text || '使用'}
                  </Button>
                  <WithPermission permission="admin">
                    <Button
                      type="primary"
                      style={{ width: 0, flex: 1, marginLeft: 4 }}
                      onClick={() => navigate(`/templates/${t.id}`)}
                    >
                      编辑
                    </Button>
                    <Button
                      danger
                      style={{ width: 0, flex: 1, marginLeft: 4 }}
                      onClick={() => setDeletingTemplateId(t.id)}
                    >
                      删除
                    </Button>
                  </WithPermission>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </Spin>
  )
}

export default RoomTemplateList
