import { FC, Suspense, useMemo } from 'react'
import { useUserPackage } from '../plan/states'
import { Render } from '../types'
import { useAvailableLiveTime, useUserInfo, useUserTimeBlacklist } from './states'

const UserCenter: FC = () => {
  const userInfo = useUserInfo()
  const { phone } = useUserTimeBlacklist()

  return (
    <div style={{ padding: 24, marginTop: 60, marginLeft: 20 }}>
      <div className="section-title">基础信息</div>
      <div className="horizontal-separator" style={{ marginBottom: 20 }} />
      <div className="sub-section-title">账号</div>
      <div className="weak-info" style={{ marginBottom: 40 }}>
        {userInfo.phone}
      </div>
      <div className="sub-section-title">手机号</div>
      <div className="weak-info" style={{ marginBottom: 80 }}>
        {userInfo.phone}
      </div>
      <div className="section-title">我的订阅</div>
      <div className="horizontal-separator" style={{ marginBottom: 20 }} />
      <Suspense fallback={<div className="weak-info">加载中</div>}>
        <Render>
          {function Subscription() {
            const pkg = useUserPackage(10000)
            const expired = pkg.expire_at_date && pkg.expire_at_date < new Date()
            const pkgProduct = useMemo(() => (expired ? undefined : pkg.product), [expired, pkg])
            const availableTime = useAvailableLiveTime()
            const availableTimeHours = useMemo(
              () => Math.round((availableTime?.available_live_time || 0) / 3600),
              [availableTime]
            )

            const availableOilHours = useMemo(
              () => Math.round((availableTime?.oil_bag_surplus || 0) / 3600),
              [availableTime]
            )
            const userPackage = useUserPackage()
            const product = userPackage?.product
            const monthlyDurationHours = product?.permission?.monthly_duration || 0
            if (pkgProduct) {
              return (
                <>
                  <div className="sub-section-title">套餐</div>
                  <div className="weak-info" style={{ marginBottom: 40 }}>
                    {pkgProduct.name}
                  </div>
                  <div className="sub-section-title">有效期</div>
                  <div className="weak-info" style={{ marginBottom: 40 }}>
                    {pkg.expire_at_date ? dateText(pkg.expire_at_date) : '-'}
                  </div>

                  {!phone?.includes(userInfo.phone) && (
                    <>
                      <div className="sub-section-title">剩余可播时长</div>
                      <div className="weak-info" style={{ marginBottom: 80 }}>
                        本月套餐剩余时长：{availableTimeHours}/{monthlyDurationHours} 小时
                        <span style={{ marginLeft: 20 }}>加时包剩余时长：{availableOilHours} 小时</span>
                      </div>
                    </>
                  )}
                </>
              )
            }
            return (
              <>
                <div className="sub-section-title">套餐</div>
                <div className="weak-info" style={{ marginBottom: 20 }}>
                  未订阅套餐
                </div>
              </>
            )
          }}
        </Render>
      </Suspense>
    </div>
  )
}

export function dateText(date: Date): string {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
}

export default UserCenter
