import { Button, Checkbox, Input, List, message, Modal, Select, Slider, Tooltip, Upload, UploadFile } from 'antd'
import { RcFile, UploadProps } from 'antd/es/upload'
import { createContext, CSSProperties, FC, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { BehaviorSubject } from 'rxjs'
import * as uuid from 'uuid'
// @ts-ignore
import wavConverter from 'wav-converter'
import { CheckCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import { useAPI } from '../api'
import { usePermission } from '../auth/states'
import { resourceUrl, targetViewPort, useUploadResource, WithPermission } from '../global-vars'
import { PlansModal } from '../plan/plans-modal'
import { Product, useProducts, useUserPackage } from '../plan/states'
import { useBehaviorSubject } from '../react-rx'
import { ReactComponent as Check } from '../res/check.svg'
import { ReactComponent as CogSquare } from '../res/cog-square.svg'
import { ReactComponent as PlayCircle } from '../res/play-circle.svg'
import { ReactComponent as Play } from '../res/play.svg'
import qrCode from '../res/qr.png'
import { ReactComponent as SettingHorizontal } from '../res/setting-horizontal.svg'
import { ReactComponent as Spinner } from '../res/spin.svg'
import { ReactComponent as Trash } from '../res/trash.svg'
import { ReactComponent as Vip1 } from '../res/vip1.svg'
import { ReactComponent as Vip2 } from '../res/vip2.svg'
import { ReactComponent as Vip3 } from '../res/vip3.svg'
import {
  addLayer,
  background,
  bgImg,
  editingLayer,
  layers,
  save,
  voiceName,
  w2lVideo
} from '../script-and-layer/states'
import { Render, tuple } from '../types'
import { useUserInfo, useUserRBAC } from '../user-center/states'
import {
  Resource,
  scriptTab,
  useAvailableVoices,
  useAvailableW2LVideoIds,
  useResources,
  useVoices,
  useW2lVideos,
  Voice,
  W2lVideo,
  W2lVideosGroup
} from './states'

const { Dragger } = Upload

export const W2lVideoList: FC = () => {
  const userInfo = useUserInfo()
  const permission = usePermission()
  const { add_anchor } = useUserRBAC()
  const [voices, setVoices] = useState<Voice[]>([])
  const maleVoices = useMemo(() => voices.filter((v) => v.gender?.toLowerCase() === 'male'), [voices])
  const femaleVoices = useMemo(() => voices.filter((v) => v.gender?.toLowerCase() === 'female'), [voices])
  const [videos, setVideos] = useState<{
    groups: W2lVideosGroup[]
    videos: W2lVideo[]
  }>({
    groups: [],
    videos: []
  })

  const [video, setVideo] = useBehaviorSubject(useContext(w2lVideo))

  const { tw, th } = useContext(targetViewPort)
  const saveSubj = useContext(save)
  const [createOpen, setCreateOpen] = useState(false)
  const [userCreateOpen, setUserCreateOpen] = useState(false) //用户新建主播
  const [editingVideoId, setEditingVideoId] = useState('')
  const [deletingVideoId, setDeletingVideoId] = useState('')
  const [promptLevel, setPromptLevel] = useState(0)
  const [promptProduct, setPromptProduct] = useState<Product>()
  const [groupId, setGroupId] = useState('')
  const products = useProducts()?.products
  const product = useUserPackage()
  const api = useAPI()

  const currentGroup = useMemo(() => {
    return videos.groups?.find((v) => v.List.some((l) => l.video_id === video?.video_id))
  }, [video?.video_id, videos.groups])

  useEffect(() => {
    getVoiceList()
    getGroupList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permission.admin])

  const getVoiceList = async () => {
    const res: any = (await api.get('/voices'))?.data
    setVoices(
      res?.voices?.filter(
        (v: any) => userInfo.phone && (permission.admin || v.free || v.phones?.indexOf(userInfo.phone) > -1)
      )
    )
  }

  const getGroupList = async () => {
    const videosGroup: W2lVideosGroup[] = (await api.get('/synthesizable_videos'))?.data?.videos
    const available_ids = products?.find((p) => p.id === product.product_id)?.permission?.available_video_ids
    const levelAscProd = products?.sort((l, r) => (l.level || 0) - (r.level || 0))
    const list: W2lVideo[] = []
    const groups = (videosGroup || [])
      .map((group) => {
        return {
          ...group,
          id: uuid.v4(),
          defaultAvatar: `wl/${group.List?.[0].video_id}/avatar.png`,
          List: (group.List || [])
            .filter((v) => userInfo.phone && (permission.admin || v.free || v.phones?.indexOf(userInfo.phone) > -1))
            .map((v) => ({
              ...v,
              preview: v.preview || `wl/${v.video_id}/preview.${v.no_alpha ? 'jpg' : 'png'}`,
              avatar: v.avatar || `wl/${v.video_id}/avatar.png`,
              available: v.free || available_ids?.includes(v.id) || false,
              min_level: v.free
                ? 0
                : levelAscProd?.find((p) => p.permission?.available_video_ids?.includes(v.id))?.level || 0
            }))
            .map((v, i) => tuple(i, v))
            .sort((l, r) => {
              if (!!l[1].min_level !== !!r[1].min_level) {
                return !!l[1].min_level ? -1 : 1
              }
              if (!l[1].min_level) {
                return l[0] - r[0]
              }
              return l[1].min_level - r[1].min_level
            })

            .map(([, v]) => {
              list.push(v)
              return v
            })
        }
      })
      .filter((v) => !!v.List?.length)
    setVideos({
      groups,
      videos: list
    })
  }

  return (
    <div style={{ height: '100%', overflowY: 'auto', overflowX: 'hidden' }}>
      <div style={{ display: 'flex' }}>
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          <div className="section-title">形象选择</div>
          <PlansModal
            product={promptProduct}
            onConfirm={() => setPromptProduct(undefined)}
            onCancel={() => setPromptProduct(undefined)}
          />
          <Modal
            title="提示"
            open={!!promptLevel}
            bodyStyle={{ padding: 20 }}
            onCancel={() => setPromptLevel(0)}
            footer={null}
          >
            <p style={{ textAlign: 'center' }}>
              {promptLevel === 1 && <Vip1 />}
              {promptLevel === 2 && <Vip2 />}
              {promptLevel === 3 && <Vip3 />}
            </p>
            <h3 style={{ textAlign: 'center' }}>
              {promptLevel === 1 && '基础版'}
              {promptLevel === 2 && '旗舰版'}
              {promptLevel === 3 && '尊享版'}
              主播形象
            </h3>
            <p style={{ textAlign: 'center' }}>
              您在主播配置中使用了
              {promptLevel === 1 && '基础版'}
              {promptLevel === 2 && '旗舰版'}
              {promptLevel === 3 && '尊享版'}
              主播形象，请联系商务升级套餐
              <img src={qrCode} alt="qr" style={{ width: 250, margin: '26px 0 16px 0' }} />
            </p>
          </Modal>
          <List
            dataSource={videos.groups}
            grid={{ gutter: 4 }}
            footer={
              <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                {permission.admin && (
                  <Button type="primary" onClick={() => setCreateOpen(true)}>
                    新增
                  </Button>
                )}
                {(add_anchor?.includes(userInfo.phone) || permission.admin) && (
                  <Button type="primary" style={{ marginLeft: 'auto' }} onClick={() => setUserCreateOpen(true)}>
                    新增主播
                  </Button>
                )}
              </div>
            }
            rowKey="id"
            renderItem={(v) => (
              <List.Item
                style={{
                  borderRadius: 2,
                  overflow: 'hidden',
                  position: 'relative',
                  width: '100%',
                  padding: 0,
                  marginBottom: 4,
                  paddingBottom: '100%',
                  boxSizing: 'border-box',
                  border: `solid 2px ${v.List.some((l) => l.video_id === video?.video_id) ? '#00FFF0' : 'transparent'}`
                }}
              >
                <Render>
                  {function Video() {
                    const [previewSize, setPreviewSize] = useState<Record<'w' | 'h', number> | undefined>()
                    const url = useContext(resourceUrl)
                    return (
                      <>
                        <img
                          src={url(v.List?.[0]?.preview)}
                          alt="preview"
                          style={{ visibility: 'hidden', height: 0, width: 0 }}
                          onLoad={(e) =>
                            setPreviewSize({ w: e.currentTarget.naturalWidth, h: e.currentTarget.naturalHeight })
                          }
                        />
                        <Tooltip style={{ padding: 0 }} title={v.name} placement="top" showArrow={false}>
                          <img
                            src={url(v.List?.[0]?.in_processing ? 'making.png' : v.avatar || v.defaultAvatar)}
                            alt={v.name}
                            style={{
                              position: 'absolute',
                              top: 2,
                              left: 0,
                              width: '100%',
                              height: '100%',
                              objectFit: 'contain'
                            }}
                            onClick={
                              !!previewSize
                                ? () => {
                                    const current = v.List[0]
                                    if (!current.available && !permission.admin && current.min_level) {
                                      setPromptLevel(current.min_level)
                                      return
                                    }
                                    if (video?.video_id === current.video_id) {
                                      setVideo({} as any)
                                    } else {
                                      const scale = Math.min(th / previewSize.h, tw / previewSize.w)
                                      setVideo({
                                        w: previewSize.w * scale,
                                        h: previewSize.h * scale,
                                        video_id: current.video_id,
                                        image_key: current.preview,
                                        no_alpha: current.no_alpha
                                      })
                                    }
                                    saveSubj.next(true)
                                  }
                                : undefined
                            }
                          />
                        </Tooltip>
                        {v.group_id && v.group_id !== '0' && (
                          <WithPermission permission="admin">
                            <CogSquare
                              onClick={() => setGroupId(v.group_id)}
                              className="hidden-button"
                              style={{
                                position: 'absolute',
                                right: 6,
                                top: 6,
                                display: 'inline-flex',
                                alignItems: 'center',
                                justifyItems: 'center'
                              }}
                            />
                          </WithPermission>
                        )}

                        {v.List.some((l) => l.video_id === video?.video_id) && <SelectionBadge />}
                      </>
                    )
                  }}
                </Render>
              </List.Item>
            )}
            className="video-list"
            style={{ border: '4px solid rgb(47, 48, 44)', padding: '10px 10px 0 10px', flex: 1 }}
          />
        </div>
        <div style={{ width: 330, minWidth: 330, display: 'flex', flexDirection: 'column' }}>
          <div className="section-title" style={{ marginLeft: 12 }}>
            动作
          </div>
          <List
            dataSource={currentGroup?.List}
            grid={{ gutter: 4 }}
            rowKey="video_id"
            style={{
              border: '4px solid rgb(47, 48, 44)',
              margin: '0 12px',
              flex: 1,
              maxHeight: 616,
              overflowX: 'hidden',
              overflowY: 'scroll'
            }}
            renderItem={(v: any) => (
              <List.Item
                style={{
                  margin: '4px',
                  padding: 0
                }}
              >
                <Render>
                  {function Video() {
                    const [previewSize, setPreviewSize] = useState<Record<'w' | 'h', number> | undefined>()
                    const url = useContext(resourceUrl)
                    return (
                      <div
                        style={{
                          borderRadius: 2,
                          background: '#28282c',
                          overflow: 'hidden',
                          position: 'relative',
                          width: 135,
                          height: 240,
                          padding: 0,
                          marginBottom: 4,
                          boxSizing: 'border-box',
                          border: `solid 2px ${v.video_id === video?.video_id ? '#00FFF0' : 'transparent'}`
                        }}
                      >
                        <Tooltip style={{ padding: 0 }} title={v.name} placement="top" showArrow={false}>
                          {!v.in_processing && (
                            <img
                              src={url(v.preview)}
                              alt={v.name}
                              onLoad={(e) =>
                                setPreviewSize({ w: e.currentTarget.naturalWidth, h: e.currentTarget.naturalHeight })
                              }
                              style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: '100%',
                                height: '100%',
                                cursor: 'pointer'
                              }}
                              onClick={
                                !!previewSize
                                  ? () => {
                                      if (!v.available && !permission.admin && v.min_level) {
                                        setPromptLevel(v.min_level)
                                        return
                                      }
                                      if (video?.video_id === v.video_id) {
                                        setVideo({} as any)
                                      } else {
                                        const scale = Math.min(th / previewSize.h, tw / previewSize.w)
                                        setVideo({
                                          w: previewSize.w * scale,
                                          h: previewSize.h * scale,
                                          video_id: v.video_id,
                                          image_key: v.preview,
                                          no_alpha: v.no_alpha
                                        })
                                      }
                                      saveSubj.next(true)
                                    }
                                  : undefined
                              }
                            />
                          )}
                          {v.in_processing && (
                            <img
                              src={url('making.png')}
                              alt={v.name}
                              style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: '100%',
                                height: '100%',
                                cursor: 'pointer'
                              }}
                            />
                          )}
                          {v.in_processing && (
                            <div
                              style={{
                                backgroundColor: '#9352af',
                                position: 'relative',
                                borderRadius: '5px',
                                margin: '50%',
                                color: 'white',
                                padding: '10px 0',
                                width: '80px',
                                left: '-40px',
                                top: '20px',
                                textAlign: 'center'
                              }}
                            >
                              制作中
                            </div>
                          )}
                        </Tooltip>
                        <WithPermission permission="admin">
                          <CogSquare
                            onClick={() => setEditingVideoId(v.id)}
                            className="hidden-button"
                            style={{
                              position: 'absolute',
                              right: 6,
                              top: 6,
                              display: 'inline-flex',
                              alignItems: 'center',
                              justifyItems: 'center'
                            }}
                          />
                          <Trash
                            onClick={() => setDeletingVideoId(v.id)}
                            className="hidden-button"
                            style={{
                              position: 'absolute',
                              right: 6,
                              top: 30,
                              display: 'inline-flex',
                              alignItems: 'center',
                              justifyItems: 'center'
                            }}
                          />
                        </WithPermission>

                        {v.video_id === video?.video_id && <SelectionBadge />}
                      </div>
                    )
                  }}
                </Render>
                <p
                  style={{
                    color: '#fff',
                    width: '135px',
                    textAlign: 'center',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                  }}
                >
                  {v.name}
                </p>
                <VideoLevelBadge
                  level={v.min_level}
                  style={{ position: 'absolute', bottom: 38, right: 5, width: 30, height: 30 }}
                />
              </List.Item>
            )}
          />
        </div>
        <WithPermission permission="admin">
          <Render>
            {function New() {
              const videos = useW2lVideos()
              const products = useProducts()
              const availableVideos = useAvailableW2LVideoIds()
              const visibleProducts = products?.products?.filter((p) => p?.permission?.visible_in_list)
              // eslint-disable-next-line react-hooks/exhaustive-deps
              const [name, setName] = useState('')
              const [pipelineName, setPipelineName] = useState('')
              const [videoId, setVideoId] = useState(availableVideos[0] || '')
              const [free, setFree] = useState(false)
              const [saving, setSaving] = useState(false)
              const [phones, setPhones] = useState('')
              const [productIds, setProductIds] = useState([])
              const [groupName, setGroupName] = useState<any>(undefined)
              const api = useAPI()

              const defaultGroups: any[] = useMemo(() => {
                return videos.groups.reduce((pre, next) => {
                  if (next.group_id && next.group_id !== '0') {
                    pre.push({
                      label: next.name,
                      value: next.name
                    })
                  }
                  return pre
                }, [] as any[])
              }, [videos.groups])

              return (
                <Modal
                  title="新建"
                  open={createOpen}
                  onCancel={() => setCreateOpen(false)}
                  closable={!saving}
                  maskClosable={!saving}
                  footer={[
                    <Button key="cancel" disabled={saving} onClick={() => setCreateOpen(false)}>
                      取消
                    </Button>,
                    <Button
                      key="confirm"
                      loading={saving}
                      disabled={!name || !videoId || !groupName}
                      type="primary"
                      onClick={async () => {
                        setSaving(true)
                        try {
                          await api.post(`/synthesizable_videos`, {
                            name,
                            video_id: videoId,
                            pipeline_name: pipelineName,
                            free,
                            phones,
                            product_ids: productIds,
                            group_name: groupName
                          })
                          setCreateOpen(false)
                          getGroupList()
                        } catch (e: any) {
                          message.error(e?.message)
                        } finally {
                          setSaving(false)
                        }
                      }}
                    >
                      保存
                    </Button>
                  ]}
                  bodyStyle={{ padding: 20 }}
                >
                  <div style={{ position: 'relative', marginBottom: 20 }}>
                    <Select
                      mode="tags"
                      className="fantasy-select input-with-hint"
                      popupClassName="fantasy-popup"
                      style={{ backgroundColor: '#171719', width: '100%' }}
                      value={groupName}
                      onChange={(e) => {
                        setGroupName(e.at(-1))
                      }}
                      showArrow={false}
                      options={defaultGroups}
                    />
                    <div className="input-hint">
                      <span style={{ color: 'red' }}>*</span>形象组
                    </div>
                  </div>
                  <div style={{ position: 'relative', marginBottom: 20 }}>
                    <Input
                      disabled={saving}
                      className="input-with-hint"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <div className="input-hint">
                      <span style={{ color: 'red' }}>*</span>名称
                    </div>
                  </div>
                  <div style={{ position: 'relative', marginBottom: 20 }}>
                    <Select
                      className="fantasy-select input-with-hint"
                      popupClassName="fantasy-popup"
                      style={{ backgroundColor: '#171719', width: '100%' }}
                      value={videoId}
                      onChange={setVideoId}
                      options={(availableVideos || []).map((v) => ({ label: v, value: v }))}
                      showSearch
                    />
                    <div className="input-hint">形象</div>
                  </div>
                  <div style={{ position: 'relative', marginBottom: 20 }}>
                    <Input
                      disabled={saving}
                      className="input-with-hint"
                      value={pipelineName}
                      onChange={(e) => setPipelineName(e.target.value)}
                    />
                    <div className="input-hint">模型名称</div>
                  </div>
                  <div style={{ position: 'relative', marginBottom: 20 }}>
                    <Select
                      mode="multiple"
                      className="fantasy-select input-with-hint"
                      popupClassName="fantasy-popup"
                      style={{ backgroundColor: '#171719', width: '100%' }}
                      value={productIds}
                      onChange={(e) => {
                        setProductIds(e)
                      }}
                      options={(visibleProducts || []).map((v) => ({ label: v.name, value: v.id }))}
                      showSearch
                    />
                    <div className="input-hint">套餐</div>
                  </div>
                  <Checkbox style={{ color: 'white' }} checked={free} onChange={(e) => setFree(e.target.checked)}>
                    免费
                  </Checkbox>
                  <div style={{ position: 'relative' }}>
                    <Input.TextArea
                      disabled={saving}
                      style={{ resize: 'none', height: 100 }}
                      className="input-with-hint"
                      value={phones}
                      placeholder="填写手机号，以逗号分隔"
                      onChange={(e) => setPhones(e.target.value)}
                    />
                    <div className="input-hint">授权以下用户使用</div>
                  </div>
                </Modal>
              )
            }}
          </Render>
          <Render>
            {function Edit() {
              const videos = useW2lVideos()
              const products = useProducts()
              const availableVideos = useAvailableW2LVideoIds()
              const visibleProducts = products?.products?.filter((p) => p?.permission?.visible_in_list)
              // eslint-disable-next-line react-hooks/exhaustive-deps
              const video = useMemo(() => videos?.videos.find((v) => v.id === editingVideoId), [videos, editingVideoId])
              const [name, setName] = useBehaviorSubject(useMemo(() => new BehaviorSubject(video?.name || ''), [video]))
              const [pipelineName, setPipelineName] = useBehaviorSubject(
                useMemo(() => new BehaviorSubject(video?.extra?.Data?.pipeline_name || ''), [video])
              )
              const [videoId, setVideoId] = useBehaviorSubject(
                useMemo(() => new BehaviorSubject(video?.video_id || ''), [video])
              )
              const [productIds, setProductIds] = useBehaviorSubject(
                useMemo(() => new BehaviorSubject(video?.extra?.Data?.product_ids || []), [video])
              )
              const [free, setFree] = useBehaviorSubject(useMemo(() => new BehaviorSubject(!!video?.free), [video]))
              const [phones, setPhones] = useBehaviorSubject(
                useMemo(() => new BehaviorSubject(video?.phones || ''), [video])
              )
              const [saving, setSaving] = useState(false)
              const api = useAPI()
              const defaultGroups: any[] = useMemo(() => {
                return videos.groups.reduce((pre, next) => {
                  if (next.group_id && next.group_id !== '0') {
                    pre.push({
                      label: next.name,
                      value: next.name
                    })
                  }
                  return pre
                }, [] as any[])
              }, [videos.groups])
              const [groupName, setGroupName] = useBehaviorSubject(
                // eslint-disable-next-line react-hooks/exhaustive-deps
                useMemo(() => new BehaviorSubject(currentGroup?.name || undefined), [currentGroup])
              )

              return (
                <Modal
                  title="编辑"
                  open={!!video}
                  onCancel={() => setEditingVideoId('')}
                  closable={!saving}
                  maskClosable={!saving}
                  footer={[
                    <Button key="cancel" disabled={saving} onClick={() => setEditingVideoId('')}>
                      取消
                    </Button>,
                    <Button
                      key="confirm"
                      loading={saving}
                      disabled={!name || !groupName}
                      type="primary"
                      onClick={
                        video &&
                        (async () => {
                          setSaving(true)
                          try {
                            await api.post(`/synthesizable_videos/${video.id}`, {
                              name,
                              video_id: videoId,
                              pipeline_name: pipelineName,
                              free,
                              phones,
                              product_ids: productIds,
                              group_name: groupName
                            })
                            setEditingVideoId('')
                            getGroupList()
                          } catch (e: any) {
                            message.error(e?.message)
                          } finally {
                            setSaving(false)
                          }
                        })
                      }
                    >
                      保存
                    </Button>
                  ]}
                  bodyStyle={{ padding: 20 }}
                >
                  <div style={{ position: 'relative', marginBottom: 20 }}>
                    <Select
                      mode="tags"
                      className="fantasy-select input-with-hint"
                      popupClassName="fantasy-popup"
                      style={{ backgroundColor: '#171719', width: '100%' }}
                      value={groupName}
                      onChange={(e) => {
                        setGroupName(e.at(-1))
                      }}
                      showArrow={false}
                      options={defaultGroups}
                    />
                    <div className="input-hint">
                      <span style={{ color: 'red' }}>*</span>形象组
                    </div>
                  </div>
                  <div style={{ position: 'relative', marginBottom: 20 }}>
                    <Input
                      disabled={saving}
                      className="input-with-hint"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <div className="input-hint">
                      <span style={{ color: 'red' }}>*</span>名称
                    </div>
                  </div>
                  <div style={{ position: 'relative', marginBottom: 20 }}>
                    <Select
                      className="fantasy-select input-with-hint"
                      popupClassName="fantasy-popup"
                      style={{ backgroundColor: '#171719', width: '100%' }}
                      value={videoId}
                      onChange={setVideoId}
                      options={(availableVideos || []).map((v) => ({ label: v, value: v }))}
                      showSearch
                    />
                    <div className="input-hint">形象</div>
                  </div>
                  <div style={{ position: 'relative', marginBottom: 20 }}>
                    <Input
                      disabled={saving}
                      className="input-with-hint"
                      value={pipelineName}
                      onChange={(e) => setPipelineName(e.target.value)}
                    />
                    <div className="input-hint">模型名称</div>
                  </div>
                  <div style={{ position: 'relative', marginBottom: 20 }}>
                    <Select
                      mode="multiple"
                      className="fantasy-select input-with-hint"
                      popupClassName="fantasy-popup"
                      style={{ backgroundColor: '#171719', width: '100%' }}
                      value={productIds}
                      onChange={(e) => {
                        setProductIds(e)
                      }}
                      options={(visibleProducts || []).map((v) => ({ label: v.name, value: v.id }))}
                      showSearch
                    />
                    <div className="input-hint">套餐</div>
                  </div>
                  <Checkbox style={{ color: 'white' }} checked={free} onChange={(e) => setFree(e.target.checked)}>
                    免费
                  </Checkbox>
                  <div style={{ position: 'relative' }}>
                    <Input.TextArea
                      disabled={saving}
                      style={{ resize: 'none', height: 100 }}
                      className="input-with-hint"
                      value={phones}
                      placeholder="填写手机号，以逗号分隔"
                      onChange={(e) => setPhones(e.target.value)}
                    />
                    <div className="input-hint">授权以下用户使用</div>
                  </div>
                </Modal>
              )
            }}
          </Render>
          <Render>
            {function Delete() {
              const [deleting, setDeleting] = useState(false)
              const api = useAPI()
              return (
                <Modal
                  title="提示"
                  open={!!deletingVideoId}
                  closable={!deleting}
                  maskClosable={!deleting}
                  onCancel={() => setDeletingVideoId('')}
                  bodyStyle={{ padding: 20 }}
                  footer={[
                    <Button key="cancel" onClick={() => setDeletingVideoId('')}>
                      取消
                    </Button>,
                    <Button
                      key="confirm"
                      danger
                      loading={deleting}
                      onClick={async () => {
                        setDeleting(true)
                        try {
                          await api.delete(`/synthesizable_videos/${deletingVideoId}`)
                          setDeletingVideoId('')
                          getGroupList()
                        } catch (e: any) {
                          message.error(e?.message)
                        } finally {
                          setDeleting(false)
                        }
                      }}
                    >
                      确认删除
                    </Button>
                  ]}
                >
                  <p style={{ textAlign: 'center' }}>
                    <Trash />
                  </p>
                  <h3 style={{ textAlign: 'center' }}>删除主播</h3>
                  <p style={{ textAlign: 'center' }}>主播删除后不可恢复，请确认是否删除该主播</p>
                </Modal>
              )
            }}
          </Render>
          <Render>
            {function GroupEdit() {
              // eslint-disable-next-line react-hooks/exhaustive-deps
              const group = useMemo(() => videos.groups.find((v) => v.group_id === groupId), [videos, groupId])

              const [name, setName] = useBehaviorSubject(useMemo(() => new BehaviorSubject(group?.name || ''), [group]))

              const [saving, setSaving] = useState(false)
              const [fileList, setFileList] = useState<any[]>([])
              const [avatar, setAvatar] = useState('')
              const upload = useUploadResource()
              const url = useContext(resourceUrl)
              const api = useAPI()

              const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
                setFileList(newFileList)
              }

              useEffect(() => {
                if (group) {
                  const ava = (group.avatar || group.defaultAvatar) as string
                  setAvatar(ava)
                  setFileList([
                    {
                      avatar: ava,
                      url: url(ava)
                    }
                  ])
                } else {
                  setAvatar('')
                  setFileList([])
                }
              }, [group, url])

              if (!group) {
                return null
              }

              return (
                <Modal
                  title="编辑"
                  open={!!group}
                  onCancel={() => setGroupId('')}
                  closable={!saving}
                  maskClosable={!saving}
                  footer={[
                    <Button key="cancel" disabled={saving} onClick={() => setGroupId('')}>
                      取消
                    </Button>,
                    <Button
                      key="confirm"
                      loading={saving}
                      disabled={!name || !fileList.length}
                      type="primary"
                      onClick={
                        group &&
                        (async () => {
                          setSaving(true)
                          try {
                            await api.post(`/group_synthesizable_videos/group_update`, {
                              avatar,
                              name,
                              id: groupId
                            })
                            setGroupId('')
                            getGroupList()
                          } catch (e: any) {
                            message.error(e?.message)
                          } finally {
                            setSaving(false)
                          }
                        })
                      }
                    >
                      保存
                    </Button>
                  ]}
                  bodyStyle={{ padding: 20 }}
                >
                  <div style={{ position: 'relative', marginBottom: 20 }}>
                    <Input
                      disabled={saving}
                      className="input-with-hint"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <div className="input-hint">
                      <span style={{ color: 'red' }}>*</span>形象组名称
                    </div>
                  </div>
                  <div style={{ color: '#fff', marginBottom: 8 }}>
                    <span style={{ color: 'red' }}>*</span>头像
                  </div>
                  <div>
                    <Upload
                      listType="picture-card"
                      fileList={fileList}
                      className="avatar-upload"
                      accept=".jpg,.jpeg,.JPG,.JPEG,.png,.PNG"
                      onChange={handleChange}
                      customRequest={async ({ onSuccess, onError, file, onProgress }) => {
                        const res: any = await upload(
                          file as RcFile,
                          'avatar',
                          {
                            onSuccess,
                            onError,
                            onProgress
                          },
                          '/group_synthesizable_videos/avatar_upload'
                        )
                        setAvatar(res)
                      }}
                    >
                      {fileList.length < 1 && (
                        <div style={{ color: '#fff' }}>
                          <PlusOutlined />
                          <div style={{ marginTop: 8 }}>Upload</div>
                        </div>
                      )}
                    </Upload>
                  </div>
                </Modal>
              )
            }}
          </Render>
        </WithPermission>

        <Render>
          {function AddAnchor() {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            const videos = useW2lVideos()
            const [name, setName] = useState('')
            const [userSaving, setUserSaving] = useState(false)
            const [groupName, setGroupName] = useState<any>(undefined)
            const [video_key, setVideoKey] = useState('')
            const [similarity, setSimilarity] = useState(400)
            const [smoothness, setSmoothness] = useState(80)
            const [advancedConfig, setAdvancedConfig] = useState('')
            const [color, setColor] = useState(2)
            const [chromaFlag, setChromaFlag] = useState(false)
            const [showFinish, setShowFinish] = useState(false)
            const chromaOptions = [
              { name: '红色', id: 1 },
              { name: '绿色', id: 2 },
              { name: '蓝色', id: 3 }
            ]
            const api = useAPI()

            const defaultGroups: any[] = useMemo(() => {
              return videos.groups.reduce((pre, next) => {
                if ((permission.admin || next.writable) && next.group_id && next.group_id !== '0') {
                  pre.push({
                    label: next.name,
                    value: next.name
                  })
                }
                return pre
              }, [] as any[])
            }, [videos.groups])

            return (
              <Modal
                title="新建"
                open={userCreateOpen}
                onCancel={() => {
                  setShowFinish(false)
                  setUserCreateOpen(false)
                }}
                closable={!userSaving}
                maskClosable={!userSaving}
                footer={
                  showFinish
                    ? [
                        <Button
                          key="finish"
                          type="primary"
                          onClick={() => {
                            setShowFinish(false)
                            setUserCreateOpen(false)
                          }}
                        >
                          期待
                        </Button>
                      ]
                    : [
                        <Button key="cancel" disabled={userSaving} onClick={() => setUserCreateOpen(false)}>
                          取消
                        </Button>,
                        <Button
                          key="confirm"
                          loading={userSaving}
                          disabled={!name || !video_key || !groupName}
                          type="primary"
                          onClick={async () => {
                            setUserSaving(true)
                            let taskParams: any = {
                              name,
                              video_key,
                              group_name: groupName
                            }
                            if (chromaFlag) {
                              taskParams.chroma_key_filter = {
                                color,
                                similarity,
                                smoothness
                              }
                              taskParams.advanced_pb_text_config = advancedConfig
                            }
                            try {
                              await api.post(`/submit_synthesizable_video_gen_task`, taskParams)
                              setShowFinish(true)
                              getGroupList()
                            } catch (e: any) {
                              message.error(e?.message)
                            } finally {
                              setUserSaving(false)
                            }
                          }}
                        >
                          保存
                        </Button>
                      ]
                }
                bodyStyle={{ padding: 20 }}
              >
                {showFinish && (
                  <div>
                    <p style={{ textAlign: 'center' }}>
                      <CheckCircleOutlined style={{ fontSize: '5em', color: '#08c' }} />
                    </p>
                    <p style={{ fontSize: '1.2em', textAlign: 'center' }}>视频已成功上传！</p>
                    <p>我们已收到视频，会尽快开始复刻。任务可能会排队，请留意首页数字人列表的状态。</p>
                  </div>
                )}
                {!showFinish && (
                  <>
                    <div style={{ position: 'relative', marginBottom: 20 }}>
                      <Select
                        mode="tags"
                        className="fantasy-select input-with-hint"
                        popupClassName="fantasy-popup"
                        style={{ backgroundColor: '#171719', width: '100%' }}
                        value={groupName}
                        onChange={(e) => {
                          setGroupName(e.at(-1))
                        }}
                        showArrow={false}
                        options={defaultGroups}
                      />
                      <div className="input-hint">
                        <span style={{ color: 'red' }}>*</span>形象名称
                      </div>
                    </div>
                    <div style={{ position: 'relative', marginBottom: 20 }}>
                      <Input
                        disabled={userSaving}
                        className="input-with-hint"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                      <div className="input-hint">
                        <span style={{ color: 'red' }}>*</span>动作名称
                      </div>
                    </div>
                    <div style={{ paddingLeft: 16 }}>
                      <span style={{ color: 'red' }}>*</span>上传视频
                    </div>
                    <div style={{ padding: '0 6px' }}>
                      <div className="upload-anchor-tip">
                        <h5>视频要求</h5>
                        <ul>
                          <li>
                            <b>视频时长：</b>不超过30分钟
                          </li>
                          <li>
                            <b>视频方向：</b>纵向
                          </li>
                          <li>
                            <b>文件格式：</b>mp4、mov
                          </li>
                          <li>
                            <b>分辨率：</b>1080p - 4k
                          </li>
                          <li>
                            <b>视频帧率：</b>不低于25fps
                          </li>
                        </ul>
                      </div>

                      <div
                        style={{
                          overflow: 'hidden',
                          height: '100%',
                          marginTop: '10px'
                        }}
                      >
                        <UploadAnchorField
                          onClose={() => {}}
                          onUpload={(resource: any) => {
                            setVideoKey(resource?.key)
                          }}
                          accept=".mp4,.MP4,.mov,.MOV"
                          uploadUrl="/upload"
                        />
                      </div>
                    </div>
                    <Checkbox
                      style={{ color: 'white', marginTop: 10 }}
                      checked={chromaFlag}
                      onChange={(e) => setChromaFlag(e.target.checked)}
                    >
                      绿幕处理
                    </Checkbox>
                    {chromaFlag && (
                      <div style={{ marginTop: '10px', display: 'flex' }}>
                        <div style={{ flex: 'auto' }}>
                          <Select
                            className="fantasy-select input-with-hint"
                            popupClassName="fantasy-popup"
                            style={{ backgroundColor: '#171719', width: '100px' }}
                            value={color}
                            onChange={(e) => {
                              setColor(e)
                            }}
                            options={(chromaOptions || []).map((v) => ({ label: v.name, value: v.id }))}
                          />
                        </div>
                        <div style={{ flex: 'auto' }}>
                          相似度：
                          <br />
                          <Slider
                            min={1}
                            max={1000}
                            onAfterChange={(value: number | number[]) => {
                              if (typeof value === 'number') setSimilarity(value)
                            }}
                            defaultValue={400}
                          />
                        </div>
                        <div style={{ flex: 'auto' }}>
                          平滑度：
                          <Slider
                            min={1}
                            max={1000}
                            onAfterChange={(value: number | number[]) => {
                              if (typeof value === 'number') setSmoothness(value)
                            }}
                            defaultValue={80}
                          />
                        </div>
                      </div>
                    )}

                    {permission.admin && chromaFlag && (
                      <div style={{ marginTop: '10px', display: 'flex' }}>
                        <div style={{ flex: 'auto' }}>
                          <Input.TextArea
                            style={{ resize: 'none', height: 100 }}
                            placeholder="绿幕处理高级参数："
                            className="input-with-hint"
                            value={advancedConfig}
                            onChange={(e) => setAdvancedConfig(e.target.value)}
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}
              </Modal>
            )
          }}
        </Render>
      </div>
      <div className="section-title" style={{ marginTop: 20 }}>
        声音选择
      </div>
      <div className="sub-section-title">男声</div>
      <VoiceList voices={maleVoices} update={getVoiceList} />
      <div className="sub-section-title">女声</div>
      <VoiceList voices={femaleVoices} update={getVoiceList} />
    </div>
  )
}

const VoiceList: FC<{
  voices: Voice[]
  update: () => void
}> = ({ voices, update }) => {
  const [voice, setVoice] = useBehaviorSubject(useContext(voiceName))
  const saveSubj = useContext(save)
  const permission = usePermission()
  const visibleVoices = voices
  const [createOpen, setCreateOpen] = useState(false)
  const [editingVoiceId, setEditingVoiceId] = useState('')
  const [deletingVoiceId, setDeletingVoiceId] = useState('')
  const [settingOpen, setSettingOpen] = useState(false)
  const [playLoading, setPlayLoading] = useState(false)
  const [defaultLoading, setDefaultLoading] = useState(false)
  const [voiceConfig, setVoiceConfig] = useState<any>({})
  const [playId, setPlayId] = useState('')
  const audio = useMemo(() => new Audio(), [])
  const queryClient = useQueryClient()
  const api = useAPI()

  const markupList = useMemo(() => {
    const { id } = voiceConfig
    const curVoice = voices.find((v) => v.id === id)
    if (curVoice?.markup_list?.Data) {
      return {
        pitchList: curVoice?.markup_list?.Data.pitch?.list || [],
        rateList: curVoice?.markup_list?.Data.rate?.list || [],
        volumeList: curVoice?.markup_list?.Data.volume?.list || []
      }
    } else {
      return {}
    }
  }, [voiceConfig, voices])

  const changeStatus = useMemo(() => {
    const { style, pitch, rate, volume } = voiceConfig
    return !(
      !style &&
      pitch === markupList?.pitchList?.[3].name &&
      rate === markupList?.rateList?.[3].name &&
      volume === markupList?.volumeList?.[3].name
    )
  }, [voiceConfig, markupList])

  const styleList = useMemo(() => {
    const { id } = voiceConfig
    const curVoice = voices.find((v) => v.id === id)
    const defaultStyle = {
      description: '默认',
      name: ''
    }
    if (curVoice?.style_and_role_list?.Data?.list?.length) {
      return [defaultStyle, ...curVoice.style_and_role_list.Data.list]
    } else {
      return [defaultStyle]
    }
  }, [voiceConfig, voices])

  const updateVoiceConfig = (key: string, val: string) => {
    if (voiceConfig[key] === val) {
      return
    }
    setVoiceConfig({
      ...voiceConfig,
      [key]: val
    })
  }

  const resetSetting = () => {
    setVoiceConfig({
      ...voiceConfig,
      style: '',
      pitch: markupList?.pitchList?.[3].name,
      rate: markupList?.rateList?.[3].name,
      volume: markupList?.volumeList?.[3].name
    })
  }

  const previewVoice = async (v: any) => {
    try {
      await Promise.all([
        (async () => {
          audio.src = await queryClient.fetchQuery({
            queryKey: [`/voices/${v.id}/preview`],
            queryFn: async () => {
              const { data } = (
                await api.post<{ data: string }>(`/voices/${v.id}/preview`, {
                  style: v.style || v.Style || '',
                  pitch: v.pitch || v.Pitch,
                  rate: v.rate || v.Rate,
                  volume: v.volume || v.Volume
                })
              ).data
              return `data:audio/wav;base64,${wavConverter
                .encodeWav(new Buffer(data, 'base64'), {
                  numChannels: 1,
                  sampleRate: 16000,
                  byteRate: 32_000
                })
                .toString('base64')}`
            }
          })
          await audio.play()
        })(),
        new Promise((r) => setTimeout(r, 1000))
      ])
    } catch (e: any) {
      message.error(e?.message)
    } finally {
      setPlayId('')
      setDefaultLoading(false)
      setPlayLoading(false)
    }
  }

  const confirmUpdateVoice = async () => {
    try {
      await api.post(`/voices/${voiceConfig?.id}/user_markup`, {
        style: voiceConfig.style,
        pitch: voiceConfig.pitch,
        rate: voiceConfig.rate,
        volume: voiceConfig.volume
      })
      update()
      setSettingOpen(false)
    } catch (e: any) {
      message.error(e?.message)
    }
  }

  return (
    <>
      <List
        dataSource={visibleVoices}
        grid={{ gutter: 4 }}
        footer={
          permission.admin && (
            <Button type="primary" onClick={() => setCreateOpen(true)}>
              新增
            </Button>
          )
        }
        renderItem={(v, index) => (
          <List.Item
            key={`${v}-${index}`}
            style={{
              boxSizing: 'border-box',
              position: 'relative',
              width: 262,
              height: 48,
              borderRadius: 2,
              overflow: 'hidden',
              padding: 0,
              marginBottom: 4,
              border: `solid 2px ${v.name === voice ? '#00FFF0' : 'transparent'}`
            }}
          >
            <Render>
              {function VoiceItem() {
                return (
                  <Tooltip open={permission.admin ? undefined : false} showArrow={false} title={v.name}>
                    <div
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        width: '100%',
                        height: '100%',
                        color: 'white',
                        backgroundColor: '#28282C',
                        cursor: 'pointer'
                      }}
                      onClick={async () => {
                        if (voice !== v.name) {
                          setVoice(v.name)
                          setTimeout(() => {
                            saveSubj.next(false)
                          }, 100)
                        }

                        if (playId === v.id) return
                        setPlayId(v.id)
                        previewVoice({
                          id: v.id,
                          ...v.user_markup
                        })
                      }}
                    >
                      <div
                        style={{
                          width: 0,
                          flex: 1,
                          height: '100%',
                          display: 'inline-flex',
                          alignItems: 'center'
                        }}
                      >
                        <div
                          style={{
                            display: 'inline-flex',
                            justifyContent: 'center',
                            width: 30,
                            marginLeft: 4
                          }}
                        >
                          {playId === v.id ? <Spinner className="spin" /> : <PlayCircle />}
                        </div>
                        <span
                          className="ellipsis"
                          style={{
                            width: 0,
                            flex: 1,
                            marginRight: 6
                          }}
                        >
                          {v.local_name}
                        </span>
                      </div>
                      {v.description && (
                        <span
                          className="ellipsis"
                          style={{
                            display: 'inline-block',
                            width: 0,
                            flex: 1,
                            paddingLeft: 4,
                            maxWidth: 72
                          }}
                        >
                          {v.description}
                        </span>
                      )}

                      <div style={{ display: 'flex', alignItems: 'center', marginRight: 6 }}>
                        <SettingHorizontal
                          onClick={(e) => {
                            e.stopPropagation()
                            setSettingOpen(true)
                            setVoiceConfig({
                              id: v.id,
                              local_name: v.local_name,
                              style: v.user_markup?.Style || '',
                              pitch: v.user_markup?.Pitch || v.markup_list?.Data?.pitch?.list[3]?.name,
                              rate: v.user_markup?.Rate || v.markup_list?.Data?.rate?.list[3]?.name,
                              volume: v.user_markup?.Volume || v.markup_list?.Data?.volume?.list[3]?.name
                            })
                          }}
                        />
                        <WithPermission permission="admin">
                          <Trash
                            onClick={(e) => {
                              e.stopPropagation()
                              setDeletingVoiceId(v.id)
                            }}
                          />
                          <CogSquare
                            onClick={(e) => {
                              e.stopPropagation()
                              setEditingVoiceId(v.id)
                            }}
                          />
                        </WithPermission>
                      </div>
                    </div>
                  </Tooltip>
                )
              }}
            </Render>
            {v.name === voice && <SelectionBadge />}
          </List.Item>
        )}
      />
      <Modal
        open={settingOpen}
        title="声音调整"
        footer={null}
        onCancel={() => setSettingOpen(false)}
        width={1000}
        bodyStyle={{ padding: '20px 0' }}
      >
        <div style={{ padding: '0 14px 0 30px', borderBottom: '1px solid #434343' }}>
          {!!styleList?.length && (
            <div style={{ marginBottom: 12 }}>
              <div style={{ fontWeight: 'bold', marginBottom: 12 }}>风格</div>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {styleList.map((style) => (
                  <div
                    key={style.name}
                    style={{
                      padding: '6px 16px',
                      color: voiceConfig.style === style.name ? '#00FFF0' : '#fff',
                      border: voiceConfig.style === style.name ? '1px solid #00FFF0' : '1px solid #434343',
                      borderRadius: 8,
                      cursor: 'pointer',
                      marginRight: 16,
                      marginBottom: 12
                    }}
                    onClick={() => updateVoiceConfig('style', style.name)}
                  >
                    {style.description}
                  </div>
                ))}
              </div>
            </div>
          )}

          {!!markupList.pitchList?.length && (
            <div style={{ marginBottom: 12 }}>
              <div style={{ fontWeight: 'bold', marginBottom: 12 }}>音调</div>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {markupList.pitchList?.map((pitch) => (
                  <div
                    key={pitch.name}
                    style={{
                      padding: '6px 16px',
                      color: voiceConfig.pitch === pitch.name ? '#00FFF0' : '#fff',
                      border: voiceConfig.pitch === pitch.name ? '1px solid #00FFF0' : '1px solid #434343',
                      borderRadius: 8,
                      cursor: 'pointer',
                      marginRight: 16,
                      marginBottom: 12
                    }}
                    onClick={() => updateVoiceConfig('pitch', pitch.name)}
                  >
                    {pitch.description}
                  </div>
                ))}
              </div>
            </div>
          )}

          {!!markupList.rateList?.length && (
            <div style={{ marginBottom: 12 }}>
              <div style={{ fontWeight: 'bold', marginBottom: 12 }}>语速</div>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {markupList.rateList?.map((rate) => (
                  <div
                    key={rate.name}
                    style={{
                      padding: '6px 16px',
                      color: voiceConfig.rate === rate.name ? '#00FFF0' : '#fff',
                      border: voiceConfig.rate === rate.name ? '1px solid #00FFF0' : '1px solid #434343',
                      borderRadius: 8,
                      cursor: 'pointer',
                      marginRight: 16,
                      marginBottom: 12
                    }}
                    onClick={() => updateVoiceConfig('rate', rate.name)}
                  >
                    {rate.description}
                  </div>
                ))}
              </div>
            </div>
          )}

          {!!markupList.volumeList?.length && (
            <div style={{ marginBottom: 12 }}>
              <div style={{ fontWeight: 'bold', marginBottom: 12 }}>音量</div>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {markupList.volumeList?.map((volume) => (
                  <div
                    key={volume.name}
                    style={{
                      padding: '6px 16px',
                      color: voiceConfig.volume === volume.name ? '#00FFF0' : '#fff',
                      border: voiceConfig.volume === volume.name ? '1px solid #00FFF0' : '1px solid #434343',
                      borderRadius: 8,
                      cursor: 'pointer',
                      marginRight: 16,
                      marginBottom: 12
                    }}
                    onClick={() => updateVoiceConfig('volume', volume.name)}
                  >
                    {volume.description}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="footer" style={{ display: 'flex', alignItems: 'center', padding: '32px 30px 0 30px' }}>
          <div>发音人 {voiceConfig.local_name}</div>
          <div
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              marginLeft: 40,
              padding: '0 12px',
              width: 180,
              height: 44,
              color: '#fff',
              backgroundColor: '#28282C',
              cursor: 'pointer'
            }}
            onClick={() => {
              if (defaultLoading) return
              setDefaultLoading(true)
              previewVoice({
                id: voiceConfig.id,
                style: '',
                pitch: markupList?.pitchList?.[3].name,
                rate: markupList?.rateList?.[3].name,
                volume: markupList?.volumeList?.[3].name
              })
            }}
          >
            {defaultLoading ? <Spinner className="spin" /> : <PlayCircle />}
            <label style={{ paddingLeft: 10 }}>默认声音</label>
          </div>
          <div style={{ margin: '0 20px' }}>-</div>
          <div
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              padding: '0 12px',
              width: 180,
              height: 44,
              color: '#fff',
              backgroundColor: '#28282C',
              cursor: changeStatus ? 'pointer' : 'not-allowed',
              opacity: changeStatus ? 1 : 0.5
            }}
            onClick={() => {
              if (!changeStatus || playLoading) return
              setPlayLoading(true)
              previewVoice(voiceConfig)
            }}
          >
            {playLoading ? <Spinner className="spin" /> : <PlayCircle />}
            <label style={{ paddingLeft: 10 }}>调整后声音</label>
          </div>
          <div style={{ marginLeft: 'auto' }}>
            <Button style={{ marginRight: 20 }} onClick={resetSetting}>
              恢复默认
            </Button>
            <Button type="primary" onClick={confirmUpdateVoice}>
              确认
            </Button>
          </div>
        </div>
      </Modal>
      <WithPermission permission="admin">
        <Render>
          {function New() {
            const availableVoices = useAvailableVoices()
            const [localName, setLocalName] = useState('')
            const [previewText, setPreviewText] = useState('')
            const [name, setName] = useState('')
            const [free, setFree] = useState(true)
            const [gender, setGender] = useState('female')
            const [description, setDescription] = useState('')
            const [phones, setPhones] = useState('')
            const [saving, setSaving] = useState(false)
            const [language, setLanguage] = useState('')
            const api = useAPI()

            const languageList = useMemo(() => {
              return availableVoices?.reduce((pre: any[], next) => {
                if (next.locale) {
                  return Array.from(new Set([...pre, next.locale]))
                }
                return pre
              }, [])
            }, [availableVoices])

            const voiceList = useMemo(() => {
              if (!language) return availableVoices
              return availableVoices.filter((t) => {
                if (!t.locale) {
                  return t.name.includes(language)
                } else {
                  return t.locale === language
                }
              })
            }, [language, availableVoices])

            return (
              <Modal
                title="新建"
                open={createOpen}
                onCancel={() => setCreateOpen(false)}
                closable={!saving}
                maskClosable={!saving}
                footer={[
                  <Button key="cancel" disabled={saving} onClick={() => setCreateOpen(false)}>
                    取消
                  </Button>,
                  <Button
                    key="confirm"
                    loading={saving}
                    disabled={!name || !localName || !gender}
                    type="primary"
                    onClick={async () => {
                      setSaving(true)
                      try {
                        await api.post<Voice>('/voices', {
                          name,
                          description,
                          local_name: localName,
                          gender,
                          preview_text: previewText,
                          free,
                          phones
                        })
                        setCreateOpen(false)
                        setName('')
                        setDescription('')
                        setCreateOpen(false)
                      } catch (e: any) {
                        message.error(e?.message)
                      } finally {
                        setSaving(false)
                      }
                    }}
                  >
                    保存
                  </Button>
                ]}
                bodyStyle={{ padding: 20 }}
              >
                <div style={{ position: 'relative', marginBottom: 20 }}>
                  <Input
                    disabled={saving}
                    className="input-with-hint"
                    value={localName}
                    onChange={(e) => setLocalName(e.target.value)}
                  />
                  <div className="input-hint">
                    <span style={{ color: 'red' }}>*</span>名称
                  </div>
                </div>
                <div style={{ position: 'relative', marginBottom: 20 }}>
                  <Input
                    disabled={saving}
                    className="input-with-hint"
                    value={previewText}
                    onChange={(e) => setPreviewText(e.target.value)}
                  />
                  <div className="input-hint">预览 TTS 文本</div>
                </div>
                <div style={{ display: 'flex', width: '100%', marginBottom: 20 }}>
                  <div style={{ position: 'relative', marginRight: 12, width: 0, flex: 1 }}>
                    <Select
                      className="fantasy-select input-with-hint"
                      popupClassName="fantasy-popup"
                      style={{ backgroundColor: '#171719', width: '100%' }}
                      value={language}
                      onChange={(e: any) => {
                        setLanguage(e)
                        setName('')
                      }}
                      options={(languageList || []).map((v) => ({ label: v, value: v }))}
                      showSearch
                      allowClear
                    />
                    <div className="input-hint">语言</div>
                  </div>
                  <div style={{ position: 'relative', marginRight: 12, width: 0, flex: 1 }}>
                    <Select
                      className="fantasy-select input-with-hint"
                      popupClassName="fantasy-popup"
                      style={{ backgroundColor: '#171719', width: '100%' }}
                      value={name}
                      onChange={setName}
                      options={(voiceList || []).map((v) => ({ label: v.local_name, value: v.name }))}
                      showSearch
                    />
                    <div className="input-hint">
                      <span style={{ color: 'red' }}>*</span>声音
                    </div>
                  </div>
                </div>
                <div style={{ display: 'flex', width: '100%', marginBottom: 20 }}>
                  <div style={{ position: 'relative', width: 0, flex: 1 }}>
                    <Select
                      className="fantasy-select input-with-hint"
                      popupClassName="fantasy-popup"
                      style={{ backgroundColor: '#171719', width: '100%' }}
                      value={gender}
                      onChange={setGender}
                      options={[
                        { value: 'female', label: '女性' },
                        { value: 'male', label: '男性' }
                      ]}
                    />
                    <div className="input-hint">性别</div>
                  </div>
                </div>
                <Checkbox
                  style={{ color: 'white', marginBottom: 20 }}
                  checked={free}
                  onChange={(e) => setFree(e.target.checked)}
                >
                  免费
                </Checkbox>
                <div style={{ position: 'relative' }}>
                  <Input.TextArea
                    disabled={saving}
                    style={{ resize: 'none', height: 100 }}
                    className="input-with-hint"
                    value={phones}
                    placeholder="填写手机号，以逗号分隔"
                    onChange={(e) => setPhones(e.target.value)}
                  />
                  <div className="input-hint">授权以下用户使用</div>
                </div>
                <div style={{ position: 'relative' }}>
                  <Input.TextArea
                    disabled={saving}
                    style={{ resize: 'none', height: 100 }}
                    className="input-with-hint"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                  <div className="input-hint">简介</div>
                </div>
              </Modal>
            )
          }}
        </Render>
        <Render>
          {function Edit() {
            const voices = useVoices()
            const availableVoices = useAvailableVoices()
            // eslint-disable-next-line react-hooks/exhaustive-deps
            const voice = useMemo(() => voices?.find((v) => v.id === editingVoiceId), [voices, editingVoiceId])
            const [localName, setLocalName] = useBehaviorSubject(
              useMemo(() => new BehaviorSubject(voice?.local_name || ''), [voice])
            )
            const [name, setName] = useBehaviorSubject(useMemo(() => new BehaviorSubject(voice?.name || ''), [voice]))
            const [previewText, setPreviewText] = useBehaviorSubject(
              useMemo(() => new BehaviorSubject(voice?.preview_text || ''), [voice])
            )
            const [gender, setGender] = useBehaviorSubject(
              useMemo(() => new BehaviorSubject(voice?.gender || ''), [voice])
            )
            const [free, setFree] = useBehaviorSubject(
              useMemo(() => new BehaviorSubject(voice?.free || false), [voice])
            )
            const [description, setDescription] = useBehaviorSubject(
              useMemo(() => new BehaviorSubject(voice?.description || ''), [voice])
            )
            const [phones, setPhones] = useBehaviorSubject(
              useMemo(() => new BehaviorSubject(voice?.phones || ''), [voice])
            )
            const [saving, setSaving] = useState(false)
            const api = useAPI()
            const audio = useMemo(() => new Audio(), [])
            const queryClient = useQueryClient()

            const [language, setLanguage] = useState('')
            const languageList = useMemo(() => {
              return availableVoices?.reduce((pre: any[], next) => {
                if (next.locale) {
                  return Array.from(new Set([...pre, next.locale]))
                }
                return pre
              }, [])
            }, [availableVoices])

            const voiceList = useMemo(() => {
              if (!language) return availableVoices
              return availableVoices.filter((t) => {
                if (!t.locale) {
                  return t.name.includes(language)
                } else {
                  return t.locale === language
                }
              })
            }, [language, availableVoices])

            useEffect(() => {
              let l = availableVoices?.find((t) => t.name === voice?.name)
              if (!l?.locale && voice?.name) {
                setLanguage(voice.name.substring(0, 5))
              }
              if (l?.locale) {
                setLanguage(l.locale)
              }
            }, [availableVoices, voice])

            return (
              <Modal
                title="编辑"
                open={!!voice}
                onCancel={() => setEditingVoiceId('')}
                closable={!saving}
                maskClosable={!saving}
                footer={[
                  <Button
                    key="preview"
                    disabled={saving}
                    onClick={
                      voice &&
                      (async () => {
                        await api.post(`/voices/${voice.id}`, {
                          name,
                          description,
                          local_name: localName,
                          free,
                          gender,
                          preview_text: previewText,
                          phones
                        })
                        audio.src = await queryClient.fetchQuery({
                          queryKey: [`/voices/${voice.id}/preview`],
                          queryFn: async () => {
                            const { data } = (await api.get<{ data: string }>(`/voices/${voice.id}/preview`)).data
                            return `data:audio/wav;base64,${wavConverter
                              .encodeWav(new Buffer(data, 'base64'), {
                                numChannels: 1,
                                sampleRate: 16000,
                                byteRate: 32_000
                              })
                              .toString('base64')}`
                          }
                        })
                        await audio.play()
                      })
                    }
                  >
                    预览声音
                  </Button>,
                  <Button key="cancel" disabled={saving} onClick={() => setEditingVoiceId('')}>
                    取消
                  </Button>,
                  <Button
                    key="confirm"
                    loading={saving}
                    disabled={!name}
                    type="primary"
                    onClick={
                      voice &&
                      (async () => {
                        setSaving(true)
                        try {
                          await api.post(`/voices/${voice.id}`, {
                            name,
                            description,
                            local_name: localName,
                            free,
                            gender,
                            preview_text: previewText,
                            phones
                          })
                          setEditingVoiceId('')
                        } catch (e: any) {
                          message.error(e?.message)
                        } finally {
                          setSaving(false)
                        }
                      })
                    }
                  >
                    保存
                  </Button>
                ]}
                bodyStyle={{ padding: 20 }}
              >
                <div style={{ position: 'relative', marginBottom: 20 }}>
                  <Input
                    disabled={saving}
                    className="input-with-hint"
                    value={localName}
                    onChange={(e) => setLocalName(e.target.value)}
                  />
                  <div className="input-hint">
                    <span style={{ color: 'red' }}>*</span>名称
                  </div>
                </div>
                <div style={{ position: 'relative', marginBottom: 20 }}>
                  <Input
                    disabled={saving}
                    className="input-with-hint"
                    value={previewText}
                    onChange={(e) => setPreviewText(e.target.value)}
                  />
                  <div className="input-hint">预览 TTS 文本</div>
                </div>
                <div style={{ display: 'flex', width: '100%', marginBottom: 20 }}>
                  <div style={{ position: 'relative', marginRight: 12, width: 0, flex: 1 }}>
                    <Select
                      className="fantasy-select input-with-hint"
                      popupClassName="fantasy-popup"
                      style={{ backgroundColor: '#171719', width: '100%' }}
                      value={language}
                      onChange={(e: any) => {
                        setLanguage(e)
                        setName('')
                      }}
                      options={(languageList || []).map((v) => ({ label: v, value: v }))}
                      showSearch
                      allowClear
                    />
                    <div className="input-hint">语言</div>
                  </div>
                  <div style={{ position: 'relative', marginRight: 12, width: 0, flex: 1 }}>
                    <Select
                      className="fantasy-select input-with-hint"
                      popupClassName="fantasy-popup"
                      style={{ backgroundColor: '#171719', width: '100%' }}
                      value={name}
                      onChange={setName}
                      options={(voiceList || []).map((v) => ({ label: v.local_name, value: v.name }))}
                      showSearch
                    />
                    <div className="input-hint">声音</div>
                  </div>
                </div>
                <div style={{ display: 'flex', width: '100%', marginBottom: 20 }}>
                  <div style={{ position: 'relative', width: 0, flex: 1 }}>
                    <Select
                      className="fantasy-select input-with-hint"
                      popupClassName="fantasy-popup"
                      style={{ backgroundColor: '#171719', width: '100%' }}
                      value={gender}
                      onChange={setGender}
                      options={[
                        { value: 'female', label: '女性' },
                        { value: 'male', label: '男性' }
                      ]}
                    />
                    <div className="input-hint">性别</div>
                  </div>
                </div>
                <Checkbox
                  style={{ color: 'white', marginBottom: 20 }}
                  checked={free}
                  onChange={(e) => setFree(e.target.checked)}
                >
                  免费
                </Checkbox>
                <div style={{ position: 'relative' }}>
                  <Input.TextArea
                    disabled={saving}
                    style={{ resize: 'none', height: 100 }}
                    className="input-with-hint"
                    value={phones}
                    placeholder="填写手机号，以逗号分隔"
                    onChange={(e) => setPhones(e.target.value)}
                  />
                  <div className="input-hint">授权以下用户使用</div>
                </div>
                <div style={{ position: 'relative' }}>
                  <Input.TextArea
                    disabled={saving}
                    style={{ resize: 'none', height: 100 }}
                    className="input-with-hint"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                  <div className="input-hint">简介</div>
                </div>
              </Modal>
            )
          }}
        </Render>
        <Render>
          {function Delete() {
            const [deleting, setDeleting] = useState(false)
            const api = useAPI()
            return (
              <Modal
                title="提示"
                open={!!deletingVoiceId}
                closable={!deleting}
                maskClosable={!deleting}
                onCancel={() => setDeletingVoiceId('')}
                bodyStyle={{ padding: 20 }}
                footer={[
                  <Button key="cancel" onClick={() => setDeletingVoiceId('')}>
                    取消
                  </Button>,
                  <Button
                    key="confirm"
                    danger
                    loading={deleting}
                    onClick={async () => {
                      setDeleting(true)
                      try {
                        await api.delete(`/voices/${deletingVoiceId}`)
                        setDeletingVoiceId('')
                      } catch (e: any) {
                        message.error(e?.message)
                      } finally {
                        update()
                        setDeleting(false)
                      }
                    }}
                  >
                    确认删除
                  </Button>
                ]}
              >
                <p style={{ textAlign: 'center' }}>
                  <Trash />
                </p>
                <h3 style={{ textAlign: 'center' }}>删除声音</h3>
                <p style={{ textAlign: 'center' }}>声音删除后不可恢复，请确认是否删除该声音</p>
              </Modal>
            )
          }}
        </Render>
      </WithPermission>
    </>
  )
}

const VideoLevelBadge: FC<{ level: number; style?: CSSProperties }> = ({ level, style }) => {
  switch (level) {
    case 1:
      return <Vip1 style={style} />
    case 2:
      return <Vip2 style={style} />
    case 3:
      return <Vip3 style={style} />
  }
  return null
}

export const BackgroundList: FC = () => {
  const url = useContext(resourceUrl)
  const resources = useResources()
  const backgrounds = useMemo(() => resources.filter((r) => r.tag?.split(',').includes('background')), [resources])
  const bgSubj = useContext(background)
  let [bg, setBackground] = useBehaviorSubject(
    useMemo(() => bgSubj || new BehaviorSubject<bgImg | undefined>(undefined), [bgSubj])
  )
  bg = bgSubj && bg
  const [uploadOpen, setUploadOpen] = useState(false)
  const upload = useUploadResource()
  const [deletingResourceId, setDeletingResourceId] = useState<string>()
  const [editId, setEditId] = useState('')
  const api = useAPI()

  return (
    <div style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto' }}>
      <div
        className="section-title"
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignContent: 'center'
        }}
      >
        {!!bgSubj && '背景选择'}
        {!bgSubj && (
          <div style={{ display: 'inline' }}>
            背景<span style={{ color: 'rgb(109, 109, 109)', fontSize: 12, marginLeft: 12 }}>可在配置直播间时使用</span>
          </div>
        )}

        <Button type="primary" onClick={() => setUploadOpen(true)}>
          上传
        </Button>
      </div>
      <Render>
        {function Delete() {
          const [deleting, setDeleting] = useState(false)
          const api = useAPI()
          return (
            <Modal
              title="提示"
              open={!!deletingResourceId}
              closable={!deleting}
              maskClosable={!deleting}
              onCancel={() => setDeletingResourceId(undefined)}
              bodyStyle={{ padding: 20 }}
              footer={[
                <Button key="cancel" onClick={() => setDeletingResourceId(undefined)}>
                  取消
                </Button>,
                <Button
                  key="confirm"
                  danger
                  loading={deleting}
                  onClick={async () => {
                    setDeleting(true)
                    try {
                      await api.delete(`/resources/${deletingResourceId}`)
                      setDeletingResourceId(undefined)
                    } catch (e: any) {
                      message.error(e?.message)
                    } finally {
                      setDeleting(false)
                    }
                  }}
                >
                  确认删除
                </Button>
              ]}
            >
              <p style={{ textAlign: 'center' }}>
                <Trash />
              </p>
              <h3 style={{ textAlign: 'center' }}>删除背景</h3>
              <p style={{ textAlign: 'center' }}>背景删除后不可恢复，请确认是否删除该背景</p>
            </Modal>
          )
        }}
      </Render>
      <Render>
        {function Upload() {
          const [fileList, setFileList] = useState<UploadFile[]>([])
          const content = useMemo(() => {
            const uploading = fileList?.filter((f) => f.status === 'uploading').length
            if (uploading) {
              return (
                <>
                  <p>
                    <Spinner className="spin" />
                  </p>
                  <p>
                    图片上传中 {fileList.length - uploading} / {fileList.length}
                  </p>
                </>
              )
            }
            return (
              <>
                <h4 style={{ color: 'white' }}>将文件拖到此处，或点击上传按钮</h4>
                <p>
                  <Button type="primary">上传</Button>
                </p>
                <p style={{ color: 'white' }}>
                  支持上传 jpg/png 文件，图片高宽比例为 9:16，不符合比例的图片我们会做处理
                </p>
              </>
            )
          }, [fileList])
          return (
            <Modal
              title="上传背景图片"
              open={uploadOpen}
              onCancel={() => setUploadOpen(false)}
              footer={[]}
              bodyStyle={{ padding: 12 }}
            >
              <Dragger
                name="background-image"
                multiple
                style={{ padding: 12, color: 'white' }}
                showUploadList={false}
                accept=".jpg,.jpeg,.JPG,.JPEG,.png,.PNG,.gif,.GIF,.apng,.APNG,.webp,.WEBP"
                customRequest={({ onSuccess, onError, file, onProgress }) =>
                  upload(file as RcFile, 'background', {
                    onSuccess,
                    onError,
                    onProgress
                  })
                }
                onChange={(info) => {
                  if (info?.file?.status === 'error') {
                    message.error(`${info.file.name} 上传失败`)
                  }
                  setFileList(info.fileList)
                  const uploading = info.fileList?.filter((f) => f.status === 'uploading').length
                  if (!uploading) {
                    setTimeout(() => setUploadOpen(false), 1000)
                  }
                }}
              >
                {content}
              </Dragger>
            </Modal>
          )
        }}
      </Render>
      <List
        grid={{ gutter: 24 }}
        dataSource={backgrounds}
        rowKey="id"
        renderItem={(b) => (
          <List.Item
            key={b.id}
            style={{
              boxSizing: 'border-box',
              position: 'relative',
              width: 112,
              height: 226,
              padding: 0,
              marginBottom: 20,
              cursor: 'pointer',
              borderRadius: 2,
              overflow: 'hidden'
            }}
          >
            <Render>
              {function Background() {
                const { tw, th } = useContext(targetViewPort)
                const [size, setSize] = useState<Record<'w' | 'h', number> | undefined>()
                const [name, setName] = useState('')

                useEffect(() => {
                  setName(b.name || '')
                  // eslint-disable-next-line react-hooks/exhaustive-deps
                }, [b.name])

                const onValueChange = (e: any) => {
                  setName(e.target.value)
                }

                const saveChange = (v: any) => {
                  setEditId('')
                  if (!name) {
                    setName(v.name)
                  }
                  api.put(`/resources/${v.id}`, {
                    name: name || v.name
                  })
                }

                return (
                  <>
                    <img
                      src={url(b.key)}
                      alt="background"
                      style={{
                        display: 'block',
                        width: 108,
                        height: 192,
                        objectFit: 'cover',
                        border: `solid 2px ${bg?.key === b.key ? '#00FFF0' : 'transparent'}`
                      }}
                      onLoad={(e) => setSize({ w: e.currentTarget.naturalWidth, h: e.currentTarget.naturalHeight })}
                      onClick={
                        size &&
                        (() => {
                          if (bg?.key === b.key) return
                          const scale = Math.max(th / size.h, tw / size.w)
                          setBackground({
                            key: b.key,
                            w: size.w * scale,
                            h: size.h * scale
                          })
                        })
                      }
                    />
                    {editId === b.id ? (
                      <Input
                        style={{
                          backgroundColor: 'transparent',
                          outline: 'none',
                          boxShadow: 'none',
                          border: 'none',
                          color: 'white'
                        }}
                        value={name}
                        autoFocus
                        onChange={onValueChange}
                        onBlur={() => saveChange(b)}
                      />
                    ) : (
                      <div
                        className="ellipsis"
                        style={{ color: '#FFF', lineHeight: '30px', textAlign: 'center' }}
                        onClick={() => setEditId(b.id)}
                      >
                        {name}
                      </div>
                    )}
                    {bg?.key === b.key && <SelectionBadge />}
                    {b.deletable && (
                      <Trash
                        className="hidden-button"
                        onClick={() => setDeletingResourceId(b.id)}
                        style={{
                          position: 'absolute',
                          width: 24,
                          height: 24,
                          borderRadius: 8,
                          padding: 4,
                          backgroundColor: 'rgba(24, 24, 26, 0.9)',
                          top: 6,
                          right: 6
                        }}
                      />
                    )}
                  </>
                )
              }}
            </Render>
          </List.Item>
        )}
      />
    </div>
  )
}

export const resourceEditable = createContext(true)

export const DeleteResourceModal: FC<{ id: string; onClose: () => void }> = ({ id, onClose }) => {
  const [deleting, setDeleting] = useState(false)
  const api = useAPI()
  return (
    <Modal
      title="提示"
      open={!!id}
      closable={!deleting}
      maskClosable={!deleting}
      onCancel={onClose}
      bodyStyle={{ padding: 20 }}
      footer={[
        <Button key="cancel" onClick={onClose}>
          取消
        </Button>,
        <Button
          key="confirm"
          danger
          loading={deleting}
          onClick={async () => {
            setDeleting(true)
            try {
              await api.delete(`/resources/${id}`)
              onClose()
            } catch (e: any) {
              message.error(e?.message)
            } finally {
              setDeleting(false)
            }
          }}
        >
          确认删除
        </Button>
      ]}
    >
      <p style={{ textAlign: 'center' }}>
        <Trash />
      </p>
      <h3 style={{ textAlign: 'center' }}>删除素材</h3>
      <p style={{ textAlign: 'center' }}>素材删除后不可恢复，请确认是否删除该素材</p>
    </Modal>
  )
}
export const Videos: FC = () => {
  const editable = useContext(resourceEditable)
  const resources = useResources()
  const url = useContext(resourceUrl)
  const add = useContext(addLayer)
  const [deletingResourceId, setDeletingResourceId] = useState<string>('')
  const [previewVideoKey, setPreviewVideoKey] = useState('')
  const videoRef = useRef<HTMLVideoElement>(null)
  const tab = useContext(scriptTab)
  const editing = useContext(editingLayer)
  const [ls, setLayers] = useBehaviorSubject(useContext(layers))
  const saveSubj = useContext(save)
  const [editId, setEditId] = useState('')
  const api = useAPI()

  const videos = useMemo(
    () =>
      resources
        .filter((r) => r.tag?.split(',').some((t) => ['video', 'decor'].includes(t)) && r.type === 'videos')
        .reverse()
        .map((r) => ({
          ...r,
          image_url: url(r.key) + '?x-oss-process=video/snapshot,t_0,m_fast'
        })),
    [resources, url]
  )

  return (
    <>
      <Modal
        title="预览"
        open={!!previewVideoKey}
        width={600}
        footer={null}
        onCancel={() => {
          videoRef.current?.pause()
          setPreviewVideoKey('')
        }}
      >
        <video
          width="100%"
          height={400}
          ref={videoRef}
          autoPlay
          controls
          controlsList="nodownload noplaybackrate"
          src={url(previewVideoKey)}
        />
      </Modal>
      <DeleteResourceModal id={deletingResourceId} onClose={() => setDeletingResourceId('')} />
      <List
        grid={{ gutter: 12 }}
        dataSource={videos}
        style={{
          overflow: 'hidden auto',
          maxHeight: '40%'
        }}
        renderItem={(d) => (
          <List.Item
            key={d.id}
            style={{
              cursor: 'pointer',
              boxSizing: 'border-box',
              position: 'relative',
              width: 140,
              height: 170,
              padding: 0,
              marginBottom: 12
            }}
          >
            <Render>
              {function Video() {
                const { tw, th } = useContext(targetViewPort)
                const [maxW, maxH] = [Math.min(300, tw), Math.min(300, th)]
                const [size, setSize] = useState<Record<'w' | 'h', number> | undefined>()
                const [name, setName] = useState('')

                useEffect(() => {
                  setName(d.name || '')
                  // eslint-disable-next-line react-hooks/exhaustive-deps
                }, [d.name])

                const onValueChange = (e: any) => {
                  setName(e.target.value)
                }

                const saveChange = (v: any) => {
                  setEditId('')
                  if (!name) {
                    setName(v.name)
                  }
                  api.put(`/resources/${v.id}`, {
                    name: name || v.name
                  })
                }

                return (
                  <>
                    <img
                      src={url(d.key) + '?x-oss-process=video/snapshot,t_0,m_fast'}
                      alt="decoration"
                      style={{
                        display: 'block',
                        width: 140,
                        height: 140,
                        objectFit: 'contain',
                        backgroundColor: 'rgba(40, 40, 44, 1)'
                      }}
                      onLoad={(e) => setSize({ w: e.currentTarget.naturalWidth, h: e.currentTarget.naturalHeight })}
                      onClick={
                        size &&
                        (() => {
                          const scale = Math.min(maxW / size.h, maxH / size.w)
                          if (tab.value === 'resource_change') {
                            if (editing.value) {
                              setLayers(
                                ls.map((v) => {
                                  if (v.key === editing.value) {
                                    return {
                                      ...v,
                                      type: 'video',
                                      image_key: d.key + '?x-oss-process=video/snapshot,t_0,m_fast',
                                      video_key: d.key,
                                      w: size.w * scale,
                                      h: size.h * scale,
                                      muted: false
                                    }
                                  } else {
                                    return v
                                  }
                                })
                              )
                              saveSubj.next(false)
                            } else {
                              message.warning('请在画面编辑区选择要替换的素材')
                            }
                          } else {
                            add?.next({
                              type: 'video',
                              resource_key: d.key,
                              w: size.w * scale,
                              h: size.h * scale,
                              persistent: true
                            })
                          }
                        })
                      }
                    />
                    {editId === d.id ? (
                      <Input
                        style={{
                          backgroundColor: 'transparent',
                          outline: 'none',
                          boxShadow: 'none',
                          border: 'none',
                          color: 'white'
                        }}
                        value={name}
                        autoFocus
                        onChange={onValueChange}
                        onBlur={() => saveChange(d)}
                      />
                    ) : (
                      <div
                        className="ellipsis"
                        style={{ color: '#FFF', lineHeight: '30px', textAlign: 'center' }}
                        onClick={() => setEditId(d.id)}
                      >
                        {name}
                      </div>
                    )}
                    {editable && d.deletable && (
                      <Trash
                        className="hidden-button"
                        onClick={() => setDeletingResourceId(d.id)}
                        style={{
                          position: 'absolute',
                          width: 24,
                          height: 24,
                          borderRadius: 8,
                          padding: 4,
                          backgroundColor: 'rgba(24, 24, 26, 0.9)',
                          top: 6,
                          right: 6
                        }}
                      />
                    )}
                    <Play
                      className="hidden-button"
                      onClick={() => setPreviewVideoKey(d.key)}
                      style={{
                        position: 'absolute',
                        width: 24,
                        height: 24,
                        borderRadius: 8,
                        padding: 4,
                        backgroundColor: 'rgba(24, 24, 26, 0.9)',
                        top: 44,
                        right: 6
                      }}
                    />
                  </>
                )
              }}
            </Render>
          </List.Item>
        )}
      />
    </>
  )
}

export const Decors: FC = () => {
  const editable = useContext(resourceEditable)
  const resources = useResources()
  const url = useContext(resourceUrl)
  const add = useContext(addLayer)
  const [deletingResourceId, setDeletingResourceId] = useState<string>('')
  const tab = useContext(scriptTab)
  const editing = useContext(editingLayer)
  const [ls, setLayers] = useBehaviorSubject(useContext(layers))
  const saveSubj = useContext(save)
  const [editId, setEditId] = useState('')
  const api = useAPI()

  const decors = useMemo(
    () =>
      resources
        .filter((r) => r.tag?.split(',').includes('decor') && r.type === 'images')
        .reverse()
        .map((r) => ({
          ...r,
          image_url: url(r.key)
        })),
    [resources, url]
  )

  return (
    <>
      <DeleteResourceModal id={deletingResourceId} onClose={() => setDeletingResourceId('')} />
      <List
        grid={{ gutter: 12 }}
        dataSource={decors}
        style={{
          overflow: 'hidden auto',
          maxHeight: '40%'
        }}
        rowKey="id"
        renderItem={(d) => (
          <List.Item
            style={{
              cursor: 'pointer',
              boxSizing: 'border-box',
              position: 'relative',
              width: 140,
              height: 170,
              padding: 0,
              marginBottom: 16
            }}
          >
            <Render>
              {function Decor() {
                const { tw, th } = useContext(targetViewPort)
                const [maxW, maxH] = [Math.min(300, tw), Math.min(300, th)]
                const [size, setSize] = useState<Record<'w' | 'h', number> | undefined>()
                const [name, setName] = useState('')

                useEffect(() => {
                  setName(d.name || '')
                  // eslint-disable-next-line react-hooks/exhaustive-deps
                }, [d.name])

                const onValueChange = (e: any) => {
                  setName(e.target.value)
                }

                const saveChange = (v: any) => {
                  setEditId('')
                  if (!name) {
                    setName(v.name)
                  }
                  api.put(`/resources/${v.id}`, {
                    name: name || v.name
                  })
                }

                return (
                  <>
                    <img
                      src={url(d.key)}
                      alt="decoration"
                      style={{
                        display: 'block',
                        width: 140,
                        height: 140,
                        objectFit: 'contain',
                        backgroundColor: 'rgba(40, 40, 44, 1)'
                      }}
                      onLoad={(e) => setSize({ w: e.currentTarget.naturalWidth, h: e.currentTarget.naturalHeight })}
                      onClick={
                        size &&
                        (() => {
                          const scale = Math.min(maxW / size.h, maxH / size.w)
                          if (tab.value === 'resource_change') {
                            if (editing.value) {
                              setLayers(
                                ls.map((v) => {
                                  if (v.key === editing.value) {
                                    return {
                                      ...v,
                                      type: 'image',
                                      image_key: d.key,
                                      w: size.w * scale,
                                      h: size.h * scale
                                    }
                                  } else {
                                    return v
                                  }
                                })
                              )
                              saveSubj.next(false)
                            } else {
                              message.warning('请在画面编辑区选择要替换的素材')
                            }
                          } else {
                            add?.next({
                              type: 'image',
                              resource_key: d.key,
                              w: size.w * scale,
                              h: size.h * scale,
                              persistent: true
                            })
                          }
                        })
                      }
                    />
                    {editId === d.id ? (
                      <Input
                        style={{
                          backgroundColor: 'transparent',
                          outline: 'none',
                          boxShadow: 'none',
                          border: 'none',
                          color: 'white'
                        }}
                        value={name}
                        autoFocus
                        onChange={onValueChange}
                        onBlur={() => saveChange(d)}
                      />
                    ) : (
                      <div
                        className="ellipsis"
                        style={{ color: '#FFF', lineHeight: '30px', textAlign: 'center' }}
                        onClick={() => setEditId(d.id)}
                      >
                        {name}
                      </div>
                    )}
                    {editable && d.deletable && (
                      <Trash
                        className="hidden-button"
                        onClick={() => setDeletingResourceId(d.id)}
                        style={{
                          position: 'absolute',
                          width: 24,
                          height: 24,
                          borderRadius: 8,
                          padding: 4,
                          backgroundColor: 'rgba(24, 24, 26, 0.9)',
                          top: 6,
                          right: 6
                        }}
                      />
                    )}
                  </>
                )
              }}
            </Render>
          </List.Item>
        )}
      />
    </>
  )
}

export const UploadAnchorField: FC<{
  onClose: () => void
  onUpload: (resource: any) => void
  uploadUrl?: string
  accept?: string
}> = ({ onClose, onUpload, uploadUrl, accept }) => {
  const api = useAPI()
  const urlSource = useContext(resourceUrl)
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [videoKey, setVideoKey] = useState<string>('')
  const content = useMemo(() => {
    const uploading = fileList?.filter((f) => f.status === 'uploading').length
    if (videoKey) {
      return (
        <>
          <video
            width="100%"
            height="140px"
            controls
            controlsList="nodownload noplaybackrate"
            src={urlSource(videoKey)}
          />
        </>
      )
    }
    if (uploading) {
      return <div style={{ textAlign: 'center', color: '#777' }}>上传中...</div>
    }
    return (
      <>
        <p>
          <Button type="primary">上传</Button>
        </p>
        {<p style={{ color: 'white' }}>请上传一段视频，作为驱动数字人的底版视频</p>}
        {<p style={{ color: 'gray' }}>将文件拖到此处，或点击此区域上传</p>}
      </>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoKey, fileList])

  const uploadResource = async (file: RcFile, options?: any, path?: string, onUpload?: any) => {
    path = path || `/upload`
    const { onError } = options || {}
    try {
      const segs = (file.name || '').split(/\./)
      const { resource, upload_url } =
        (
          await api.post(path, {
            extension: segs[segs.length - 1]
          })
        ).data || {}
      if (!upload_url) {
        throw new Error('failed to upload file')
      }

      await api.put(upload_url.replace(/^http:\/\//, 'https://').replace('-internal', ''), file, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      setVideoKey(resource.key)
      onUpload(resource)
      return resource
    } catch (err: any) {
      onError?.({ err })
      throw err
    }
  }

  const beforeUpload = async (file: RcFile) => {
    return new Promise((resolve) => {
      const videoElement = document.createElement('video')
      videoElement.src = URL.createObjectURL(file)
      videoElement.addEventListener('loadedmetadata', () => {
        if (videoElement.videoWidth < 1080) {
          message.warning('视频素材分辨率需要大于1080p(1080*1920)')
          resolve(false)
        } else if (videoElement.videoWidth > videoElement.videoHeight) {
          message.warning('视频素材需要是纵屏')
          resolve(false)
        } else if (videoElement.duration > 1800) {
          message.warning('视频素材不能超过30分钟')
          resolve(false)
        }
        resolve(true)
      })
    })
  }

  const removeFile = () => {
    setFileList([])
    setVideoKey('')
  }

  return (
    <div style={{ position: 'relative' }}>
      <Dragger
        name="upload"
        style={{ padding: 12, color: 'white' }}
        showUploadList={false}
        accept={accept}
        customRequest={({ onSuccess, onError, file, onProgress }) =>
          uploadResource(
            file as RcFile,
            {
              onSuccess,
              onError,
              onProgress
            },
            uploadUrl,
            onUpload
          )
        }
        beforeUpload={async (file) => {
          return (await beforeUpload(file)) as any
        }}
        onChange={(info) => {
          if (info?.file?.status === 'error') {
            message.error(`${info.file.name} 上传失败`)
          }
          setFileList(info.fileList)
          const uploading = info.fileList?.filter((f) => f.status === 'uploading').length
          if (!uploading) {
            onClose()
          }
        }}
      >
        {content}
      </Dragger>
      <Trash
        onClick={() => removeFile()}
        className="hidden-button"
        style={{
          position: 'absolute',
          right: 6,
          top: 30,
          display: 'inline-flex',
          alignItems: 'center',
          justifyItems: 'center'
        }}
      />
    </div>
  )
}

export const UploadModal: FC<{
  uploadType?: 'decor' | 'video'
  onClose: () => void
  onUploadSucces?: (resource: Resource) => void
  title?: string
  uploadUrl?: string
}> = ({ uploadType, onClose, onUploadSucces, title, uploadUrl }) => {
  const upload = useUploadResource()
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const content = useMemo(() => {
    const uploading = fileList?.filter((f) => f.status === 'uploading').length
    if (uploading) {
      return (
        <>
          <p>
            <Spinner className="spin" />
          </p>
          <p>
            上传中 {fileList.length - uploading} / {fileList.length}
          </p>
        </>
      )
    }
    return (
      <>
        <h4 style={{ color: 'white' }}>将文件拖到此处，或点击上传按钮</h4>
        <p>
          <Button type="primary">上传</Button>
        </p>
        {uploadType === 'decor' && <p style={{ color: 'white' }}>支持上传 jpg/png/gif 文件，单个文件须不大于 2M</p>}
        {uploadType === 'video' && <p style={{ color: 'white' }}>支持上传 mp4 文件，单个文件须不大于 20M</p>}
      </>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList, uploadType])
  const accept = useMemo(() => {
    switch (uploadType) {
      case 'decor':
        return '.jpg,.jpeg,.JPG,.JPEG,.png,.PNG,.gif,.GIF,.apng,.APNG,.webp,.WEBP'
      case 'video':
        return '.mp4,.MP4'
    }
    return ''
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadType])

  const beforeUpload = async (file: RcFile) => {
    return new Promise((resolve) => {
      if (uploadType === 'video') {
        const videoElement = document.createElement('video')
        videoElement.src = URL.createObjectURL(file)
        videoElement.addEventListener('loadedmetadata', () => {
          if (videoElement.videoWidth > 1080) {
            message.warning('视频素材分辨率不超过1080p(1080*1920)')
            resolve(false)
          }
          resolve(true)
        })
      } else {
        resolve(true)
      }
    })
  }

  return (
    <Modal
      title={title || '上传商品素材'}
      open={!!uploadType}
      onCancel={onClose}
      footer={[]}
      bodyStyle={{ padding: 12 }}
    >
      <Dragger
        name="upload"
        multiple
        style={{ padding: 12, color: 'white' }}
        showUploadList={false}
        accept={accept}
        customRequest={async ({ onSuccess, onError, file, onProgress }) => {
          const res = await upload(
            file as RcFile,
            uploadType || 'decor',
            {
              onSuccess,
              onError,
              onProgress
            },
            uploadUrl
          )
          onUploadSucces?.(res)
        }}
        fileList={fileList}
        beforeUpload={async (file) => {
          return (await beforeUpload(file)) as any
        }}
        onChange={(info) => {
          if (info?.file?.status === 'error') {
            message.error(`${info.file.name} 上传失败`)
          }
          setFileList(info.fileList)
          const uploading = info.fileList?.filter((f) => f.status === 'uploading').length
          if (!uploading) {
            setFileList([])
            onClose()
          }
        }}
      >
        {content}
      </Dragger>
    </Modal>
  )
}
export const DecorList: FC = () => {
  const editable = useContext(resourceEditable)
  const [uploadType, setUploadType] = useState<'decor' | 'video'>()
  const add = useContext(addLayer)
  return (
    <div
      style={{
        overflow: 'hidden',
        height: '100%'
      }}
    >
      <UploadModal uploadType={uploadType} onClose={() => setUploadType(undefined)} />
      <div
        className="section-title"
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignContent: 'center'
        }}
      >
        {!!add && '图片选择'}
        {!add && (
          <div style={{ display: 'inline' }}>
            图片<span style={{ color: 'rgb(109, 109, 109)', fontSize: 12, marginLeft: 12 }}>可在配置直播间时使用</span>
          </div>
        )}
        {editable && (
          <Button type="primary" onClick={() => setUploadType('decor')}>
            上传
          </Button>
        )}
      </div>
      <Decors />
      <div
        className="section-title"
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignContent: 'center',
          marginTop: '20px'
        }}
      >
        {!!add && '视频选择'}
        {!add && (
          <div style={{ display: 'inline' }}>
            视频<span style={{ color: 'rgb(109, 109, 109)', fontSize: 12, marginLeft: 12 }}>可在配置直播间时使用</span>
          </div>
        )}
        {editable && (
          <Button type="primary" onClick={() => setUploadType('video')}>
            上传
          </Button>
        )}
      </div>
      <Videos />
    </div>
  )
}

export const SelectionBadge: FC<{ style?: CSSProperties }> = ({ style }) => (
  <Check
    style={{
      position: 'absolute',
      top: -1,
      left: -1,
      borderBottomRightRadius: 8,
      color: 'black',
      boxSizing: 'border-box',
      padding: 2,
      width: 20,
      height: 17,
      backgroundColor: '#00FFF0',
      ...style
    }}
  />
)
