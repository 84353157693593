import { createContext, useContext, useMemo } from 'react'
import { BehaviorSubject } from 'rxjs'
import * as uuid from 'uuid'
import { useGet } from '../api'
import { roomId } from '../global-vars'
import { useProducts, useUserPackage } from '../plan/states'
import { scriptTemplateTypes, scriptTypes } from '../script-and-layer/script-card'
import { ScriptSegment, SelectedRange } from '../script-and-layer/states'
import { tuple } from '../types'
import { useUserInfo } from '../user-center/states'

// 房间
export type Room = {
  id: string
  name: string
  description: string
  created_at: string
}

export type SectionType = {
  title: string
  key: string
  segments: ScriptSegment[]
  w2l_video_frame_key?: string
  intro?: {
    frame_key: string
    prev_overlap_msec?: number
    current_overlap_msec?: number
  }
}

// 房间配置，包括素材/剧本/声音
export type RoomSetting = {
  frames: {
    key: string
    x: number
    y: number
    w: number
    h: number
    w2l_video?: { video_id: string; user_repo_key?: string; disable_alpha_channel?: boolean | undefined }
    image?: { key: string }
    loop_video?: { key: string; duration_sec: number; muted: boolean }
    range?: {
      key: string
      line_start: number
      line_end: number
    }
    intro_video?: {
      key: string
      alpha_key: string
      prev_overlap_msec: number
      current_overlap_msec: number
    }
  }[]
  script?: {
    script_template_type: (typeof scriptTemplateTypes)[number]
    script_type: (typeof scriptTypes)[number]
    segments: ScriptSegment[]
    sections?: SectionType[]
    multiple_w2l_videos?: boolean
  }
  markup?: {
    style: string
    pitch: string
    rate: string
    volume: string
  }
  voice_name: string
  ratio: number // 分辨率
}

export const reactionSection = ['enter', 'follow', 'gift', 'like'] as const
export type PatternReply = {
  key: string
  pattern: string
  cool_down_duration_seconds?: number
  replies: { key: string; text: string; voice_key?: string }[]
  conditions?: { key: string; text: string }[]
}

export type AutoGreeting = {
  enabled: boolean
  cooldown_duration_seconds: number
  contents: {
    key: string
    text: string
    voice_key?: string
  }[]
}

export type ProductExplanationCfg = {
  enabled: boolean
  duration_seconds: number
  delay_seconds: number
}

export type CommentCfg = {
  enabled: boolean
  duration_seconds: number
  interact_text: string[]
}

// 互动配置
export type ReactionSetting = Record<(typeof reactionSection)[number], { key: string; text: string }[]> & {
  pattern_reply: PatternReply[]
  auto_greeting: AutoGreeting
  comment_cfg: CommentCfg
  product_explanation_cfg: ProductExplanationCfg
  enable_time_telling: boolean
  time_telling_duration_seconds: number
  enable_keyword_reply_blackboard: boolean
  pause_probability_percent: number
  pause_seconds_range_start: number
  pause_seconds_range_end: number
  enable_comment_driven_mk: boolean
  comment_driven_mK_cooldown_seconds: number
  enable_comment_reply_by_llm: boolean
  comment_reply_by_llm_cooldown_seconds: number
}

// 房间配置中的一个素材
export type Layer = {
  key: string
  image_key: string
  x: number
  y: number
  w: number
  h: number
  sx: number
  sy: number
  range?: SelectedRange
} & (
  | { type: 'image' }
  | { type: 'w2l_video'; video_id: string; user_repo_key?: string; disable_alpha_channel?: boolean | undefined }
  | { type: 'multiple_w2l_video'; user_repo_key: string; disable_alpha_channel?: boolean | undefined }
  | {
      type: 'intro_video'
      intro_video: { key: string; alpha_key: string; prev_overlap_msec: number; current_overlap_msec: number }
    }
  | { type: 'video'; muted: boolean; video_key: string }
)

// 素材
export type Resource = {
  id: string
  user_id: string
  tag: string
  type: string
  key: string
  deletable: boolean
  name?: string
}

// 主播形象
export type W2lVideo = {
  id: string
  name: string
  video_id: string
  avatar: string
  preview: string
  free: boolean
  min_level: number
  available: boolean
  phones: string
  no_alpha: boolean
  in_processing: boolean
  extra?: {
    Data?: {
      pipeline_name: string
      product_ids: number[]
    }
  }
}

export type W2lVideosGroup = {
  id: string
  avatar: string // 设置形象
  name: string
  group_id: string
  defaultAvatar: string // 默认形象
  writable: string
  List: W2lVideo[]
}

export const empty = {} as const

export function useReactionSetting() {
  return useGet<{ version?: string; reaction?: ReactionSetting }>(`/rooms/${useContext(roomId)}/reaction`, {
    suspense: true,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  }).data
}

export function useW2lVideos(): {
  groups: W2lVideosGroup[]
  videos: W2lVideo[]
} {
  const products = useProducts()?.products
  const product = useUserPackage()
  const videosGroup = useGet<{ videos: W2lVideosGroup[] }>('/synthesizable_videos').data?.videos
  return useMemo(() => {
    const available_ids = products?.find((p) => p.id === product.product_id)?.permission?.available_video_ids
    const levelAscProd = products?.sort((l, r) => (l.level || 0) - (r.level || 0))
    const list: W2lVideo[] = []
    const groups = (videosGroup || [])
      .filter((v) => !!v.List?.length)
      .map((group) => {
        return {
          ...group,
          id: uuid.v4(),
          defaultAvatar: `wl/${group.List?.[0].video_id}/avatar.png`,
          List: (group.List || [])
            .map((v) => ({
              ...v,
              preview: v.preview || `wl/${v.video_id}/preview.${v.no_alpha ? 'jpg' : 'png'}`,
              avatar: v.avatar || `wl/${v.video_id}/avatar.png`,
              available: v.free || available_ids?.includes(v.id) || false,
              min_level: v.free
                ? 0
                : levelAscProd?.find((p) => p.permission?.available_video_ids?.includes(v.id))?.level || 0
            }))
            .map((v, i) => tuple(i, v))
            .sort((l, r) => {
              if (!!l[1].min_level !== !!r[1].min_level) {
                return !!l[1].min_level ? -1 : 1
              }
              if (!l[1].min_level) {
                return l[0] - r[0]
              }
              return l[1].min_level - r[1].min_level
            })
            .map(([, v]) => {
              list.push(v)
              return v
            })
        }
      })
    return {
      groups,
      videos: list
    }
  }, [product, products, videosGroup])
}

export function useResources(): Resource[] {
  const userInfo = useUserInfo()
  const resources = useGet<{ resources: Resource[] }>(`/resources`, { suspense: true }).data?.resources
  return useMemo(
    () =>
      resources?.map((r) => ({
        ...r,
        deletable: userInfo?.user_id === r.user_id
      })) || [],
    [userInfo, resources]
  )
}

export function useRoomSetting(sync: true): { version: string; setting: RoomSetting }
export function useRoomSetting(): { version: string; setting: RoomSetting } | undefined
export function useRoomSetting(sync?: boolean): { version: string; setting: RoomSetting } | undefined {
  const data = useGet<{ version: string; setting: RoomSetting }>(`/rooms/${useContext(roomId)}/setting`, {
    suspense: sync,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  }).data

  return useMemo(() => {
    if (data && data.setting && data.setting.script) {
      return {
        ...data,
        setting: {
          ...data?.setting,
          script: {
            ...data.setting.script,
            segments: (data.setting.script.sections || [])?.reduce((pre, next) => {
              return pre.concat(
                (next.segments || []).map((s) => {
                  return {
                    ...s,
                    groupInfo: {
                      key: next.key,
                      title: next.title
                    }
                  }
                })
              )
            }, [] as any[])
          }
        }
      }
    } else {
      return data
    }
  }, [data])
}

export function useRooms(): Room[] {
  return useGet<Room[]>('/rooms', { suspense: true }).data || []
}

export type Session = {
  id?: string
  room_id?: string
  video_url?: string
  flv_video_url?: string
  created_at?: string
  updated_at?: string
  server_time?: string
  description?: {
    script_id: string
    Data?: {
      room_id?: string
      room_type: string
      flv_play_url?: string
      synthesize_started_at?: string
    }
  }
}

export const emptySession: Session = {}

export function useSession(): Session {
  const session =
    useGet<{ session: Session }>('/session', {
      suspense: true,
      refetchInterval: 30000
    }).data?.session || emptySession
  return useMemo(
    () =>
      !session.video_url || session.flv_video_url
        ? session
        : {
            ...session,
            flv_video_url: toFlvUrl(session.description?.Data?.flv_play_url || '')
          },
    [session]
  )
}

function toFlvUrl(rtmp: string): string {
  if (!rtmp.includes('https')) {
    return rtmp.replace('http', 'https')
  }
  return rtmp
}

// 是否正在保存
export const saving = createContext<BehaviorSubject<boolean>>(undefined as any)

// 工作台 tab
export const roomEditorPanel = createContext('video' as 'video' | 'background' | 'decors' | 'script')

// 剧本编辑 tab
export const scriptTab = createContext<
  BehaviorSubject<'script' | 'resource' | 'script_type' | 'resource_change' | 'multiple_videos' | 'intro_video'>
>(undefined as any)

type VoiceList = {
  list: {
    description: string
    name: string
  }[]
}

// 主播声音
export type Voice = {
  id: string
  name: string
  local_name: string
  preview_text: string
  gender: string
  description: string
  free: boolean
  phones: string
  style_and_role_list?: {
    Data: VoiceList
  }
  markup_list?: {
    Data: {
      pitch: VoiceList
      rate: VoiceList
      volume: VoiceList
    }
  }
  user_markup?: {
    Style: string
    Pitch: string
    Rate: string
    Volume: string
  }
}

export function useVoices(): Voice[] {
  return useGet<{ voices: Voice[] }>('/voices', { suspense: true }).data?.voices || []
}

// 支持的声音。仅 admin 可见。
export type AvailableVoice = {
  name: string
  local_name: string
  locale: string
}
export const emptyArray = []

export function useAvailableVoices(): AvailableVoice[] {
  return useGet<{ voices: AvailableVoice[] }>('/available_voices', { suspense: true }).data?.voices || emptyArray
}

export function useAvailableW2LVideoIds(): string[] {
  const mp4Paths = useGet<string[]>('/available_synthesizable_videos', { suspense: true }).data
  //去重
  return useMemo(() => Array.from(new Set(mp4Paths?.map((s) => s.split('/')[2])?.filter(Boolean) || [])), [mp4Paths])
}
