import './index.scss'
import { Button, message, Modal } from 'antd'
import { FC, memo, useContext, useEffect, useState } from 'react'
import Recorder from 'recorder-core/recorder.wav.min'
import { useAPI } from '../../../api'
import { resourceUrl } from '../../../global-vars'
import { ReactComponent as Warning } from '../../../res/warning.svg'

interface IProps {
  showTips?: boolean
  visible: boolean
  onCancel: () => void
  onOk?: (resource: any) => void
}

const RecordingModal: FC<IProps> = (props) => {
  const { visible, showTips, onOk } = props
  const [status, setStatus] = useState(0)
  const [audioFile, setAudioFile] = useState<any>()
  const [recorder, setRecorder] = useState<typeof Recorder>()
  const [source, setSource] = useState<any>()
  const api = useAPI()
  const urlSource = useContext(resourceUrl)

  useEffect(() => {
    return () => {
      recorder?.close()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (visible) {
      setStatus(0)
      setAudioFile(undefined)
    }
  }, [visible])

  useEffect(() => {
    if (audioFile) {
      uploadRec()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioFile])

  const onCancel = () => {
    setStatus(0)
    setSource(undefined)
    recorder?.close()
    props.onCancel()
  }

  const startRec = async () => {
    try {
      const recorder = new Recorder({
        type: 'wav',
        sampleBits: 16,
        sampleRate: 16000,
        numChannels: 1,
        compiling: true
      })
      await new Promise(recorder.open.bind(recorder))
      recorder.start()
      setStatus(1)
      setRecorder(recorder)
    } catch (e: any) {
      message.error(e)
    }
  }

  const stopRec = async () => {
    const wavBlob: any = await new Promise(recorder.stop.bind(recorder))
    const duration = recorder.envInLast - recorder.envInFirst
    const file: any = new File([wavBlob], 'audio.wav', { type: 'audio/wav' })
    file.duration = duration
    setAudioFile(file)
    setStatus(2)
    await new Promise(recorder.close.bind(recorder))
    setRecorder(undefined)
  }

  const uploadRec = async () => {
    try {
      const { resource, upload_url } =
        (
          await api.post(
            '/upload',
            {
              extension: 'wav'
            },
            {
              refreshMode: 'disabled'
            } as any
          )
        ).data || {}
      if (!upload_url) {
        throw new Error('failed to upload file')
      }
      await api.put(upload_url.replace(/^http:\/\//, 'https://').replace('-internal', ''), audioFile, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      setSource(resource)
      setStatus(3)
    } catch (err: any) {
      message.error(err)
    }
  }

  const useNewRecording = () => {
    if (audioFile?.duration > 90 * 60 * 1000) {
      message.warning('单次录音长度不能大于 90 分钟')
      return
    }
    setStatus(0)
    onOk?.(source)
  }

  return (
    <Modal
      title="录制音频"
      open={!!visible}
      onCancel={onCancel}
      footer={
        status === 3 ? (
          <>
            <Button onClick={onCancel}>取消</Button>
            <Button type="primary" onClick={useNewRecording}>
              确定使用新音频
            </Button>
          </>
        ) : null
      }
      bodyStyle={{ paddingTop: 20, textAlign: 'center' }}
      className="recording-modal"
    >
      {showTips && (
        <div className="recording-modal-tips">
          <Warning />
          <h4>原文本将会被覆盖</h4>
          <p className="text">
            您当前剧本中已有话术内容，如果选择录制新音频，我们会将您新上传的音频自动识别成文本，您剧本中的原文本会被覆盖
          </p>
        </div>
      )}
      <div className="recording-modal-content">
        {status === 0 && (
          <>
            <div className="img-status">
              <img src={require('../../../res/recording-start.png')} alt="" onClick={startRec} />
            </div>
            <div>开始录制</div>
          </>
        )}
        {status === 1 && (
          <>
            <div className="img-status">
              <img src={require('../../../res/recording-stop.png')} alt="" onClick={stopRec} />
            </div>
            <div>停止录制</div>
          </>
        )}
        {status === 2 && (
          <>
            <div className="img-status">
              <img src={require('../../../res/recording-upload.png')} alt="" />
            </div>
            <div>正在完成录制...</div>
          </>
        )}
        {status === 3 && (
          <>
            <audio src={urlSource(source?.key)} controls />
          </>
        )}
      </div>
    </Modal>
  )
}

export default memo(RecordingModal)
