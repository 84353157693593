import { Button, Checkbox, Input, notification, Tabs } from 'antd'
import {
  createContext,
  FC,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { Link } from 'react-router-dom'
import { BehaviorSubject, map, take, timer } from 'rxjs'
import { useAuthAPI } from '../api'
import { RetryOnError } from '../errors'
import { Providing } from '../global-vars'
import { useBehaviorSubject } from '../react-rx'
import { ReactComponent as Bang } from '../res/bang.svg'
import { ReactComponent as Logo } from '../res/logo-large.svg'
import { ReactComponent as Spinner } from '../res/spin.svg'
import { currentUser, userState } from './states'

export const Auth: FC<PropsWithChildren<{}>> = ({ children }) => {
  return useContext(currentUser)?.token ? <RetryOnError>{children}</RetryOnError> : <Login />
}

const agreementChecked = createContext<BehaviorSubject<boolean>>(undefined as any)
const phone = createContext<BehaviorSubject<string>>(undefined as any)
const Login: FC = () => {
  const agreementCheckedSubj = useMemo(() => new BehaviorSubject(false), [])
  const phoneSubj = useMemo(() => new BehaviorSubject(''), [])
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: 400,
          justifyContent: 'center',
          height: '100%',
          alignItems: 'center'
        }}
      >
        <Logo style={{ marginBottom: 20 }} />
        <div className="section-title" style={{ marginBottom: 20, fontSize: 22, fontWeight: 'bolder' }}>
          飞影 AI 直播
        </div>
        <div className="weak-info" style={{ marginBottom: 60 }}>
          AI驱动超写实仿真数字人, 实现7*24小时自动化直播
        </div>
        <Providing _={(_) => _(agreementChecked, agreementCheckedSubj)(phone, phoneSubj)}>
          <Tabs
            style={{ width: '100%' }}
            centered
            items={[
              { label: '手机号登录', key: 'mobile', children: <MobileLogin /> },
              { label: '密码登录', key: 'password', children: <PasswordLogin /> }
            ]}
          />
        </Providing>
      </div>
      <div
        className="license"
        style={{ fontSize: 13, width: '100%', textAlign: 'center', justifyContent: 'center', marginTop: 200 }}
      >
        <div>沪ICP备2022030268号</div>
        <div>Copyright © 2023 上海灵之宇技术有限公司</div>
      </div>
    </div>
  )
}

const MobileLogin: FC = () => {
  const [mobile, setMobile] = useBehaviorSubject(useContext(phone))
  const [agreed, setAgreed] = useBehaviorSubject(useContext(agreementChecked))
  const [mobileError, setMobileError] = useState(false)
  const [mobileHint, setMobileHint] = useState<ReactElement | null>()
  const [captcha, setCaptcha] = useState('')
  const [captchaError, setCaptchaError] = useState(false)
  const [captchaHint, setCaptchaHint] = useState<ReactElement | null>()
  const [captchaButtonDisableMessage, setCaptchaButtonDisableMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const userSubj = useContext(userState)
  const authApi = useAuthAPI()
  useEffect(() => {
    if (!mobile.length || mobile.length === 11) {
      setMobileHint(null)
    } else if (mobile.length < 11) {
      setMobileHint(<span style={{ color: '#6D6D6D', marginLeft: 4 }}>请输入 11 位手机号</span>)
    } else {
      setMobileHint(<span style={{ color: '#EF446D', marginLeft: 4 }}>手机号位数错误</span>)
    }
    setMobileError(mobile.length > 11)
  }, [mobile])
  useEffect(() => {
    if (!captcha.length || captcha.length === 6) {
      setCaptchaHint(null)
    } else if (captcha.length < 6) {
      setCaptchaHint(<span style={{ color: '#6D6D6D', marginLeft: 4 }}>请输入 6 位验证码</span>)
    } else {
      setCaptchaHint(<span style={{ color: '#EF446D', marginLeft: 4 }}>验证码位数错误</span>)
    }
    setCaptchaError(captcha.length > 6)
  }, [captcha])
  const doLogin = useCallback(async () => {
    setLoading(true)
    try {
      const user = (
        await authApi.post('/api/v1/meta/login', {
          phone: mobile,
          code: captcha,
          type: 'CODE'
        })
      ).data
      if (!user.token) {
        throw new Error('登录失败')
      }
      userSubj.next(user)
    } catch (e: any) {
      notification.error(e)
    } finally {
      setLoading(false)
    }
  }, [setLoading, authApi, userSubj, mobile, captcha])
  return (
    <>
      <div style={{ position: 'relative', display: 'flex', flexDirection: 'row', alignItems: 'end' }}>
        <Input
          className="input-with-hint"
          style={{ width: 0, flex: 1 }}
          value={mobile}
          maxLength={20}
          onChange={(e) => setMobile(e.target.value.replaceAll(/\D/g, ''))}
          placeholder="请输入 11 位手机号"
          status={mobileError ? 'error' : undefined}
          suffix={<Bang style={{ visibility: mobileError ? 'visible' : 'hidden' }} />}
        />
        <Button
          className="borderless"
          style={{ height: 42, marginLeft: 8, border: 'unset' }}
          onClick={async () => {
            timer(0, 1000)
              .pipe(take(60))
              .pipe(map((_, index) => `已发送 ${60 - index}S`))
              .subscribe({
                complete: () => setCaptchaButtonDisableMessage(''),
                next: setCaptchaButtonDisableMessage
              })
            try {
              await authApi.post('/api/v1/meta/send_sms', { phone: mobile, code_type: 'REORLOGINCODE', system: 'live' })
            } catch (e: any) {
              notification.error(e)
            }
          }}
          disabled={!!captchaButtonDisableMessage || mobile.length !== 11}
        >
          {captchaButtonDisableMessage || '获取验证码'}
        </Button>
        <div className="input-hint">手机号{mobileHint}</div>
      </div>
      <div style={{ position: 'relative', marginTop: 20 }}>
        <Input
          className="input-with-hint"
          value={captcha}
          maxLength={20}
          onChange={(e) => setCaptcha(e.target.value.replaceAll(/\D/g, ''))}
          status={captchaError ? 'error' : undefined}
          placeholder="请输入 6 位验证码"
          onPressEnter={() => {
            if (mobile.length === 11 && captcha.length === 6 && agreed) {
              doLogin()
            }
          }}
          suffix={<Bang style={{ visibility: captchaError ? 'visible' : 'hidden' }} />}
        />
        <div className="input-hint">验证码{captchaHint}</div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 21 }}>
        <Checkbox checked={agreed} onChange={(e) => setAgreed(e.target.checked)} style={{ color: '#B4B4B4' }}>
          我已阅读并同意
          <a href="/eula.html" target="_blank">
            《用户协议》
          </a>
          与
          <a href="/privacy_agreement.html" target="_blank">
            《隐私条款》
          </a>
        </Checkbox>
      </div>

      <Button
        className="borderless"
        id="mobile-login-button"
        loading={loading}
        style={{ width: '100%', height: 48, marginTop: 20 }}
        disabled={mobile.length !== 11 || captcha.length !== 6 || !agreed}
        onClick={doLogin}
      >
        {loading ? (
          <Spinner className="spin" />
        ) : (
          <>
            <div style={{ fontWeight: 'bold' }}>登录</div>
            <div style={{ fontSize: 10 }}>(新手机号将自动完成注册)</div>
          </>
        )}
      </Button>
    </>
  )
}

const PasswordLogin: FC = () => {
  const [mobile, setMobile] = useBehaviorSubject(useContext(phone))
  const [agreed, setAgreed] = useBehaviorSubject(useContext(agreementChecked))
  const [mobileError, setMobileError] = useState(false)
  const [mobileHint, setMobileHint] = useState<ReactElement | null>()
  const [password, setPassword] = useState('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [passwordError, setPasswordError] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [setPasswordHint, setSetPasswordHint] = useState<ReactElement | null>()
  const [loading, setLoading] = useState(false)
  const userSubj = useContext(userState)
  const authApi = useAuthAPI()
  useEffect(() => {
    if (!mobile.length || mobile.length === 11) {
      setMobileHint(null)
    } else if (mobile.length < 11) {
      setMobileHint(<span style={{ color: '#6D6D6D', marginLeft: 4 }}>请输入 11 位手机号</span>)
    } else {
      setMobileHint(<span style={{ color: '#EF446D', marginLeft: 4 }}>手机号位数错误</span>)
    }
    setMobileError(mobile.length > 11)
  }, [mobile])
  const doLogin = useCallback(async () => {
    setLoading(true)
    try {
      const user = (
        await authApi.post('/api/v1/meta/login', {
          phone: mobile,
          password,
          type: 'PASSWORD'
        })
      ).data
      if (!user.token) {
        throw new Error('登录失败')
      }
      userSubj.next(user)
    } catch (e: any) {
      notification.error(e)
    } finally {
      setLoading(false)
    }
  }, [setLoading, authApi, mobile, password, userSubj])
  return (
    <>
      <div style={{ position: 'relative', display: 'flex', flexDirection: 'row', alignItems: 'end' }}>
        <Input
          className="input-with-hint"
          style={{ width: 0, flex: 1 }}
          value={mobile}
          maxLength={20}
          onChange={(e) => setMobile(e.target.value.replaceAll(/\D/g, ''))}
          placeholder="请输入 11 位手机号"
          status={mobileError ? 'error' : undefined}
          suffix={<Bang style={{ visibility: mobileError ? 'visible' : 'hidden' }} />}
        />
        <div className="input-hint">手机号{mobileHint}</div>
      </div>
      <div style={{ position: 'relative', marginTop: 20 }}>
        <Input.Password
          className="input-with-hint"
          value={password}
          autoComplete="new-password"
          visibilityToggle={{ visible: false }}
          iconRender={() => (
            <>
              <Link to="/reset_password">忘记密码</Link>
              {passwordError && <Bang />}
            </>
          )}
          onChange={(e) => setPassword(e.target.value)}
          status={passwordError ? 'error' : undefined}
          placeholder="请输入密码"
          onPressEnter={() => {
            if (agreed && mobile.length === 11 && password.length) {
              doLogin()
            }
          }}
        />
        <div className="input-hint">密码{setPasswordHint}</div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 21 }}>
        <Checkbox checked={agreed} onChange={(e) => setAgreed(e.target.checked)} style={{ color: '#B4B4B4' }}>
          我已阅读并同意
          <a href="/eula.html" target="_blank">
            《用户协议》
          </a>
          与
          <a href="/privacy_agreement.html" target="_blank">
            《隐私条款》
          </a>
        </Checkbox>
      </div>

      <Button
        className="borderless"
        id="mobile-login-button"
        loading={loading}
        style={{ width: '100%', height: 48, marginTop: 20 }}
        disabled={mobile.length !== 11 || !password.length || !agreed}
        onClick={doLogin}
      >
        {loading ? <Spinner className="spin" /> : '登录'}
      </Button>
    </>
  )
}
