import { Modal } from 'antd'
import {
  $createParagraphNode,
  $createRangeSelection,
  $createTextNode,
  $getNodeByKey,
  $getRoot,
  $getSelection,
  $isParagraphNode,
  $setSelection,
  BLUR_COMMAND,
  COMMAND_PRIORITY_LOW,
  DecoratorNode,
  EditorConfig,
  FOCUS_COMMAND,
  LexicalEditor,
  ParagraphNode,
  RootNode,
  TextNode
} from 'lexical'
import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import {
  BehaviorSubject,
  combineLatestWith,
  distinctUntilChanged,
  filter,
  map,
  mergeWith,
  Observable,
  startWith,
  Subject
} from 'rxjs'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { SensitiveRule, useSensitiveCheckRulesConfig } from '../components/lexical/states'
import { mouseDown, Providing } from '../global-vars'
import { useBehaviorSubject, useObservable } from '../react-rx'
import { ReactComponent as Bang } from '../res/bang.svg'
import { ReactComponent as HyperlinkCircle } from '../res/hyperlink-circle.svg'
import TrashGreen from '../res/trash-green.svg'
import TrashRed from '../res/trash-red.svg'
import { reselect } from '../room/room-editor'
import { scriptTab } from '../room/states'
import { tuple } from '../types'
import { mouseInTextEditor, randomLineIndex, segments, segmentsChange, segmentsIndex } from './script-card'
import { layers, segmentKey, SelectedRange } from './states'

const ParagraphSelection: FC = () => {
  const selectionState = useMemo(() => new BehaviorSubject<{ start: number; end: number } | undefined>(undefined), [])
  return (
    <LexicalComposer
      initialConfig={{
        namespace: 'paragraph-selection',
        onError(error, editor) {
          console.error('editor error', error)
        },
        nodes: [ParagraphSelectionNode],
        editorState: () => $getRoot().append($createParagraphNode().append($createTextNode('hello')))
      }}
    >
      <Providing _={(_) => _(rangeSelection, selectionState)}>
        <RichTextPlugin
          contentEditable={<ContentEditable style={{ color: 'white', outline: 'none' }} />}
          placeholder={<></>}
          ErrorBoundary={LexicalErrorBoundary}
        />
      </Providing>
      <HistoryPlugin />
      <ParagraphSelectionDotPlugin />
      <OnChangePlugin onChange={(state) => selectionState.next(state.read($selectedParagraphRange))} />

      <OnFocusChangePlugin
        onChange={(focused) => {
          if (!focused) selectionState.next(undefined)
        }}
      />
    </LexicalComposer>
  )
}

export function $paragraphContent(): string[] {
  return $getRoot()
    .getChildren()
    .map((_) => _.getTextContent())
}

export function $selectedParagraphRange(): { start: number; end: number } | undefined {
  const selection = $getSelection()
  const paragraphs = selection?.getNodes()?.filter($isParagraphNode) || []
  const indices = $getRoot()
    .getChildren<ParagraphNode>()
    .map((n, index) => tuple(n, index))
    .filter(
      ([n]) => n.isSelected(selection) || n.getChildren().some((c) => c.isSelected(selection)) || paragraphs.includes(n)
    )
    .map(([, index]) => index)

  // 解决双击选中一行时富文本获取选择区域错误问题，用浏览器getSelection进行兼容
  let selectionLength = 0
  if (document.getSelection()?.toString()) {
    selectionLength =
      document
        .getSelection()
        ?.toString()
        ?.split(/\r?\n\n/)?.length || 0
  }

  if (!indices?.length) return

  return { start: indices[0], end: selectionLength ? indices[0] + selectionLength : indices[indices.length - 1] + 1 }
}

export function $paragraphCount(): number {
  return $getRoot().getChildrenSize()
}

export const SelectParagraphPlugin: FC<{ selection: Observable<{ start: number; end: number } | undefined> }> = ({
  selection
}) => {
  const [editor] = useLexicalComposerContext()
  const tabSubj = useContext(scriptTab)
  const reselectSubj = useContext(reselect)
  const focused = useMemo(() => new BehaviorSubject(false), [])
  const mouseDownSubj = useContext(mouseDown)
  const clickSubj = useMemo(() => new Subject<number>(), [])
  const mouseInTextEditorSubj = useContext(mouseInTextEditor)

  useEffect(() => {
    const subscription = selection
      .pipe(
        mergeWith(
          focused.pipe(
            filter(() => mouseInTextEditorSubj.value),
            filter((_) => tabSubj.value === 'script'),
            filter((_) => !_),
            map(() => undefined)
          )
        ),
        filter((range) => !rangeEq(range, editor.getEditorState().read($selectedParagraphRange))),
        combineLatestWith(tabSubj, reselectSubj.pipe(startWith(undefined))),
        map(([_]) => _),
        mergeWith(clickSubj.pipe(map((i) => ({ start: i, end: i + 1 }))))
      )
      .subscribe((range) => {
        if (!range) {
          editor.update(() => $setSelection(null))
          return
        }
        editor.focus(() => {
          editor.update(() => {
            console.log('selecting', range)
            const paragraphs = $getRoot().getChildren<ParagraphNode>()
            if (!paragraphs?.length) return
            let start = Math.max(0, Math.min(range.start, paragraphs.length))
            let end = Math.max(1, Math.min(range.end, paragraphs.length))
            start = Math.min(start, end - 1)
            const firstParagraph = paragraphs[start]
            const lastParagraph = paragraphs[end - 1]
            let firstNode = firstParagraph.getAllTextNodes()?.[0]
            if (!firstNode) {
              firstNode = $createTextNode()
              firstParagraph.append(firstNode)
            }
            let lastNode = last(lastParagraph.getAllTextNodes())
            if (!lastNode) {
              lastNode = $createTextNode()
              lastParagraph.append(lastNode)
            }
            const selection = $createRangeSelection()
            selection.setTextNodeRange(firstNode, 0, lastNode, lastNode.getTextContentSize())
            $setSelection(selection)
          })
        })
      })
    return () => subscription.unsubscribe()
  }, [editor, selection, focused, mouseDownSubj, clickSubj, tabSubj, reselectSubj, mouseInTextEditorSubj])
  useEffect(
    () =>
      editor.registerCommand(
        BLUR_COMMAND,
        () => {
          console.log('blur')
          focused.next(false)
          return false
        },
        COMMAND_PRIORITY_LOW
      ),
    [editor, focused]
  )

  useEffect(
    () =>
      editor.registerCommand(
        FOCUS_COMMAND,
        () => {
          console.log('focus')
          focused.next(true)
          return false
        },
        COMMAND_PRIORITY_LOW
      ),
    [editor, focused]
  )

  return null

  function last<T>(arr: T[] | undefined): T | undefined {
    if (!arr?.length) return
    return arr[arr.length - 1]
  }

  function rangeEq(
    l: { start: number; end: number } | undefined,
    r: { start: number; end: number } | undefined
  ): boolean {
    if (l === r) return true
    if (!l || !r) return false
    return l.start === r.start && l.end === r.end
  }
}

export const rangeSelection = createContext<BehaviorSubject<{ start: number; end: number } | undefined>>(
  undefined as any
)

export function rangeEquals(l: SelectedRange | undefined, r: SelectedRange | undefined): boolean {
  if (l === r) return true
  if (!l || !r) return false
  if (l.key !== r.key) return false
  if (l.range === r.range) return true
  if (!l.range || !r.range) return false
  return l.range.start === r.range.start && l.range.end === r.range.end
}

export function rangeIntersects(l: SelectedRange | undefined, r: SelectedRange | undefined): boolean {
  if (l === r) return true
  if (!r) return true
  if (!l) return false
  if (l.key !== r.key) return false
  if (l.range === r.range) return true
  if (!r.range) return true
  if (!l.range) return false
  return l.range.start < r.range.end && r.range.start < l.range.end
}

export function $installParagraphSelectionNodes() {
  $getRoot()
    .getChildren<ParagraphNode>()
    ?.forEach((p, index) => {
      const first = p.getFirstChild()
      if ($isParagraphSelectionNode(first)) {
        first.setIndex(index)
        if (!first.getNextSibling()) {
          first.insertAfter($createTextNode(' '))
        }
        return
      }
      if (first) first.insertBefore($createParagraphSelectionNode(index))
      else p.append($createParagraphSelectionNode(index)).append($createTextNode(' '))
    })
}

export function $resetParagraphSelectionNodes() {
  $getRoot()
    .getChildren<ParagraphNode>()
    ?.forEach((p, index) => {
      const first = p.getFirstChild()
      if ($isParagraphSelectionNode(first)) {
        first.getWritable().__index = index
        if (!first.getNextSibling()) {
          first.insertAfter($createTextNode(' '))
        }
        return
      }
      if (first) first.insertBefore($createParagraphSelectionNode(index))
      else p.append($createParagraphSelectionNode(index)).append($createTextNode(' '))
    })
}

export const ParagraphLinkPlugin: FC<{ className: string }> = ({ className }) => {
  const [editor] = useLexicalComposerContext()
  const key = useContext(segmentKey)
  const [ls] = useBehaviorSubject(useContext(layers))
  const relevantLayers = useMemo(() => ls.filter((l) => l.range?.key === key), [ls, key])
  const segmentsSubj = useContext(segments)
  const [s] = useBehaviorSubject(segmentsSubj)
  useEffect(() => {
    const root = editor?.getRootElement()
    if (!root) return
    for (let i = 0; i < root.childElementCount; i++) {
      const child = root.children[i]
      if (relevantLayers.some((l) => rangeIncludes(i, l.range?.range))) {
        child.className = addClassName(child.className, className)
      } else {
        child.className = removeClassName(child.className, className)
      }
    }
  }, [relevantLayers, editor, className, s])
  return null
}

export const ParagraphNewLinePlugin: FC = () => {
  const [editor] = useLexicalComposerContext()

  useEffect(
    () =>
      editor.registerNodeTransform(ParagraphNode, (paragraphNode) => {
        const text = paragraphNode.getTextContent()
        if (text?.split(/\r?\n/)?.length > 1) {
          paragraphNode.clear()
          text.split(/\r?\n/).forEach((p) => {
            paragraphNode.insertBefore($createParagraphNode().append($createTextNode(p)))
          })
          paragraphNode.remove()
        }
      }),
    [editor]
  )

  useEffect(
    () =>
      editor.registerNodeTransform(ParagraphNode, (paragraphNode) => {
        if (paragraphNode.getAllTextNodes()?.some((t) => /[\n\r].+$/.test(t.getTextContent()))) {
          const text = paragraphNode.getTextContent()
          text.split(/\r?\n/).forEach((p, i, arr) => {
            if (i === arr.length - 1) {
              paragraphNode.getAllTextNodes()[0]?.setTextContent(p)
            } else {
              paragraphNode.insertBefore($createParagraphNode().append($createTextNode(p)))
            }
          })
        }
      }),
    [editor]
  )
  return null
}

export class RandomLineNode extends TextNode {
  _list: any[]
  _index: number
  _sIndex: number
  _setRi: any
  _setSIndex: any
  _remoteRules: SensitiveRule[]

  constructor(
    _list: any[],
    index: number,
    sIndex: number,
    setRi: any,
    setSIndex: any,
    onDelRandomLine: () => void,
    _remoteRules: SensitiveRule[]
  ) {
    super('')
    this._list = _list
    this._index = index
    this._sIndex = sIndex
    this.__setRi = setRi
    this._setSIndex = setSIndex
    this._onDelRandomLine = onDelRandomLine
    this._remoteRules = _remoteRules
  }

  isUnmergeable(): boolean {
    return true
  }

  static getType() {
    return 'randomLineNode'
  }

  exportJSON() {
    return {
      ...super.exportJSON(),
      version: 1
    }
  }

  createDOM(config: EditorConfig): HTMLElement {
    const texts = this._list.reduce((pre, next) => {
      return `${pre}${next.text || ''}`
    }, '')
    const hasSensitiveWord = this._remoteRules?.some((rule) => {
      return rule.text
        .trim()
        .split(/[,，、|]/)
        .some((text) => {
          return texts.includes(text)
        })
    })
    console.log('hasSensitiveWord', hasSensitiveWord)
    const html = `<button style="display: flex; align-items: center" type="button" class="ant-btn ${
      hasSensitiveWord ? 'ant-btn-dangerous' : 'ant-btn-primary'
    }"><label style="padding-right: 10px">随机话术 ${this._list.length}</label><img class="icon-del" src="${
      hasSensitiveWord ? TrashRed : TrashGreen
    }"/></button>`
    const root = document.createElement('div')
    root.className = 'line-random'
    root.innerHTML = html
    root.querySelector('.ant-btn')?.addEventListener('click', () => {
      this.__setRi(this._index)
      this._setSIndex(this._sIndex)
    })

    root.querySelector('.ant-btn .icon-del')?.addEventListener('click', (e) => {
      e.stopPropagation()
      Modal.confirm({
        bodyStyle: { padding: '20px 40px 0 40px' },
        className: 'base-modal',
        content: (
          <div style={{ display: 'flex', alignItems: 'center', color: '#fff' }}>
            <Bang />
            <div style={{ marginLeft: 8 }}>确认删除随机话术？</div>
          </div>
        ),
        icon: null,
        closable: true,
        okText: '确认删除',
        cancelText: '取消',
        maskClosable: true,
        onOk: () => {
          this._onDelRandomLine()
        }
      })
    })
    return root
  }
}

export const RandomLinePlugin: FC<any> = (props) => {
  const { list } = props
  const [editor] = useLexicalComposerContext()
  const segmentsSubj = useContext(segments)
  const [s] = useBehaviorSubject(segmentsSubj)
  const segmentsChangeSubj = useContext(segmentsChange)
  const key = useContext(segmentKey)
  const index = useMemo(() => s.findIndex((s) => s.key === key), [s, key])
  const time = useRef<any>()
  const [, setRi] = useBehaviorSubject(useContext(randomLineIndex))
  const [, setSIndex] = useBehaviorSubject(useContext(segmentsIndex))
  const text = s[index].line_contents?.reduce((pre, next) => {
    return (
      pre +
      next.alternatives?.reduce((cpre, cnext) => {
        return cpre + cnext.text
      }, '')
    )
  }, '')
  const remoteRules = useSensitiveCheckRulesConfig()

  const onDelRandomLine = (index: number) => {
    const ss = segmentsSubj.value.map((s) => {
      if (s.key !== key) {
        return s
      } else {
        return {
          ...s,
          line_contents: s.line_contents?.map((l, i) => {
            return i === index
              ? {
                  ...l,
                  alternatives: []
                }
              : l
          })
        }
      }
    })
    segmentsChangeSubj.next(ss)
  }

  useEffect(() => {
    if (!time.current || Date.now() - time.current > 200) {
      editor.update(
        () => {
          console.log('update')
          const roots = $getRoot().getChildren()
          roots.forEach((t, i) => {
            const altLen = list[i]?.alternatives?.length
            const child = t.getChildren()
            child.forEach((q: any) => {
              q?.getType() === 'randomLineNode' && q?.remove()
            })
            if (altLen > 0) {
              t.append(
                $createRandomLineNode(
                  list[i]?.alternatives,
                  i,
                  index,
                  setRi,
                  setSIndex,
                  onDelRandomLine.bind(this, i),
                  remoteRules
                )
              )
            }
          })
        },
        { tag: 'skip-scroll-into-view' }
      )
      time.current = Date.now()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text])
  return null
}

function $createRandomLineNode(
  alternatives: any[],
  index: number,
  sIndex: number,
  setRi: any,
  setSIndex: any,
  onDelRandomLine: () => void,
  remoteRules: SensitiveRule[]
) {
  return new RandomLineNode(alternatives, index, sIndex, setRi, setSIndex, onDelRandomLine, remoteRules)
}

function addClassName(from: string, className: string): string {
  const c = from.split(' ')
  if (!c.includes(className)) c.push(className)
  return c.join(' ')
}

function removeClassName(from: string, className: string): string {
  return from
    .split(' ')
    .filter((c) => c !== className)
    .join(' ')
}

export const ParagraphSelectionDotPlugin: FC = () => {
  const [editor] = useLexicalComposerContext()
  useEffect(() => {
    editor.update($installParagraphSelectionNodes)
  }, [editor])
  useEffect(
    () =>
      [
        editor.registerNodeTransform(RootNode, $installParagraphSelectionNodes),
        editor.registerNodeTransform(ParagraphSelectionNode, (node) => {
          if (node.getPreviousSibling()) node.remove()
        })
      ].reduce(
        (l, r) => () => {
          l()
          r()
        },
        () => void 0
      ),
    [editor]
  )
  return null
}

export class ParagraphSelectionNode extends DecoratorNode<ReactNode> {
  __index: number

  constructor(index: number) {
    super()
    this.__index = index
  }

  static getType(): string {
    return 'paragraph-selection'
  }

  static clone(node: ParagraphSelectionNode): ParagraphSelectionNode {
    return new ParagraphSelectionNode(node.__index)
  }

  isKeyboardSelectable(): boolean {
    return true
  }

  isInline(): boolean {
    return true
  }

  createDOM(config: EditorConfig): HTMLElement {
    const div = document.createElement('div')
    Object.assign(div.style, {
      display: 'contents',
      '-webkit-user-modify': 'read-write',
      '-moz-user-modify': 'read-write',
      'user-modify': 'read-write'
    })
    return div
  }

  setIndex(index: number) {
    if (this.__index === index) return
    this.getWritable().__index = index
  }

  updateDOM(): false {
    return false
  }

  exportJSON(): any {
    return {
      type: ParagraphSelectionNode.getType(),
      paragraph_selection_index: this.__index
    }
  }

  static importJSON(serializedNode: any): ParagraphSelectionNode {
    return new ParagraphSelectionNode(serializedNode.paragraph_selection_index)
  }

  decorate(editor: LexicalEditor): ReactNode {
    const paragraph = this.getParents()?.find($isParagraphNode)
    if (!paragraph) return null
    return <SelectionDot editor={editor} key={this.getKey()} nodeKey={this.getKey()} />
  }
}

export function $createParagraphSelectionNode(index: number) {
  return new ParagraphSelectionNode(index)
}

export function $isParagraphSelectionNode(node: any): node is ParagraphSelectionNode {
  return node instanceof ParagraphSelectionNode
}

const SelectionDot: FC<{ editor: LexicalEditor; nodeKey: string }> = ({ editor, nodeKey }) => {
  const index = useCallback(
    () =>
      editor
        .getEditorState()
        .read(() => $getNodeByKey(nodeKey)?.getParents()?.find($isParagraphNode)?.getIndexWithinParent() || 0),
    [editor, nodeKey]
  )
  const rangeSubj = useContext(rangeSelection)
  const layersSubj = useContext(layers)
  const key = useContext(segmentKey)
  const sel = useMemo(
    () =>
      rangeSubj.pipe(
        map((r) => rangeIncludes(index(), r)),
        distinctUntilChanged()
      ),
    [rangeSubj, index]
  )
  const [selected] = useObservable(sel, () => rangeIncludes(index(), rangeSubj.value), [index])
  const linkedObservable = useMemo(
    () =>
      layersSubj.pipe(
        map((ls) => Boolean(ls?.some((l) => key === l?.range?.key && rangeIncludes(index(), l?.range?.range))))
      ),
    [layersSubj, key, index]
  )
  const [linked] = useObservable(
    linkedObservable,
    () => layersSubj.value?.some((l) => key === l?.range?.key && rangeIncludes(index(), l?.range?.range)),
    [key]
  )
  return (
    <HyperlinkCircle
      className={linked ? 'strong' : 'weak'}
      style={
        {
          float: 'right',
          width: 20,
          height: 20,
          borderRadius: 10,
          display: 'inline-block',
          backgroundColor: selected ? '#D4FF0010' : 'transparent'
        } as any
      }
    />
  )
}

export function rangeIncludes(index: number, range: { start: number; end: number } | undefined): boolean {
  return !!range && range.start <= index && range.end > index
}

export const OnFocusChangePlugin: FC<{ onChange(focused: boolean, editor: LexicalEditor): void }> = ({ onChange }) => {
  const [editor] = useLexicalComposerContext()
  const [focused, setFocus] = useState(false)
  useEffect(
    () =>
      editor.registerCommand(
        BLUR_COMMAND,
        () => {
          setFocus(false)
          return false
        },
        COMMAND_PRIORITY_LOW
      ),
    [editor]
  )

  useEffect(
    () =>
      editor.registerCommand(
        FOCUS_COMMAND,
        () => {
          setFocus(true)
          return false
        },
        COMMAND_PRIORITY_LOW
      ),
    [editor]
  )
  useEffect(() => onChange(focused, editor), [focused, editor, onChange])
  return null
}

export default ParagraphSelection
