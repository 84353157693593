import { Affix, Button, Input, message, Popover, Switch, Tabs } from 'antd'
import { $createParagraphNode, $createTextNode, $getRoot, EditorConfig, LexicalNode, TextNode } from 'lexical'
import { createContext, FC, MutableRefObject, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { BehaviorSubject, concatMap, debounce, interval, retry, skip } from 'rxjs'
import { fromPromise } from 'rxjs/internal/observable/innerFrom'
import * as uuid from 'uuid'
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin'
import { useAPI } from '../api'
import { usePermission } from '../auth/states'
import { SensitiveWordsNode, SensitiveWordsPlugin } from '../components/lexical/sensitive-words-plugin'
import { Providing, roomId, wsContext } from '../global-vars'
import { NumericInput } from '../numeric-input'
import { useBehaviorSubject } from '../react-rx'
import minusCircle from '../res/minus-circle.svg'
import { ReactComponent as Play } from '../res/play.svg'
import { ReactComponent as Plus } from '../res/plus.svg'
import { ReactComponent as Trash } from '../res/trash.svg'
import RecordingRowModal from '../script-and-layer/components/voice-upload/recording-row-modal'
import { flatten, Render, runCatching } from '../types'
import { reactionSection, ReactionSetting, useReactionSetting, useSession } from './states'

export const reactionSetting = createContext<BehaviorSubject<ReactionSetting>>(undefined as any)

const ReactionEditor: FC = () => {
  const reaction = useReactionSetting()?.reaction
  const subj = useMemo(() => new BehaviorSubject(reaction || ({} as ReactionSetting)), [reaction])
  const api = useAPI()
  const rid = useContext(roomId)
  const destroyed = useMemo(() => new BehaviorSubject(false), [])
  useEffect(() => () => destroyed.next(true), [destroyed])
  useEffect(() => {
    const subscription = subj
      .pipe(
        skip(1),
        debounce(() => interval(3000)),
        concatMap((r) => fromPromise(api.post(`/rooms/${rid}/reaction`, r))),
        retry({ delay: (_, index) => interval(Math.pow(2, index) * 1000) })
      )
      .subscribe()
    return destroyed.value ? () => setTimeout(() => subscription.unsubscribe(), 3000) : () => subscription.unsubscribe()
  }, [subj, api, rid, destroyed])
  return (
    <Providing
      _={(p) => {
        p(reactionSetting, subj)
      }}
    >
      <div style={{ padding: 24, height: '100%' }}>
        <Tabs
          centered
          items={[
            { label: '规则互动', key: 'rule', children: <ReactionRuleEditor /> },
            { label: '弹幕互动', key: 'danmaku', children: <div style={{ color: 'white' }}>还没有</div> }
          ]}
        />
      </div>
    </Providing>
  )
}

const ReactionSegment: FC<{ section: (typeof reactionSection)[number]; defaultText: () => string }> = ({
  section,
  defaultText
}) => {
  const [reactions, setReactionSetting] = useBehaviorSubject(useContext(reactionSetting))
  const items = useMemo(() => reactions[section] || [], [reactions, section])
  return (
    <>
      <div
        onClick={() =>
          setReactionSetting({
            ...reactions,
            [section]: [...items, { key: uuid.v4(), text: defaultText() }]
          })
        }
        className="sub-section-title"
      >
        回复话术 <Plus className="small-square-button" />
      </div>
      <>
        {items.map((r) => (
          <ReactionRow
            key={r.key}
            onDelete={() => setReactionSetting({ ...reactions, [section]: items.filter(({ key }) => key !== r.key) })}
            onChange={(s) => {
              r.text = s
              setReactionSetting({ ...reactions })
            }}
            initialValue={r.text}
          />
        ))}
      </>
    </>
  )
}
export const ReactionRuleEditor: FC = () => {
  const separator = useMemo(() => <div style={{ height: 1, width: '100%', color: 'rgba(67, 67, 67, 1)' }} />, [])
  const reactionRef: MutableRefObject<any> = useRef(null)
  const [reactions, setReactionSetting] = useBehaviorSubject(useContext(reactionSetting))
  const permission = usePermission()
  const { auto_greeting, product_explanation_cfg, comment_cfg } = reactions

  return (
    <div
      ref={reactionRef}
      style={{
        padding: 24,
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
        backgroundColor: 'rgb(40, 40, 44)',
        transform: 'translateZ(0)',
        boxSizing: 'border-box'
      }}
    >
      <div className="section-title">直播玩法</div>
      <div style={{ marginBottom: 24, padding: 20, backgroundColor: 'rgb(28, 29, 30)', borderRadius: 4 }}>
        <div className="section-title" style={{ display: 'flex', alignItems: 'center' }}>
          <label style={{ marginRight: 6 }}>主动报时</label>
          <Popover
            className="popver-content"
            content={
              <div>
                <div>数字人主播会不定时播报当前时</div>
                <div>间，并问候直播间观众</div>
              </div>
            }
          >
            <img className="icon-help-circle" src={require('../res/icon-help-circle.png')} alt="" />
          </Popover>
          <div style={{ color: reactions.enable_time_telling ? '#fff' : 'rgba(255, 255, 255, 0.5)' }}>
            <Switch
              checked={!!reactions.enable_time_telling}
              className="card-switch"
              style={{ margin: '0 24px 0 16px' }}
              onChange={(checked) => {
                setReactionSetting({
                  ...reactions,
                  enable_time_telling: checked,
                  time_telling_duration_seconds: checked ? 180 : 0
                })
              }}
            />
            <label style={{ fontWeight: 'normal', fontSize: '15px' }}>触发间隔</label>
            <Input
              className="input-with-hint small"
              disabled={!reactions.enable_time_telling}
              value={reactions.enable_time_telling ? reactions.time_telling_duration_seconds : 0}
              onBlur={() => {
                setReactionSetting({
                  ...reactions,
                  time_telling_duration_seconds: Math.max(Number(reactions.time_telling_duration_seconds), 1)
                })
              }}
              onChange={(e) => {
                const { value: inputValue } = e.target
                const reg = /^-?\d*(\.\d*)?$/
                if (reg.test(inputValue)) {
                  setReactionSetting({
                    ...reactions,
                    time_telling_duration_seconds: inputValue as any
                  })
                }
              }}
              suffix="秒"
              maxLength={6}
            />
          </div>
        </div>
        <div className="section-title" style={{ display: 'flex', alignItems: 'center', marginBottom: 24 }}>
          <label style={{ marginRight: 6 }}>句末停顿时间</label>
          <Popover
            className="popver-content"
            content={
              <div>
                <div>数字人主播会在一整句话结束后停顿，停顿时间</div>
                <div>可以自行控制，并且可以控制触发概率</div>
              </div>
            }
          >
            <img className="icon-help-circle" src={require('../res/icon-help-circle.png')} alt="" />
          </Popover>
          <div style={{ color: reactions.pause_probability_percent ? '#fff' : 'rgba(255, 255, 255, 0.5)' }}>
            <Switch
              checked={!!reactions.pause_probability_percent}
              className="card-switch"
              style={{ margin: '0 24px 0 16px' }}
              onChange={(checked) => {
                setReactionSetting({
                  ...reactions,
                  pause_probability_percent: checked ? 20 : 0,
                  pause_seconds_range_start: 1,
                  pause_seconds_range_end: 3
                })
              }}
            />
            <label style={{ fontWeight: 'normal', fontSize: '15px' }}>停顿时间</label>
            <Input
              className="input-with-hint small"
              disabled={!reactions.pause_probability_percent}
              value={reactions.pause_seconds_range_start || 0}
              onChange={(e) => {
                const { value: inputValue } = e.target
                const reg = /^-?\d*(\.\d*)?$/
                if (reg.test(inputValue)) {
                  setReactionSetting({
                    ...reactions,
                    pause_seconds_range_start: Number(inputValue)
                  })
                }
              }}
              suffix="秒"
              maxLength={2}
            />
            <label style={{ fontWeight: 'normal', fontSize: '15px' }}>~</label>
            <Input
              className="input-with-hint small"
              disabled={!reactions.pause_probability_percent}
              value={reactions.pause_seconds_range_end || 0}
              onChange={(e) => {
                const { value: inputValue } = e.target
                const reg = /^-?\d*(\.\d*)?$/
                if (reg.test(inputValue)) {
                  setReactionSetting({
                    ...reactions,
                    pause_seconds_range_end: Number(inputValue)
                  })
                }
              }}
              suffix="秒"
              maxLength={2}
            />
            <label style={{ fontWeight: 'normal', fontSize: '15px', marginLeft: 30 }}>触发概率</label>
            <Input
              className="input-with-hint small"
              disabled={!reactions.pause_probability_percent}
              value={reactions.pause_probability_percent || 0}
              onChange={(e) => {
                const { value: inputValue } = e.target
                const reg = /^-?\d*(\.\d*)?$/
                if (reg.test(inputValue)) {
                  setReactionSetting({
                    ...reactions,
                    pause_probability_percent: Number(inputValue)
                  })
                }
              }}
              suffix="%"
              maxLength={3}
            />
          </div>
        </div>

        {permission.admin && (
          <div className="section-title" style={{ display: 'flex', alignItems: 'center', marginBottom: 24 }}>
            <label style={{ marginRight: 6 }}>弹幕控制MK</label>

            <div style={{ color: reactions.enable_comment_driven_mk ? '#fff' : 'rgba(255, 255, 255, 0.5)' }}>
              <Switch
                checked={!!reactions.enable_comment_driven_mk}
                className="card-switch"
                style={{ margin: '0 24px 0 16px' }}
                onChange={(checked) => {
                  setReactionSetting({
                    ...reactions,
                    enable_comment_driven_mk: checked,
                    comment_driven_mK_cooldown_seconds: checked ? 10 : 0
                  })
                }}
              />
              <label style={{ fontWeight: 'normal', fontSize: '15px' }}>间隔时间</label>
              <Input
                className="input-with-hint small"
                disabled={!reactions.enable_comment_driven_mk}
                value={reactions.comment_driven_mK_cooldown_seconds || 0}
                onChange={(e) => {
                  const { value: inputValue } = e.target
                  const reg = /^-?\d*(\.\d*)?$/
                  if (reg.test(inputValue)) {
                    setReactionSetting({
                      ...reactions,
                      comment_driven_mK_cooldown_seconds: Number(inputValue)
                    })
                  }
                }}
                suffix="秒"
                maxLength={2}
              />
            </div>
          </div>
        )}

        {permission.admin && (
          <div className="section-title" style={{ display: 'flex', alignItems: 'center', marginBottom: 24 }}>
            <label style={{ marginRight: 6 }}>大模型回复弹幕</label>

            <div style={{ color: reactions.enable_comment_reply_by_llm ? '#fff' : 'rgba(255, 255, 255, 0.5)' }}>
              <Switch
                checked={!!reactions.enable_comment_reply_by_llm}
                className="card-switch"
                style={{ margin: '0 24px 0 16px' }}
                onChange={(checked) => {
                  setReactionSetting({
                    ...reactions,
                    enable_comment_reply_by_llm: checked,
                    comment_reply_by_llm_cooldown_seconds: checked ? 10 : 0
                  })
                }}
              />
              <label style={{ fontWeight: 'normal', fontSize: '15px' }}>间隔时间</label>
              <Input
                className="input-with-hint small"
                disabled={!reactions.enable_comment_reply_by_llm}
                value={reactions.comment_reply_by_llm_cooldown_seconds || 0}
                onChange={(e) => {
                  const { value: inputValue } = e.target
                  const reg = /^-?\d*(\.\d*)?$/
                  if (reg.test(inputValue)) {
                    setReactionSetting({
                      ...reactions,
                      comment_reply_by_llm_cooldown_seconds: Number(inputValue)
                    })
                  }
                }}
                suffix="秒"
                maxLength={2}
              />
            </div>
          </div>
        )}

        <div className="section-title" style={{ display: 'flex', alignItems: 'center' }}>
          <label style={{ marginRight: 6 }}>商品讲解</label>
          <Popover
            className="popver-content"
            content={
              <div>
                <div>
                  商品自动讲解, 详见
                  <a
                    target="_blank"
                    href="https://lingverse.feishu.cn/docx/ImOkdaSDloajorxgcqKc2S0nnPb"
                    rel="noreferrer"
                  >
                    操作文档
                  </a>
                </div>
              </div>
            }
          >
            <img className="icon-help-circle" src={require('../res/icon-help-circle.png')} alt="" />
          </Popover>
          <div style={{ color: product_explanation_cfg?.enabled ? '#fff' : 'rgba(255, 255, 255, 0.5)' }}>
            <Switch
              checked={!!product_explanation_cfg?.enabled}
              className="card-switch"
              style={{ margin: '0 24px 0 16px' }}
              onChange={(checked) => {
                setReactionSetting({
                  ...reactions,
                  product_explanation_cfg: {
                    ...product_explanation_cfg,
                    enabled: checked,
                    duration_seconds: checked ? 8 : 0,
                    delay_seconds: checked ? 4 : 0
                  }
                })
              }}
            />
            <label style={{ fontWeight: 'normal', fontSize: '15px' }}>间隔时间</label>
            <Input
              className="input-with-hint small"
              disabled={!product_explanation_cfg?.enabled}
              value={product_explanation_cfg?.enabled ? product_explanation_cfg?.duration_seconds : 0}
              onBlur={() => {
                setReactionSetting({
                  ...reactions,
                  product_explanation_cfg: {
                    ...product_explanation_cfg,
                    duration_seconds: Math.max(Number(product_explanation_cfg.duration_seconds), 1)
                  }
                })
              }}
              onChange={(e) => {
                const { value: inputValue } = e.target
                const reg = /^-?\d*(\.\d*)?$/
                if (reg.test(inputValue)) {
                  setReactionSetting({
                    ...reactions,
                    product_explanation_cfg: {
                      ...product_explanation_cfg,
                      duration_seconds: inputValue as any
                    }
                  })
                }
              }}
              suffix="秒"
              maxLength={6}
            />
            <label style={{ fontWeight: 'normal', fontSize: '15px', marginLeft: 30 }}>端到端延迟</label>
            <Input
              className="input-with-hint small"
              disabled={!product_explanation_cfg?.enabled}
              value={product_explanation_cfg?.enabled ? product_explanation_cfg?.delay_seconds : 0}
              onBlur={() => {
                setReactionSetting({
                  ...reactions,
                  product_explanation_cfg: {
                    ...product_explanation_cfg,
                    delay_seconds: Math.max(Number(product_explanation_cfg.delay_seconds), 1)
                  }
                })
              }}
              onChange={(e) => {
                const { value: inputValue } = e.target
                const reg = /^-?\d*(\.\d*)?$/
                if (reg.test(inputValue)) {
                  setReactionSetting({
                    ...reactions,
                    product_explanation_cfg: {
                      ...product_explanation_cfg,
                      delay_seconds: inputValue as any
                    }
                  })
                }
              }}
              suffix="秒"
              maxLength={6}
            />
          </div>
        </div>

        <div className="section-title" style={{ display: 'flex', alignItems: 'center' }}>
          <label style={{ marginRight: 6 }}>主动引导</label>
          <Popover
            className="popver-content"
            content={
              <div>
                <div>数字人每过一段时间随机说出自定义话术</div>
              </div>
            }
          >
            <img className="icon-help-circle" src={require('../res/icon-help-circle.png')} alt="" />
          </Popover>
          <div style={{ color: auto_greeting?.enabled ? '#fff' : 'rgba(255, 255, 255, 0.5)' }}>
            <Switch
              checked={!!auto_greeting?.enabled}
              className="card-switch"
              style={{ margin: '0 24px 0 16px' }}
              onChange={(checked) => {
                setReactionSetting({
                  ...reactions,
                  auto_greeting: {
                    ...auto_greeting,
                    enabled: checked,
                    cooldown_duration_seconds: checked ? 180 : 0
                  }
                })
              }}
            />
            <label style={{ fontWeight: 'normal', fontSize: '15px' }}>触发间隔</label>
            <Input
              className="input-with-hint small"
              disabled={!auto_greeting?.enabled}
              value={auto_greeting?.enabled ? auto_greeting?.cooldown_duration_seconds : 0}
              onBlur={() => {
                setReactionSetting({
                  ...reactions,
                  auto_greeting: {
                    ...auto_greeting,
                    cooldown_duration_seconds: Math.max(Number(auto_greeting.cooldown_duration_seconds), 1)
                  }
                })
              }}
              onChange={(e) => {
                const { value: inputValue } = e.target
                const reg = /^-?\d*(\.\d*)?$/
                if (reg.test(inputValue)) {
                  setReactionSetting({
                    ...reactions,
                    auto_greeting: {
                      ...auto_greeting,
                      cooldown_duration_seconds: inputValue as any
                    }
                  })
                }
              }}
              suffix="秒"
              maxLength={6}
            />
          </div>
        </div>
        {!!auto_greeting?.enabled && (
          <div style={{ color: '#fff', marginBottom: 20 }}>
            <div
              onClick={() =>
                setReactionSetting({
                  ...reactions,
                  auto_greeting: {
                    ...auto_greeting,
                    contents: [
                      ...(auto_greeting.contents || []),
                      {
                        text: '',
                        key: uuid.v4()
                      }
                    ]
                  }
                })
              }
              style={{ cursor: 'pointer' }}
            >
              自定义话术 <Plus className="small-square-button" />
            </div>
            {auto_greeting?.contents?.map((c, index) => (
              <ReactionRow
                placeholder=""
                key={index}
                onDelete={() => {
                  setReactionSetting({
                    ...reactions,
                    auto_greeting: {
                      ...auto_greeting,
                      contents: auto_greeting.contents.filter(({ key }) => key !== c.key)
                    }
                  })
                }}
                onChange={(s) => {
                  c.text = s
                  setReactionSetting({ ...reactions })
                }}
                reply={c}
                initialValue={c.text}
                onVoiceChange={(s: any) => {
                  c.voice_key = s.voice_key
                  setReactionSetting({ ...reactions })
                }}
              />
            ))}
          </div>
        )}
        <div className="section-title" style={{ display: 'flex', alignItems: 'center' }}>
          <label style={{ marginRight: 6 }}>评论区互动</label>
          <Popover
            className="popver-content"
            content={
              <div>
                <div>定时通过百应后台随机发送弹幕</div>
              </div>
            }
          >
            <img className="icon-help-circle" src={require('../res/icon-help-circle.png')} alt="" />
          </Popover>
          <div style={{ color: comment_cfg?.enabled ? '#fff' : 'rgba(255, 255, 255, 0.5)' }}>
            <Switch
              checked={!!comment_cfg?.enabled}
              className="card-switch"
              style={{ margin: '0 24px 0 16px' }}
              onChange={(checked) => {
                setReactionSetting({
                  ...reactions,
                  comment_cfg: {
                    ...comment_cfg,
                    enabled: checked,
                    duration_seconds: checked ? 8 : 0
                  }
                })
              }}
            />
            <label style={{ fontWeight: 'normal', fontSize: '15px' }}>触发间隔</label>
            <Input
              className="input-with-hint small"
              disabled={!comment_cfg?.enabled}
              value={comment_cfg?.enabled ? comment_cfg?.duration_seconds : 0}
              onBlur={() => {
                setReactionSetting({
                  ...reactions,
                  comment_cfg: {
                    ...comment_cfg,
                    duration_seconds: Math.max(Number(comment_cfg.duration_seconds), 1)
                  }
                })
              }}
              onChange={(e) => {
                const { value: inputValue } = e.target
                const reg = /^-?\d*(\.\d*)?$/
                if (reg.test(inputValue)) {
                  setReactionSetting({
                    ...reactions,
                    comment_cfg: {
                      ...comment_cfg,
                      duration_seconds: inputValue as any
                    }
                  })
                }
              }}
              suffix="秒"
              maxLength={6}
            />
          </div>
        </div>
        {!!comment_cfg?.enabled && (
          <div style={{ color: '#fff', marginBottom: 20 }}>
            <div
              onClick={() =>
                setReactionSetting({
                  ...reactions,
                  comment_cfg: {
                    ...comment_cfg,
                    interact_text: [...(comment_cfg.interact_text || []), '']
                  }
                })
              }
              style={{ cursor: 'pointer' }}
            >
              弹幕内容 <Plus className="small-square-button" />
              <span style={{ marginLeft: '20px', color: '#999' }}>每条弹幕最多50字</span>
            </div>
            {comment_cfg?.interact_text?.map((c, index) => (
              <ReactionRow
                placeholder=""
                key={index}
                onDelete={() => {
                  setReactionSetting({
                    ...reactions,
                    comment_cfg: {
                      ...comment_cfg,
                      interact_text: comment_cfg.interact_text.filter((_, i: number) => i !== index)
                    }
                  })
                }}
                onChange={(s) => {
                  reactions.comment_cfg.interact_text[index] = s
                  setReactionSetting({ ...reactions })
                }}
                initialValue={c}
                maxLength={50}
              />
            ))}
          </div>
        )}
      </div>
      <div className="section-title">观众互动</div>
      <div style={{ marginBottom: 24, padding: 20, backgroundColor: 'rgb(28, 29, 30)', borderRadius: 4 }}>
        <div className="section-title" style={{ display: 'flex', alignItems: 'center' }}>
          <label>观众进入直播间</label>
        </div>
        {separator}
        <ReactionSegment section="enter" defaultText={() => '欢迎[观众昵称]进入直播间'} />
      </div>
      <div style={{ marginBottom: 24, padding: 20, backgroundColor: 'rgb(28, 29, 30)', borderRadius: 4 }}>
        <div className="section-title" style={{ display: 'flex', alignItems: 'center' }}>
          <label>观众关注</label>
        </div>
        {separator}
        <ReactionSegment section="follow" defaultText={() => '欢迎[观众昵称]关注直播间'} />
      </div>
      <div style={{ marginBottom: 24, padding: 20, backgroundColor: 'rgb(28, 29, 30)', borderRadius: 4 }}>
        <div className="section-title" style={{ display: 'flex', alignItems: 'center' }}>
          <label>观众点赞</label>
        </div>
        {separator}
        <ReactionSegment section="like" defaultText={() => '欢迎[观众昵称]的点赞'} />
      </div>
      <div style={{ marginBottom: 24, padding: 20, backgroundColor: 'rgb(28, 29, 30)', borderRadius: 4 }}>
        <div className="section-title" style={{ display: 'flex', alignItems: 'center' }}>
          <label>观众送礼</label>
        </div>
        {separator}
        <ReactionSegment section="gift" defaultText={() => '欢迎[观众昵称]的[礼物数量]个[礼物名称]'} />
      </div>
      <PatternReplies reactionRef={reactionRef} />
    </div>
  )
}

const MatchVerification: FC = () => {
  const [openMatch, setOpenMatch] = useState(false)
  const [text, setText] = useState('')
  const [hitRule, setHitRule] = useState('')
  const [reactions] = useBehaviorSubject(useContext(reactionSetting))

  useEffect(() => {
    if (!openMatch) {
      setText('')
    }
  }, [openMatch])

  useEffect(() => {
    setHitRule('')
    reactions.pattern_reply?.forEach((rule) => {
      if (rule.pattern && text.indexOf(rule.pattern) > -1) {
        setHitRule('匹配:' + rule.pattern)
        return
      }
      rule.conditions?.forEach((cond) => {
        if (!cond?.text) return
        if (cond.text.startsWith('^') && cond.text.endsWith('$')) {
          if (new RegExp(cond.text).test(text)) {
            setHitRule('匹配:' + rule.pattern)
            return
          }
        } else if (cond.text.indexOf('|') > -1) {
          cond.text.split('|').forEach((word) => {
            if (text.indexOf(word) > -1) {
              setHitRule('匹配:' + rule.pattern)
              return
            }
          })
        } else if (cond.text && text.indexOf(cond.text) > -1) {
          setHitRule('匹配:' + rule.pattern)
          return
        }
      })
    })
  }, [text, reactions.pattern_reply])

  return (
    <>
      <Popover
        placement="topRight"
        content={
          <div>
            <input
              style={{ width: '200px' }}
              placeholder="输入测试文本"
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
            <button style={{ marginLeft: '20px' }} onClick={() => setOpenMatch(false)}>
              关闭
            </button>
            <br />
            <span style={{ color: hitRule ? 'green' : 'red', display: text ? 'inline' : 'none' }}>
              {hitRule || '不匹配'}
            </span>
          </div>
        }
        trigger="click"
        open={openMatch}
        onOpenChange={(newOpen) => setOpenMatch(newOpen)}
      >
        <Button style={{ marginLeft: '15px' }}>测试一下</Button>
      </Popover>
    </>
  )
}

const PatternReplies: FC<{ reactionRef: MutableRefObject<any> }> = ({ reactionRef }) => {
  const [reactions, setReactions] = useBehaviorSubject(useContext(reactionSetting))
  return (
    <>
      <div className="section-title" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <label>观众问答</label>
        <Affix offsetTop={-60} target={() => reactionRef.current}>
          <div>
            <div
              style={{
                display: 'inline-flex',
                alignItems: 'center',
                position: 'relative',
                top: 9,
                marginRight: 36,
                fontSize: 14,
                fontWeight: 'normal'
              }}
            >
              <Popover
                className="popver-content"
                content={
                  <div>
                    <div>回复小白板，打开后可以在右侧画面编辑区</div>
                    <div>调整其位置，当有用户提问时，小白板会回</div>
                    <div>复用户，其他时间不会出现</div>
                  </div>
                }
              >
                <img className="icon-help-circle" src={require('../res/icon-help-circle.png')} alt="" />
              </Popover>
              <Switch
                checked={reactions.enable_keyword_reply_blackboard}
                className="card-switch"
                style={{ margin: '0 8px' }}
                onChange={(checked) => {
                  setReactions({
                    ...reactions,
                    enable_keyword_reply_blackboard: checked
                  })
                }}
              />
              <label>问答回复小白板</label>
            </div>
            <Button
              type="primary"
              onClick={() => {
                setReactions({
                  ...reactions,
                  pattern_reply: [
                    ...(reactions?.pattern_reply || []),
                    {
                      key: uuid.v4(),
                      pattern: '这里填写问题',
                      replies: [],
                      conditions: []
                    }
                  ]
                })
              }}
            >
              添加一条问答
            </Button>
            <MatchVerification />
          </div>
        </Affix>
      </div>
      {(reactions.pattern_reply || []).map((p) => (
        <PatternReplyCard id={p.key} key={p.key} />
      ))}
    </>
  )
}

const PatternReplyCard: FC<{ id: string }> = ({ id }) => {
  const reactionSubj = useContext(reactionSetting)
  const [reactions, setReactionSetting] = useBehaviorSubject(reactionSubj)
  const pattern = useMemo(() => reactions?.pattern_reply?.find((p) => p.key === id), [reactions, id])
  const [error, setError] = useState('')
  const [collapsed, setCollapsed] = useState(false)
  const [regex, setRegex] = useBehaviorSubject(useMemo(() => new BehaviorSubject(pattern?.pattern || ''), [pattern]))
  const ws = useContext(wsContext)
  const globalSession = useSession()
  const rid = useContext(roomId)
  const session = useMemo(
    () => (!globalSession?.room_id || globalSession.room_id === rid ? globalSession : undefined),
    [globalSession, rid]
  )
  const [playDisabled, setPlayDisabled] = useState(false)

  useEffect(() => {
    const r = flatten(runCatching(() => new RegExp(regex)))
    if (r.error) {
      setError(r.error.message)
    } else {
      setError('')
      reactionSubj.next({
        ...reactionSubj.value,
        pattern_reply: reactionSubj.value.pattern_reply?.map((r) =>
          r.key === id
            ? {
                ...r,
                pattern: regex
              }
            : r
        )
      })
    }
  }, [setError, regex, reactionSubj, id])
  return (
    <div style={{ marginBottom: 24, padding: 20, backgroundColor: 'rgb(28, 29, 30)', borderRadius: 4 }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          fontWeight: 'bold',
          color: 'white',
          alignItems: 'center'
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>
          {collapsed ? (
            <CaretUpOutlined onClick={() => setCollapsed(false)} />
          ) : (
            <CaretDownOutlined onClick={() => setCollapsed(true)} />
          )}
          <div style={{ display: 'flex', width: '100%', marginLeft: 12 }}>
            <Input className="input-with-hint" value={regex} onChange={(e) => setRegex(e.target.value)} />
            <div className="input-hint" style={{ color: 'red', backgroundColor: 'rgb(28, 29, 30)' }}>
              {error}
            </div>
          </div>
          <div style={{ position: 'relative', right: 0, width: 200, marginRight: 12, marginLeft: 12 }}>
            <NumericInput
              className="input-with-hint"
              value={pattern?.cool_down_duration_seconds || 0}
              onChange={(cool_down_duration_seconds) =>
                reactionSubj.next({
                  ...reactionSubj.value,
                  pattern_reply: reactionSubj.value.pattern_reply?.map((r) =>
                    r.key === id
                      ? {
                          ...r,
                          cool_down_duration_seconds
                        }
                      : r
                  )
                })
              }
            />
            <div className="input-hint" style={{ backgroundColor: 'rgb(28, 29, 30)' }}>
              触发间隔(秒)
            </div>
          </div>

          <Button
            size="small"
            icon={<Play width={16} style={{ marginRight: 4 }} />}
            style={{
              display: 'flex',
              alignItems: 'center',
              height: 28,
              margin: '0 20px 0 12px',
              fontSize: 13
            }}
            disabled={!session?.room_id || playDisabled}
            onClick={() => {
              setPlayDisabled(true)
              message.success('即将播放回复话术')
              setTimeout(() => {
                setPlayDisabled(false)
              }, 1000)
              ws &&
                ws((_) =>
                  _.send(
                    JSON.stringify({
                      type: 'play_reaction',
                      pattern_reply_key: id
                    })
                  )
                )
            }}
          >
            立即播放
          </Button>

          <Trash
            title="删除这条规则"
            style={{ cursor: 'pointer' }}
            width={32}
            onClick={() => {
              setReactionSetting({ ...reactions, pattern_reply: reactions?.pattern_reply?.filter((p) => p.key !== id) })
            }}
          />
        </div>
      </div>

      {collapsed ? (
        ''
      ) : (
        <div
          onClick={() =>
            setReactionSetting({
              ...reactions,
              pattern_reply: reactions?.pattern_reply?.map((p) =>
                p.key === id
                  ? {
                      ...p,
                      conditions: [...(p.conditions || []), { key: uuid.v4(), text: '' }]
                    }
                  : p
              )
            })
          }
          className="sub-section-title"
        >
          触发条件 <Plus className="small-square-button" />
          <span style={{ marginLeft: '20px', color: '#999' }}>
            可以填写多个关键词(以|分隔)，或正则表达式(以^开头且以$结尾)
          </span>
        </div>
      )}
      {collapsed ? (
        ''
      ) : (
        <>
          {(pattern?.conditions || []).map((r) => (
            <ReactionRow
              key={r.key}
              placeholder=""
              onDelete={() =>
                setReactionSetting({
                  ...reactions,
                  pattern_reply: reactions.pattern_reply?.map((p) =>
                    p.key === id
                      ? {
                          ...p,
                          conditions: p.conditions?.filter((rr) => rr.key !== r.key)
                        }
                      : p
                  )
                })
              }
              onChange={(s) => {
                r.text = s
                setReactionSetting({ ...reactions })
              }}
              initialValue={r.text}
            />
          ))}
        </>
      )}
      {collapsed ? (
        ''
      ) : (
        <div
          onClick={() =>
            setReactionSetting({
              ...reactions,
              pattern_reply: reactions?.pattern_reply?.map((p) =>
                p.key === id
                  ? {
                      ...p,
                      replies: [...(p.replies || []), { key: uuid.v4(), text: '你好' }]
                    }
                  : p
              )
            })
          }
          className="sub-section-title"
        >
          回复话术 <Plus className="small-square-button" />
        </div>
      )}
      {collapsed ? (
        ''
      ) : (
        <>
          {(pattern?.replies || []).map((r) => (
            <ReactionRow
              key={r.key}
              onDelete={() =>
                setReactionSetting({
                  ...reactions,
                  pattern_reply: reactions.pattern_reply?.map((p) =>
                    p.key === id
                      ? {
                          ...p,
                          replies: p.replies?.filter((rr) => rr.key !== r.key)
                        }
                      : p
                  )
                })
              }
              onChange={(s) => {
                r.text = s
                setReactionSetting({ ...reactions })
              }}
              initialValue={r.text}
              reply={r}
              onVoiceChange={(s: any) => {
                setReactionSetting({
                  ...reactions,
                  pattern_reply: reactions.pattern_reply?.map((p) =>
                    p.key === id
                      ? {
                          ...p,
                          replies: p.replies?.map((t) => (t.key === s.key ? { ...s } : t))
                        }
                      : p
                  )
                })
              }}
            />
          ))}
        </>
      )}
    </div>
  )
}
const ReactionRow: FC<{
  onDelete: () => void
  onChange: (_: string) => void
  initialValue: string
  placeholder?: string
  reply?: any
  onVoiceChange?: (_: any) => void
  maxLength?: number
}> = ({ onDelete, onChange, initialValue, placeholder, reply, onVoiceChange, maxLength }) => {
  const [recordingRowModalVisble, setRecordingRowModalVisble] = useState(false)
  const [content, setContent] = useState<any>()
  const onVoiceClick = () => {
    setRecordingRowModalVisble(true)
    setContent({
      text: reply?.text,
      voice: reply?.voice_key || ''
    })
  }

  const onRecordingRowSuccess = (resource: any) => {
    setRecordingRowModalVisble(false)
    onVoiceChange?.({
      ...reply,
      voice_key: resource.key
    })
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img
        className="primary-button-color hidden-img"
        src={minusCircle}
        alt="delete"
        style={{
          color: 'red',
          width: 18,
          height: 18,
          verticalAlign: 'center',
          textAlign: 'center',
          margin: '0 10px'
        }}
        onClick={onDelete}
      />
      <LexicalComposer
        initialConfig={{
          namespace: 'row',

          editorState: () => $getRoot().append($createParagraphNode().append($createTextNode(initialValue))),
          onError(error, editor) {
            console.error('editor error', error)
          },
          nodes: [HighlightNode, SensitiveWordsNode]
        }}
      >
        <Render>
          {function InputField() {
            const [editor] = useLexicalComposerContext()

            editor.update(() => {
              if (maxLength) {
                const text = $getRoot().getTextContent()
                if (text.length > maxLength) {
                  $getRoot().clear()
                  const paragraphNode = $createParagraphNode()
                  const textNode = new TextNode(text.substring(0, maxLength))
                  paragraphNode.append(textNode)
                  $getRoot().append(paragraphNode)
                }
              }
            })

            return (
              <PlainTextPlugin
                contentEditable={<ContentEditable style={{ color: 'white', flex: 1, width: 0, outline: 'none' }} />}
                placeholder={<div className="lexical-placeholder"> {placeholder ?? '互动话术'} </div>}
                ErrorBoundary={LexicalErrorBoundary}
              />
            )
          }}
        </Render>

        <HighlightPlugin text="[观众昵称]" />
        <HighlightPlugin text="[礼物名称]" />
        <HighlightPlugin text="[礼物数量]" />
        <OnChangePlugin
          ignoreSelectionChange
          onChange={(state) => onChange(state.read(() => $getRoot().getTextContent()))}
        />
        <HistoryPlugin />
        <SensitiveWordsPlugin />
      </LexicalComposer>
      {reply && (
        <>
          {reply?.voice_key ? (
            <img
              style={{
                width: '24px',
                cursor: 'pointer'
              }}
              src={require('../res/icon-voice-on.png')}
              alt=""
              onClick={onVoiceClick}
            />
          ) : (
            <img
              style={{
                width: '24px',
                cursor: 'pointer'
              }}
              src={require('../res/icon-voice.png')}
              alt=""
              onClick={onVoiceClick}
            />
          )}
          <RecordingRowModal
            content={content}
            visible={recordingRowModalVisble}
            onCancel={() => {
              setRecordingRowModalVisble(false)
            }}
            onOk={onRecordingRowSuccess}
          />
        </>
      )}
    </div>
  )
}

const HighlightPlugin: FC<{ text: string; className?: string }> = ({ text, className }) => {
  const __className = useMemo(() => className || 'highlighted', [className])
  const [editor] = useLexicalComposerContext()
  useEffect(
    () =>
      [
        editor.registerNodeTransform(TextNode, (node) => {
          const index = node.getTextContent().indexOf(text)
          if (index < 0) return
          const target = node.splitText(index, index + text.length)[node.getTextContent() === text ? 0 : 1]
          target?.replace($createHighlightNode(text, __className))
        }),
        editor.registerNodeTransform(HighlightNode, (node) => {
          if (node.getTextContent() !== node.__target) node.replace($createTextNode(node.getTextContent()))
        })
      ].reduce(
        (l, r) => () => {
          l()
          r()
        },
        () => void 0
      ),
    [__className, editor, text]
  )
  return null
}

class HighlightNode extends TextNode {
  __className: string
  __target: string

  constructor(text: string, target: string, className: string) {
    super(text)
    this.__target = target
    this.__className = className
  }

  isUnmergeable(): boolean {
    return true
  }

  static getType() {
    return 'highlightNode'
  }

  exportJSON() {
    return {
      ...super.exportJSON(),
      type: 'highlightNode',
      highlight_target: this.__target,
      highlight_class_name: this.__className,
      version: 1
    }
  }

  static importJSON(serializedNode: any): HighlightNode {
    return new HighlightNode(
      TextNode.importJSON(serializedNode).getTextContent(),
      serializedNode.highlight_target || '',
      serializedNode.highlight_class_name || ''
    )
  }

  static clone(node: HighlightNode) {
    return new HighlightNode(node.__text, node.__target, node.__className)
  }

  createDOM(config: EditorConfig): HTMLElement {
    const node = super.createDOM(config)
    node.className = addClassName(node.className, this.__className)
    return node
  }
}

function $createHighlightNode(text: string, className: string) {
  return new HighlightNode(text, text, className)
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function $isHighlightNode(node: LexicalNode): boolean {
  return node instanceof HighlightNode
}

function addClassName(current: string | undefined, className: string): string {
  if (!className) return current || ''
  if (!current) return className
  if (current === className) return current
  if (current.includes(' ' + className)) return current
  return current + ' ' + className
}

export default ReactionEditor
