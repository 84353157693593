import axios from 'axios'
import { createContext, useContext } from 'react'
import { BehaviorSubject, Subject } from 'rxjs'
import { useQuery } from '@tanstack/react-query'
import { useGet } from '../api'
import { resourceUrl } from '../global-vars'
import { Layer, Room, SectionType } from '../room/states'

// 当前选中的剧本范围
export type SelectedRange = {
  // 剧本卡片的 key
  key: string
  range?: {
    // 开始行, including
    start: number
    // 结束行, excluding
    end: number
  }
}
// 当前选中的剧本范围
export const selectedRange = createContext<BehaviorSubject<SelectedRange | undefined>>(undefined as any)

// 选中剧本范围变化信号
export const selectedRangeChange = createContext<Subject<SelectedRange | undefined>>(undefined as any)

// 当前剧本卡片对应的 key
export const segmentKey = createContext('')
// 当前剧本卡片对应的 title
export const segmentTitle = createContext<string | undefined>('')

// 保存信号。`true` 表示转菊花。
export const save = createContext<Subject<boolean>>(undefined as any)

export type LineContent = {
  is_assistant?: boolean
  text: string
  voice_key?: string
  line_mark?: string
  alternatives?: {
    text?: string
    voice_key?: string
  }[]
}

// 剧本卡片
export type ScriptSegment = {
  key: string
  title?: string
  attributes?: {
    slot?: { slot: string }
    product?: { slot: string; product_id: string }
    member_count_increase?: { ratio: number }
    probability?: { probability: number }
    enter_count?: { duration_seconds: number; cool_down_duration_seconds: number; count: number }
    sku?: { product_id?: string }
  }[]
  voice_key?: string
  voice_switch?: boolean
  id?: string
  line_contents?: LineContent[]
  groupInfo?: {
    title: string
    key: string
  }
}

const emptyArray: never[] = []

// 直播间模版
export type RoomTemplate = {
  id: string
  name: string
  room: Room
  order: number
  preview_key: string
  button_text: string
  category: string
}

export function useRoomTemplates() {
  return useGet<RoomTemplate[]>('/templates', { suspense: true }).data || emptyArray
}

// 剧本模版中的一个卡片 / 选项
export type scriptSegmentConfig = {
  title: string
  candidates: string[]
  probability?: number
  member_count_increase?: number
}

// 剧本模版
export type scriptTemplateConfig<T> = {
  title: string
  description?: string
  features?: string[]
  detail?: {
    features?: { title: string; description: string }[]
    video_key?: string
  }
  // 这里的卡片会被默认加入用户剧本
  script: T
  // 这里的卡片需要用户主动加入
  options: T
}

export const plainTextParts = ['intro', 'loop'] as const
export const intelligentUniversalParts = [
  'intro',
  'lurking',
  'save_up_1',
  'save_up_2',
  'welfare',
  'before_all_products',
  'before_each_product',
  'after_each_product',
  'after_all_products'
] as const

export type plainTextConfig = Partial<Record<(typeof plainTextParts)[number], scriptSegmentConfig[]>>

export function usePlainTextConfig(): scriptTemplateConfig<plainTextConfig> {
  const url = useContext(resourceUrl)
  const config = useQuery({
    queryKey: ['/plain-text-script'],
    suspense: true,
    queryFn: async () => {
      return (await axios.get(url('plain-text-script.json'))).data as scriptTemplateConfig<plainTextConfig>
    }
  }).data || { title: '自由创作', script: {}, options: {} }
  if (!config.options) config.options = config.script
  return config
}

export function useUniversalPlainTextConfig(): scriptTemplateConfig<plainTextConfig> {
  const url = useContext(resourceUrl)
  const config = useQuery({
    queryKey: ['/universal-plain-text-script'],
    suspense: true,
    queryFn: async () => {
      return (await axios.get(url('universal-plain-text-script.json'))).data as scriptTemplateConfig<plainTextConfig>
    }
  }).data || { title: '通用模版', script: {}, options: {} }
  if (!config.options) config.options = config.script
  return config
}

export type intelligentUniversalConfig = Partial<
  Record<(typeof intelligentUniversalParts)[number], scriptSegmentConfig[]>
>

// 通用智能。具体名字请遵循剧本管理者的选择。
export function useIntelligentUniversalConfig(): scriptTemplateConfig<intelligentUniversalConfig> {
  const url = useContext(resourceUrl)
  const config = useQuery({
    queryKey: ['/intelligent-universal-config'],
    suspense: true,
    queryFn: async () => {
      return (await axios.get(url('intelligent-universal-script.json')))
        .data as scriptTemplateConfig<intelligentUniversalConfig>
    }
  }).data || {
    title: '通用智能带货',
    script: {},
    options: {}
  }
  if (!config.options) config.options = config.script
  return config
}

// 爆品。具体名字请遵循剧本管理者的选择。
export function useCreateExplosiveProductConfig(): scriptTemplateConfig<intelligentUniversalConfig> {
  const url = useContext(resourceUrl)
  const config = useQuery({
    queryKey: ['/create-explosive-products-script'],
    suspense: true,
    queryFn: async () => {
      return (await axios.get(url('create-explosive-products-script.json')))
        .data as scriptTemplateConfig<intelligentUniversalConfig>
    }
  }).data || {
    title: '爆品剧本',
    script: {},
    options: {}
  }
  if (!config.options) config.options = config.script
  return config
}

// 秒杀。具体名字请遵循剧本管理者的选择。
export function useSecKillConfig(): scriptTemplateConfig<intelligentUniversalConfig> {
  const url = useContext(resourceUrl)
  const config = useQuery({
    queryKey: ['/seckill-script'],
    suspense: true,
    queryFn: async () => {
      return (await axios.get(url('seckill-script.json'))).data as scriptTemplateConfig<intelligentUniversalConfig>
    }
  }).data || {
    title: '爆品剧本',
    script: {},
    options: {}
  }
  if (!config.options) config.options = config.script
  return config
}

// 憋人气剧本
export function useSaveUpPopularityConfig(): scriptTemplateConfig<intelligentUniversalConfig> {
  const url = useContext(resourceUrl)
  const config = useQuery({
    queryKey: ['/save-up-popularity'],
    suspense: true,
    queryFn: async () => {
      return (await axios.get(url('save-up-popularity-script.json')))
        .data as scriptTemplateConfig<intelligentUniversalConfig>
    }
  }).data || {
    title: '憋人气剧本',
    script: {},
    options: {}
  }
  if (!config.options) config.options = config.script
  return config
}

// 预览框的尺寸，w/h 为实际尺寸，tw/th 为目标视频尺寸
export const viewPort = createContext<Record<'w' | 'h' | 'tw' | 'th', number> | undefined>(undefined)

// 当前所有已添加素材。不包括背景
export const layers = createContext<BehaviorSubject<Layer[]>>(undefined as any)

// 当前选中的素材 key
export const editingLayer = createContext<BehaviorSubject<string>>(undefined as any)

export type addLayerSignal = {
  w: number
  h: number
  resource_key: string
  persistent?: true
  section_key?: string
  alpha_key?: string
  type: 'image' | 'video' | 'multiple_w2l_video' | 'intro_video'
  prev_overlap_msec?: number
  current_overlap_msec?: number
}
export type bgImg = { key: string; w: number; h: number }
export type w2lVid = { w: number; h: number; image_key: string; video_id: string; no_alpha?: boolean }

// 新增素材的信号
export const addLayer = createContext<Subject<addLayerSignal>>(undefined as any)

// 当前选中的主播声音
export const voiceName = createContext<BehaviorSubject<string>>(undefined as any)

// 当前背景
export const background = createContext<BehaviorSubject<bgImg | undefined>>(undefined as any)

// 当前选中的主播形象
export const w2lVideo = createContext<BehaviorSubject<w2lVid | undefined>>(undefined as any)

// 素材变化信号。在一些场景下，例如拖动/缩放素材，为了优化页面性能，直接 in-place 改变了 layer 数据，此时通过 layersChange 表示数据发生了变化。
export const layersChange = createContext<Subject<void>>(undefined as any)

export type AsrTask = {
  card_level: number // 1: 一级卡片（section），2: 二级卡片（segment）
  card_key: string // 卡片 key，UUID格式
  done: boolean // 是否完成
  failed: boolean // 是否失败
  failed_reason?: string // 失败原因
  section: SectionType // 类型跟剧本里的一级卡片一致，仅在 done == true && card_level == 1 时有意义
  segment: ScriptSegment // 类型跟剧本里的二级卡片一致，仅在 done == true && card_level == 2 时有意义
}
