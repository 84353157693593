import axios from 'axios'
import { createContext, useContext } from 'react'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'

const api = axios.create()
export const currentApi = createContext(api)
export const currentAuthApi = createContext(api)
export const currentOrderApi = createContext(api)

export const currentPayApi = createContext(api)
export const useAPI = () => useContext(currentApi)
export const useAuthAPI = () => useContext(currentAuthApi)
export const useOrderAPI = () => useContext(currentOrderApi)
export const usePayApi = () => useContext(currentPayApi)

export function useGet<T>(
  path: string,
  options?: Partial<Omit<UseQueryOptions<T, unknown, T, string[]>, 'queryFn' | 'queryKey'>>
) {
  const api = useAPI()
  return useQuery({
    ...options,
    queryKey: [path],
    queryFn: () => {
      return api.get(path).then(({ data }: { data: T }) => data)
    }
  })
}
