import 'recorder-core/src/extensions/buffer_stream.player'
import { Button, Modal } from 'antd'
import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useSessionStorage } from 'react-use-storage'
import Recorder from 'recorder-core/recorder.wav.min'
import { useAPI } from '../api'
import { roomId, WithPermission, wsContext } from '../global-vars'
import { ReactComponent as PlayCircle } from '../res/play-circle.svg'
import { useSession } from '../room/states'
import { controlButtonStyle } from './index'

//语音接管
export const VoiceTakeOver: FC<{}> = () => {
  const globalSession = useSession()
  const rid = useContext(roomId)
  const session = useMemo(
    () => (!globalSession?.room_id || globalSession.room_id === rid ? globalSession : undefined),
    [globalSession, rid]
  )
  const [cacheTakeOverType, setCacheTakeOverType, removeCacheTakeOverType] = useSessionStorage('_takeOver_' + rid, 0)
  //0不接管、1接管主播、2接管助播、3数字人静音
  const [takeOverType, setTakeOverType] = useState<number>(cacheTakeOverType)
  const ws = useContext(wsContext)
  const isEnd = useRef(false)
  const recorder = useRef<typeof Recorder>()
  const api = useAPI()
  const [cloneTakeOver, setCloneTakeOver] = useState(false)
  const [selectClone, setSelectClone] = useState('')
  const [cloneList, setCloneList] = useState<any[]>([])

  const wsMsgType = 'microphone_control'
  const sampleRate = 16000 //采样率
  const sampleBits = 16 //比特率
  const sendInterval = 200 //上传间隔ms
  const voiceType = 'pcm' //音频格式
  let queueLength = 0 //堆积的数量
  let msgNumber = 0 //消息序号
  let msgNumberMax = 0 //当前最大的消息序号，检测乱序
  let lastChunk: any = null //上次处理的chunk
  let lastTime = 0 //上次处理的时间

  useEffect(() => {
    recAction()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [takeOverType])

  useEffect(() => {
    if (cloneTakeOver) {
      getCloneList()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cloneTakeOver])

  const getCloneList = async () => {
    const res: any = await api.get(`/convertible_voices`)
    setCloneList(res?.data?.convertible_voices || [])
  }

  const recAction = async () => {
    setCacheTakeOverType(takeOverType)
    if (takeOverType) {
      isEnd.current = false
      recorder.current?.close()
      recorder.current = new Recorder({
        type: voiceType,
        sampleBits: sampleBits,
        sampleRate: sampleRate,
        numChannels: 1,
        compiling: true,
        onProcess: (
          buffers: any,
          powerLevel: Number,
          bufferDuration: Number,
          bufferSampleRate: Number,
          a: any,
          b: any,
          c: any
        ) => {
          tryRealTimeSend(buffers, bufferSampleRate)
        }
      })
      await new Promise(recorder.current.open.bind(recorder.current))
      recorder.current.start()
    } else {
      isEnd.current = true
      removeCacheTakeOverType()
    }
  }

  const tryRealTimeSend = (buffers: any, bufferSampleRate: Number) => {
    if (lastTime === 0) {
      queueLength = 0
      msgNumber = 0
      msgNumberMax = 0
      lastChunk = null
    }
    if (!isEnd.current && Date.now() - lastTime < sendInterval) {
      return
    }
    lastTime = Date.now()
    var number = ++msgNumber

    if (buffers.length > 0) {
      var chunk = Recorder.SampleData(buffers, bufferSampleRate, sampleRate, lastChunk, { frameType: voiceType })

      for (var i = lastChunk ? lastChunk.index : 0; i < chunk.index; i++) {
        buffers[i] = null
      }
      if (takeOverType === 3) {
        for (var j = 0; j < chunk.data.length; j++) {
          chunk.data[j] = 0
        }
      }
      lastChunk = chunk

      if (!isEnd.current && queueLength >= 2) {
        console.warn('编码队列阻塞，已丢弃一帧', 1)
        return
      }
      queueLength++

      let status = isEnd.current ? 'end' : number === 1 ? 'begin' : 'pedding',
        action = takeOverType === 1 ? 'human_driven' : 'human_voice'
      if (status !== 'pedding') {
        console.log(status, 'No.', number, action, takeOverType)
      }
      if (status === 'end') {
        recorder.current?.close()
      }

      var reader = new FileReader()
      reader.onloadend = function () {
        var content = (/.+;\s*base64\s*,\s*(.+)$/i.exec(reader.result as string) || [])[1]
        ws &&
          ws((_) =>
            _.send(
              JSON.stringify({
                type: wsMsgType,
                status,
                action,
                convertible_voice_name: status === 'begin' && selectClone ? selectClone : '',
                sample_rate: sampleRate,
                duration_msec: sendInterval,
                content
              })
            )
          )
      }
      reader.readAsDataURL(new Blob([chunk.data]))

      if (number < msgNumberMax) {
        console.log('No.' + number + ':乱顺')
      } else {
        msgNumberMax = number
        if (number % 30 === 0) {
          console.log('No.', number, ' transfered')
        }
      }
      queueLength--
    }
  }

  useEffect(() => {
    if (!session?.room_id && takeOverType > 0) {
      setTakeOverType(0)
    }
  }, [session?.room_id, takeOverType])

  const sendMuteCommand = (mute?: boolean) => {
    ws &&
      ws((_) =>
        _.send(
          JSON.stringify({
            type: mute ? 'start_silence' : 'end_silence'
          })
        )
      )
  }

  return (
    <>
      {!takeOverType ? (
        <>
          <Button
            disabled={!session?.room_id}
            onClick={() => setTakeOverType(1)}
            danger
            style={{ ...controlButtonStyle, display: 'block', marginBottom: 12 }}
          >
            本人声音 - 麦克风接管
          </Button>
          <Button
            disabled={!session?.room_id}
            onClick={() => {
              setTakeOverType(0)
              setCloneTakeOver(true)
            }}
            danger
            style={{ ...controlButtonStyle, display: 'block', marginBottom: 12 }}
          >
            克隆声音 - 麦克风接管
          </Button>
        </>
      ) : (
        ''
      )}
      <WithPermission permission="admin">
        {!takeOverType ? (
          <Button
            disabled={!session?.room_id}
            onClick={() => setTakeOverType(2)}
            danger
            style={{ ...controlButtonStyle, display: 'block', marginBottom: 12 }}
          >
            接管(助播)
          </Button>
        ) : (
          ''
        )}
      </WithPermission>
      <WithPermission permission="admin">
        {!takeOverType ? (
          <Button
            disabled={!session?.room_id}
            onClick={() => {
              setTakeOverType(3)
              sendMuteCommand(true)
            }}
            danger
            style={{ ...controlButtonStyle, display: 'block', marginBottom: 12 }}
          >
            数字人静音
          </Button>
        ) : (
          ''
        )}
      </WithPermission>
      {takeOverType ? (
        <Button
          onClick={() => {
            setTakeOverType(0)
            setSelectClone('')
            takeOverType === 3 && sendMuteCommand(false)
          }}
          danger
          style={{ ...controlButtonStyle, display: 'block', marginBottom: 12 }}
        >
          停止接管
        </Button>
      ) : (
        ''
      )}
      <Modal
        open={cloneTakeOver}
        title="选择克隆声音"
        onCancel={() => {
          setSelectClone('')
          setCloneTakeOver(false)
        }}
        width={500}
        cancelText="取消"
        okText="确认"
        okButtonProps={{ disabled: !selectClone }}
        onOk={() => {
          setCloneTakeOver(false)
          setTakeOverType(1)
        }}
      >
        <div style={{ padding: '20px 30px', maxHeight: 400, overflowY: 'auto' }}>
          {cloneList.map((c) => (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '6px 12px',
                background: 'rgb(47, 48, 44)',
                borderRadius: 4,
                marginBottom: 16,
                cursor: 'pointer',
                border: selectClone === c.voice ? '1px solid #D4FF00' : '1px solid rgb(47, 48, 44)',
                color: selectClone === c.voice ? '#D4FF00' : '#fff'
              }}
              onClick={() => setSelectClone(c.voice)}
            >
              <div
                className={`play-circle ${selectClone === c.voice ? 'selected' : ''}`}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <PlayCircle />
              </div>
              <span style={{ paddingLeft: 12 }}>{c.voice}</span>
            </div>
          ))}
        </div>
      </Modal>
    </>
  )
}
