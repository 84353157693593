import './index.scss'
import { Button, message, Popover } from 'antd'
import { FC, memo, useContext, useEffect, useMemo, useRef, useState } from 'react'
import * as uuid from 'uuid'
import { useAPI } from '../../../api'
import { currentUser } from '../../../auth/states'
import { devDomains, prodDomains, roomId, socketContext, socketTransings } from '../../../global-vars'
import { useBehaviorSubject } from '../../../react-rx'
import { asrTag, recordingRow, segments, segmentsChange } from '../../script-card'
import { segmentKey } from '../../states'
import RecordingModal from './recording-modal'
import RecordingRowModal from './recording-row-modal'
import { UploadVoiceModal } from './voice-upload-modal'

const VoiceUpload: FC = () => {
  const [uploadType, setUploadType] = useState<string>()
  const [recordingModalVisble, setRecordingModalVisble] = useState(false)
  const [recordingRowModalVisble, setRecordingRowModalVisble] = useState(false)
  const [, setSRecording] = useBehaviorSubject(useContext(recordingRow))
  const [ws, setWs] = useBehaviorSubject(useContext(socketContext))
  const wsTransing = useContext(socketTransings)
  const [content] = useBehaviorSubject(useContext(recordingRow))
  const isDev = useMemo(() => !!window.location.host.match(/dev|localhost/), [])
  const domains = useMemo(() => (isDev ? devDomains : prodDomains), [isDev])
  const key = useContext(segmentKey)
  const segmentsSubj = useContext(segments)
  const [s] = useBehaviorSubject(segmentsSubj)
  const index = useMemo(() => s.findIndex((s) => s.key === key), [s, key])
  const segmentsChangeSubj = useContext(segmentsChange)
  const seg = s[index]
  const user = useContext(currentUser)
  const timer = useRef<any>()
  const rid = useContext(roomId)
  const api = useAPI()
  const [asr, setAsr] = useBehaviorSubject(useContext(asrTag))

  const showTips = useMemo(() => {
    return !!seg.line_contents?.reduce((pre, next) => {
      return pre + next.text
    }, '')
  }, [seg.line_contents])

  useEffect(() => {
    let rowDoms: any[]
    setTimeout(() => {
      const c = document.querySelectorAll('.rich-text-content')[index]
      rowDoms = c?.childNodes as any
      rowDoms?.forEach((r: any, i: number) => {
        let c = [...(r.classList || [].filter((t: string) => t !== 'voice-gray' && t !== 'voice-gray'))]
        if (!c.includes('assistant') && s[index]?.line_contents?.[i]?.is_assistant) {
          c.push('assistant')
        }
        if (c.includes('assistant') && !s[index]?.line_contents?.[i]?.is_assistant) {
          c = c.filter((t) => t !== 'assistant')
        }
        if (s[index]?.line_contents?.[i]?.voice_key) {
          r.className = `${c.join(' ')} voice`
        } else {
          r.className = `${c.join(' ')} voice-gray`
        }
        r?.addEventListener('click', uploadClick)
      })
    }, 50)
    return () => {
      rowDoms?.forEach((r: any) => {
        r?.removeEventListener('click', uploadClick)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [s])

  const uploadClick = (e: any) => {
    if (e?.target?.className?.includes('voice')) {
      const i = Array.prototype.indexOf.call(e.target.parentNode.childNodes, e.target)
      setRecordingRowModalVisble(true)
      setSRecording({
        text: s[index]?.line_contents?.[i]?.text,
        voice: s[index]?.line_contents?.[i]?.voice_key,
        is_assistant: s[index]?.line_contents?.[i]?.is_assistant,
        pIndex: index,
        index: i
      })
    }
  }

  const uploadSwitch = () => {
    segmentsChangeSubj.next(
      segmentsSubj.value.map((s: any) =>
        s.key !== key
          ? s
          : {
              ...s,
              voice_switch: !s.voice_switch
            }
      )
    )
  }

  const updateKey = (voice_key: string) => {
    segmentsChangeSubj.next(
      segmentsSubj.value.map((s) =>
        s.key !== key
          ? s
          : {
              ...s,
              voice_key,
              id: uuid.v4(),
              line_contents: []
            }
      )
    )
  }

  const onUploadSuccess = async (resource: any) => {
    setUploadType(undefined)
    if (resource.split_mode === 'split') {
      await api.post(`/rooms/${rid}/async_asr/submit`, {
        audio_key: resource?.key,
        card_level: 2,
        card_key: key
      })
      setAsr(!asr)
    } else {
      updateKey(resource?.key)
      createWs(resource?.key, resource.language, resource.split_mode)
    }
  }

  const onRecordingSuccess = (resource: any) => {
    setRecordingModalVisble(false)
    updateKey(resource?.key)
    createWs(resource?.key)
  }

  const onRecordingRowSuccess = (resource: any) => {
    setRecordingRowModalVisble(false)
    setSRecording(undefined)
    const { pIndex, index } = content
    const ss = segmentsSubj.value.map((s, i: number) => {
      if (pIndex !== i) {
        return s
      } else {
        return {
          ...s,
          line_contents: s.line_contents?.map((t, i) => {
            return {
              ...t,
              voice_key: index === i ? resource?.key : t.voice_key
            }
          })
        }
      }
    })
    segmentsChangeSubj.next(ss)
  }

  const onAssistantChange = (is_assistant: boolean) => {
    const { pIndex, index } = content
    const ss = segmentsSubj.value.map((s, i: number) => {
      if (pIndex !== i) {
        return s
      } else {
        return {
          ...s,
          line_contents: s.line_contents?.map((t, i) => {
            return {
              ...t,
              is_assistant: index === i ? is_assistant : !!t.is_assistant
            }
          })
        }
      }
    })
    segmentsChangeSubj.next(ss)
  }

  const createWs = (resourceKey: string, language?: string, split_mode?: string) => {
    if (!ws) {
      const cws = new WebSocket(`wss:${domains.cms}/asr?token=${user.token}&language_code=${language || 'zh-CN'}`)
      timer.current && clearTimeout(timer.current)
      timer.current = setInterval(() => {
        cws?.send(JSON.stringify({ type: 'keep-alive' }))
      }, 10000)

      cws.onmessage = (msg: MessageEvent) => {
        const data = JSON.parse(msg.data)
        const { sentence, line_no, id, type, voice_key } = data
        if (type === 'transcript-temp' || type === 'transcript') {
          segmentsChangeSubj.next(
            segmentsSubj.value.map((s) => {
              if (s.key !== id) {
                return s
              } else {
                if (line_no > (s.line_contents || []).length) {
                  s.line_contents?.push({
                    text: sentence,
                    voice_key
                  })
                } else {
                  if (s.line_contents) {
                    s.line_contents[line_no] = {
                      text: sentence,
                      voice_key
                    }
                  }
                }
                return {
                  ...s,
                  id: !sentence ? `blank${uuid.v4()}` : sentence
                }
              }
            })
          )
        }
        if (type === 'asr-session-finished') {
          setTimeout(() => {
            message.success('解析完成')
            segmentsChangeSubj.next(
              segmentsSubj.value.map((s: any) => {
                if (s.key !== id) {
                  return s
                } else {
                  return {
                    ...s,
                    id: undefined
                  }
                }
              })
            )
            const rain = wsTransing.value?.filter((t: any) => t !== id)
            wsTransing.next(rain)
            if (!rain.length) {
              cws.close()
              setWs(undefined)
              timer.current && clearTimeout(timer.current)
              timer.current = null
            }
          }, 100)
        }
      }

      cws.onerror = () => {}

      cws.onopen = () => {
        wsTransing.next([...wsTransing.value, seg.key])
        cws.send(JSON.stringify({ type: 'keep-alive' }))
        cws.send(JSON.stringify({ type: 'new-asr-session', key: resourceKey, id: seg.key, split_mode }))
      }

      setWs(cws)
    } else {
      wsTransing.next([...wsTransing.value, seg.key])
      ws.send(JSON.stringify({ type: 'new-asr-session', key: resourceKey, id: seg.key, split_mode }))
    }
  }

  return (
    <>
      <div className="voice-upload">
        <Popover
          className="popver-content"
          content="开启音频驱动按钮后, 可以点击每一行话术行尾的音频按钮去添加音频, 生成直播内容时会用已上传的音频驱动; 未上传音频的话术, 会通过AI语音合成去驱动."
        >
          <img className="icon-help-circle" src={require('../../../res/icon-help-circle.png')} alt="" />
        </Popover>
        {seg.voice_switch ? (
          <img
            className="icon-video-switch"
            src={require('../../../res/icon-video-switch.png')}
            alt=""
            onClick={uploadSwitch}
          />
        ) : (
          <img
            className="icon-video-switch"
            src={require('../../../res/icon-video-switch-off.png')}
            alt=""
            onClick={uploadSwitch}
          />
        )}
        <div>
          <Button className="btn-text" onClick={() => setUploadType('bgm')} disabled={!seg.voice_switch || !!seg.id}>
            上传
          </Button>
          <i className="divider" />
          <Button
            className="btn-text"
            onClick={() => setRecordingModalVisble(true)}
            disabled={!seg.voice_switch || !!seg.id}
          >
            录制
          </Button>
        </div>
      </div>
      <RecordingRowModal
        visible={recordingRowModalVisble}
        onCancel={() => {
          setRecordingRowModalVisble(false)
          setSRecording(undefined)
        }}
        onOk={onRecordingRowSuccess}
        onAssistantChange={onAssistantChange}
      />
      <RecordingModal
        showTips={showTips}
        visible={recordingModalVisble}
        onCancel={() => setRecordingModalVisble(false)}
        onOk={onRecordingSuccess}
      />
      <UploadVoiceModal
        uploadType={uploadType}
        onClose={() => setUploadType(undefined)}
        onUploadSuccess={onUploadSuccess}
        showTips={showTips}
      />
    </>
  )
}

export default memo(VoiceUpload)
