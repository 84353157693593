import { Context, createContext, FC, PropsWithChildren, RefObject, useContext, useMemo, useRef } from 'react'
import { ConnectDragSource, useDrag, useDrop } from 'react-dnd'
import * as uuid from 'uuid'

import type { Identifier, XYCoord } from 'dnd-core'

export const DndType: FC<PropsWithChildren> & {
  type: Context<string>
} = Object.assign(
  function Component({ children }: Parameters<FC<PropsWithChildren>>[0]) {
    const type = useMemo(() => uuid.v4(), [])
    // eslint-disable-next-line react/jsx-pascal-case
    return <DndType.type.Provider value={type}>{children}</DndType.type.Provider>
  },
  { type: createContext<string>(uuid.v4()) }
)

interface DragItem {
  index: number
  id: string
  type: string
}

export const DndItem: FC<
  PropsWithChildren<{
    id: any
    index: number
    moveItem: (dragIndex: number, hoverIndex: number) => void
  }>
> = ({ id, children, index, moveItem }) => {
  const ref = useRef<HTMLDivElement>(null)
  const dndType = useContext(DndType.type)
  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: dndType,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      }
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      // Time to actually perform the action
      moveItem(dragIndex, hoverIndex)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    }
  })

  const [{ isDragging }, drag, dragPreview] = useDrag({
    type: dndType,
    item: () => {
      return { id, index }
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging()
    })
  })
  dragPreview(drop(ref))
  return (
    <dndProps.Provider
      value={{
        isDragging,
        drag,
        drop: ref,
        handlerId: handlerId || undefined
      }}
    >
      {children}
    </dndProps.Provider>
  )
}

type dndProps = {
  drag: ConnectDragSource
  drop: RefObject<any>
  isDragging: boolean
  handlerId: Identifier | undefined
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const dndProps = createContext(undefined as unknown as dndProps)
