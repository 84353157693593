import { Button, message, Modal, Switch, UploadFile } from 'antd'
import { RcFile } from 'antd/es/upload'
import Dragger from 'antd/es/upload/Dragger'
import { FC, memo, useContext, useEffect, useMemo, useState } from 'react'
import Recorder from 'recorder-core/recorder.wav.min'
import { useAPI } from '../../../api'
import { resourceUrl } from '../../../global-vars'
import { useBehaviorSubject } from '../../../react-rx'
import { ReactComponent as Spinner } from '../../../res/spin.svg'
import { recordingRow } from '../../script-card'

interface IProps {
  visible: boolean
  onCancel: () => void
  onOk?: (resource: any) => void
  content?: any
  onAssistantChange?: (assistant: boolean) => void // 助播
}

const RecordingRowModal: FC<IProps> = (props) => {
  const { visible, onOk, onAssistantChange } = props
  const [status, setStatus] = useState(0)
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [audioFile, setAudioFile] = useState<any>()
  const [recorder, setRecorder] = useState<typeof Recorder>()
  const [source, setSource] = useState<any>()
  const [rec] = useBehaviorSubject(useContext(recordingRow))
  const content = props.content || rec
  const [assistant, setAssistant] = useState(false)

  const urlSource = useContext(resourceUrl)
  const api = useAPI()

  useEffect(() => {
    return () => {
      recorder?.close()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setAssistant(!!content?.is_assistant)
  }, [content])

  useEffect(() => {
    if (visible) {
      setFileList([])
      setSource(undefined)
    }
  }, [visible])

  useEffect(() => {
    if (audioFile) {
      uploadRec()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioFile])

  const onCancel = () => {
    setStatus(0)
    setFileList([])
    recorder?.close()
    props.onCancel()
  }

  const uploadContent = useMemo(() => {
    const uploading = fileList?.filter((f) => f.status === 'uploading').length
    if (uploading) {
      return (
        <>
          <Spinner className="spin" />
          <p>音频上传中</p>
        </>
      )
    }
    return (
      <div className="upload-row-content">
        <div className="left">
          <h4 className="white">将文件拖到此处，或点击上传按钮</h4>
          <p className="white">支持上传 wav/mp3 文件，单个文件大小不大于 100M</p>
        </div>
        <div className="right">
          <Button type="primary">上传</Button>
          <Button
            onClick={(e) => {
              e.stopPropagation()
              onCancel()
            }}
          >
            取消
          </Button>
        </div>
      </div>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList, source])

  if (!content) return null

  const startRec = async () => {
    try {
      const recorder = new Recorder({
        type: 'wav',
        sampleBits: 16,
        sampleRate: 16000,
        numChannels: 1,
        compiling: true
      })
      await new Promise(recorder.open.bind(recorder))
      recorder.start()
      setStatus(4)
      setRecorder(recorder)
    } catch (e: any) {
      message.error(e)
    }
  }

  const stopRec = async () => {
    const wavBlob: any = await new Promise(recorder.stop.bind(recorder))
    const duration = recorder.envInLast - recorder.envInFirst
    const file: any = new File([wavBlob], 'audio.wav', { type: 'audio/wav' })
    file.duration = duration
    setAudioFile(file)
    setStatus(5)
    await new Promise(recorder.close.bind(recorder))
    setRecorder(undefined)
  }

  const uploadRec = async () => {
    try {
      const { resource, upload_url } =
        (
          await api.post(
            '/upload',
            {
              extension: 'wav'
            },
            {
              refreshMode: 'disabled'
            } as any
          )
        ).data || {}
      if (!upload_url) {
        throw new Error('failed to upload file')
      }
      await api.put(upload_url.replace(/^http:\/\//, 'https://').replace('-internal', ''), audioFile, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      setStatus(6)
      setSource(resource)
    } catch (err: any) {
      message.error(err)
    }
  }

  const uploadResource = async (file: RcFile, options?: any) => {
    const path = `/upload`
    const { onError } = options || {}
    try {
      const segs = (file.name || '').split(/\./)
      const { resource, upload_url } =
        (
          await api.post(path, {
            extension: segs[segs.length - 1]
          })
        ).data || {}
      if (!upload_url) {
        throw new Error('failed to upload file')
      }

      await api.put(upload_url.replace(/^http:\/\//, 'https://').replace('-internal', ''), file, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      setStatus(6)
      setSource(resource)
      return resource
    } catch (err: any) {
      onError?.({ err })
      throw err
    }
  }

  const beforeUpload = async (file: RcFile) => {
    const size = file?.size || 0
    if (size / 1000 / 1000 > 100) {
      message.warning('单个文件大小不能大于 100M')
      return false
    }
    return true
  }

  const useVoice = () => {
    if (audioFile?.duration > 90 * 60 * 1000) {
      message.warning('单次录音长度不能大于 90 分钟')
      return
    }
    setStatus(0)
    onOk?.(source)
  }

  return (
    <Modal
      title="音频管理"
      open={!!visible}
      onCancel={onCancel}
      bodyStyle={{ padding: '20px 32px', textAlign: 'center' }}
      footer={null}
      width={860}
      className="recording-modal"
    >
      {(content?.voice || status === 6) && (
        <div className="record-switch">
          <Switch
            checked={assistant}
            className="card-switch"
            onChange={(checked) => {
              setAssistant(checked)
              onAssistantChange?.(checked)
            }}
          />
          <label>助播（中控音）</label>
        </div>
      )}
      <div className="recording-modal-desc">
        {content.text?.split(/\r?\n/).map((t: any, index: number) => (
          <p className="text" key={index}>
            {t}
          </p>
        ))}
      </div>
      <div className="recording-modal-content-row">
        {status === 0 && (
          <div className="row-header">
            <div className="name">{!content?.voice && '暂无录音'}</div>
            <div className="btns">
              <Button className="btn-text" onClick={() => setStatus(1)}>
                {content?.voice ? '重新上传' : '上传'}
              </Button>
              <i className="divider" />
              <Button className="btn-text" onClick={() => setStatus(3)}>
                {content?.voice ? '重新录制' : '录制'}
              </Button>
            </div>
          </div>
        )}

        {status === 0 && <>{!!content.voice && <audio src={urlSource(content.voice || '')} controls />}</>}

        {status === 1 && (
          <Dragger
            name="upload"
            showUploadList={false}
            accept=".mp3,.wav"
            customRequest={({ onSuccess, onError, file, onProgress }) =>
              uploadResource(file as RcFile, {
                onSuccess,
                onError,
                onProgress
              })
            }
            beforeUpload={async (file) => {
              return await beforeUpload(file)
            }}
            onChange={(info) => {
              if (info?.file?.status === 'error') {
                message.error(`${info.file.name} 上传失败`)
              }
              setFileList(info.fileList)
            }}
          >
            {uploadContent}
          </Dragger>
        )}

        {status === 3 && (
          <div className="row-recording">
            <div className="img" onClick={startRec}>
              <img src={require('../../../res/recording-start.png')} alt="" />
            </div>
            <span className="desc">开始录制</span>
            <Button onClick={onCancel}>取消</Button>
          </div>
        )}
        {status === 4 && (
          <div className="row-recording">
            <div className="img" onClick={stopRec}>
              <img src={require('../../../res/recording-stop.png')} alt="" />
            </div>
            <span className="desc">录制中...</span>
            <Button onClick={onCancel}>取消</Button>
          </div>
        )}
        {status === 5 && (
          <div className="row-recording">
            <div className="img">
              <img src={require('../../../res/recording-upload.png')} alt="" />
            </div>
            <span className="desc">正在完成录制...</span>
            <Button onClick={onCancel}>取消</Button>
          </div>
        )}
        {status === 6 && (
          <>
            <div className="row-header">
              <div className="name"></div>
              <div className="btns">
                <Button type="primary" onClick={useVoice}>
                  使用该音频
                </Button>
                <Button onClick={onCancel}>取消</Button>
              </div>
            </div>
            <>
              <audio src={urlSource(source?.key)} controls />
            </>
          </>
        )}
      </div>
    </Modal>
  )
}

export default memo(RecordingRowModal)
