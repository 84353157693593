import { Button, Checkbox, Modal, notification } from 'antd'
import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react'
import { BehaviorSubject } from 'rxjs'
import { useOrderAPI, usePayApi } from '../api'
import { useBehaviorSubject } from '../react-rx'
import { ReactComponent as Alipay } from '../res/alipay.svg'
import { ReactComponent as Bang } from '../res/bang.svg'
import { ReactComponent as FilledCheck } from '../res/filled-check.svg'
import { ReactComponent as FilledCross } from '../res/filled-cross.svg'
import qrCode from '../res/qr.png'
import { ReactComponent as Vip1 } from '../res/vip1.svg'
import { ReactComponent as Vip2 } from '../res/vip2.svg'
import { ReactComponent as Vip3 } from '../res/vip3.svg'
import { SelectionBadge } from '../room/room-setting'
import { tuple } from '../types'
import { dateText } from '../user-center'
import { Product, useProducts, useUserPackage } from './states'

const level1Features: { title: ReactNode; value: boolean | string }[] = [
  { title: '开播时长', value: '100小时/月' },
  { title: '可用数字人形象', value: '10+' },
  { title: '形象声音免费定制', value: false },
  { title: '直播间模板推荐', value: true },
  { title: '智能剧本模板创作', value: true },
  { title: '脚本话术与素材关联', value: true },
  { title: 'AI智能创作剧本', value: '100次/月' },
  {
    title: (
      <>
        智能销售互动<sup style={{ color: '#D4FF00' }}>TM</sup>
      </>
    ),
    value: true
  },
  { title: '多平台观众互动', value: true },
  { title: '在线客服', value: true },
  { title: '专属直播运营支持', value: false }
]

const level2Features: { title: ReactNode; value: boolean | string }[] = [
  { title: '开播时长', value: '300小时/月' },
  { title: '可用数字人形象', value: '36+' },
  { title: '形象声音免费定制', value: false },
  { title: '直播间模板推荐', value: true },
  { title: '智能剧本模板创作', value: true },
  { title: '脚本话术与素材关联', value: true },
  { title: 'AI智能创作剧本', value: '500次/月' },
  {
    title: (
      <>
        智能销售互动<sup style={{ color: '#D4FF00' }}>TM</sup>
      </>
    ),
    value: true
  },
  {
    title: (
      <>
        智能增长策略<sup style={{ color: '#D4FF00' }}>TM</sup>
      </>
    ),
    value: false
  },
  { title: '专属直播运营支持', value: false },
  { title: '专属成功团队支持', value: false }
]

const level3Features: { title: ReactNode; value: boolean | string }[] = [
  { title: '开播时长', value: '7*24h 无限开播' },
  { title: '可用数字人形象', value: '全部解锁' },
  { title: '形象声音免费定制', value: true },
  { title: '直播间模板定制', value: true },
  { title: '智能剧本模板创作', value: true },
  { title: '脚本话术与素材关联', value: true },
  { title: 'AI智能创作剧本', value: '次数不限' },
  {
    title: (
      <>
        智能销售互动<sup style={{ color: '#D4FF00' }}>TM</sup>
      </>
    ),
    value: true
  },
  {
    title: (
      <>
        智能增长策略<sup style={{ color: '#D4FF00' }}>TM</sup>
      </>
    ),
    value: true
  },
  { title: '专属直播运营支持', value: true },
  { title: '专属成功团队支持', value: true }
]
export const PlansModal: FC<{
  product?: Product
  onCancel?: (e: React.MouseEvent<HTMLButtonElement>) => void
  onConfirm?: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent> | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void
}> = ({ product, onCancel, onConfirm }) => {
  const products = useProducts().products
  const userPackage = useUserPackage()
  const currentProduct = userPackage.product
  const currentLevel = useMemo(() => currentProduct?.level || 1, [currentProduct])
  const [eulaConsent, setEulaConsent] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectedProduct, setSelectedProduct] = useBehaviorSubject(
    useMemo(() => new BehaviorSubject(product), [product])
  )
  const [level, setLevel] = useBehaviorSubject(useMemo(() => new BehaviorSubject(product?.level || 1), [product]))
  const expired = userPackage.expire_at_date && userPackage.expire_at_date < new Date()
  const disabled = useMemo(() => !expired && level < currentLevel, [expired, level, currentLevel])

  useEffect(() => {
    if (disabled || !selectedProduct) {
      setSelectedProduct(
        products?.filter((p) => p.level === currentLevel)?.sort((l, r) => (r.duration || 0) - (l.duration || 0))[0]
      )
    }
  }, [currentLevel, selectedProduct, products, setSelectedProduct, disabled])
  const features = useMemo(() => {
    switch (level) {
      case 1:
        return level1Features
      case 2:
        return level2Features
      case 3:
        return level3Features
    }
    return level1Features
  }, [level])
  const expiresAt = useMemo(() => {
    const exp = new Date()
    if (selectedProduct) {
      exp.setDate(exp.getDate() + selectedProduct.duration)
    }
    return `${exp.getFullYear()}-${exp.getMonth() + 1}-${exp.getDate()}`
  }, [selectedProduct])
  const orderApi = useOrderAPI()
  const payApi = usePayApi()
  return (
    <Modal open={!!product} onCancel={onCancel} footer={null} width={1189} title="订阅套餐">
      <div style={{ display: 'flex', width: '100%', padding: 40, boxSizing: 'border-box' }}>
        <div
          style={{
            width: 0,
            flex: 1,
            marginRight: 40
          }}
        >
          <div
            style={{
              display: 'flex',
              height: 120,
              justifyContent: 'space-between',
              paddingLeft: 40,
              paddingRight: 40,
              boxSizing: 'border-box'
            }}
          >
            {[tuple(1, '基础版', <Vip1 />), tuple(2, '旗舰版', <Vip2 />), tuple(3, '尊享版', <Vip3 />)].map(
              ([l, title, icon], index) => (
                <div
                  key={l}
                  onClick={() => {
                    if (l === level) return
                    setLevel(l)
                    setSelectedProduct(products?.find((p) => p.level === l))
                  }}
                  style={{
                    cursor: 'pointer',
                    display: 'inline-flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingTop: 20,
                    width: 157,
                    backgroundColor: l === level ? '#28282C' : '#1C1D1E',
                    border: l === level ? 'none' : 'solid 1px #434343',
                    color: l === level ? 'white' : '#6D6D6D'
                  }}
                >
                  {icon}
                  {title}
                </div>
              )
            )}
          </div>
          <div style={{ backgroundColor: '#28282C', padding: 33, width: '100%', boxSizing: 'border-box' }}>
            {level === 3 && (
              <div
                style={{ marginTop: 40, marginBottom: 40, display: 'flex', justifyContent: 'center', width: '100%' }}
              >
                <img src={qrCode} alt="qr code" style={{ width: 300 }} />
              </div>
            )}
            {level !== 3 && (
              <div style={{ display: 'flex', height: 108 }}>
                {(products?.filter((p) => p.level === level)?.sort((l, r) => r.duration - l.duration) || []).map(
                  (product, index) => (
                    <div
                      key={product.id}
                      onClick={disabled ? undefined : () => setSelectedProduct(product)}
                      style={{
                        position: 'relative',
                        display: 'inline-flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        paddingTop: 12,
                        paddingBottom: 12,
                        width: 0,
                        flex: 1,
                        marginLeft: index ? 20 : 0,
                        boxSizing: 'border-box',
                        backgroundColor: '#1C1D1E',
                        border: `solid 2px ${selectedProduct?.id === product?.id ? '#D4FF00' : 'transparent'}`
                      }}
                    >
                      {`${Math.round(product.duration / 31)} 个月`}
                      <div
                        style={{
                          fontSize: 18,
                          fontWeight: 'bolder',
                          color: '#D4FF00'
                        }}
                      >{`¥ ${Math.round(((product.price / product.duration) * 31) / 100)}/月`}</div>
                      {`总价 ${Math.round(product.price / 100)}元`}
                      {selectedProduct?.id === product?.id && <SelectionBadge style={{ backgroundColor: '#D4FF00' }} />}
                    </div>
                  )
                )}
              </div>
            )}
            {disabled && level !== 3 && (
              <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 80, paddingBottom: 227 }}>
                <Bang />
                {currentLevel === 1 && '基础版'}
                {currentLevel === 2 && '旗舰版'}
                {currentLevel === 3 && '尊享版'}
                套餐生效中
                {userPackage.expire_at_date ? `（${dateText(userPackage.expire_at_date)}到期）` : ''}
                ，不支持降级购买基础版
              </div>
            )}
            {!disabled && level !== 3 && (
              <>
                <div style={{ fontWeight: 'bolder', marginTop: 24 }}>有效期至</div>
                <div style={{ marginTop: 5 }}>{expiresAt}</div>
                <div style={{ fontWeight: 'bolder', marginTop: 24 }}>支付方式</div>
                <div style={{ height: 40, marginTop: 5 }}>
                  <div
                    style={{
                      width: 120,
                      height: '100%',
                      display: 'inline-flex',
                      justifyContent: 'center',
                      boxSizing: 'border-box',
                      alignItems: 'center',
                      position: 'relative',
                      border: `solid 2px #D4FF00`
                    }}
                  >
                    <Alipay />
                    <SelectionBadge style={{ backgroundColor: '#D4FF00' }} />
                  </div>
                </div>
                <div style={{ marginTop: 40 }}>
                  {!!userPackage?.remains &&
                    (selectedProduct?.level || 0) >
                      (products?.find((p) => p.id === userPackage?.product_id)?.level || 0) && (
                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                        <span>退补金额</span>
                        <div
                          style={{
                            display: 'block',
                            borderBottom: 'dashed 1px #434343',
                            marginLeft: 18,
                            marginRight: 18,
                            width: 0,
                            flex: 1,
                            height: 1
                          }}
                        />
                        <span>¥ -{priceStr(userPackage.remains || 0)}</span>
                      </div>
                    )}
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span>支付金额</span>
                    <div
                      style={{
                        display: 'block',
                        borderBottom: 'dashed 1px #434343',
                        marginLeft: 18,
                        marginRight: 18,
                        width: 0,
                        flex: 1,
                        height: 1
                      }}
                    />
                    <span>¥ {priceStr((selectedProduct?.price || 0) - (userPackage.remains || 0))}</span>
                  </div>
                </div>
                <Button
                  style={{ width: '100%', marginTop: 20 }}
                  className="highlight-contentful"
                  disabled={!selectedProduct || !eulaConsent}
                  loading={loading}
                  onClick={async (e) => {
                    if (!selectedProduct) return
                    setLoading(true)
                    try {
                      const order = (await orderApi.post<{ id: string }>(`/v1/buy?id=${selectedProduct.id}&num=1`)).data
                      const payment = (
                        await payApi.post<{ pay_list: { alipay: string } }>('/api/v1/plutus/payment/init', {
                          order_id: order.id,
                          pay_type: 'ALIPAY'
                        })
                      ).data
                      if (payment?.pay_list?.alipay) {
                        window.open(payment?.pay_list?.alipay)
                      }
                      onConfirm?.(e)
                    } catch (e: any) {
                      notification.error(e)
                    } finally {
                      setLoading(false)
                    }
                  }}
                >
                  立即支付
                </Button>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 21 }}>
                  <Checkbox
                    checked={eulaConsent}
                    onChange={(e) => setEulaConsent(e.target.checked)}
                    style={{ color: '#B4B4B4' }}
                  >
                    我已阅读并同意
                    <a href="/service_agreement.html" target="_blank">
                      《服务协议》
                    </a>
                  </Checkbox>
                </div>
              </>
            )}
          </div>
        </div>
        <div style={{ width: 460 }}>
          <FeatureList features={features} />
        </div>
      </div>
    </Modal>
  )
}

function priceStr(p: number) {
  return `${Math.round((p || 0) / 100)}.${padded(Math.round((p || 0) % 100))}`

  function padded(n: number) {
    if (n < 10) return '0' + n
    return '' + n
  }
}

const FeatureList: FC<{ features: { title: ReactNode; value: boolean | string }[] }> = ({ features }) => {
  return (
    <>
      {features.map((f, index) => (
        <div
          key={index}
          style={{
            display: 'flex',
            backgroundColor: index % 2 ? '#1C1D1E' : '#28282C',
            height: 54,
            alignItems: 'center'
          }}
        >
          <div
            style={{
              display: 'inline-flex',
              flex: 1,
              width: 0,
              background: 'linear-gradient(to right, #D4FF00, #89E56B, #32C6E7)',
              backgroundClip: 'text',
              WebkitBackgroundClip: 'text',
              color: 'transparent',
              justifyContent: 'center'
            }}
          >
            {f.title}
          </div>
          <div
            style={{
              display: 'inline-flex',
              flex: 1,
              width: 0,
              color: 'white',
              justifyContent: 'center'
            }}
          >
            {typeof f.value === 'boolean' ? f.value ? <FilledCheck /> : <FilledCross /> : f.value}
          </div>
        </div>
      ))}
    </>
  )
}
