import { Button, Input, notification } from 'antd'
import React, { FC, useContext, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { BehaviorSubject } from 'rxjs'
import { useQueryClient } from '@tanstack/react-query'
import { useAPI } from '../api'
import { Providing, roomId, templateId } from '../global-vars'
import { NumericInput } from '../numeric-input'
import { useBehaviorSubject } from '../react-rx'
import { ReactComponent as ChevronLeft } from '../res/chevron-left.svg'
import { background, bgImg, useRoomTemplates } from '../script-and-layer/states'
import { BackgroundList } from './room-setting'

export const RoomTemplateEditor: FC = () => {
  const templates = useRoomTemplates()
  const rid = useContext(roomId)
  const tid = useContext(templateId)
  const editingTemplate = useMemo(() => (tid ? templates?.find((t) => t.id === tid) : undefined), [templates, tid])
  const [category, setCategory] = useBehaviorSubject(
    useMemo(() => new BehaviorSubject(editingTemplate?.category || ''), [editingTemplate])
  )
  const [buttonText, setButtonText] = useBehaviorSubject(
    useMemo(() => new BehaviorSubject(editingTemplate?.button_text || ''), [editingTemplate])
  )
  const [order, setOrder] = useBehaviorSubject(
    useMemo(() => new BehaviorSubject<number>(editingTemplate?.order || 0), [editingTemplate])
  )
  const [name, setName] = useBehaviorSubject(
    useMemo(() => new BehaviorSubject(editingTemplate?.name || ''), [editingTemplate])
  )
  const previewSubj = useMemo(
    () =>
      new BehaviorSubject<bgImg | undefined>({
        key: editingTemplate?.preview_key || '',
        w: 1,
        h: 1
      }),
    [editingTemplate]
  )
  const [saving, setSaving] = useState(false)
  const queryClient = useQueryClient()
  const api = useAPI()
  const navigate = useNavigate()
  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
        padding: 24,
        boxSizing: 'border-box'
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 20 }}>
        <Link
          to="/templates"
          replace
          style={{
            color: '#6B83FF',
            marginRight: 12,
            display: 'inline-flex',
            alignContent: 'center',
            alignItems: 'center',
            textDecoration: 'none'
          }}
        >
          <ChevronLeft style={{ marginRight: 8 }} />
          返回
        </Link>
        <Button
          type="primary"
          loading={saving}
          onClick={async () => {
            setSaving(true)
            try {
              if (editingTemplate) {
                await api.post(`/templates/${editingTemplate.id}`, {
                  category,
                  name,
                  preview_key: previewSubj.value?.key,
                  button_text: buttonText,
                  order
                })
              } else {
                await api.post(`/rooms/${rid}/template`, {
                  category,
                  name,
                  preview_key: previewSubj.value?.key,
                  button_text: buttonText,
                  order
                })
                await queryClient.invalidateQueries({ queryKey: ['/templates'] })
              }
              navigate('/templates')
            } catch (e: any) {
              notification.error(e)
            } finally {
              setSaving(false)
            }
          }}
        >
          保存
        </Button>
      </div>
      <div style={{ position: 'relative', marginBottom: 20, width: '100%' }}>
        <Input className="input-with-hint" value={name} onChange={(e) => setName(e.target.value)} disabled={saving} />
        <div className="input-hint">模版标题</div>
      </div>
      <div style={{ position: 'relative', marginBottom: 20, width: '100%' }}>
        <Input
          className="input-with-hint"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          disabled={saving}
        />
        <div className="input-hint">模版类别</div>
      </div>
      <div style={{ position: 'relative', marginBottom: 20, width: '100%' }}>
        <Input
          className="input-with-hint"
          value={buttonText}
          onChange={(e) => setButtonText(e.target.value)}
          disabled={saving}
        />
        <div className="input-hint">按钮文字</div>
      </div>
      <div style={{ position: 'relative', marginBottom: 20, width: '100%' }}>
        <NumericInput
          className="input-with-hint"
          value={order}
          onChange={(e) => setOrder(Math.floor(e))}
          disabled={saving}
        />
        <div className="input-hint">顺序</div>
      </div>
      <Providing _={(_) => _(background, previewSubj)}>
        <BackgroundList />
      </Providing>
    </div>
  )
}
