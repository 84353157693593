import axios from 'axios'
import { useContext } from 'react'
import { useQuery } from '@tanstack/react-query'
import { resourceUrl } from '../../global-vars'

export type SensitiveRule = {
  tip: string
  text: string
}
/**
 * 敏感词检测规则
 * @returns
 */
export function useSensitiveCheckRulesConfig(): Array<SensitiveRule> {
  const url = useContext(resourceUrl)
  const config =
    useQuery({
      queryKey: ['/sensitive-check-rules'],
      suspense: true,
      queryFn: async () => {
        return (await axios.get(url(`sensitive-check-rules.json?v=${Date.now()}`))).data as Array<SensitiveRule>
      }
    }).data || []
  return config
}
