import '../index.sass'
import { Button } from 'antd'
import { FC, Suspense, useContext, useMemo, useRef, useState } from 'react'
import { BehaviorSubject } from 'rxjs'
import { Providing, wsContext } from '../global-vars'
import { getTimeDesc } from '../lib/util'
import { useBehaviorSubject } from '../react-rx'
import { scriptTab } from '../room/states'
import { Render, tuple } from '../types'
import { DndType } from './draggable'
import { PlainTextProductList } from './plain-text-product-list'
import { chatGPTContext, mouseInTextEditor, ScriptCard, scriptType, segments } from './script-card'
import {
  intelligentUniversalConfig,
  save,
  ScriptSegment,
  scriptTemplateConfig,
  segmentKey,
  segmentTitle
} from './states'

export const IntelligentUniversalScriptEditor: FC<{
  useConfig: () => scriptTemplateConfig<intelligentUniversalConfig>
}> = ({ useConfig }) => {
  const [s] = useBehaviorSubject(useContext(segments))
  const endOfList = useRef<HTMLDivElement>(null)
  const mouseInTextEditorSubj = useMemo(() => new BehaviorSubject(false), [])
  const [, setTab] = useBehaviorSubject(useContext(scriptTab))
  const ws = useContext(wsContext)
  const config = useConfig()
  const uniqueS = s.filter((q, i) => s.findIndex((r) => r.title === q.title) === i)

  const textNum: number = useMemo(() => {
    return s.reduce((pre, next) => {
      const num =
        next.line_contents?.reduce((p, n) => {
          return p + (n.text?.trim().length || 0)
        }, 0) || 0

      return pre + num
    }, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [s])

  const textTime = useMemo(() => {
    const num = (textNum || 0) * 0.15
    return getTimeDesc(num)
  }, [textNum])

  return (
    <Providing
      _={(p) => {
        p(mouseInTextEditor, mouseInTextEditorSubj)
      }}
    >
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: '100%',
          backgroundColor: '#28282C',
          borderRadius: 4,
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: 24,
            paddingRight: 24,
            paddingTop: 24,
            paddingBottom: 8
          }}
        >
          <div style={{ display: 'inline-flex', color: 'white', alignItems: 'center' }}>
            <span style={{ fontSize: 20, fontWeight: 'bolder' }}>{config.title}</span>
            <span style={{ color: '#6B83FF', marginLeft: 8, cursor: 'pointer' }} onClick={() => setTab('script_type')}>
              重选
            </span>
            <div style={{ display: 'flex', alignItems: 'center', color: '#fff', opacity: '0.7', marginLeft: 40 }}>
              <label>字数：{textNum}</label>
              <label style={{ position: 'relative', top: '-0.5px', padding: '0 6px' }}>|</label>
              <label>预估时长：{textTime || 0}</label>
            </div>
          </div>
          <Suspense>
            <Render>
              {function NewSegment() {
                const [productOpen, setProductOpen] = useState(false)
                const segmentsSubj = useContext(segments)
                const [stype] = useBehaviorSubject(useContext(scriptType))
                const chatGPT = useContext(chatGPTContext)
                const script = config.script
                const saveSubj = useContext(save)

                const getSegmentsByslot = (slot: string) => {
                  return segmentsSubj.value?.filter(
                    (s) => s?.attributes?.length && s?.attributes[0].slot?.slot === slot
                  )
                }

                return (
                  <div>
                    <PlainTextProductList
                      title="添加商品"
                      dType={stype}
                      open={productOpen}
                      onCancel={() => setProductOpen(false)}
                      reorderable
                      onConfirm={() => {
                        setProductOpen(false)
                        if (stype === 'save_up_popularity') {
                          let lurking = getSegmentsByslot('lurking')
                          lurking.splice(7, lurking.length)
                          const save_up_1 = getSegmentsByslot('save_up_1')
                          const save_up_2 = getSegmentsByslot('save_up_2')
                          const welfare = getSegmentsByslot('welfare')
                          const products = segmentsSubj.value?.filter(
                            (s) => s?.attributes?.length && s?.attributes[0].product?.product_id
                          )
                          lurking = [
                            ...lurking,
                            ...products.map((r) => {
                              return {
                                ...r,
                                attributes: [{ slot: { slot: 'lurking' } }]
                              }
                            })
                          ]

                          if (chatGPT.value.length) {
                            const welfareContent = chatGPT.value[0].content
                            let content: any[] = []
                            if (script.lurking) {
                              const l = script.lurking[3]
                              content = [l.candidates[Math.floor(Math.random() * l.candidates.length)]]
                            }

                            lurking[3].key = chatGPT.value[0].product_id
                            const lc = [...content, ...welfareContent].map((t: string) => {
                              return {
                                text: t
                              }
                            })
                            lurking[3].line_contents = lc
                            save_up_1[1].line_contents = lc
                            save_up_2[0].line_contents = lc
                            welfare[2].line_contents = lc
                            welfare[8].line_contents = lc
                          }

                          segmentsSubj.next([
                            ...lurking,
                            ...save_up_1,
                            ...save_up_2,
                            ...welfare,
                            ...getSegmentsByslot('before_all_products'),
                            ...getSegmentsByslot('before_each_product'),
                            ...segmentsSubj.value?.filter(
                              (s) => s?.attributes?.length && s?.attributes[0].product?.product_id
                            ),
                            ...getSegmentsByslot('after_each_product'),
                            ...getSegmentsByslot('after_all_products')
                          ])
                          saveSubj.next(false)
                        } else {
                          const sorted = segmentsSubj.value
                            ?.map((s, index) => tuple(sortByType(s), index, s))
                            .sort((l, r) => {
                              if (l[0] !== r[0]) return l[0] - r[0]
                              return l[1] - r[1]
                            })
                            .map(([, , s]) => s)
                          segmentsSubj.next(sorted)
                        }
                      }}
                    />
                    <Button type="primary" onClick={() => setProductOpen(true)}>
                      配置商品
                    </Button>
                  </div>
                )
              }}
            </Render>
          </Suspense>
        </div>
        <div
          style={{ width: '100%', height: 0, flex: 1, overflowY: 'auto', overflowX: 'hidden' }}
          onMouseEnter={() => mouseInTextEditorSubj.next(true)}
          onMouseLeave={() => mouseInTextEditorSubj.next(false)}
        >
          <DndType>
            {uniqueS.map((s) => (
              <div key={s.key} style={{ marginBottom: 20, marginRight: 20 }}>
                <Providing
                  _={(p) => {
                    p(segmentKey, s.key)
                    p(segmentTitle, s.title)
                    p(wsContext, ws)
                  }}
                >
                  <ScriptCard
                    reorderable={s.attributes?.some((a) => a.product?.product_id)}
                    deletable={s.attributes?.some((a) => a.product?.product_id)}
                  />
                </Providing>
              </div>
            ))}
          </DndType>
          <div ref={endOfList} />
        </div>
      </div>
    </Providing>
  )
}

function sortByType(s: ScriptSegment) {
  for (let a of s.attributes || []) {
    if (a.slot?.slot === 'intro') return 0
    if (a.slot?.slot === 'lurking') return 1
    if (a.slot?.slot === 'save_up_1') return 2
    if (a.slot?.slot === 'save_up_2') return 3
    if (a.slot?.slot === 'welfare') return 4
    if (a.slot?.slot === 'before_all_products') return 5
    if (a.slot?.slot === 'before_each_product') return 6
    if (a.product?.product_id) return 7
    if (a.slot?.slot === 'after_each_product') return 8
    if (a.slot?.slot === 'after_all_products') return 9
  }
  return 10
}
