import './index.scss'
import React, { FC, useEffect, useState } from 'react'

interface IProps {
  t: number
}

export const CountDown: FC<IProps> = (props) => {
  const { t } = props
  const [time, setTime] = useState(0)

  useEffect(() => {
    t && setTime(t)
  }, [t])

  useEffect(() => {
    const interval = setInterval(() => {
      if (time > 0) {
        setTime(time - 1)
      } else {
        clearInterval(interval)
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [time])

  const fillZero = (n: number) => {
    return n < 10 ? `0${n}` : n
  }

  const getTimeBySeconds = (n: number) => {
    const hour = Math.floor(n / 3600)
    const minute = Math.floor((n % 3600) / 60)
    const second = n % 60
    return `${fillZero(hour)}:${fillZero(minute)}:${fillZero(second)}`
  }

  return <span className="count-down">{getTimeBySeconds(time)}</span>
}
