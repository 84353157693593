import './index.css'
import { ConfigProvider } from 'antd'
import React from 'react'
import ReactDOM from 'react-dom/client'
import * as Sentry from '@sentry/react'
import App from './App'
import reportWebVitals from './reportWebVitals'

if (window.location.href.indexOf('localhost') !== -1)
  Sentry.init({
    dsn: 'https://826f79c361174d43a292922528d7d6cb@sentry.lingverse.co/11'
  })

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <ConfigProvider theme={{ token: { colorPrimaryHover: 'red' } }}>
      <App />
    </ConfigProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
