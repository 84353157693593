import { Button, Col, Form, Input, Modal, Row, Space, Spin, Typography } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import Upload, { UploadFile } from 'antd/es/upload'
import { useContext, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { BehaviorSubject } from 'rxjs'
import { PlusOutlined } from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import { useAPI, useGet } from '../api'
import { resourceUrl } from '../global-vars'
import { useBehaviorSubject } from '../react-rx'
import { ReactComponent as ProductSvg } from '../res/product.svg'
import { Resource } from '../room/states'

import type { RcFile } from 'antd/es/upload'
import type { Product } from './product_center_page'

const { Text } = Typography

function AddProductPage() {
  const location = useLocation()
  const api = useAPI()
  const client = useQueryClient()
  const url = useContext(resourceUrl)
  const [product, setProduct] = useState<Product>(
    location.state !== null
      ? (location.state as Product)
      : {
          id: '',
          name: '',
          description: '',
          price: 0,
          long_description: '',
          skuId: '',
          state: 0,
          platform: ''
        }
  )

  let oldResources = useGet<{ resources: Resource[] }>(`products/${product.id}/resources`).data?.resources
  const fileListSubj = useMemo(() => {
    let oldFileList: UploadFile[] =
      oldResources?.map((v) => ({
        uid: v.id,
        name: v.id,
        url: url(v.key) + (v.type === 'videos' ? '?x-oss-process=video/snapshot,t_0,m_fast' : '')
      })) ?? []
    return new BehaviorSubject(oldFileList || [])
  }, [oldResources, url])

  const [fileList, setFileList] = useBehaviorSubject(fileListSubj)
  const [priceRawValue, setPriceRawValue] = useState(product.price === 0 ? '' : (product.price / 100).toString())
  const [skuId, setSkuId] = useState(product.skuId)

  const [fileToResourceId] = useState(new Map<string, string>())
  const [fileToPreviewUrl] = useState(new Map<string, string>())

  const navigate = useNavigate()

  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')

  const handleCancel = () => setPreviewOpen(false)

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result as string)
      reader.onerror = (error) => reject(error)
    })

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile)
    }

    setPreviewImage(file.url || (file.preview as string))
    setPreviewOpen(true)
    let title = ''
    let url = file.url || file.preview
    if (url !== undefined) {
      let end: number | undefined = url.indexOf('?')
      if (end === -1) end = undefined
      title = url.substring(url.lastIndexOf('/', end) + 1, end)
    } else {
      title = file.name
    }
    setPreviewTitle(title)
  }

  const uploadButton = (
    <Space direction="vertical">
      <PlusOutlined style={{ color: 'white' }} />
      <Text style={{ color: 'white' }}>上传商品素材</Text>
    </Space>
  )

  const [loading, setLoading] = useState(false)

  const addProduct = async (values: any) => {
    setLoading(true)
    try {
      const vals = priceRawValue.split('.')
      if (vals.length > 1) {
        const p = `${vals[0]}.${vals[1].substring(0, 2)}`
        product.price = (Number(p) * 1000) / 10
      } else {
        product.price = Number(priceRawValue) * 100
      }
      product.skuId = skuId

      if (product.id.length !== 0) await api.post('/products/' + product.id, product)
      else {
        let result = await api.post<Product>('/products', product)
        product.id = result.data.id
      }

      let oldList = oldResources?.map((v) => v.id) ?? []
      let newList = fileList.map((v) => {
        if (v.url === undefined) return fileToResourceId.get(v.uid)!
        else return v.uid
      })

      for (let index = oldList.length - 1; index >= 0; index--) {
        const id = oldList[index]
        let index2 = newList.indexOf(id)
        if (index2 !== -1) {
          oldList.splice(index, 1)
          newList.splice(index2, 1)
        }
      }
      let promises: Promise<any>[] = []
      oldList.forEach((v) => {
        promises.push(api.delete(`/products/${product.id}/resources/${v}`))
      })
      newList.forEach((v) => {
        promises.push(api.post(`/products/${product.id}/resources`, { id: v }))
      })
      await Promise.all(promises)
    } catch (e) {
      setLoading(false)
      return
    }
    navigate(-1)
  }

  return (
    <Spin spinning={loading}>
      <div style={{ padding: '16px 21px 0px 32px' }}>
        <Form onFinish={addProduct}>
          <Row justify="space-between" align="middle">
            <Row align="middle">
              <ProductSvg stroke="#6D6D6D" />
              <Text style={{ color: '#6D6D6D' }}>商品管理</Text>
              <Text style={{ color: '#6D6D6D', marginLeft: 5, marginRight: 5 }}>/</Text>
              <Text
                style={{
                  color: 'white'
                }}
              >
                {product.id.length === 0 ? '添加商品' : 'ID: ' + product.id}
              </Text>
            </Row>
            <Col>
              <Button style={{ marginRight: 24 }} onClick={() => navigate(-1)}>
                取消
              </Button>
              <Button type="primary" htmlType="submit">
                确定
              </Button>
            </Col>
          </Row>

          <Space direction="vertical" style={{ marginTop: 25, width: '100%' }}>
            <Row>
              <Col span={12}>
                <Form.Item name="name">
                  <div style={{ position: 'relative', marginRight: 30 }}>
                    <Input
                      className="input-with-hint"
                      value={product.name}
                      onChange={(e) => setProduct((old) => ({ ...old, name: e.target.value }))}
                      placeholder="输入商品名称"
                    />
                    <div className="input-hint">
                      <span style={{ color: 'red' }}>*</span>
                      商品名称
                    </div>
                  </div>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="price">
                  <div style={{ position: 'relative', marginRight: 30 }}>
                    <Input
                      className="input-with-hint"
                      prefix="￥"
                      value={priceRawValue.length === 0 ? '' : priceRawValue}
                      onChange={(e) => {
                        let rawValue = e.target.value
                        if (!Number.isNaN(Number(rawValue))) setPriceRawValue(rawValue)
                        if (rawValue === '123') {
                          console.log('fake error')
                          throw Error('Invalid price')
                        }
                      }}
                      placeholder="0.00"
                    />
                    <div className="input-hint">
                      <span style={{ color: 'red' }}>*</span>
                      商品价格
                    </div>
                  </div>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="skuId">
                  <div style={{ position: 'relative' }}>
                    <Input
                      className="input-with-hint"
                      value={skuId}
                      onChange={(e) => {
                        setSkuId(e.target.value)
                      }}
                    />
                    <div className="input-hint">商品skuId</div>
                  </div>
                </Form.Item>
              </Col>
            </Row>
            <Col span={24}>
              <Form.Item name="description">
                <div style={{ position: 'relative' }}>
                  <TextArea
                    className="input-with-hint"
                    value={product.description}
                    onChange={(e) => setProduct((old) => ({ ...old, description: e.target.value }))}
                    placeholder="输入商品详情"
                    autoSize={{ minRows: 5 }}
                  />
                  <div className="input-hint">
                    <span style={{ color: 'red' }}>*</span>
                    商品详情
                  </div>
                </div>
              </Form.Item>
            </Col>
            <div style={{ color: 'white' }}>
              {/* <span style={{color: 'red'}}>*</span> */}
              商品素材
            </div>
            <Form.Item name="resources">
              <Upload
                listType="picture-card"
                // style={{ width: 152, height: 152 }}
                fileList={fileList}
                onPreview={handlePreview}
                accept=".jpg,.jpeg,.JPG,.JPEG,.png,.PNG,.mp4"
                customRequest={async ({ onSuccess, onError, file, onProgress }) => {
                  const path = `/resources`
                  const rcFile = file as RcFile
                  try {
                    const segs = (rcFile.name || '').split(/\./)
                    const { resource, upload_url } =
                      (
                        await api.post<{ resource: Resource; upload_url: string }>(
                          path,
                          {
                            extension: segs[segs.length - 1],
                            tag: 'decor'
                          },
                          {
                            refreshMode: 'disabled'
                          } as any
                        )
                      ).data || {}
                    if (!upload_url) {
                      throw new Error('failed to upload file')
                    }
                    fileToPreviewUrl.set(rcFile.uid, url(resource.key))
                    await api.put(upload_url.replace(/^http:\/\//, 'https://').replace('-internal', ''), rcFile, {
                      headers: { 'Content-Type': 'multipart/form-data' },
                      onUploadProgress: (event) => {
                        onProgress?.({
                          lengthComputable: true,
                          ...event,
                          percent: (event.loaded / (event.total || rcFile.size)) * 100
                        })
                      }
                    })
                    await client.invalidateQueries({ queryKey: [path] })
                    onSuccess?.(undefined)
                    fileToResourceId.set(rcFile.uid, resource.id)
                  } catch (err: any) {
                    onError?.({ err } as any)
                    throw err
                  }
                }}
                onChange={(info) => {
                  info.fileList.forEach((file) => {
                    if (file.type?.startsWith('video')) {
                      let url = fileToPreviewUrl.get(file.uid) + '?x-oss-process=video/snapshot,t_0,m_fast'
                      file.preview = url
                      file.thumbUrl = url
                    }
                  })
                  setFileList(info.fileList)
                }}
              >
                {fileList.length >= 8 ? null : uploadButton}
              </Upload>
            </Form.Item>
            <Col span={24}>
              <Form.Item name="long_description">
                <div style={{ position: 'relative' }}>
                  <TextArea
                    className="input-with-hint"
                    value={product.long_description}
                    onChange={(e) => setProduct((old) => ({ ...old, long_description: e.target.value }))}
                    placeholder="输入核心卖点"
                    autoSize={{ minRows: 7 }}
                  />
                  <div className="input-hint" style={{ fontSize: 14 }}>
                    核心卖点
                  </div>
                </div>
              </Form.Item>
            </Col>
          </Space>
        </Form>
        <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </div>
    </Spin>
  )
}

export default AddProductPage
