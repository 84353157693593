import './index.scss'
import { Dropdown, MenuProps } from 'antd'
import { FC, useEffect, useState } from 'react'
import { CaretDownOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { ReactComponent as CustomerServiceIcon } from '../../res/customer-service.svg'
import { ReactComponent as HelpDocIcon } from '../../res/help-doc.svg'

const CustomerService: FC = () => {
  const [showQR, setShowQR] = useState<boolean>(false)
  const [open, setOpen] = useState(false)

  const items: MenuProps['items'] = [
    {
      label: '添加客服',
      key: '0',
      icon: <CustomerServiceIcon />
    },
    {
      label: (
        <a target="_blank" rel="noreferrer" href="https://lingverse.feishu.cn/docx/ImOkdaSDloajorxgcqKc2S0nnPb">
          帮助文档
        </a>
      ),
      key: '1',
      icon: <HelpDocIcon />
    }
  ]

  const onItemClick: MenuProps['onClick'] = ({ key }) => {
    if (key === '0') {
      setShowQR(true)
      setOpen(true)
    }
  }

  useEffect(() => {
    if (!open) {
      setShowQR(false)
    }
  }, [open])

  return (
    <Dropdown
      menu={{ items, onClick: onItemClick }}
      placement="topRight"
      overlayClassName="customer-dropdown"
      trigger={['click']}
      open={open}
      onOpenChange={setOpen}
    >
      <div className="customer-service" onClick={(e) => e.preventDefault()}>
        {open ? <CaretDownOutlined /> : <QuestionCircleOutlined />}
        {showQR && <img className="qr-service" src={require('../../res/qr-service.webp')} alt="客服二维码" />}
      </div>
    </Dropdown>
  )
}

export default CustomerService
