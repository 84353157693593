/* eslint-disable jsx-a11y/anchor-has-content */
import './index.scss'
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Dropdown, Input, Menu, message, Modal, Popover, Select } from 'antd'
import moment from 'moment'
import React, {
  FC,
  forwardRef,
  lazy,
  Suspense,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react'
import { Link } from 'react-router-dom'
import { useSessionStorage } from 'react-use-storage'
import Recorder from 'recorder-core/recorder.wav.min'
import { connectable, filter, interval, map, retry, startWith, Subscription, timer } from 'rxjs'
import * as uuid from 'uuid'
import {
  AudioOutlined,
  InfoCircleOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  QuestionCircleOutlined,
  SendOutlined,
  UnorderedListOutlined
} from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import { useAPI } from '../api'
import { currentUser, usePermission } from '../auth/states'
import { CountDown } from '../components/base/countDown'
import { BaseModal } from '../components/base/modal'
import {
  devDomains,
  prodDomains,
  Providing,
  resourceUrl,
  roomId,
  WithPermission,
  wsContext,
  wsMessages
} from '../global-vars'
import { useEventCallback } from '../hooks'
import { getMsgTypeText, getSaveOrPlayInfo } from '../lib/common'
import { PlansModal } from '../plan/plans-modal'
import { Product, useUserPackage } from '../plan/states'
import { ReactComponent as Bang } from '../res/bang.svg'
import { ReactComponent as Copy } from '../res/copy.svg'
import { ReactComponent as NewScreen } from '../res/new-screen.svg'
import qrCode from '../res/qr.png'
import { ReactComponent as Trash } from '../res/trash.svg'
import { useRoomSetting, useSession } from '../room/states'
import { Render } from '../types'
import { RoomConnection } from './room-connection'
import { VoiceTakeOver } from './takeover'
import { useSessionConnection } from './websocket'

const FlvLivePlayer = lazy(() => import('./player'))

export const controlButtonStyle = {
  height: '100%',
  marginLeft: 8
}

const LiveControl: FC = () => {
  return (
    <div style={{ display: 'flex', height: '100%', width: '100%' }}>
      <div
        style={{
          boxSizing: 'border-box',
          height: '100%',
          flex: 1,
          width: '100%',
          padding: '24px 24px 16px 24px'
        }}
      >
        <ControlPanel />
      </div>
    </div>
  )
}

export const ServerErrorModal: FC<{ open: boolean; onClose: () => void }> = ({ open, onClose }) => {
  return (
    <Modal
      title="提示"
      open={open}
      onCancel={onClose}
      footer={[
        <Button type="primary" onClick={onClose}>
          确认
        </Button>
      ]}
      bodyStyle={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: 40
      }}
    >
      <Bang />
      <div style={{ marginTop: 18, fontWeight: 'bolder' }}>老板别急，马上就好！</div>
      <div style={{ color: '#B4B4B4', marginTop: 12 }}>目前开播人数较多，系统正在优化升级，请稍后重试</div>
    </Modal>
  )
}
export const LiveLimitModal: FC<{
  open: boolean
  onCancel?: (e: React.MouseEvent<HTMLButtonElement>) => void
  onOk?: () => void
}> = ({ open, onCancel, onOk }) => {
  return (
    <Modal
      title="提示"
      open={open}
      onCancel={onCancel}
      footer={[
        <Button type="primary" onClick={onOk}>
          确认
        </Button>
      ]}
      bodyStyle={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: 40
      }}
    >
      <Bang />
      <div style={{ marginTop: 18, fontWeight: 'bolder' }}>超出同时直播限额</div>
      <div style={{ color: '#B4B4B4', marginTop: 12 }}>已有直播间在进行直播，需停止其他直播后才可开播</div>
      <Link to="/rooms" onClick={onOk} style={{ marginTop: 16, marginBottom: 40 }}>
        {'打开直播管理页 >'}
      </Link>
    </Modal>
  )
}

export const TimeRemainedModal: FC<{
  open: boolean
  time: number
  onCancel?: (e: React.MouseEvent<HTMLButtonElement>) => void
  onOk?: () => void
}> = ({ time, open, onCancel }) => {
  const exhaustive = time === 0

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      footer={null}
      bodyStyle={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '20px 40px 0 28px'
      }}
      className="time-remain-modal"
      width={exhaustive ? 440 : 625}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Bang style={{ position: 'relative', top: 1 }} />
        {exhaustive ? (
          <div style={{ paddingLeft: 15 }}>直播时长已用尽，请联系商务充值时长</div>
        ) : (
          <div style={{ paddingLeft: 15 }}>
            直播时长即将用尽，请联系商务充值时长
            <span style={{ paddingLeft: 12 }}>
              <label>直播时长剩余</label>
              <CountDown t={time} />
            </span>
          </div>
        )}
      </div>
      <img src={qrCode} alt="qr" style={{ width: 250, margin: '26px 0 16px 0' }} />
    </Modal>
  )
}

type MKCommand = { time: string; params: object; command: string; status: string; source?: string }

const ControlPanel: FC = () => {
  const globalSession = useSession()
  const rid = useContext(roomId)
  const session = useMemo(
    () => (!globalSession?.room_id || globalSession.room_id === rid ? globalSession : undefined),
    [globalSession, rid]
  )
  const [history, setHistory] = useState<{ time: string; msg: string; source?: string }[]>([])
  const [mkHistory, setMKHistory] = useState<MKCommand[]>([])
  let lastCmd = ''
  const [comment, setComment] = useState<
    { time: string; type: string; time_stamp: number; user_name: string; content: string }[]
  >([])
  const [messages, ws] = useSessionConnection()
  const msgEnd = useRef<HTMLDivElement>(null)
  const msgMKEnd = useRef<HTMLDivElement>(null)
  const commentEnd = useRef<HTMLDivElement>(null)
  const [limitModalOpen, setLimitModalOpen] = useState(false)
  const [timeRemainedModalOpen, setTimeRemainedModalOpen] = useState(false)
  const [timeRemained, setTimeRemained] = useState<number>(0)
  const [promotingProduct, setPromotingProduct] = useState<Product>()
  const [help, setHelp] = useState<{ docUrl: string; videoUrl: string }>()
  const [serverErrorOpen, setServerErrorOpen] = useState(false)
  const url = useContext(resourceUrl)
  const [baseModalInfo, setBaseModalInfo] = useState<any>()
  const [onOk, setOnOk] = useState<any>()
  const [preStream, setPreStream] = useState<any>()
  const api = useAPI()
  const setting = useRoomSetting(true)
  const [ratio, setRatio] = useState(1)
  const [prepare, setPrepare] = useState(false)
  const [starting, setStarting] = useState(false)
  const timerRef = useRef<any>()
  const playRef = useRef<any>()
  const [collapsed, setCollapsed] = useState(true)
  const [cacheTakeOverType, setCacheTakeOverType] = useSessionStorage('_takeOver_' + rid, 0)
  const isVoiceTake = cacheTakeOverType === 1 || cacheTakeOverType === 2
  const [playKey, setPlayKey] = useState('')
  const playSegmentRef = useRef<any>()
  const [menuKey, setMenuKey] = useState<any>()
  const meunKeyRef = useRef<any>()
  const permission = usePermission()

  const pause = () => {
    playRef.current?.pause()
  }

  const play = () => {
    playRef.current?.play()
  }

  const segmentsList = useMemo(() => {
    const sections = setting?.setting?.script?.sections || []
    return sections.map((section) => ({
      ...section,
      label: section.title,
      key: section.key,
      children: (section.segments || []).map((s) => ({
        ...s,
        label: s.title,
        key: s.key
      }))
    }))
  }, [setting?.setting?.script?.sections])

  const defaultOpenKeys = useMemo(() => {
    return segmentsList.map((s) => `${s.key}`)
  }, [segmentsList])

  useEffect(() => {
    return () => clearTimer()
  }, [])

  useEffect(() => {
    setRatio(setting.setting.ratio || 1)
  }, [setting])

  useEffect(() => {
    console.log(1111, playKey)
    playSegmentRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [playKey])

  useEffect(() => {
    meunKeyRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [menuKey])

  ws((_) => {
    _.onmessage = (res) => {
      const data: any = JSON.parse(res.data)
      const type = data.type
      if (type === 'live-time-check') {
        const info = getSaveOrPlayInfo('live-time-check', data)
        setBaseModalInfo(info)
      } else if (type === 'live_time_about_to_exhausted') {
        setTimeRemainedModalOpen(true)
        setTimeRemained(data.seconds_remained)
      } else if (type === 'live-time-exhausted') {
        setTimeRemainedModalOpen(true)
        setTimeRemained(0)
      } else if (type === 'resource_exhausted_error') {
        setPrepare(true)
      } else if (type === 'session_completed') {
        setStarting(false)
      } else if (type === 'takeover_type') {
        setCacheTakeOverType(data.takeover_type)
      }
    }
  })

  useEffect(() => {
    if (!session?.video_url) {
      setCacheTakeOverType(0)
      return
    }
    const subscription = timer(0, 10_000).subscribe(() =>
      ws((_) => _.send(JSON.stringify({ type: 'healthcheck_live_message' })))
    )
    setPreStream(undefined)
    setPrepare(false)
    setStarting(false)
    clearTimer()
    return () => subscription.unsubscribe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session?.video_url])
  useEffect(() => {
    const subscription = new Subscription()
    const messageJson = connectable(
      messages.pipe(
        map((s) => JSON.parse(s)),
        retry()
      )
    )
    subscription.add(
      messageJson
        .pipe(
          filter((o) => o?.type === 'video_synthesis_error'),
          map(() => true)
        )
        .subscribe(setServerErrorOpen)
    )
    subscription.add(
      messageJson
        .pipe(
          filter((o) => o?.type === 'text' && typeof o?.content === 'string'),
          map((o) => ({ time: now(), msg: o.content, source: o.source }))
        )
        .subscribe((item) => {
          setHistory((h) => [...h, item])
          setTimeout(() => msgEnd.current?.scrollIntoView({ behavior: 'smooth' }))
        })
    )
    subscription.add(
      messageJson.pipe(filter((o) => o?.type === 'mk_response' || o?.type === 'mk-next')).subscribe((item) => {
        item.mk_response?.forEach((cmd: MKCommand) => {
          if (cmd.status !== 'stop') {
            cmd.time = now()
            cmd.source = item?.type
            cmd.params = cmd.params ?? {}
            setMKHistory((h) => [...h, cmd])
          }
        })
        setTimeout(() => msgMKEnd.current?.scrollIntoView({ behavior: 'smooth' }))
      })
    )
    subscription.add(
      messageJson
        .pipe(
          filter((o) => ['comment', 'enter', 'like', 'gift', 'social'].indexOf(o?.type) > -1),
          map((o) => ({ time: now(), ...o }))
        )
        .subscribe((item) => {
          setComment((h) => [...h, item])
          setTimeout(() => commentEnd.current?.scrollIntoView({ behavior: 'smooth' }))
        })
    )
    subscription.add(
      messageJson.pipe(filter((o) => o?.type === 'start_playing_segment')).subscribe((item) => {
        setPlayKey(item.segment_key)
      })
    )

    subscription.add(messageJson.connect())
    return () => subscription.unsubscribe()
  }, [messages, setCacheTakeOverType, setHistory])
  const videoRef = useRef<HTMLVideoElement>(null)

  const getPreStream = async () => {
    const res = (await api.post(`/rooms/${rid}/url/pre_generation`))?.data
    setPreStream(res)
  }

  const clearTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current)
      timerRef.current = undefined
    }
  }

  return (
    <div className="live-main">
      <BaseModal info={baseModalInfo} onOk={onOk} />
      <TimeRemainedModal
        open={timeRemainedModalOpen}
        onOk={() => setTimeRemainedModalOpen(false)}
        onCancel={() => setTimeRemainedModalOpen(false)}
        time={timeRemained}
      />
      <ServerErrorModal open={serverErrorOpen} onClose={() => setServerErrorOpen(false)} />
      <Modal
        title="帮助"
        open={!!help}
        width={1080}
        footer={null}
        onCancel={() => {
          setHelp(undefined)
          videoRef.current?.pause()
        }}
        bodyStyle={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}
      >
        <video
          width={1080}
          ref={videoRef}
          autoPlay
          controls
          controlsList="nodownload noplaybackrate"
          src={help?.videoUrl}
        />
        <a
          href={help?.docUrl}
          style={{ display: 'block', marginRight: 20, marginTop: 20 }}
          target="_blank"
          rel="noreferrer"
        >
          {'帮助链接>>'}
        </a>
      </Modal>
      <PlansModal
        product={promotingProduct}
        onCancel={() => setPromotingProduct(undefined)}
        onConfirm={() => setPromotingProduct(undefined)}
      />
      <LiveLimitModal
        open={limitModalOpen}
        onOk={() => setLimitModalOpen(false)}
        onCancel={() => setLimitModalOpen(false)}
      />
      <Providing
        _={(p) => {
          p(roomId, globalSession.room_id!)
          p(wsContext, ws)
          p(wsMessages, messages)
        }}
      >
        <div className="live-main-control">
          <div className="live-box box-segments left">
            <div className="section-title live-box-title">
              <a title="目录" onClick={() => setCollapsed(!collapsed)}>
                {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              </a>
              播放列表
            </div>

            <div className="live-box-main">
              <div className={`list-menu ${collapsed ? 'collapsed' : ''}`}>
                <Menu
                  mode="inline"
                  theme="light"
                  selectedKeys={[playKey]}
                  defaultOpenKeys={defaultOpenKeys}
                  items={segmentsList}
                  onClick={(menu) => {
                    setMenuKey(menu.key)
                  }}
                />
              </div>
              <div className="live-box-main__list">
                {segmentsList.map((s) => (
                  <div className="list">
                    <div className="title">
                      <label>{s.label}</label>
                    </div>
                    {s.children?.map((c) => (
                      <>
                        {playKey === c.key && session?.video_url && <div ref={playSegmentRef}></div>}
                        {menuKey === c.key && <div ref={meunKeyRef}></div>}
                        <div
                          className={`list-sub ${
                            playKey === c.key && session?.video_url && cacheTakeOverType === 0 ? 'active' : ''
                          }`}
                        >
                          <div className="title-sub">
                            <label className="text ellipsis">{c.label}</label>

                            {session?.video_url && (
                              <div className="ot">
                                <div className="play">
                                  <Button
                                    className="highlight-contentful"
                                    onClick={() => {
                                      message.success(`即将播放 ${c.title || ''}`)
                                      ws((_) =>
                                        _.send(
                                          JSON.stringify({
                                            type: 'play_segment_immediately',
                                            segment_key: c.key
                                          })
                                        )
                                      )
                                    }}
                                  >
                                    立即播放
                                  </Button>
                                  <Button
                                    className="highlight-contentful"
                                    onClick={() => {
                                      message.success(`稍后播放 ${c.title || ''}`)
                                      ws((_) =>
                                        _.send(
                                          JSON.stringify({
                                            type: 'play_segment',
                                            segment_key: c.key
                                          })
                                        )
                                      )
                                    }}
                                  >
                                    稍后播放
                                  </Button>
                                </div>
                                {c.attributes?.some((s) => s.enter_count) && <label className="ceil">指定触发</label>}
                                {playKey === c.key && session?.video_url && cacheTakeOverType === 0 && (
                                  <div className="living">
                                    <div className="loading-wave">
                                      <div className="loading-bar"></div>
                                      <div className="loading-bar"></div>
                                      <div className="loading-bar"></div>
                                      <div className="loading-bar"></div>
                                    </div>
                                    正在播放
                                  </div>
                                )}
                              </div>
                            )}
                          </div>

                          {c.line_contents?.map((l, index) => {
                            return index > 2 ? null : (
                              <p>{index === 2 && (c.line_contents?.length || 0) > 2 ? `${l.text || ''}...` : l.text}</p>
                            )
                          })}
                        </div>
                      </>
                    ))}
                  </div>
                ))}
              </div>
            </div>
            {isVoiceTake && (
              <div className="box-mask">
                <AudioOutlined />
                <p>真人麦克风接管中</p>
              </div>
            )}
          </div>
          <div className="center">
            <div className="live-box">
              <div className="section-title">
                连接直播间弹幕
                <span
                  style={{ color: '#6B83FF', fontWeight: 'normal', fontSize: 13, marginLeft: 6, cursor: 'pointer' }}
                  onClick={() => {
                    setHelp({
                      videoUrl: url('videos/connect_danmaku.mp4'),
                      docUrl: 'https://lingverse.feishu.cn/docx/IdqXdmjAEo2lecxGw4PcoOFynNe'
                    })
                  }}
                >
                  查看连接教程
                </span>
              </div>
              <RoomConnection />
            </div>

            <div className="live-box box-room">
              <div className="section-title">房间信息</div>
              <div
                style={{
                  width: '100%',
                  flex: 1,
                  backgroundColor: '#28282C',
                  borderRadius: 8,
                  overflow: 'auto',
                  color: 'white'
                }}
              >
                {comment.map(({ time, time_stamp, user_name, content, type }, index) => (
                  <div key={index} className="room-his-line">
                    <span>{moment(time_stamp * 1000).format('HH:mm:ss')}</span> <b>[{user_name}]</b>{' '}
                    <span className={type}>{type === 'comment' ? content : getMsgTypeText(type)}</span>
                  </div>
                ))}
                <div ref={commentEnd} />
              </div>
            </div>
          </div>

          <div className="right">
            <div className="live-box box-people-control">
              <div className="section-title">
                人工场控
                {/* <Dropdown
                  overlayStyle={{ color: '#fff', border: '1px solid #D4FF00', maxHeight: 300, overflowY: 'scroll' }}
                  disabled={!session?.room_id}
                  menu={segmentsList}
                  placement="bottom"
                >
                  <Button
                    className="border-button selected"
                    disabled={!session?.room_id}
                    style={{ position: 'absolute', top: 0, right: 0 }}
                  >
                    插播模块
                  </Button>
                </Dropdown> */}
              </div>
              <div
                style={{
                  width: '100%',
                  height: 0,
                  flex: 1,
                  backgroundColor: '#28282C',
                  borderRadius: 8,
                  overflow: 'auto',
                  color: 'white'
                }}
              >
                {history.map(({ time, msg, source }, index) => (
                  <div
                    key={index}
                    className="ctrl-his-line"
                    style={{
                      color: source === 'pattern_reply' ? '#D4FF00' : undefined
                    }}
                  >
                    <span>{time}</span> {msg}
                  </div>
                ))}
                <div ref={msgEnd} />
              </div>
              {isVoiceTake && (
                <div className="box-mask">
                  <AudioOutlined />
                  <p>真人麦克风接管中</p>
                </div>
              )}
            </div>
            <div
              style={{
                width: '100%',
                height: 32,
                marginTop: -8,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <Render>
                {function Send() {
                  const [text, setText] = useState('')
                  const [quickWordings, setQuickWordings] = useState<any>([])
                  const [editQuickWordings, setEditQuickWordings] = useState<any>([])
                  const [quickWordingsModal, setQuickWordingsModal] = useState(false)
                  const send = useEventCallback(() => {
                    if (!text) return
                    ws((_) => _.send(JSON.stringify({ type: 'text_at_end_of_segment', content: text })))
                    setText('')
                  })

                  const [recorder, setRecorder] = useState<typeof Recorder>()
                  const [audioSrc, setAudioSrc] = useState('')
                  const permission = usePermission()

                  useEffect(() => {
                    getQuickWordings()
                  }, [])

                  const getQuickWordings = async () => {
                    const data: any = await api.get(`/rooms/${rid}/quick_wordings`)
                    setQuickWordings(data?.data?.quick_wordings || [])
                  }

                  const updateQuickWordings = async () => {
                    if (editQuickWordings.some((q: any) => !q)) {
                      message.warning('常用话术内容不能为空')
                      return
                    }
                    await api.post(`/rooms/${rid}/quick_wordings`, {
                      quick_wordings: editQuickWordings
                    })
                    message.success('保存成功')
                    setQuickWordingsModal(false)
                    getQuickWordings()
                  }

                  return (
                    <>
                      <Modal
                        title="预览"
                        open={!!audioSrc}
                        onCancel={() => setAudioSrc('')}
                        footer={[
                          <Button
                            type="primary"
                            key="send"
                            onClick={() => {
                              ws((_) =>
                                _.send(
                                  JSON.stringify({
                                    type: 'play_wav',
                                    payload: (/.+;\s*base64\s*,\s*(.+)$/i.exec(audioSrc) || [])[1]
                                  })
                                )
                              )
                              setAudioSrc('')
                            }}
                          >
                            发送
                          </Button>
                        ]}
                      >
                        <audio src={audioSrc} controls />
                      </Modal>
                      <Modal
                        width={800}
                        title="快捷用语管理"
                        open={!!quickWordingsModal}
                        onCancel={() => setQuickWordingsModal(false)}
                        footer={[
                          <Button type="primary" onClick={updateQuickWordings}>
                            确认
                          </Button>
                        ]}
                      >
                        <div>
                          <div
                            style={{ padding: '20px', color: 'rgb(107, 131, 255)', cursor: 'pointer' }}
                            onClick={() => {
                              setEditQuickWordings((s: any) => [...s, ''])
                            }}
                          >
                            + 增加快捷用语
                          </div>
                          {!!editQuickWordings.length && (
                            <div style={{ padding: '0 20px', maxHeight: 340, overflowY: 'scroll', marginBottom: 28 }}>
                              {editQuickWordings.map((q: any, index: number) => (
                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
                                  <Input
                                    className="placeholder-gray borderless shadowless"
                                    style={{
                                      backgroundColor: '#28282C',
                                      color: '#fff',
                                      height: 40,
                                      flex: 1
                                    }}
                                    onChange={(e) => {
                                      setEditQuickWordings(
                                        editQuickWordings.map((edit: any, i: number) => {
                                          return i === index ? e.target.value : edit
                                        })
                                      )
                                    }}
                                    maxLength={50}
                                    value={q}
                                    placeholder="在这里添加直播间常用话术，直播中发送更快捷"
                                  />
                                  <Trash
                                    style={{ cursor: 'pointer', marginLeft: 10 }}
                                    width={32}
                                    onClick={() => {
                                      setEditQuickWordings(editQuickWordings.filter((e: any, i: number) => i !== index))
                                    }}
                                  />
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </Modal>
                      <div style={{ position: 'relative', width: 0, flex: 1, height: '100%' }}>
                        {!!session?.room_id && (
                          <Dropdown
                            trigger={['click']}
                            overlayStyle={{
                              color: '#fff',
                              maxHeight: 300,
                              overflowY: 'scroll',
                              width: 300
                            }}
                            menu={{
                              items: [
                                ...quickWordings.map((q: any) => {
                                  return {
                                    key: q,
                                    label: (
                                      <div style={{ display: 'flex', alignItems: 'center', color: '#fff' }}>
                                        <span
                                          style={{ paddingRight: 10, flex: 1, cursor: 'pointer' }}
                                          onClick={() => setText(q)}
                                        >
                                          {q}
                                        </span>
                                        <SendOutlined
                                          title="发送"
                                          style={{ color: 'rgb(107, 131, 255)', marginLeft: 'auto' }}
                                          onClick={() =>
                                            ws((_) =>
                                              _.send(JSON.stringify({ type: 'text_at_end_of_segment', content: q }))
                                            )
                                          }
                                        />
                                      </div>
                                    )
                                  }
                                }),
                                {
                                  key: 'setting',
                                  label: (
                                    <div
                                      onClick={() => {
                                        setQuickWordingsModal(true)
                                        setEditQuickWordings(quickWordings)
                                      }}
                                      style={{ color: 'rgb(107, 131, 255)', cursor: 'pointer' }}
                                    >
                                      设置快捷回复
                                    </div>
                                  )
                                }
                              ]
                            }}
                          >
                            <UnorderedListOutlined
                              style={{
                                position: 'absolute',
                                left: 8,
                                top: 7,
                                fontSize: 20,
                                cursor: 'pointer',
                                color: 'rgb(107, 131, 255)',
                                zIndex: 1
                              }}
                            />
                          </Dropdown>
                        )}

                        <Input
                          className="borderless shadowless"
                          disabled={!session?.room_id}
                          style={{
                            position: 'relative',
                            top: 2,
                            backgroundColor: '#28282C',
                            color: 'white',
                            height: '100%',
                            paddingLeft: 40
                          }}
                          value={text}
                          onPressEnter={send}
                          onChange={(e) => setText(e.target.value)}
                        />
                      </div>

                      <Button type="primary" disabled={!session?.room_id} style={controlButtonStyle} onClick={send}>
                        发送
                      </Button>

                      <Dropdown
                        disabled={!session?.room_id}
                        menu={{
                          items: permission.admin
                            ? [
                                {
                                  key: 'speed',
                                  label: (
                                    <Button
                                      danger
                                      disabled={!session?.room_id}
                                      style={controlButtonStyle}
                                      onClick={() => {
                                        if (!text) return
                                        ws((_) => _.send(JSON.stringify({ type: 'text_immediately', content: text })))
                                        setText('')
                                      }}
                                    >
                                      快
                                    </Button>
                                  )
                                },
                                {
                                  key: 'recoder',
                                  label: recorder ? (
                                    <Button
                                      style={controlButtonStyle}
                                      onClick={async () => {
                                        const reader = new FileReader()
                                        const src = new Promise<string>((r) => {
                                          reader.onloadend = () => {
                                            r(reader.result as string)
                                          }
                                        })
                                        reader.readAsDataURL(await new Promise(recorder.stop.bind(recorder)))
                                        setAudioSrc(await src)
                                        await new Promise(recorder.close.bind(recorder))
                                        setRecorder(undefined)
                                      }}
                                    >
                                      停止
                                    </Button>
                                  ) : (
                                    <Button
                                      onClick={async () => {
                                        try {
                                          const recorder = new Recorder({
                                            type: 'wav',
                                            sampleBits: 16,
                                            sampleRate: 16000,
                                            numChannels: 1,
                                            compiling: true
                                          })
                                          await new Promise(recorder.open.bind(recorder))
                                          recorder.start()
                                          setRecorder(recorder)
                                        } catch (e: any) {
                                          message.error(e?.message || e)
                                        }
                                      }}
                                      disabled={!session?.room_id}
                                      style={controlButtonStyle}
                                    >
                                      录音
                                    </Button>
                                  )
                                },
                                {
                                  key: 'takeover',
                                  label: (
                                    <Providing
                                      _={(p) => {
                                        p(wsContext, ws)
                                      }}
                                    >
                                      <VoiceTakeOver />
                                    </Providing>
                                  )
                                }
                              ]
                            : [
                                {
                                  key: 'takeover',
                                  label: (
                                    <Providing
                                      _={(p) => {
                                        p(wsContext, ws)
                                      }}
                                    >
                                      <VoiceTakeOver />
                                    </Providing>
                                  )
                                }
                              ]
                        }}
                        placement="topRight"
                      >
                        <Button type="primary" style={controlButtonStyle}>
                          操作
                        </Button>
                      </Dropdown>
                    </>
                  )
                }}
              </Render>
            </div>
            <div className="live-box box-live">
              <PlayerPanel ref={playRef} />
            </div>
          </div>
        </div>
      </Providing>
      {!session?.video_url && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            position: 'absolute',
            top: 12,
            right: 360,
            width: 'calc(100% - 760px)'
          }}
        >
          {
            <Render>
              {function Start() {
                const rid = useContext(roomId)
                const api = useAPI()
                const queryClient = useQueryClient()
                const pkg = useUserPackage()
                const [riskWarningShow, setRiskWarningShow] = useState(false)
                const [riskList, setRiskList] = useState([])
                const count = useRef<any>() // 尝试40次，约20分钟左右

                useEffect(() => {
                  if (prepare) {
                    timerRef.current = setInterval(() => {
                      if (count.current > 40) {
                        clearTimer()
                      }
                      count.current = (count.current || 0) + 1
                      toPlay()
                    }, 30000)
                  } else {
                    count.current = 0
                  }

                  return () => {
                    count.current = 0
                  }
                  // eslint-disable-next-line react-hooks/exhaustive-deps
                }, [prepare])

                const showProduct = () => {
                  setLimitModalOpen(true)
                  setBaseModalInfo(null)
                  setOnOk(null)
                }

                const toPlay = async () => {
                  setRiskWarningShow(false)
                  // check_available_time无法区分新用户和套餐过期用户
                  if (pkg?.expire_at_date && pkg?.expire_at_date < new Date()) {
                    setBaseModalInfo({
                      ...getSaveOrPlayInfo(432)
                    })
                    return
                  }
                  setStarting(true)
                  const data = await api.get(`/rooms/${rid}/setting`)

                  api.post(
                    `/rooms/${rid}/setting`,
                    {
                      ...data.data.setting,
                      ratio
                    },
                    { refreshMode: 'disabled' } as any
                  )
                  try {
                    if (preStream?.pre_generation_id) {
                      const res = (
                        await api.post(`/rooms/${rid}/url/check`, {
                          pre_generation_id: preStream.pre_generation_id
                        })
                      )?.data
                      if (res?.pre_generation_can_use !== 1) {
                        message.warning('直播地址已失效，请重新生产！')
                        setPreStream(undefined)
                        return
                      }
                    }

                    await api.get(`/rooms/${rid}/session/check_available_time`)
                    await api.post(`/rooms/${rid}/session`, {
                      pre_generation_id: preStream?.pre_generation_id
                    })
                    await queryClient.invalidateQueries(['/session'])
                  } catch (e: any) {
                    const status = e?.response?.status
                    const info = getSaveOrPlayInfo(status)
                    if (status === 432) {
                      setOnOk(() => {
                        return () => {
                          showProduct()
                        }
                      })
                      setBaseModalInfo({
                        ...info
                      })
                    } else if (status === 434) {
                      setOnOk(() => {
                        return async () => {
                          setBaseModalInfo(null)
                          setOnOk(null)
                          await api.post(`/rooms/${rid}/session`, {})
                          await queryClient.invalidateQueries(['/session'])
                        }
                      })
                      setBaseModalInfo(info)
                    } else if (status === 403) {
                      setBaseModalInfo(info)
                    } else {
                      setBaseModalInfo(info)
                    }
                  }
                }

                return (
                  <>
                    {prepare ? (
                      <StartButton loading={true} text="直播准备中, 请勿离开页面" />
                    ) : (
                      <StartButton
                        loading={starting}
                        onClick={async () => {
                          if (globalSession?.room_id) {
                            setLimitModalOpen(true)
                          } else {
                            const res = await api.get(`/rooms/${rid}/block_check`)
                            if (res.data?.length) {
                              setRiskWarningShow(true)
                              setRiskList(res.data)
                            } else {
                              toPlay()
                            }
                          }
                        }}
                      />
                    )}

                    <RiskWarning
                      onConfirm={() => {
                        toPlay()
                      }}
                      open={riskWarningShow}
                      onCancel={() => setRiskWarningShow(false)}
                      riskList={riskList}
                    />
                  </>
                )
              }}
            </Render>
          }

          <div style={{ width: 200, position: 'relative', top: -4, marginLeft: 20 }}>
            <Select
              className="fantasy-select input-with-hint"
              popupClassName="fantasy-popup"
              style={{ backgroundColor: '#171719', width: '100%' }}
              dropdownMatchSelectWidth={120}
              value={ratio}
              onChange={(v) => setRatio(v)}
              options={
                permission.admin
                  ? [
                      { value: 1, label: '720x1280' },
                      { value: 1.5, label: '1080x1920' },
                      { value: 2, label: '1440x2560' },
                      { value: 3, label: '2160x3840' }
                    ]
                  : [
                      { value: 1, label: '720x1280' },
                      { value: 1.5, label: '1080x1920' }
                    ]
              }
            />
            <div className="input-hint">分辨率</div>
          </div>
          <Input
            style={{ margin: '8px 0 8px 20px', paddingRight: 100, fontSize: 15 }}
            className="input-with-hint input"
            value={preStream?.pre_generation_rtmp_play_url}
            disabled={true}
            placeholder="rtmp:暂无"
          />
          <label
            style={{
              display: 'flex',
              alignItems: 'center',
              position: 'absolute',
              top: 18,
              right: 10,
              color: '#fff',
              cursor: 'pointer'
            }}
            onClick={() => (preStream?.pre_generation_rtmp_play_url ? null : getPreStream())}
          >
            {preStream?.pre_generation_rtmp_play_url ? (
              <Copy
                onClick={async () => {
                  const res = (
                    await api.post(`/rooms/${rid}/url/check`, {
                      pre_generation_id: preStream.pre_generation_id
                    })
                  )?.data
                  if (res?.pre_generation_can_use === 1) {
                    await navigator.clipboard.writeText(preStream.pre_generation_rtmp_play_url)
                    message.success('直播地址已复制到剪贴板')
                  } else {
                    message.warning('直播地址已失效，请重新生产！！')
                    setPreStream(undefined)
                  }
                }}
                style={{ flexShrink: 0, marginLeft: 8 }}
              />
            ) : (
              <>
                <label style={{ marginRight: 2 }}>预生成流地址</label>
                <Popover
                  className="popver-content"
                  content={
                    <div>
                      <div>预生成流地址，方便您提前做好直播配置。</div>
                      <div>配置完成后点击“开始直播”即可。五分钟</div>
                      <div>后未点击开播，需要重新预生产</div>
                    </div>
                  }
                >
                  <img className="icon-help-circle" src={require('../res/icon-help-circle.png')} alt="" />
                </Popover>
              </>
            )}
          </label>
        </div>
      )}
      {session?.video_url && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            position: 'absolute',
            top: 22,
            right: 360,
            width: 'calc(100% - 760px)',
            height: 42
          }}
        >
          <Button
            danger
            style={{ height: '100%' }}
            onClick={() => {
              setPrepare(false)
              setStarting(false)
              ws((_) => _.send(JSON.stringify({ type: 'request_end_session' })))
            }}
          >
            结束直播
            <Render>
              {function Counter() {
                const session = useSession()
                const [time, setTime] = useState('')
                useEffect(() => {
                  const startTime = session.description?.Data?.synthesize_started_at
                  if (!startTime || !session.server_time) {
                    setTime('')
                    return
                  }
                  const date = Date.parse(startTime)
                  const now = Date.parse(session.server_time)
                  const start = Math.floor((now - date) / 1000)
                  const subscription = interval(1000)
                    .pipe(
                      map((i) => i + 1 + start),
                      startWith(start),
                      map((s) => {
                        const hours = Math.max(0, Math.floor(s / 3600))
                        const minutes = Math.max(0, Math.floor((s % 3600) / 60))
                        const seconds = Math.max(0, Math.floor(s % 60))
                        if (hours) return `${padded(hours)}:${padded(minutes)}:${padded(seconds)}`
                        return `${padded(minutes)}:${padded(seconds)}`
                      })
                    )
                    .subscribe(setTime)
                  return () => subscription.unsubscribe()
                }, [session, setTime])

                return (
                  <span style={{ marginLeft: 8, display: 'inline-block', width: time.length * 12 }}>
                    {session?.video_url ? time : '00:00'}
                  </span>
                )

                function padded(n: number) {
                  if (n < 10) return '0' + n
                  return '' + n
                }
              }}
            </Render>
          </Button>
          <div
            style={{
              marginLeft: 20,
              width: 0,
              flex: 1,
              borderRadius: 8,
              height: '100%',
              backgroundColor: '#28282C',
              alignItems: 'center',
              color: 'white',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              whiteSpace: 'nowrap',
              paddingLeft: 12,
              paddingRight: 12
            }}
          >
            <div style={{ flex: 1, width: 0, height: '100%' }}>
              <div className="live-video-url">{session?.video_url || '直播准备中'}</div>
            </div>
            <Copy
              onClick={async () => {
                await navigator.clipboard.writeText(session?.video_url || '')
                message.success('直播地址已复制到剪贴板')
              }}
              style={{ flexShrink: 0, marginLeft: 8 }}
            />
          </div>
        </div>
      )}
      <div style={{ width: '100%', height: 0, flex: 1, display: 'none' }}>
        <WithPermission permission="admin">
          <div style={{ width: 0, flex: 1, display: 'flex', flexDirection: 'column' }}>
            <div className="section-title" style={{ marginTop: 40 }}>
              MK
            </div>
            <div
              style={{
                width: '100%',
                height: 0,
                flex: 1,
                backgroundColor: '#28282C',
                borderRadius: 8,
                overflow: 'auto',
                color: 'white'
              }}
            >
              {mkHistory.map((cmd, index) => {
                let cmdMsg =
                  cmd.command === 'addContentScript' && lastCmd === 'addContentScript' ? (
                    <span className="ctrl-his-line-content">{JSON.parse(JSON.stringify(cmd.params)).content}</span>
                  ) : (
                    <div key={index} className={['ctrl-his-line', cmd.source].join(' ')}>
                      {cmd.source === 'help' ? (
                        <div className="mk-help-line">
                          <div>
                            <b>
                              <QuestionCircleOutlined style={{ color: '#D4FF00' }} />
                              可以这样输入:
                            </b>
                          </div>
                          <li>语速快一点</li>
                          <li>音量小一点</li>
                          <li>换成男生的声音</li>
                          <li>换成小辉的声音</li>
                          <li>换一个卷发女主播</li>
                          <li>换成小红来讲</li>
                          <li>讲话风格更温柔些</li>
                        </div>
                      ) : cmd.source === 'mk-next' ? (
                        <div className="mk-help-line">
                          <div>
                            <b>
                              <InfoCircleOutlined style={{ color: '#D4FF00' }} />
                              指令识别失败或参数缺失，输入/或?符号查看帮助
                            </b>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <span>{cmd.time} </span>
                          {cmd.source === 'help' || cmd.source === 'me' ? (
                            cmd.command
                          ) : (
                            <span>
                              [
                              {cmd.command === 'changeVoiceMarkup'
                                ? '调整声音'
                                : cmd.command === 'changeAvatar'
                                ? '调整主播'
                                : cmd.command === 'addContentScript'
                                ? '切换内容'
                                : cmd.command === 'ignore'
                                ? '无动作或未识别'
                                : cmd.command === 'rapShow'
                                ? 'Rap表演'
                                : cmd.command === 'talkShow'
                                ? '脱口秀'
                                : '暂不支持' + cmd.command}
                              ]{' '}
                              {Object.keys(cmd.params).map((paramKey) => {
                                const paramTextMap: {
                                  [key: string]: string
                                } = {
                                  rate: '语速',
                                  pitch: '语调',
                                  volume: '音量',
                                  style: '风格',
                                  voice: '声音',
                                  gender: '性别',
                                  avatar: '主播',
                                  nickname: '昵称',
                                  hair: '发型',
                                  clothes: '着装',
                                  personality: '性格',
                                  content: '内容',
                                  theme: '文本风格'
                                }
                                type ObjectKey = keyof typeof cmd.params
                                return cmd.params[paramKey as ObjectKey]
                                  ? [
                                      <span>
                                        [{paramTextMap[paramKey] ? paramTextMap[paramKey] : paramKey}:
                                        {cmd.params[paramKey as ObjectKey]}]
                                      </span>
                                    ]
                                  : ''
                              })}
                            </span>
                          )}
                        </div>
                      )}
                    </div>
                  )

                lastCmd = cmd.command
                console.log(cmd, lastCmd, index)

                return cmdMsg
              })}
              <div ref={msgMKEnd} />
            </div>
            <div style={{ width: '100%', height: 38, marginTop: 8, display: 'flex', flexDirection: 'row' }}>
              <Render>
                {function Send() {
                  const [text, setText] = useState('')
                  const audioFile = useRef<any>()
                  const asrWs = useRef<any>()
                  const timer = useRef<any>()
                  const [recorder, setRecorder] = useState<typeof Recorder>()
                  const isDev = useMemo(() => !!window.location.host.match(/dev|localhost/), [])
                  const domains = useMemo(() => (isDev ? devDomains : prodDomains), [isDev])
                  const user = useContext(currentUser)

                  const startRecoder = async () => {
                    pause()
                    const recorder = new Recorder({
                      type: 'wav',
                      sampleBits: 16,
                      sampleRate: 16000,
                      numChannels: 1,
                      compiling: true
                    })
                    await new Promise(recorder.open.bind(recorder))
                    recorder.start()
                    setRecorder(recorder)
                  }

                  const stopRecoder = async () => {
                    const wavBlob: any = await new Promise(recorder.stop.bind(recorder))
                    const duration = recorder.envInLast - recorder.envInFirst
                    const file: any = new File([wavBlob], 'audio.wav', { type: 'audio/wav' })
                    file.duration = duration
                    audioFile.current = file
                    play()
                    uploadRecoder()
                    await new Promise(recorder.close.bind(recorder))
                    setRecorder(undefined)
                  }

                  const uploadRecoder = async () => {
                    if (audioFile.current.duration > 60 * 1000) {
                      message.warning('单次录音长度不能大于 1分钟，请重新录制')
                      return
                    }
                    try {
                      const { resource, upload_url } =
                        (
                          await api.post(
                            '/upload',
                            {
                              extension: 'wav'
                            },
                            {
                              refreshMode: 'disabled'
                            } as any
                          )
                        ).data || {}
                      if (!upload_url) {
                        throw new Error('failed to upload file')
                      }
                      await api.put(
                        upload_url.replace(/^http:\/\//, 'https://').replace('-internal', ''),
                        audioFile.current,
                        {
                          headers: { 'Content-Type': 'multipart/form-data' }
                        }
                      )
                      asrRecoder(resource)
                    } catch (err: any) {
                      message.error(err)
                    }
                  }

                  const asrRecoder = async (resource: { key: string }) => {
                    clearAsrWs()
                    const ws = new WebSocket(`wss:${domains.cms}/asr?token=${user.token}`)
                    timer.current && clearTimeout(timer.current)
                    timer.current = setInterval(() => {
                      ws.send(JSON.stringify({ type: 'keep-alive' }))
                    }, 10000)

                    ws.onmessage = (msg: MessageEvent) => {
                      const data = JSON.parse(msg.data)
                      const { sentence, type } = data
                      if (type === 'transcript-temp' || type === 'transcript') {
                        setText((text) => {
                          return `${text}${sentence}`
                        })
                      }
                      if (type === 'asr-session-finished') {
                        message.success('解析完成')
                      }
                    }

                    ws.onerror = () => {
                      clearAsrWs()
                    }

                    ws.onopen = () => {
                      setText('')
                      ws.send(JSON.stringify({ type: 'keep-alive' }))
                      ws.send(JSON.stringify({ type: 'new-asr-session', key: resource.key, id: uuid.v4() }))
                    }
                    asrWs.current = ws
                  }

                  const clearAsrWs = () => {
                    asrWs.current && asrWs.current.close()
                    asrWs.current = undefined
                    timer.current && clearTimeout(timer.current)
                    timer.current = null
                  }

                  const send = useEventCallback(() => {
                    if (!text) return
                    if (text === '?' || text === '/' || text === '怎么用' || text === '帮助') {
                      setMKHistory((h) => [...h, { time: now(), params: {}, command: text, source: 'me', status: '' }])
                      setMKHistory((h) => [
                        ...h,
                        {
                          time: now(),
                          command: 'help',
                          params: {},
                          source: 'help',
                          status: ''
                        }
                      ])
                    } else {
                      ws((_) => _.send(JSON.stringify({ type: 'mk', text })))

                      setMKHistory((h) => [...h, { time: now(), params: {}, command: text, source: 'me', status: '' }])
                    }
                    setText('')
                  })

                  return (
                    <>
                      <Input
                        className="borderless shadowless"
                        disabled={!session?.room_id}
                        style={{ backgroundColor: '#28282C', color: 'white', height: '100%', width: 0, flex: 1 }}
                        value={text}
                        onPressEnter={send}
                        onChange={(e) => setText(e.target.value)}
                      />

                      <Button
                        type="primary"
                        disabled={!session?.room_id}
                        style={controlButtonStyle}
                        onClick={() => (recorder ? stopRecoder() : startRecoder())}
                      >
                        {recorder ? '停止' : '语音'}
                      </Button>
                      <Button type="primary" disabled={!session?.room_id} style={controlButtonStyle} onClick={send}>
                        发送2
                      </Button>
                    </>
                  )
                }}
              </Render>
            </div>
          </div>
        </WithPermission>
      </div>
    </div>
  )
}

function now() {
  const date = new Date()
  return `${date.getHours()}:${padded(date.getMinutes())}:${padded(date.getSeconds())}`

  function padded(n: number) {
    if (n < 10) return '0' + n
    return '' + n
  }
}

const PlayerPanel = forwardRef((props, ref) => {
  const flvRef = useRef<any>()

  useImperativeHandle(ref, () => ({
    pause: () => {
      flvRef.current?.pause()
    },
    play: () => {
      flvRef.current?.play()
    }
  }))

  return (
    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
      <div className="live-video">
        <div className="section-title">实时画面</div>
        <Suspense>
          <Render>
            {function Session() {
              const globalSession = useSession()
              const rid = useContext(roomId)
              const session = useMemo(
                () => (!globalSession?.room_id || globalSession.room_id === rid ? globalSession : undefined),
                [globalSession, rid]
              )
              if (!session?.flv_video_url) return <></>
              return <FlvLivePlayer url={session?.flv_video_url} ref={flvRef} />
            }}
          </Render>
        </Suspense>
      </div>
      <Suspense>
        <Render>
          {function NewTab() {
            const globalSession = useSession()
            const rid = useContext(roomId)
            const session = useMemo(
              () => (!globalSession?.room_id || globalSession.room_id === rid ? globalSession : undefined),
              [globalSession, rid]
            )
            if (!session?.video_url) return <></>
            return (
              <Link to="/live" target="_blank" style={{ display: 'none' }}>
                <NewScreen style={{ padding: 10, backgroundColor: '#28282C', borderRadius: 8, marginTop: 14 }} />
              </Link>
            )
          }}
        </Render>
      </Suspense>
    </div>
  )
})

const StartButton: FC<{
  loading?: boolean
  disabled?: boolean
  onClick?: React.MouseEventHandler<HTMLButtonElement & HTMLAnchorElement>
  text?: string
}> = ({ loading, disabled, onClick, text }) => {
  return (
    <Button className="highlight-contentful" {...{ loading, disabled, onClick }}>
      {text || '开始直播'}
    </Button>
  )
}

export const RiskWarning: FC<{
  onConfirm: () => void
  onCancel: () => void
  open: boolean
  riskList: {
    metrics: string
    msg: string
    line_info?: {
      key: string
      value: string
    }[]
  }[]
}> = (props) => {
  const { onConfirm, open, onCancel, riskList } = props

  const baseInfo = useMemo(() => {
    if (!open) return undefined
    return {
      title: <div style={{ color: '#ff4d4f' }}>该剧本存在违规风险</div>,
      width: 700,
      desc: (
        <ul style={{ textAlign: 'left', marginBottom: 20 }}>
          {riskList.map((r) => (
            <li style={{ marginBottom: 16 }}>
              <div>
                {r.msg}
                {!!r.line_info?.length && (
                  <Dropdown
                    overlayStyle={{
                      color: '#fff',
                      border: '1px solid rgb(107, 131, 255)',
                      borderRadius: 5,
                      maxWidth: 600,
                      maxHeight: 300,
                      overflowY: 'scroll'
                    }}
                    trigger={['click']}
                    menu={{
                      items: r.line_info?.map((l) => ({
                        key: l.key,
                        label: <div style={{ color: '#fff', margin: '5px 6px' }}>{l.value}</div>
                      }))
                    }}
                    placement="bottom"
                  >
                    <span style={{ marginLeft: 6, color: 'rgb(107, 131, 255)', cursor: 'pointer' }}>查看详情</span>
                  </Dropdown>
                )}
              </div>
            </li>
          ))}
        </ul>
      ),
      footer: [
        <div>
          <Link to="../setting">
            <Button type="primary">前往修改</Button>
          </Link>
        </div>,
        <Button style={{ marginLeft: 30 }} onClick={onConfirm}>
          继续直播
        </Button>
      ]
    }
  }, [open, riskList, onConfirm])

  return <BaseModal info={baseInfo} onCancel={onCancel} />
}

export default LiveControl
