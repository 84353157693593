import { Button, Modal, Select, UploadFile } from 'antd'
import { RcFile } from 'antd/es/upload'
import Dragger from 'antd/es/upload/Dragger'
import { message } from 'antd/lib'
import { FC, useContext, useEffect, useMemo, useState } from 'react'
import { useAPI } from '../../../api'
import { resourceUrl } from '../../../global-vars'
import { ReactComponent as Spinner } from '../../../res/spin.svg'
import { ReactComponent as Warning } from '../../../res/warning.svg'

interface IProps {
  uploadType?: string
  onClose?: () => void
  onUploadSuccess?: (resource?: any) => void
  showTips?: boolean
  title?: string
  apiPath?: string
}

export const UploadVoiceModal: FC<IProps> = (props) => {
  const { uploadType, onClose, onUploadSuccess, showTips, title, apiPath } = props
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [resource, setResource] = useState<any>()
  const [language, setLanguage] = useState<string>()
  const urlSource = useContext(resourceUrl)
  const api = useAPI()
  const languageList = [
    {
      value: 'zh-CN',
      label: '中文'
    },
    {
      value: 'en-US',
      label: '英文'
    },
    {
      value: 'th-TH',
      label: '泰语'
    }
  ]

  useEffect(() => {
    setFileList([])
    setResource(undefined)
    setLanguage('zh-CN')
  }, [uploadType])

  const content = useMemo(() => {
    const uploading = fileList?.filter((f) => f.status === 'uploading').length
    if (resource) {
      return (
        <>
          <audio src={urlSource(resource.key)} controls />
        </>
      )
    }
    if (uploading) {
      return (
        <>
          <Spinner className="spin" />
          <p>音频上传中</p>
        </>
      )
    }
    return (
      <>
        <h4 className="white">将文件拖到此处，或点击上传按钮</h4>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Select
            className="fantasy-select"
            style={{
              backgroundColor: '#171719',
              width: 80,
              border: '1px solid #fff',
              borderRadius: 6,
              marginRight: 36
            }}
            onChange={(value) => setLanguage(value)}
            value={language}
            options={languageList}
            onClick={(e) => e.stopPropagation()}
          />
          <Button type="primary">上传</Button>
        </div>
        <p className="white">支持上传 wav/mp3 文件，单个文件大小不大于 300MB</p>
      </>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList, language, resource, urlSource])

  const uploadResource = async (file: RcFile, tag?: string, options?: any) => {
    const path = apiPath || `/upload`
    const { onError } = options || {}
    try {
      const segs = (file.name || '').split(/\./)
      const { resource, upload_url } =
        (
          await api.post(
            path,
            {
              extension: segs[segs.length - 1],
              tag
            },
            {
              refreshMode: 'disabled'
            } as any
          )
        ).data || {}
      if (!upload_url) {
        throw new Error('failed to upload file')
      }

      await api.put(upload_url.replace(/^http:\/\//, 'https://').replace('-internal', ''), file, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      setResource(resource)
      return resource
    } catch (err: any) {
      onError?.({ err })
      throw err
    }
  }

  const beforeUpload = async (file: RcFile) => {
    const size = file?.size || 0
    if (size / 1000 / 1000 > 300) {
      message.warning('单个文件大小不能大于 300MB')
      return false
    }
    return true
  }

  const useVoice = (split_mode: string) => {
    onUploadSuccess?.({
      ...resource,
      language,
      split_mode
    })
    setResource(undefined)
  }

  return (
    <Modal
      className="upload-modal"
      title={title || '上传音频'}
      open={!!uploadType}
      onCancel={onClose}
      footer={
        resource ? (
          <>
            <Button onClick={onClose}>取消</Button>
            <Button type="primary" onClick={useVoice.bind(this, 'asr')}>
              识别文本+切分音频
            </Button>
            <Button type="primary" onClick={useVoice.bind(this, 'silence')}>
              只切分音频
            </Button>
            <Button type="primary" onClick={useVoice.bind(this, 'split')}>
              说话人分离
            </Button>
          </>
        ) : null
      }
    >
      {showTips && (
        <div className="upload-modal-tips">
          <Warning />
          <h4>原文本将会被覆盖</h4>
          <p className="text">
            您当前剧本中已有话术内容，如果选择上传新音频，我们会将您新上传的音频自动识别成文本，您剧本中的原文本会被覆盖
          </p>
        </div>
      )}
      <Dragger
        name="upload"
        showUploadList={false}
        accept=".mp3,.wav"
        customRequest={({ onSuccess, onError, file, onProgress }) =>
          uploadResource(file as RcFile, uploadType, {
            onSuccess,
            onError,
            onProgress
          })
        }
        beforeUpload={async (file) => {
          return await beforeUpload(file)
        }}
        onChange={(info: any) => {
          if (info?.file?.status === 'error') {
            message.error(`${info.file.name} 上传失败`)
          }
          setFileList(info.fileList)
        }}
      >
        {content}
      </Dragger>
      {resource && (
        <>
          <div style={{ marginTop: 10, color: 'red' }}>
            使用“说话人分离”时, 请确保音频开头第一句话是主播在说话, 否则主播及助播声音的划分会颠倒.
          </div>
        </>
      )}
    </Modal>
  )
}
